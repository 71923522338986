import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppSettings, endToday, EnvironmentConfig, startToday } from '@bs/models';
import { AppSettingsService } from './app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class DateFactoryService {

  date: Date;
  settings: AppSettings;

  private datePipe: DatePipe;

  constructor(private appSettingsService: AppSettingsService, private config: EnvironmentConfig) {
    this.datePipe = new DatePipe(this.config.defaultLangCode);
    this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.settings = settings;
        this.datePipe = new DatePipe(settings.languageCode)
      }
    });
  }

  dateNow(): Date {
    return this.now().date;
  }

  dateToday(): Date {
    return this.today().date;
  }

  dateFrom(days: number): Date {
    return this.from(days, 'days').date;
  }

  format(format: string) {
    if (!this.date) {
      return this.datePipe.transform(this.now().date, format);
    }
    return this.datePipe.transform(this.date, format);
  }

  from(value, unit?: any): DateFactoryService {
    const from = this.formatFromDate(value, unit);
    this.date = new Date(from);
    return this;
  }

  // Maybe we can use this method with DATE.UTC
  private toObjectTransform(value) {
    const datum = new Date(new Date(new Date(startToday)).setDate(startToday.getDate() - value));
    const years = datum.getFullYear();
    const months = datum.getMonth();
    const date = datum.getDate();
    const hours = datum.getHours();
    const minutes = datum.getMinutes();
    const seconds = datum.getSeconds();
    const ms = datum.getMilliseconds();

    return {
      years,
      months,
      date,
      hours,
      minutes,
      seconds,
      ms
    }
  }

  // transform(value: string): Date {
  //   const from = timezone.tz(value, this.settings.timeZone).toObject();
  //   return new Date(
  //     from.years,
  //     from.months,
  //     from.date,
  //     from.hours,
  //     from.minutes,
  //     from.seconds
  //   );
  // }

  to(value, unit?: any): DateFactoryService {
    const to = this.formatToDate(value, unit);
    this.date = new Date(to);
    return this;
  }

  now(): DateFactoryService {
    this.date = new Date();
    return this;
  }

  today(): DateFactoryService {
    this.date = new Date(endToday);
    return this;
  }

  private formatFromDate(value: number, unit: any) {
    if (unit === 'hours') {
      const hoursToAdd = value;
      const currentDate = new Date();
      return new Date(currentDate.getTime() - hoursToAdd * 60 * 60 * 1000);
    }
    return new Date(new Date(new Date(endToday)).setDate(endToday.getDate() - value)).toLocaleDateString(this.settings.languageCode, {timeZone: this.settings.timeZone});
  }

  private formatToDate(value: number, unit?: any) {
    if (unit === 'hours') {
      const hoursToAdd = value;
      const currentDate = new Date();
      return new Date(currentDate.getTime() + hoursToAdd * 60 * 60 * 1000);
    }
    return new Date(new Date(new Date(endToday)).setDate(endToday.getDate() + value)).toLocaleDateString(this.settings.languageCode, {timeZone: this.settings.timeZone});
  }

  /**
   * @description it returns the Greenwich Mean Time corresponding to the timeZone selected
   *
   * @param {Date | string} date - date in local time from which GMT is got
   * @param isEnd boolean - start or end of day
   * @param isStart
   */
  // TODO: to find a way not to format twice in this method
  toTz(date: string | Date, isEnd = false, isStart = false): string {
    let dateCalc: Date;
    if (isEnd) {
      dateCalc = new Date(new Date(date).setHours(21, 59, 59, 0));
    } else if (!isStart) {
      dateCalc = new Date(new Date(new Date(date).setDate(new Date(date).getDate() - 1)).setHours(22, 0, 0, 0));
    } else {
      dateCalc = new Date(new Date(new Date(date).setDate(new Date(date).getDate() - 1)).setHours(22, 0, 0, 0));
      //timeZone.startOf('day'); was
    }
    return this.datePipe.transform(dateCalc, 'yyyy-MM-ddTHH:mm:ss.SSS\'Z\'', this.settings.timeZone);
  }
}

import { ICmsPageLanguage } from '../cms/page';
import { IdName, idNames } from '../common/id-name';
import { IGameExternalSystem } from './external-system';
import { IGameSubType } from './gameType';

export enum GameAttributeType {
  volatility = 3,
  lines = 6,
  themes = 17,
  features = 32,
  stakeLevels = 1
}

export namespace GameAttributeType {
  export function values(): Array<IdName<string>> {
    return idNames(GameAttributeType);
  }
}

export interface IGameAttribute {
  typeId: GameAttributeType;
  id: number;
  name: string;
}

export enum GameTag {
  new = 1,
  featured = 2
}

export namespace GameTag {
  export function values(): Array<IdName<string>> {
    return idNames(GameTag);
  }
}

export enum GameDeviceTypes {
  both = 3,
  desktop = 1,
  mobile,
}

export namespace GameDeviceTypes {
  export function values(): Array<IdName<string>> {
    return idNames(GameDeviceTypes);
  }
}

export interface IBaseGame {
  id: number;
  position: number;
  name: string;
  thumbnail: string;
  gameTypeId: number;
  gameSubType: IGameSubType;
  extId: string;
  wallpaperImage: string;
  deviceTypeId: number;
  forFun: boolean;
  description: string;
  provider?: IGameProvider;
  tags: Array<IGameTag>
  url?: string;
  creationDate: string;
  attributes?: Array<number>;
}

export interface IGameTag {
  id: number;
  name: string;
  icon: string;
}


export interface IGame extends IBaseGame {
  isHidden: boolean;
  descriptions: Array<ICmsPageLanguage>;
  bookmakerId?: number;
  sorting: Array<IGameSorting>;
}

export interface IGameSorting {
  deviceTypeId: number;
  position: number;
  countryId?: number;
}

export interface IGameProvider {
  bookmakerId?: number;
  id: number;
  name: string;
  slug: string;
  code: string;
  icon?: string;
  isHidden?: boolean;
  externalSystem?: IGameExternalSystem;
}

export interface IGameSearchRequest {
  gameTypeId?: number;
  deviceTypeId?: number;
  /**
   * @deprecated: brand not used
   */
  brandId?: number;
  p: number;
  pp: number;
}

<div class="grid p-fluid">
  @switch (options?.multiple) {
    @case (false) {
      <div class="col-12 md:col-6">
        <label>{{'bookmaker' | translate}}</label>
        <p-dropdown [options]="bookmakersOptions" [(ngModel)]="model.bookmakers" (onChange)="bookmakersChanged(model.bookmakers)" [disabled]="isDisabled"
        optionLabel="name" optionValue="id"></p-dropdown>
      </div>
    }
    @default {
      <div class="col-12 md:col-6">
        <label>{{'bookmakers' | translate}}</label>
        <p-multiSelect [options]="bookmakersOptions" [(ngModel)]="model.bookmakers" [defaultLabel]="'selectBookmaker' | translate" (ngModelChange)="bookmakersChanged($event)" [disabled]="isDisabled"
          optionLabel="name" optionValue="id" selectedItemsLabel="{0} items selected">
        </p-multiSelect>
      </div>
    }
  }

  <div class="col-12 md:col-6">
    <label>{{'currency' | translate}}</label>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon pre"><i class="pi pi-wallet"></i></span>
      <p-dropdown
        [showClear]="true" [placeholder]="'selectCurrency'"
        [options]="currencies" [(ngModel)]="model.currencyId" (onChange)="update(model)"
        [disabled]="isDisabled" optionValue="id" optionLabel="name">
      </p-dropdown>
    </div>
  </div>
</div>

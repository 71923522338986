import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { AppSettings, EnvironmentConfig, timezones } from '@bs/models';
import { AppSettingsService } from '@bs/services';

/**
 * transforms date value to a certain format, similar like date pipe of angular, formats to: '14:18:23'
 */
@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  /**
   * timezone string
   */
  timezone: string;
  /**
   * angular datepipe variable
   */
  private datePipe: DatePipe;
  /**
   * app settings variable
   */
  private settings: AppSettings;

  /**
   * The constructor sets up app settings and timezone
   */
  constructor(private config: EnvironmentConfig, private appSettingsService: AppSettingsService) {
    this.datePipe = new DatePipe(this.config.defaultLangCode);
    this.appSettingsService.appSettings$.subscribe({
      next: ({settings, valueChanged}) => {
        this.settings = settings;
        if (([null, 'timeZone', 'languageCode'].includes(valueChanged)) && settings.languageCode) {
          this.datePipe = new DatePipe(settings.languageCode);
          this.timezone = this.settings.timeZone;
        }
      }
    });
    this.timezone = this.settings.timeZone;
  }

  /**
   * returns the formated date to string
   * @param value {any} the date time value
   * @param format {string} the format that we want the time to be outputed
   */
  transform(value: Date | string, format='dd-MM-yyyy, HH:mm:ss'): string {
    const tx = timezones[this.timezone];

    if (tx) {
      const timezoneRegex = /\(GMT([+-]\d+:\d+)\)/;
      const match = tx.match(timezoneRegex)[1].replace(':', '');
      return value ? this.datePipe.transform(value, format, match, this.settings.languageCode) : '';
    }
    return value ? this.datePipe.transform(value, format, null, this.settings.languageCode) : '';
  }
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 *  This pipe overrides the default date pipe from angular,
 */
@Pipe({
  name: 'date'
})
export class DateProxyPipe extends DatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
    super(translate.currentLang);
  }

  override transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  override transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;

  override transform(value: string | null | undefined, format = 'mediumDate', timezone?: string, locale?: string): string | null {
    if (!value) {
      return '';
    }

    if (!locale) {
      locale = this.translate.currentLang
    }

    return super.transform(value, format, timezone, locale);
  }
}

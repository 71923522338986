<p-dropdown
  class="mr-2 inline"
  [options]="bookmakers"
  [(ngModel)]="bookmaker"
  [disabled]="isDisabled"
  [filter]="true" filterBy="name"
  [required]="true"
  (ngModelChange)="update()"
  optionLabel="name">
</p-dropdown>

import { Pipe, PipeTransform } from '@angular/core';

/**
 * the value found in the values array
 */
@Pipe({name: 'find'})
export class FindPipe<T> implements PipeTransform {

  /**
   *
   * @param values
   * @param predicate
   * @param extras
   */
  transform(values: Array<T>, predicate: keyof T | object | ((value: T, extras: string) => boolean), extras = ''): T | undefined {
    if (!values) {
      return undefined;
    }

    if (typeof predicate === 'function') {
      return values.find(value => predicate(value, extras));
    }


    if (typeof predicate !== 'string') {

      const [key, val] = Object.entries(predicate)[0];

      return values.find((x: T) => (x[key as keyof T]) === val);
    }

    return undefined;
  }
}

<div class="p-inputgroup flex-column md:flex-row">

  @switch (mode) {
    @default {
      <p-dropdown class="preset" [(ngModel)]="selectedPreset" (ngModelChange)="preset($event)" [options]="presets" optionLabel="key"></p-dropdown>
    }
    @case ('buttons') {
      @for (button of presets.slice(0, -1); track button) {
        <button class="mr-0 border-noround mb-2 md:mr-1 md:mb-0 buttons" pButton [label]="button.key" (click)="preset(button)" type="button"></button>
      }
    }
  }

  @if (!options?.hideCal) {
    <p-calendar
      [(ngModel)]="model.from"
      (ngModelChange)="update({from:$event, to:model.to})"
      [disabled]="disabled"
      [placeholder]="'date-from'| translate"
      [maxDate]="model.to"
      [minDate]="minDate"
      [showIcon]="true"
      [showTime]="showTime"
      class="date-from"
      dateFormat="dd/mm/yy">
    </p-calendar>
  }
  @if (!options?.hideCal) {
    <p-calendar
      [(ngModel)]="model.to"
      (ngModelChange)="update({to:$event, from:model.from})"
      [disabled]="disabled"
      [placeholder]="'date-to'| translate"
      [minDate]="model.from"
      [maxDate]="maxDate"
      [showIcon]="true"
      [showTime]="showTime"
      class="date-to"
      dateFormat="dd/mm/yy">
    </p-calendar>
  }
</div>

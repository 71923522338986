{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "sportId",
      "label": "sportId",
      "type": "select",
      "autoselect": true,
      "values": [
        {
          "id": 1,
          "name": "Soccer",
          "selected": true
        },
        {
          "id": 2,
          "name": "Tennis"
        }
      ]
    },
    {
      "type": "select",
      "name": "webAppId",
      "label": "tournamentEventTypes",
      "noReset": true,
      "autoselect": true,
      "validations": {
        "required": true
      }
    },
    {
      "name": "categoryId",
      "label": "categoryId",
      "type": "select",
      "autoselect": true,
      "values": [
        {
          "id": 1,
          "name": "England",
          "selected": true
        },
        {
          "id": 2,
          "name": "Italy"
        }
      ]
    },
    {
      "label": "name",
      "name": "name",
      "type": "text",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      }
    }
  ]
}

<!-- <pre>{{ model | json }}</pre> -->
<!-- <pre>{{ typeId | json }}</pre> -->
<!-- <pre>{{ date | json }}</pre> -->
<!-- <pre>{{ afterX | json }}</pre> -->

<div class="flex align-items-end gap-2">
  <div class="flex-1">
    <p-dropdown [options]="optionsValues" [(ngModel)]="typeId" (ngModelChange)="update()" [placeholder]="'select' | translate" optionLabel="name" [autoDisplayFirst]="false" [disabled]="isDisabled"></p-dropdown>
  </div>

  <div class="max-w-16rem flex">
    @if (isDateInputVisbile) {
      <p-calendar [(ngModel)]="date" (ngModelChange)="update()" [showIcon]="true"></p-calendar>
    }
    @if (isNumberInputVisible) {
      <p-inputNumber [(ngModel)]="afterX" (ngModelChange)="update()" [useGrouping]="false"></p-inputNumber>
    }
  </div>
</div>

<html>

  <head>
    <meta charset="utf-8">
    <title>{{code}}</title>
  </head>

  <body style="font-family: sans-serif;font-size: 11px;">

    <ng-template #taxType1>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'netStake' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">{{(ticket.tax.stake - ticket.tax.stakeTax) | displayCurrencyConversion: ticket.amounts.rate}}</td>
      </tr>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'stakeTax' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">{{ticket.tax.stakeTax | displayCurrencyConversion: ticket.amounts.rate}}</td>
      </tr>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winningTax' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">{{ticket.tax.potentialWinTax | displayCurrencyConversion: ticket.amounts.rate}}</td>
      </tr>
    </ng-template>
    <ng-template #taxType2>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winTax' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">
          {{ticket.tax.winTax | displayCurrencyConversion: ticket.amounts.rate}}
        </td>
      </tr>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winTaxCalculation' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">
          ({{ticket.tax.potentialWinTax | displayCurrencyConversion: ticket.amounts.rate}} - {{ticket.amounts.stake  | displayCurrencyConversion: ticket.amounts.rate }}) * {{(ticket.tax.winTaxPercentage | number : '1.2-2')}}%
        </td>
      </tr>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winningWithoutTax' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">
          {{ticket.tax.potentialWinTaxNet | displayCurrencyConversion: ticket.amounts.rate}}
        </td>
      </tr>
    </ng-template>
    <ng-template #taxType3>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winTax' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">
          {{ticket.tax.winTax | displayCurrencyConversion: ticket.amounts.rate}}
        </td>
      </tr>
      <tr>
        <td width="50%" align="left" style="font-size: 12px">{{'winTaxCalculation' | translate}}</td>
        <td width="50%" align="right" style="font-size: 12px">
          ({{ticket.tax.potentialWinTax | displayCurrencyConversion: ticket.amounts.rate}}) * {{(ticket.tax.winTaxPercentage | number : '1.2-2')}}%
        </td>
      </tr>
    </ng-template>
    <ng-template #taxType4></ng-template>
    <ng-template #defaultMatchTpl>
      <table width="100%" border="1" cellspacing="0" cellpadding="0">
        <tbody>
          <tr>
            <td colspan="5" align="center" style="text-transform: uppercase">{{ ticket?.bettingGroupId | formValues:{values: 'bettingGroups'}  }} - {{ ticket?.bettingTypeId | formValues:{values: 'bettingTypes'} }}</td>
          </tr>
          <tr>
            <td colspan="2" align="center">{{'events' | translate}}</td>
            <td width="10%" align="center">{{'outcomeShort' | translate}}</td>
            <td width="10%" align="center">O</td>
          </tr>
          @for (odd of ticket?.odds; track odd) {
            <tr style="font-size: 12px">
              <td width="65%" align="left">
                {{ odd.sport }} - {{ odd.category }} - {{ odd.tournament }}
                <br>
                  {{odd.eventName}} - {{ odd.oddTypeDescription }}
                </td>
                <td width="20%" align="center">
                  {{odd.eventDate | localizedDate:'yyyy/MM/dd HH:mm'}}
                </td>
                <td align="center">{{ odd.outcome }} {{odd.specialBetValue}}</td>
                <td align="center">{{ odd.odd | oddFormat:oddFormat:true }}</td>
              </tr>
            }
          </table>
        </ng-template>

        <ng-template #singleOrMultipleTpl let-tax>
          <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <ng-template [ngTemplateOutlet]="tax ? SingleOrMultipleTaxTableTpl : SingleOrMultipleNoTaxTableTpl"></ng-template>
            <tr>
              <td colspan="2" align="center">
                <span [innerHTML]="'ticket-receipt-disclaimer' | translate"></span>
              </td>
            </tr>
          </table>
        </ng-template>

        <ng-template #SingleOrMultipleTaxTableTpl>
          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'total-odds' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.multiplier | oddFormat:oddFormat:true}}
            </td>
          </tr>
          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'stake' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.amounts.stake | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>
          @if (ticket.amounts.potentialBonus > 0) {
            <tr>
              <td width="50%" align="left" style="font-size: 12px">{{'total-bonus' | translate}}</td>
              <td width="50%" align="right" style="font-size: 12px">
                {{ticket.amounts.potentialBonus | displayCurrencyConversion: ticket.amounts.rate}}
              </td>
            </tr>
          }
          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'potentialNetWin' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.tax.potentialWinTaxNet | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'taxType' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.tax.taxType }}
            </td>
          </tr>
          @switch (ticket.tax.taxTypeId) {
            @case (1) {
              <ng-container [ngTemplateOutletContext]="{$implicit: ticket}" [ngTemplateOutlet]="taxType1"></ng-container>
            }
            @case (2) {
              <ng-container [ngTemplateOutletContext]="{$implicit: ticket}" [ngTemplateOutlet]="taxType2"></ng-container>
            }
            @case (3) {
              <ng-container [ngTemplateOutletContext]="{$implicit: ticket}" [ngTemplateOutlet]="taxType3"></ng-container>
            }
            @case (4) {
              <ng-container [ngTemplateOutletContext]="{$implicit: ticket}" [ngTemplateOutlet]="taxType4"></ng-container>
            }
          }
        </ng-template>

        <ng-template #SingleOrMultipleNoTaxTableTpl>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'total-odds' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.multiplier | oddFormat:oddFormat:true}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'total-stake' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.amounts.stake | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          @if (ticket.amounts.potentialBonus > 0) {
            <tr>
              <td width="50%" align="left" style="font-size: 12px">{{'total-bonus' | translate}}</td>
              <td width="50%" align="right" style="font-size: 12px">
                {{ticket.amounts.potentialBonus | displayCurrencyConversion: ticket.amounts.rate}}
              </td>
            </tr>
          }

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'total-potential-winnings' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.amounts.potentialWin| displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>
        </ng-template>

        <ng-template #systemOrIntegralTpl let-systemTax>
          <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <ng-template [ngTemplateOutlet]="systemTax ? SystemOrIntegralTaxTableTpl : SystemOrIntegralNoTaxTableTpl"></ng-template>
              <tr>
                <td colspan="2" align="center">
                  <span [innerHTML]="'ticket-receipt-disclaimer' | translate"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>

        <ng-template #SystemOrIntegralTaxTableTpl></ng-template>

        <ng-template #SystemOrIntegralNoTaxTableTpl>
          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'stake' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.amounts.stake | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'minOdds' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.minOdd | oddFormat:oddFormat:true}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'maxOdds' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.maxOdd | oddFormat:oddFormat:true}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'min-bonus' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.minBonus | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'max-bonus' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.maxBonus | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'minWinnings' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.minWin | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

          <tr>
            <td width="50%" align="left" style="font-size: 12px">{{'maxWinnings' | translate}}</td>
            <td width="50%" align="right" style="font-size: 12px">
              {{ticket.derived.maxWin | displayCurrencyConversion: ticket.amounts.rate}}
            </td>
          </tr>

        </ng-template>

        <div id="printable" style="width:80mm">
          <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <th colspan="2">
                  <img src="{{host}}/assets/images/logo-black.svg" alt="logo" width="100%" height="80px">
                </th>
              </tr>
            </tbody>
          </table>
          <br>
            <table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody>
                <tr>
                  <td align="center" colspan="2" style="font-size: 12px">
                    {{domain}}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td align="center" width="40%">
                    <p>{{'coupon-code' | translate}}</p>
                  </td>
                  <td align="center" width="60%">
                    {{code}}
                  </td>
                </tr>
                <tr style="font-size: 12px">
                  <td align="center" width="40%">{{'username' | translate}}</td>
                  <td align="center" width="60%">
                    {{ticket.username}}
                  </td>
                </tr>
              </tbody>
            </table>
            <br>
              <ng-container [ngTemplateOutlet]="defaultMatchTpl"></ng-container>
              @switch (ticket.bettingTypeId) {
                @case (1) {
                  <ng-container [ngTemplateOutletContext]="{$implicit: ticket.tax}" [ngTemplateOutlet]="singleOrMultipleTpl"></ng-container>
                }
                @case (2) {
                  <ng-container [ngTemplateOutletContext]="{$implicit: ticket.tax}" [ngTemplateOutlet]="singleOrMultipleTpl"></ng-container>
                }
                @case (3) {
                  <ng-container [ngTemplateOutletContext]="{$implicit: ticket.systemTax}" [ngTemplateOutlet]="systemOrIntegralTpl"></ng-container>
                }
                @case (4) {
                  <ng-container [ngTemplateOutletContext]="{$implicit: ticket.systemTax}" [ngTemplateOutlet]="systemOrIntegralTpl"></ng-container>
                }
              }

              <div style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
                @if (isReprint) {
                  <h3>{{'rePrintDisclaimer' | translate}}</h3>
                }
                @if (config.features.betSlip?.showQrCode) {
                  <qrcode [qrdata]=qrUrl [allowEmptyString]="false" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
                  <!--      <canvas #qrcode qrCode="{{qrUrl}}" style="display: none;" [qrCodeMargin]="0"></canvas>-->
                  <!--      <img [src]="qrcode.toDataURL()" alt="code" height="80px" style="margin-bottom: 20px;"/>-->
                }

                @if (config.features.betSlip?.showBarCode) {
                  <ngx-barcode6 [bc-value]="ticket.code" [bc-width]="1" [bc-height]="70" [bc-font-size]="12" [bc-display-value]="true"></ngx-barcode6>
                }
              </div>

            </div>

          </body>
        </html>

import { AfterViewInit, Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { EnvironmentConfig, ICurrency, ITicketBooking } from '@bs/models';
import { CatalogService } from '@bs/services';
import { WINDOW } from '@bs/universal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { BsCommonModule } from '@bs/common';

@Component({
  selector: 'booking-bet-receipt',
  templateUrl: './booking-bet-receipt.component.html',
  styleUrls: ['./booking-bet-receipt.component.scss'],
  imports: [
    QRCodeModule,
    NgxBarcode6Module,
    TranslateModule,
    BsCommonModule
  ],
  standalone: true
})
export class BookingBetReceiptComponent implements AfterViewInit, OnInit {
  loaded: EventEmitter<void> = new EventEmitter<void>();
  data: ITicketBooking;
  host: string;
  defaultCurrency: ICurrency;
  qrUrl: string;

  constructor(catalog: CatalogService, @Inject(WINDOW) window: Window, private translate: TranslateService,
              public config: EnvironmentConfig) {
    this.defaultCurrency = catalog.currentCurrency;
    this.host = window.location.origin;
  }

  ngAfterViewInit(): void {
    this.loaded.emit();
  }

  ngOnInit(): void {
    this.qrUrl = `${this.host}/${this.translate.currentLang}/sportbook/prematch?bookingCode=${this.data.code}`;
  }
}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "sports",
      "label": "sports",
      "type": "select",
      "autoselect": true
    }
  ]
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Ages, endToday, EnvironmentConfig, FormInterface, IInput, IValidationTypes } from '@bs/models';
import { Observable, Subject } from 'rxjs';
import { debounceTime, first, share, switchMap } from 'rxjs/operators';
import { IMobilePrefix } from '../models/mobile-prefix';
import { birthPlaceValidator } from '../validators/birth-place.validator';
import { currenciesAmountValidator } from '../validators/currencies-amount.validator';
import { deliveriesValidator } from '../validators/deliveries.validator';
import { geoInfosValidator } from '../validators/geoInfos.validator';
import { isPresent } from '../validators/is-present.validator';
import { mailFormat } from '../validators/mail-format.validator';
import { MobilePrefixValidator } from '../validators/mobile-prefix.validator';
import { urlFormat } from '../validators/url.validator';

/*const majority: Map<string, number> = new Map<string, number>([
  ['it', 18]
]);*/

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  readonly url = `${this.config.api.identity}/validate`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
    if (config.minAge) {
      Ages.adult = new Date(new Date(endToday).setFullYear(endToday.getFullYear() - config.minAge))
    }
  }

  get minAge(): number {
    return this.config.minAge || 18;
  }

  /* ASYNC VALIDATORS */
  cpfValidator(cpf: string): Promise<any> {
    return this.http.post<string>(`${this.url}/cpf`, {cpf}).toPromise()
  }

  usernameExists(username: string): Promise<ValidationErrors> {
    return this.http.post<ValidationErrors>(`${this.url}/username`, {username}).toPromise();
  }

  emailExists(email: string): Promise<ValidationErrors> {
    return this.http.post<ValidationErrors>(`${this.url}/email`, {email}).toPromise();
  }

  phoneExists(mobilePrefix: IMobilePrefix): Promise<ValidationErrors> {
    if (mobilePrefix) {
      return this.http.post<ValidationErrors>(`${this.url}/phone`, {phone: mobilePrefix.phone, prefixid: mobilePrefix.prefix.id}).toPromise();
    } else {
      return Promise.reject('noMobilePrefixProvided');
    }

  }

  requestMobileOtp(phone: IMobilePrefix, validationType: 'sms' | 'ivr'): Promise<number> {
    return this.http.post<number>(`${this.url}/otp/send`, {phone, validationType}).toPromise();
  }

  /**
   * check the otp is valid or not
   * @param data otp 4digits, transaction string, phone as Imobileprefix type
   */
  checkMobileOtp(data: { otp: string, transactionId: string, phone: IMobilePrefix, deliveryId?: number }): Promise<any> {
    return this.http.post<any>(`${this.url}/otp/check`, data).toPromise();
  }

  requestEmailActivation(deliveryId: number): Promise<any> {
    return this.http.post<any>(`${this.url}/email/send`, {deliveryId}).toPromise();
  }

  checkEmailActivation(token: string): Promise<any> {
    return this.http.post<any>(`${this.url}/email/check`, {token}).toPromise();
  }

  buildForm(config: FormInterface, fb: FormBuilder): FormGroup {

    const form: FormGroup = fb.group({});

    const cycle = config.inputs ? config.inputs : config.fieldset.flatMap(f => f.inputs);

    cycle.filter(e => e.name).forEach(i => {
      form.setControl(i.name, fb.control({
        value: '',
        disabled: i?.disabled
      }, this.buildValidator(i), this.buildAsyncValidator(i)));
    });

    // manage exclusive fields
    cycle.filter(e => e.exclusive).forEach(i => {
      form.get(i.name).valueChanges.subscribe({
        next: v => {

          Object.entries(form.controls).forEach(([k, control]) => {
            if (k !== i.name) {
              if (v) {
                control.disable({onlySelf: true, emitEvent: false});
              } else {
                control.enable({onlySelf: true, emitEvent: false});
              }
            }
          });
        }
      });
    });

    return form;
  }

  buildValidator(input: IInput): ValidatorFn {
    const validations: Partial<IValidationTypes> = input.validations;

    if (!validations) {
      return null;
    }

    const validators = [];

    if (validations.mailFormat) {
      validators.push(mailFormat);
    }

    if (validations.urlFormat) {
      validators.push(urlFormat);
    }

    if (validations.geoInfos) {
      validators.push(geoInfosValidator(this.config.features.hideProvinces));
    }

    if (validations.mobilePrefixValidator) {
      let regex;
      if (this.config.features?.validators?.phone) {
        regex = new RegExp(this.config.features.validators.phone);
      } else {
        regex = new RegExp(validations.mobilePrefixValidator)
      }
      validators.push(MobilePrefixValidator.number(regex));
    }

    if (validations.maxLength) {
      validators.push(Validators.maxLength(validations.maxLength));
    }

    if (validations.minLength) {
      validators.push(Validators.minLength(validations.minLength));
    }

    if (validations.max) {
      validators.push(Validators.max(validations.max));
    }

    if (validations.min) {
      validators.push(Validators.min(validations.min));
    }

    if (validations.required) {
      validators.push(Validators.required);
    }

    if (validations.requiredTrue) {
      validators.push(Validators.requiredTrue);
    }

    if (validations.pattern) {
      validators.push(Validators.pattern(new RegExp(validations.pattern)));
    }

    if (validations.matchPassword) {
      validators.push(matchPassword);
    }

    if (validations.password) {
      const password = this.config.features.validators.password;
      validators.push(Validators.pattern(password || validations.password));
    }

    if (validations.adult) {
      validators.push(ageValidator(validations.adult));
    }

    if (validations.japanese) {
      validators.push(noJapaneseCharactersValidator())
    }

    if (validations.birthPlace || input.type === 'birth-place') {
      validators.push(birthPlaceValidator);
    }

    if (validations.delivery) {
      validators.push(deliveriesValidator(validations.delivery));
    }

    if (validations.currenciesAmount) {
      validators.push(currenciesAmountValidator);
    }

    return Validators.compose(validators);
  }

  buildAsyncValidator(input: IInput): AsyncValidatorFn {

    if (input.asyncValidator === 'cpfValidator') {
      return cpfValidator(this);
    }

    if (input.asyncValidator === 'emailExists') {
      return emailExists(this, input.options?.inverse);
    }

    if (input.asyncValidator === 'usernameExists') {
      return usernameExists(this);
    }

    if (input.asyncValidator === 'phoneExists') {
      return phoneExists(this);
    }
  }
}

export const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const PHONE_REGEXP = /^(\+[1-9]{1,3})*?[0-9]{6,13}$/;

  const v: string | IMobilePrefix = control.value;

  if (typeof v === 'string') {
    return PHONE_REGEXP.test(v) ? null : {'phone-format': true};
  } else {

    if (v.phone === '') {
      control.markAsPristine();
      return null;
    }

    return PHONE_REGEXP.test(`+${v.prefix.prefix}${v.phone}`) ? null : {'phone-format': true};
  }
};

export const matchPassword: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  if (!control.parent) {
    return null;
  }

  const passwordCtrl = control.parent.get('newPassword') || control.parent.get('password');
  const password = passwordCtrl.value;
  const passwordMatch = control.value;
  const currentObservers = passwordCtrl.valueChanges['observers'];

  if (passwordMatch.length === 1 && currentObservers.length <= 1) {
    passwordCtrl.valueChanges.subscribe({
      next: () => {
        control.updateValueAndValidity({onlySelf: true, emitEvent: false});
      }
    });
  } else if (currentObservers.length > 1) {
    currentObservers.reverse().pop();
  }

  // console.error('obs: ' + currentObservers.length);

  return password === passwordMatch ? null : {validateEqual: true};
};

export function ageValidator(age = 18): ValidatorFn {
  return (control: AbstractControl) => {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const today = new Date();
    const ageLimit = new Date(new Date(today.getFullYear() - age, today.getMonth(), today.getDate()).setHours(0, 0, 0, 0)).getTime();
    const ageEntered = new Date(new Date(control.value).setHours(0, 0, 0, 0)).getTime();

    if (ageEntered > ageLimit) {
      return {isMinor: true};
    }
    return null;
  };
}

export function noJapaneseCharactersValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = /[\u3040-\u30FF\u31F0-\u31FF\uFF66-\uFF9F\u4E00-\u9FFF]/.test(control.value);
    return forbidden ? { 'japanese-characters-not-allowed': { value: control.value } } : null;
  };
}

/* ASYNC VALIDATORS */
function debounceValidator(asyncValidator: AsyncValidatorFn, time = 500): (c: AbstractControl) => Observable<any> {
  const subject: Subject<AbstractControl> = new Subject();

  const obs: Observable<any> = subject.pipe(debounceTime(time), switchMap(abstractControl => asyncValidator(abstractControl)), share());

  obs.subscribe();

  return (c: AbstractControl) => {
    subject.next(c);
    return obs.pipe(first());
  };
}

export function emailExists(service: ValidationService, inverse = false): AsyncValidatorFn {
  return debounceValidator((control: AbstractControl): Promise<ValidationErrors | null> => {
    if (control.pristine) {
      return Promise.resolve(null);
    }
    const v: string = control.value;
    if (inverse) {
      return service.emailExists(v).then(() => null).catch(() => ({'email-not-exist': true}));
    } else {
      return service.emailExists(v).then(() => ({'email-exist': true})).catch(() => null);
    }
  });
}

export function cpfValidator(service: ValidationService, inverse = false): AsyncValidatorFn {
  return debounceValidator((control: AbstractControl): Promise<ValidationErrors | null> => {
    if (control.pristine) {
      return Promise.resolve(null);
    }

    const cpf = control.value;

    if (inverse) {
      return service.cpfValidator(cpf).then(() => null).catch((value) => {
        const errorKey = value.toLowerCase().replace(/\s+/g, '-'); // manipola il valore restituito dal servizio
        return {[errorKey]: true}
      });
    } else {
      return service.cpfValidator(cpf).then((value) => {
        const errorKey = value.toLowerCase().replace(/\s+/g, '-'); // manipola il valore restituito dal servizio
        return {[errorKey]: true}
      }).catch(() => null);
    }
  });
}

export function usernameExists(service: ValidationService, inverse = false): AsyncValidatorFn {
  return debounceValidator((control: AbstractControl): Promise<ValidationErrors | null> => {
    if (control.pristine) {
      return Promise.resolve(null);
    }
    const v: string = control.value;
    if (inverse) {
      return service.usernameExists(v).then(() => null).catch(() => ({'username-not-exist': true}));
    } else {
      return service.usernameExists(v).then(() => ({'username-exist': true})).catch(() => null);
    }
  });
}

export function phoneExists(service: ValidationService, inverse = false): AsyncValidatorFn {
  return debounceValidator((control: AbstractControl): Promise<ValidationErrors | null> => {
    if (control.pristine) {
      return Promise.resolve(null);
    }
    const v: IMobilePrefix = control.value;
    if (inverse) {
      return service.phoneExists(v).then(() => null).catch(() => ({'phone-not-exist': true}));
    } else {
      return service.phoneExists(v).then(() => ({'phone-exist': true})).catch(() => null);
    }
  });
}

/*export const ibanFormat: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  const IBAN_REGEXP = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}/;

  if (isPresent(Validators.required(control))) {
    return null;
  }

  if ((control.value.length <= 15 || !IBAN_REGEXP.test(control.value))) {
    return {'iban-format': true};
  }
  return null;
};

*/

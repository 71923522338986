import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseCausalGroups } from '@bs/forms';

/**
 * The component contains the causal, and causalGroup field
 */
@Component({
  selector: 'causal-groups',
  templateUrl: './causal-groups.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CausalGroupsComponent),
      multi: true
    }
  ]
})
export class CausalGroupsComponent extends BaseCausalGroups {
}

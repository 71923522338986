import { IdName, idNames } from '../common/id-name';

export interface IReferralLinkCreateRequest {
  networkId?: number;
  name: string;
  expiringDate: string,
}

export interface IReferralLinkSearchRequest {
  statusId?: number;
  networkId?: number;
  displayCurrencyId?: number;
  dateFrom?: Date;
  dateTo?: Date;
  p?: number;
  pp?: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IReferralLink {
  name: string;
  link: string;
  code: string;
  creationDate: string;
  expiringDate: string,
  statusId: number;
  viewers: number;
  registrations: number;
  ftd: number;
  //network?: Partial<IAccount>
}

export enum ReferralLinkStatus {
  active = 1,
  expired,
  suspended,
  deleted
}

export namespace ReferralLinkStatus {
  export function values(): Array<IdName<string>> {
    return idNames(ReferralLinkStatus);
  }
}

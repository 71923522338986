import { IdName, idNames } from '../common/id-name';

export enum OddStatus {
  open,
  lost,
  won,
  refund
}

export namespace OddStatus {
  export function values(): Array<IdName<any>> {
    return idNames(OddStatus);
  }
}

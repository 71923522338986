import { IAuthor } from '../common/author';
import { IdName, idNames } from '../common/id-name';
import { ISearchBaseSportsBookFilters } from './common';
import { IMarketSport } from './market-sport';
import { SettlementTimeTypes } from './settlement-time-types';
import { SettlementTypes } from './settlement-types';

export enum MarketTypes {
  match = 1,
  goalsKeeper = 2,
  cards = 3,
  corners = 4,
  shots = 5,
  outrights = 10,
}

export namespace MarketTypes {
  export function values(): Array<IdName<string>> {
    return idNames(MarketTypes);
  }
}

export interface IMarket {
  id: number;
  typeId: number; // tipo di mercato normale, marcatori, outright
  // selections: IMarketSelection[];
  templateWebId: number; // tipo di visualizzazione per il frontend
  name: string; // in lingua richiesta
  code: string;
  description?: string; // in lingua richiesta
}

export interface IMarketSelection {
  id: number; // 1x2 calcio e 1x2 basket hanno lo stesso Id
  marketSport: IMarketSport;
  outcomeId: number;
  outcome: string;
  fastBetCode: string;
  specifierId?: number;
  specifier?: string;
  competitorId?: number;
  competitor?: string;
  lastUpdate: string;
  odd: IMarketSelectionOdd;
}

export interface IMarketSelectionOdd {
  id: number;
  value: number;
  newValue?: number;
  bettingStatusId: number;
  playability: number;
  results?: IMarketSelectionOddResult[];
}

export interface IMarketSelectionOddResult {
  id: number;
  oddId?: number;
  creationDate: string;
  statusId: number;
  author: IAuthor;
}

export interface IMarketOption {
  settlementTypeId: SettlementTypes;
  settlementTimeTypeId: SettlementTimeTypes;
  settlementTime: number;
}

export interface IMarketsSearchFilters extends ISearchBaseSportsBookFilters {
  betOfferId: number;
}

@if (!data?.length) {
  <div class="flex align-items-center justify-content-between">
    <span [translate]="'noDeliveriesAvailable'"></span>
    @if (permission.canAddContacts | hasPermission) {
      <button (click)="openModal()" [pTooltip]="'addDeliveryDetails'| translate"
        [label]="'addDelivery' | translate"
      class="m-1" icon="pi pi-plus" pButton pRipple type="button"></button>
    }
  </div>
}

@if (data?.length) {
  @if (permission.canAddContacts | hasPermission) {
    <p-toolbar styleClass="mb-4">
      <ng-template pTemplate="left">
        <button (click)="openModal()" [pTooltip]="'addDeliveryDetails'| translate"
          [label]="'addDelivery' | translate"
        class="m-1" icon="pi pi-plus" pButton pRipple type="button"></button>
      </ng-template>
    </p-toolbar>
  }
  <p-table [value]="data" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'typeId' | translate }}</th>
        <th>{{ 'delivery' | translate }}</th>
        <th>{{ 'confirmed' | translate }}</th>
        <th>{{ 'primary' | translate }}</th>
        <th>{{ 'actions' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-delivery>
      <tr>
        <td>{{ delivery.typeId | deliveryType }}</td>
        <td>{{ delivery.delivery }}</td>
        <td>
          <p-checkbox [disabled]="true" [ngModel]="delivery.isConfirmed" [binary]="true"></p-checkbox>
        </td>
        <td>
          <p-checkbox [disabled]="true" [ngModel]="delivery.isPrimary" [binary]="true"></p-checkbox>
        </td>
        <td>
          @if (permission.canEditContacts | hasPermission) {
            <button (click)="openModal(delivery)" [disabled]="delivery.isConfirmed" class="p-button-rounded p-button-success mr-2" icon="pi pi-pencil"
            pButton pRipple></button>
          }
          @if (permission.canDeleteContacts | hasPermission) {
            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
            (click)="submit(delivery, 'delete')"></button>
          }
        </td>
      </tr>
    </ng-template>
  </p-table>
}

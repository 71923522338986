{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "mode": "cashier",
  "typeId": 21,
  "steps": [
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "account-cog",
      "inputs": [
        {
          "name": "username",
          "icon": "pi-user",
          "type": "text",
          "label": "username",
          "restrict": [
            "noSpaces",
            "username"
          ],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          },
          "asyncValidator": "usernameExists"
        },
        {
          "name": "password",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "restrict": [
            "noSpaces"
          ],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "restrict": [
            "noSpaces"
          ],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20,
            "matchPassword": true
          }
        }
      ]
    }
  ]
}

import { IdName, idNames } from '../common/id-name';
import { ICmsPageLanguage } from './page';

export interface ICmsFaqSection {
  id: number;
  name: string;
  title: string;
  description: string;
  slug: string;
  faqs: Array<ICmsFaqBase>;
}

export interface ICmsFaqBase {
  id: number;
  name: string;
  section: IdName<string>;
  question: string; // in lingua
  answer: string; // in lingua
  slug: string; // in lingua
  bookmakerId: number;
}

export interface ICmsFaq extends ICmsFaqBase {
  questions: Array<ICmsPageLanguage>;
  answers: Array<ICmsPageLanguage>;
  slugs: Array<ICmsPageLanguage>;
}

export interface ICmsFaqSearchFilters {
  bookmakerId?: number;
  webAppId: number;
  p: number;
  pp: number;
}

export enum FaqSection {
  registration = 1,
  accountManagement,
  deposits,
  withdrawals,
  bonuses,
  promotions,
  loyalty,
  cashBacks,
  vipSystem,
  affiliates = 10,
  casinoLobby = 11,
  casinoLiveLobby,
  crashGamesLobby,
  sportsBook,
  eSports,
  virtualLobby,
  casinoInstantGames,
  casinoScratchCards,
  casinoTableGames,
  casinoVideoPokers,
  casinoLiveBaccarat,
  casinoLivePoker,
  casinoLiveRoulette,
  casinoLiveTableGames,
  virtualHorses,
  virtualSoccer,
  virtualTennis,
  virtualVirtualSports,
  casinoVideoSlots,
  casinoLiveBlackjack
}

export namespace FaqSection {
  export function values(): Array<IdName<number>> {
    return idNames(FaqSection);
  }
}

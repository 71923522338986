import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ISettlement, ISettlementSearchFilters, Pager } from '@bs/models';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettlementService {
  readonly url = `${this.config.api.sportsbook}/settlements`;

  settlements: Array<ISettlement> = [];
  constructor(private config: EnvironmentConfig, private http: HttpClient) {

    this.settlements.push({
      id: 10,
      creationDate: '2023-10-30T15:00:00Z',
      count: 100,
      totals: {stake: 30000, paid: 3000, profit: 27000, rate: 1, currencyCode: 'EUR', currencyId: 1, currencySymbol: '€', subunit: 100},
      stats: {won: 10, lost: 1, void: 1, refunded: 1}
    })
  }

  get(id: number): Observable<ISettlement> {
    return this.http.get<ISettlement>(`${this.url}/${id}`, {params: {id}});
  }

  search(filters: ISettlementSearchFilters): Observable<Pager<ISettlement>> {
    const pager: Pager<ISettlement> = {list: this.settlements, count: this.settlements.length}
    console.log(pager)
    return of(pager);
    //return this.http.post<Pager<ISettlement>>(`${this.url}/search`, filters);
  }

}

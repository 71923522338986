import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ICountry, IdName, IGeoInfo, IProvince, IRegion } from '@bs/models';
import { CatalogService } from '@bs/services';
import { Subscription } from 'rxjs';
import * as subGeoInfos from '../../../lib/me/subGeoInfos.json';

/**
 * Component renders 2 templates, one with the saved geographic information of user, other with the input fields to fill in the user information
 *
 * @description contains fields: city, country, region, province, address, zip code
 */
@Component({
  selector: 'geo-infos',
  templateUrl: './geo-infos.component.html',
  styleUrls: ['./geo-infos.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoInfosComponent),
      multi: true
    }
  ]
})
export class GeoInfosComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * when true we display the fill template where we fill in the form, otherwise we use the static template
   */
  @Input()
  readOnly: boolean;
  /**
   * responsible for disabling a field
   */
  isDisabled: boolean;
  /**
   * keeps tracks if we get IPersonBirthPlace value from writeValue method
   *
   * see {@link IPersonBirthPlace} for more details of the model
   */
  hasValues: boolean;
  /**
   * local reference for FormGroup
   */
  geoInfos: FormGroup;
  /**
   * local reference of countries array
   */
  countries: Array<IdName<string>>;
  /**
   * local reference of  regions array
   */
  regions: Array<IdName<string>>;
  /**
   * local reference of provinces array
   */
  provinces: Array<IdName<string>>;
  /**
   * local reference of cities
   */
  cities: Array<IdName<string>>;
  /**
   * deep cloning of the json form configuration
   */
  subGeoInfosConfig: any = JSON.parse(JSON.stringify(subGeoInfos));
  // todo: use structuredClone(subGeoInfos); when browser support (end 2022)
  /**
   * local reference to Subscription
   */
  subs = new Subscription();

  /**
   * The constructor, where we fetch all the countries, and initialize the countries
   * @param fb
   * @param catalogService
   */
  constructor(private fb: FormBuilder, private catalogService: CatalogService) {
    this.catalogService.countries().subscribe({
      next: countries => this.countries = countries.map(c => ({id: c.id, name: c.name, code: c.code}))
    });
  }

  /**
   * lifecycle hook, where we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(_fn: any) {
    this.propagateChange = _fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(_fn: any) {
  }

  /**
   * creates a new geoinfos form group, when programmatic changes from model to view are requested
   * @param geoInfos
   */
  writeValue(geoInfos: IGeoInfo) {
    this.hasValues = !!geoInfos;

    this.geoInfos = this.buildGeoInfos(geoInfos);

    this.subs.add(this.geoInfos.valueChanges.subscribe({
      next: res => this.propagateChange(res)
    }));
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;

    if (isDisabled) {
      this.geoInfos.disable();
    }
  }

  /**
   * fetches the regions when we change the country value in the view, and sets a new values of provinces for that country
   * @param country
   * @param i
   */
  setRegions(country: ICountry) {
    this.resetFields(true, true, true);
    if (!country) {
      return;
    }
    this.catalogService.regions(country).subscribe({
      next: regions => {
        this.geoInfos.get('region').enable();
        if (regions.length) {
          this.regions = regions.map(c => ({id: c.id, name: c.name}));
        } else {
          this.bypass(true, true, true);
        }
      },

      error: () => this.bypass(true, true, true)
    });
  }

  /**
   * fetches the provinces from the api, on change of region value from the view, and initialize the new provinces values
   * @param region
   * @param i
   */
  setProvinces(region: IRegion) {
    this.resetFields(true, true);
    if (!region) {
      return;
    }
    this.catalogService.provinces(region).subscribe({
      next: provinces => {
        this.geoInfos.get('province').enable();
        if (provinces.length) {
          this.provinces = provinces.map(c => ({id: c.id, name: c.name}));
        } else {
          this.bypass(true, true);
        }
      },

      error: () => this.bypass(true, true)
    });
  }

  /**
   * fetches the cities from the api, on change of province value from the view, and initialize the new cities
   * @param province
   * @param i
   */
  setCities(province: IProvince) {
    this.resetFields(true);
    if (!province) {
      return;
    }
    this.catalogService.cities(province).subscribe({
      next: cities => {
        this.geoInfos.get('city').enable();
        if (cities.length) {
          this.cities = cities.map(c => ({id: c.id, name: c.name}));
        } else {
          this.bypass(true);
        }
      },

      error: () => this.bypass(true)
    });
  }

  /**
   * sets the geoinfos types tabs, and in runtime we change the inputs type of the address and zipcode from readonly
   */
  ngOnInit() {
    this.subGeoInfosConfig.inputs.forEach(i => {
      i.type = this.readOnly || ['address', 'zipCode'].includes(i.name) ? 'input' : 'select';
    });
  }

  /**
   * function returns us IInput, so we can decide in template which template to render, a dropdown, or a single input for a control
   * @param input
   * @param isDropdown
   */
  getInput(input, isDropdown) {
    Object.assign(input, {type: isDropdown ? 'select' : 'input'});
    return input;
  }

  /**
   * this method resets the field value, when true value is passed for one of them
   *
   * @description for example when we pass resetFields(1, true, false, false) only the cities value will be cleared
   *
   * @param r reset region
   * @param p reset province
   * @param c reset city
   * @private
   */
  private resetFields(c?: boolean, p?: boolean, r?: boolean) {

    const patch = {};

    if (r) {
      this.regions = null;
      Object.assign(patch, {region: null});
    }

    if (p) {
      this.provinces = null;
      Object.assign(patch, {province: null});
    }

    if (c) {
      this.cities = null;
      Object.assign(patch, {city: null});
    }

    this.geoInfos.patchValue(patch);
  }

  /**
   * function builds us the geo infos form
   * @param infos
   * @private
   */
  private buildGeoInfos(infos?: Partial<IGeoInfo>) {
    const group = this.fb.group({
      address: ['', Validators.compose([Validators.required, Validators.maxLength(64)])],
      country: ['', Validators.required],
      region: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.compose([Validators.required, Validators.maxLength(16)])]
    });

    if (infos) {
      group.patchValue(infos as any);
    }

    return group;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<IGeoInfo>) {
  }

  /**
   * function enables the control field, when true value is passed for one of them
   *
   * @description for example when we pass bypass(true) only the cities value will be cleared and enabled
   *
   * @param c
   * @param p
   * @param r
   * @private
   */
  private bypass(c?: boolean, p?: boolean, r?: boolean) {

    if (c) {
      this.cities = [];
      this.geoInfos.get('city').enable();
    }

    if (p) {
      this.provinces = [];
      this.geoInfos.get('province').enable();
    }

    if (r) {
      this.regions = [];
      this.geoInfos.get('region').enable();
    }
  }
}

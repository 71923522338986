import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, INetworkTransfer, INetworkTransfersChangeStatesRequest, INetworkTransfersRequest, INetworkTransfersSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class WalletNetworkTransfersService {
  readonly url = `${this.config.api.accounts}/wallets/network-transfers`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<INetworkTransfer> {
    return this.http.get<INetworkTransfer>(`${this.url}/${id}`);
  }

  search(filters: INetworkTransfersSearchRequest): Observable<Pager<INetworkTransfer>> {
    return this.http.post<Pager<INetworkTransfer>>(`${this.url}/search`, filters);
  }

  create(req: INetworkTransfersRequest): Observable<INetworkTransfer> {
    return this.http.post<INetworkTransfer>(`${this.url}`, req);
  }

  changeState(id: number, req: INetworkTransfersChangeStatesRequest): Observable<INetworkTransfer> {
    return this.http.patch<INetworkTransfer>(`${this.url}/${id}/state`, req);
  }
}

<!--<pre>{{form.value | json}}</pre>-->
<!--<pre *ngIf="currencies"> {{ currencies | json }}</pre>-->
<form [formGroup]="form">

  <ng-template #tableTpl let-offer let-subType="subType" let-eventType="eventType">
    <p-table editMode="row" dataKey="value.currencyId"
      [value]="getCurrenciesFormArray(eventType+'.'+offer.name+'.'+subType).controls">
      <ng-template pTemplate="header">
        <tr>
          <th style="width:30%">{{'code' | translate}}</th>
          <th style="width:30%">{{'maxBet' | translate}}</th>
          <th style="width:30%">{{'maxWin' | translate}}</th>
          <th style="width:10%">{{'action' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="product" [formGroup]="product">
          @if (currencies|find:{id:(product.get('currencyId')?.value)}; as currentCurrency) {
            <td>{{currentCurrency.code}}</td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber formControlName="maxBet" [allowEmpty]="false" mode="currency" [minFractionDigits]="2"
                  [currency]="currentCurrency.code"></p-inputNumber>
                  <field-error class="text-xs" [control]="product.get('maxBet')"></field-error>
                </ng-template>
                <ng-template
                pTemplate="output">{{product.get('maxBet')?.value | currency:currentCurrency.code}}</ng-template>
              </p-cellEditor>
            </td>
            <td>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber formControlName="maxWin" [allowEmpty]="false" mode="currency" [minFractionDigits]="2"
                  [currency]="currentCurrency.code"></p-inputNumber>
                  <field-error class="text-xs" [control]="product.get('maxWin')"></field-error>
                </ng-template>
                <ng-template
                pTemplate="output">{{product.get('maxWin')?.value | currency:currentCurrency.code}}</ng-template>
              </p-cellEditor>
            </td>
            <td>
              <div class="flex align-items-center justify-content-center gap-2">
                @if (!editing) {
                  <button pButton type="button" pInitEditableRow icon="pi pi-pencil"
                  (click)="onRowEditInit(product.value)" class="p-button-rounded p-button-text"></button>
                }
                @if (editing) {
                  <button pButton type="button" pSaveEditableRow icon="pi pi-check"
                    (click)="onRowEditSave(product, eventType, offer.id, subType)"
                  class="p-button-rounded p-button-text p-button-success mr-2"></button>
                }
              </div>
            </td>
          }
        </tr>
      </ng-template>
    </p-table>
  </ng-template>

  <p-panel class="p-5" formGroupName="match">
    <ng-template pTemplate="header">
      <span translate>matches</span>
      <!--      <span>currency auto conversion-->
      <!--        <p-inputSwitch [(ngModel)]="copyMode"></p-inputSwitch>-->
    <!--      </span>-->
  </ng-template>
  @for (offer of offerTypes; track offer) {
    <p-panel [header]="offer.name" [formGroupName]="offer.name">
      <p-accordion>
        <p-accordionTab [header]="'standard'" formArrayName="standard">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'standard', eventType:'match'}"></ng-template>
        </p-accordionTab>
        <p-accordionTab [header]="'booking'" formArrayName="booking">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'booking', eventType:'match'}"></ng-template>
        </p-accordionTab>
        <p-accordionTab [header]="'cumulative'" formArrayName="cumulative">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'cumulative', eventType:'match'}"></ng-template>
        </p-accordionTab>
      </p-accordion>
    </p-panel>
  }

</p-panel>

<p-panel class="p-5" formGroupName="outright" [header]="'outrights'">
  @for (offer of [{name:'preMatch', id:1}]; track offer) {
    <p-panel [header]="offer.name" [formGroupName]="offer.name">
      <p-accordion>
        <p-accordionTab [header]="'standard'" formGroupName="standard">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'standard', eventType:'outright'}"></ng-template>
        </p-accordionTab>
        <p-accordionTab [header]="'booking'" formGroupName="booking">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'booking', eventType:'outright'}"></ng-template>
        </p-accordionTab>
        <p-accordionTab [header]="'cumulative'" formGroupName="cumulative">
          <ng-template [ngTemplateOutlet]="tableTpl"
          [ngTemplateOutletContext]="{$implicit:offer, subType:'cumulative', eventType:'outright'}"></ng-template>
        </p-accordionTab>
      </p-accordion>
    </p-panel>
  }
</p-panel>
</form>

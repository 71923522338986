<div class="grid p-fluid">
  <div class="col-12 md:col-3">
    <label>{{'bookmakers' | translate}}</label>
    <p-dropdown [options]="bookmakers" [(ngModel)]="model.bookmakerId" (ngModelChange)="bookmakersChanged($event)" [disabled]="isDisabled" optionLabel="name" optionValue="id" [showClear]="false"></p-dropdown>
  </div>
  <div class="col-12 md:col-3">
    <label>{{'providers' | translate}}</label>
    <p-dropdown [options]="providers" [(ngModel)]="model.providerId" (onChange)="update(model)" [disabled]="!model.bookmakerId" [placeholder]="!model.bookmakerId ? ('none' | translate) : ('select' | translate)" [showClear]="true" optionLabel="name" optionValue="id"></p-dropdown>
  </div>
  <div class="col-12 md:col-3">
    <label>{{'type' | translate}}</label>
    <p-dropdown [options]="gameSubTypes" [(ngModel)]="model.gameSubTypeId" (onChange)="update(model)" [disabled]="!model.bookmakerId" [placeholder]="!model.bookmakerId ? ('none' | translate) : ('select' | translate)" [showClear]="true" optionLabel="name" optionValue="id"></p-dropdown>
  </div>
</div>

import { IBookmaker } from '../me/account';
import { AcceptOddsChangesTypes } from '../sportsbook/betslip';
import { ICurrency } from './currency';
import { OddFormat } from './odd-format';


export class AppSettings {
  darkTheme: boolean;
  xDeviceId: string;
  bookmaker: IBookmaker;
  /**
   * @deprecated: will be moved in betSlipSettings
   */
  oddFormat: OddFormat;
  languageCode: string;
  timeZone: string;
  sessionExpiring: any;
  displayCurrency: ICurrency;
  bookmakerSelectorDisabled: boolean;
  sportsbookGrid: boolean;

  /**
   * @deprecated: will be moved in betSlipSettings
   */
  acceptOddsChanges: number;
  /**
   * @deprecated: will be moved in betSlipSettings
   */
  stake: number

  constructor(private config) {
    this.darkTheme = this.config.theme.defaultDark;
    this.oddFormat = OddFormat.Decimal;
    this.bookmakerSelectorDisabled = false;
    this.timeZone = this.config.timeZone;
    this.acceptOddsChanges = AcceptOddsChangesTypes.always;
    this.stake = 0;
    this.sportsbookGrid = false;
  }
}

export type AppSettingsKey =
  'darkTheme'
  | 'xDeviceId'
  | 'bookmaker'
  | 'sessionExpiring'
  | 'oddFormat'
  | 'languageCode'
  | 'timeZone'
  | 'displayCurrency'
  | 'bookmakerSelectorDisabled'
  | 'acceptOddsChanges'
  | 'stake'
  | 'sportsbookGrid';

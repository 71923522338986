import { Pipe, PipeTransform } from '@angular/core';
import { IBalance } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';

/**
 * this pipe transform the user wallet balance of type IBalance into a html string readable from a tooltip
 */
@Pipe({
  name: 'balanceToolTip'
})
export class BalanceTooltipPipe implements PipeTransform {

  /**
   * The constructor
   */
  constructor(private translate: TranslateService) {
  }

  /**
   * return us the template html string
   * @param balance {IBalance} requires a IBalance value
   */
  transform(balance: IBalance): string {
    const details = this.translate.instant('details');
    const available = this.translate.instant('available');
    const withdrawable = this.translate.instant('withdrawable');

    return `<strong>${details}</strong>
            <span>${available}: ${balance.currencySymbol} ${(balance.available / 100).toFixed(2)}</span>
            <span>${withdrawable}: ${balance.currencySymbol} ${(balance.withdrawable / 100).toFixed(2)}</span>`;
  }

}

import { Component, EventEmitter, forwardRef, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IValidationTypes } from '@bs/models';

/**
 * The component contains the password field, that shows us the strength of password, and toggles between the input type, so we can see the password as text
 */
@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    }
  ],
  styles: []
})
export class PasswordComponent implements ControlValueAccessor {
  /**
   * toggles input type field, and icon class on button click
   */
  toggle: boolean;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * the step of progressBar
   *
   * @description default is 20
   */
  step = 20;
  /**
   * the estimation value of the progress bar
   */
  estimation: number;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: string;
  /**
   * the input field placeholder
   */
  @Input()
  placeholder: string;
  /**
   * if true shows us the progressbar for password strength
   */
  @Input()
  hasStrength: boolean;
  /**
   * variable checks do we show the progressbar for password strength
   */
  showStrength: boolean;
  /**
   * @ignore
   */
  @Input()
  hasTip: boolean;
  /**
   * the validators for the input field
   */
  @Input()
  validations: Partial<IValidationTypes>;
  /**
   * adds a host class p-inputgroup
   */
  @HostBinding('class')
  inputGroup = 'p-inputgroup';
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output() blur = new EventEmitter<any>(null);

  /**
   * The constructor initializes default values for toggle, and placeholder
   */
  constructor() {
    this.toggle = false;
    this.placeholder = this.placeholder || '';
  }

  /**
   * a getter where we check for model value, and for hasStrength true value, we add a host class strength, and return the strength by the estimations value
   */
  @HostBinding('class.strength')
  get strength() {
    if (!this.hasStrength || !this.model) {
      return '';
    }
    this.estimation = this.testPassword(this.model || '');
    return ['weak', 'low', 'medium', 'good', 'strong'][this.estimation];
  }

  /**
   * on change of password values in the view, we register the value changes
   * @param values
   */
  update(model: string) {

    if (this.validations) {
      if (this.validations?.minLength) {
        this.showStrength = model.length >= this.validations.minLength;
      }

      if (this.validations?.maxLength) {
        this.showStrength = model.length <= this.validations.maxLength;
      }
    } else {
      this.showStrength = true;
    }

    this.propagateChange(model);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  onTouched() {
  }

  /**
   * propagate the blur, and update the form model on blur
   * @param event
   */
  onBlur(event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * we assign a value to the model, when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(model: string): void {
    if (model !== undefined) {
      this.model = model;
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * function tests the strength of the password, so we can apply the correct message
   * @param pwd
   * @private
   */
  private testPassword(pwd: string): number {
    const strength = [];
    let sum = -1;
    // almost 6 chars

    /*
    To Review Regex
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@?[\]"£$%^&*#.,{}<>()_-])[A-Za-z\d!@?[\]"£$%^&*#.,{}<>()_-]{8,20}$/.test(pwd)) {
      return sum;
    }*/
    // almost a minimum
    strength[0] = /[a-zA-Z\d]/.test(pwd);
    // almost a special char
    strength[1] = /[!@?[\]"£$%^&*#.,{}<>()_-]/.test(pwd);
    // almost a digit and a special character
    strength[2] = /(?=.*\d)/.test(pwd) && /(?=.*[!@?[\]"£$%^&*#.,{}<>()_-])/.test(pwd);
    // almost a digit or a special character and lenght 10
    strength[3] = /[a-zA-Z\d!@?[\]"£$%^&*#.,{}<>()_-]{10}/.test(pwd);
    // almost a 2 digits and 2 special characters and lenght > 10
    strength[4] = /(?=(.*\d){2})/.test(pwd) && /(?=(.*[!@?[\]"£$%^&*#.,{}<>()_-]){2})/.test(pwd);
    for (const s in strength) {
      if (strength[s]) {
        ++sum;
      }
    }
    return sum;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: string) {
  }
}

import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { DeliveryType, FormInterface, IDelivery } from '@bs/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { FormBuilderComponent } from '@krusty/forms';

@Component({
  selector: 'delivery.dialog',
  templateUrl: './delivery.dialog.html',
  styleUrls: ['./delivery.dialog.scss']
})
export class DeliveryDialog implements AfterViewInit, OnDestroy {
  @ViewChild(FormBuilderComponent, {static: false})
  fb: FormBuilderComponent;

  currentDelivery: IDelivery;
  defaultAutoSelect = true;
  deliveriesConfig: FormInterface = {
    inputs: [
      {
        autoselect: true,
        label: "contactType",
        name: "typeId",
        type: "select",
        validations: {
          delivery: "delivery",
          required: true
        },
        values: DeliveryType.names()
      },
      {
        label: "delivery",
        name: "delivery",
        type: "mobile-prefix",
        validations: {
          required: true
        }
      },
      {
        label: "delivery",
        name: "delivery",
        type: "text",
        validations: {
          required: true
        }
      }
    ]
  };
  phoneFieldRef = this.deliveriesConfig.inputs[1];
  emailFieldRef = this.deliveriesConfig.inputs[2];
  typeFieldRef = this.deliveriesConfig.inputs.find(i => i?.name === 'typeId');
  subs = new Subscription();

  constructor(config: DynamicDialogConfig, private ref: DynamicDialogRef) {
    this.currentDelivery = config.data;
    this.typeFieldRef.autoselect = !this.currentDelivery;
    this.setEmptyField(this.currentDelivery?.typeId === DeliveryType.Email);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.fb.form.get('typeId')[this.currentDelivery ? 'disable' : 'enable']();
    });

    this.subs.add(this.fb.form.get('typeId').valueChanges.subscribe({
      next: id => {
        this.fb.form.get('delivery').patchValue('');
        this.setEmptyField(id === DeliveryType.Email);
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    // Fallback
    this.typeFieldRef.autoselect = this.defaultAutoSelect;
    this.setEmptyField(true);
  }

  /**
   * @description adjusts phone or email field as empty
   *
   * @param isEmail - whether forms shows email field
   */
  setEmptyField(isEmail: boolean): void {
    this.phoneFieldRef.empty = isEmail;
    this.emailFieldRef.empty = !isEmail;
  }

  /**
   * @description it sends the information to do the CRUD operation
   *
   * @param formValues - values from the form
   */
  createOrUpdateDelivery(formValues): void {
    const currentOperation = this.currentDelivery ? 'update' : 'create';
    this.closeDialog(currentOperation, {
      ...this.currentDelivery,
      ...formValues,
      ...((this.emailFieldRef.empty) ? {delivery: `+${formValues.delivery.prefix.prefix}${formValues.delivery.phone}`} : null)
    });
  }

  /**
   * @description it closes the dialog
   *
   * @param {string} operation - operation of the CRUD table
   * @param {Partial<IDelivery>} delivery - delivery for the operation
   */
  closeDialog(operation: string, delivery?: Partial<IDelivery>): void {
    this.ref.close({operation, delivery});
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { LocalStorage, WINDOW } from '@bs/universal';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CustomPageTitleStrategy } from './helpers/custom-page-title.strategy';

/**
 * handles the missing translations
 */
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  /**
   * the missingTranslations variable array
   */
  private missingTranslations: string[] = [];

  /**
   * A function that handles missing translations.
   */
  handle(params: MissingTranslationHandlerParams) {

    if (this.missingTranslations.indexOf(params.key) > -1) {
      return params.key;
    }
    this.missingTranslations.push(params.key);
    // console.log(params.key);
    console.log(`Translation missing: '${params.key}'`);
    return params.key;
  }

}

/**
 * service purpose is to fetch the translation
 */
export class TranslationLoaderService implements TranslateLoader {
  /**
   * the menu url from environment
   */
  readonly url = this.config.api.cms;
  /**
   * the webappid environment
   */
  readonly webAppId = this.config.webAppId;

  /**
   * The constructor
   */
  constructor(private client: HttpClient, private settings: AppSettingsService, private config: EnvironmentConfig) {
  }

  /**
   * function returns us the translations from the api
   */
  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({
      'Accept-Language': lang || this.config.defaultLangCode,
      BookmakerId: `${this.config.bookmakerId}`
    });
    this.settings.saveSetting('languageCode', lang);
    return this.client.get(`${this.url}/apps/${this.webAppId}/translations`, {headers});
  }
}

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoaderService,
        deps: [HttpClient, AppSettingsService, EnvironmentConfig]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      },
      useDefaultLang: true
    })
  ],
  exports: [
    TranslateModule
  ]
})
/**
 * The module reader
 */
export class TranslationModule {
  /**
   * The constructor where we set up the language
   */
  constructor(localStorage: LocalStorage, private config: EnvironmentConfig, translate: TranslateService, @Inject(WINDOW) private window: Window) {
    translate.onLangChange.subscribe({
      next: data => localStorage.setItem('lang', data.lang)
    });

    const langs = this.config.langs.map(l => l.code);
    translate.addLangs(langs);

    const lang = [
      this.window?.location?.pathname.split('/')[1],
      localStorage.getItem('lang'),
      translate.getBrowserLang(),
      this.config.defaultLangCode,
    ].find(l => langs.includes(l));

    translate.setDefaultLang(lang);
    translate.currentLang = lang;
    localStorage.setItem('lang', lang);

  }

  /**
   * forRoot separates providers from a module so we can import this module
   */
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        },
        {
          provide: TitleStrategy,
          useClass: CustomPageTitleStrategy
        }
      ]
    };
  }
}

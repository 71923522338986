import { IdName, idNames } from '../common/id-name';

export enum IBannerStatus {
  draft = 1,
  validated,
  suspended,
  cancelled
}

export namespace IBannerStatus {
  export function values(): Array<IdName<any>> {
    return idNames(IBannerStatus);
  }
}

export enum IBannerVisibility {
  anyone = 1,
  anonymous,
  authenticated
}

export namespace IBannerVisibility {
  export function values(): Array<IdName<any>> {
    return idNames(IBannerVisibility);
  }
}

export enum BannerPositionVertical {
  top = 1,
  center,
  bottom
}

export namespace BannerPositionVertical {
  export function values(): Array<IdName<any>> {
    return idNames(BannerPositionVertical);
  }
}

export enum BannerPositionHorizontal {
  left = 1,
  center,
  right
}

export namespace BannerPositionHorizontal {
  export function values(): Array<IdName<any>> {
    return idNames(BannerPositionHorizontal);
  }
}

interface ColorValue {
  color: string;
  value: string;
}

interface IBannerCta extends ColorValue {
  path?: any;
  url?: any;
  bgColor: string;
  bannerClickable?: boolean;
  languages?: Array<{ languageId: number, value: string }>;
}

interface TimeRange {
  from: string;
  to: string;
}

interface IBannerTimingSlot {
  day: number;
  timeSlots?: Array<TimeRange>
}

interface IBannerTiming {
  range: TimeRange;
  slots?: Array<IBannerTimingSlot>;
}

export type ISizes = Record<'desktop' | 'tablet' | 'mobile', { width: number, height: number }>;

export interface IBanner {
  id?: number;
  bookmakerId?: number;
  creationDate?: string;
  images: Array<Partial<ICmsImage>>;
  statusId: IBannerStatus;
  name: string; // for internal use
  headline?: ColorValue;
  tagline?: ColorValue;
  ctaVisible: boolean;
  cta?: IBannerCta,

  /**
   * @description position of the info and cta, mobile use horizontal only
   */
  position: {
    vertical: BannerPositionVertical;
    horizontal: BannerPositionHorizontal;
  };

  visibility?: IBannerVisibility; // default to anyone

  startDate?: string; // this can be managed even via api
  endDate?: string; // this can be managed even via api

  backgroundColor?: string; // transparent if not provided
  timing?: IBannerTiming

  sizeId: number;
  sizes?: ISizes
}

export interface ISliderConfig {
  autoPlay?: boolean;
  interval: number;
  pauseOnHover?: boolean;
  showNavigationArrows?: boolean;
  showNavigationIndicators?: boolean;

  sizes?: ISizes
}


/**
 * @description array of banner set already localized via Header 'Accept-Language' with provided configuration
 */
export interface ISlider {
  id: number;
  name: string;
  webAppId: number;
  bookmakerId?: number;
  banners: Array<IBanner>;
  config: ISliderConfig;
  sizeId: number;
}

export interface ICmsBannerLanguage {
  languageCode: string;
  buttonText: string;
  headLine: string;
  tagLine: string;
  link: string;
}

/**
 * @deprecated use new Banner interface
 */
export interface ICmsBanner {
  backgroundColor: string;
  backgroundImage: string;
  buttonColor: string;
  buttonBackgroundColor: string;
  headlineColor: string;
  taglineColor: string;
  linkIsExternal: boolean;
  textAlign: number;
  buttonAlign: number;
  orderId: number;
  langs: ICmsBannerLanguage[];
  start_time: string;
  end_time: string;
}


export interface ICmsImage {
  id?: number;
  languageId: number;
  desktop: string;
  tablet: string;
  mobile: string;
}

export interface ICmsPatchImage {
  languageId: number;
  deviceType: string;
  url: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IBetOffer, Pager } from '@bs/models';
import { CrudService } from '@bs/services';

import { Observable } from 'rxjs';

@Injectable()
export class BetOfferService implements CrudService<IBetOffer>{

  readonly url = `${this.config.api.sportsbook}/bet-offers`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }
  /**
   * get a betoffer by id
   * @param id
   * @param bookmakerId
   */
  getOffer(id: number, bookmakerId: number): Observable<IBetOffer> {
    return this.http.get<IBetOffer>(`${this.url}/${id}`, {params: {id: id, bId: bookmakerId}});
  }

  /**
   * search
   */
  search(filters: Params): Observable<Pager<IBetOffer>> {
    return this.http.post<Pager<IBetOffer>>(`${this.url}/search`, filters);
  }

  delete(item: Partial<IBetOffer>): Observable<IBetOffer> {
    return undefined;
  }

  get(item: Partial<IBetOffer>): Observable<IBetOffer> {
    return this.http.get<IBetOffer>(`${this.url}/${item.id}`, {params: {id: item.id, bId: item.bookmakers}})
  }

  patch(id: number, item: Partial<IBetOffer>): Observable<IBetOffer> {
    return undefined;
  }

  post(item: Partial<IBetOffer>): Observable<Partial<IBetOffer>> {
    return undefined;
  }

}

import { CurrencyConversion } from '../../common/currency';
import { IdName, idNames } from '../../common/id-name';

export enum KraTicketBatchType {
  stakeData = 1,
  outcomeData = 2
}

export namespace KraTicketBatchType {
  export function values(): Array<IdName<string>> {
    return idNames(KraTicketBatchType);
  }
}

export enum KraTicketBatchStatus {
  created = 1,
  sended = 2,
  not_sended
}

export namespace KraTicketBatchStatus {
  export function values(): Array<IdName<string>> {
    return idNames(KraTicketBatchStatus);
  }
}

export interface IKraTicketBatch {
  id: number;
  bookmakerId: number;
  creationDate: string;
  typeId: number;
  statusId: number;
  ticketsCount: number;
  tax: IKraTicketAmount;
  tickets?: Array<IKraTicket>;
}

export interface IKraTicket {
  ticketId: number;
  couponCode: string;
  ticketStatusId: number;
  stake: IKraTicketAmount;
  balanceAfter: IKraTicketAmount; // balance after
}

export interface IKraTicketAmount extends CurrencyConversion {
  amount: number
}

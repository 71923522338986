import { IdName, idNames } from '../common/id-name';
import { IWalletMovement } from './movement';

export interface IWithdrawalSearchRequest {
  walletTypeId: number;
  accountId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IWithdrawal extends IWalletMovement {
  stateId: WithdrawalStates;
  evasionDate: string;
  otherInfo?: any;
  histories?: Array<IWithdrawalChangeStatus>;
}

export interface IWithdrawalChangeStatus {
  stateId: number;
  //typeSenderId: number;
  authorId: number;
  author: string;
  note?: string;
  date: string;
}

export interface IWithdrawalChangeStatusRequest {
  changeStateId: number;
  note?: string;
}

export enum WithdrawalStates {
  pending = 1,
  approved = 2,
  refusedByOperator = 3,
  voidedByApplicant = 4,
  refusedByHolder = 5,
  void = 6,
  paid = 7,
  processing = 10
  //refusedByExternalSystem = 8,
}

export namespace WithdrawalStates {
  export function values(): Array<IdName<string>> {
    return idNames(WithdrawalStates);
  }
}

export enum WithdrawalChangeStates {
  fromInitialToApproved = 1,
  fromInitialToRefuse = 2,
  fromInitialToRefusedByHolder = 3,
  //fromInitialToRefusedByUser = 4,
  //fromInitialToCanceled = 5,
  fromApprovedToPaid = 8,
  //fromApprovedToRefusedByExternalSystem = 9,
  initialState = 10,
  fromApprovedToPaymentProcessing = 12,
  fromPaymentProcessingToApproved = 13,
  fromPaymentProcessingToPaid = 14,
  fromApprovedToCancelled = 15
}

export namespace WithdrawalChangeStates {
  export function values(): Array<IdName<string>> {
    return idNames(WithdrawalChangeStates);
  }
}

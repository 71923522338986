import { AfterViewInit, Component, EventEmitter, Inject } from '@angular/core';
import { NetworkCashierReport } from '@bs/models';
import { WINDOW } from '@bs/universal';

@Component({
  selector: 'reports-cashier-receipt',
  templateUrl: './reports-cashier-receipt.component.html',
  styleUrls: ['./reports-cashier-receipt.component.scss']
})
export class ReportsCashierReceiptComponent implements AfterViewInit {

  loaded: EventEmitter<void> = new EventEmitter<void>();

  host: string;
  report: NetworkCashierReport;
  totalIn: number;
  totalOut: number;
  totalBalance: number;

  today: Date;

  constructor(@Inject(WINDOW) window: Window) {
    this.host = window.location.origin;
  }

  ngAfterViewInit() {
    this.loaded.emit();
  }
}

import { IdName, idNames } from '../common/id-name';

export enum SettlementTimeTypes {
  instant = 1,
  minutes = 2,
  hours = 3,
  days = 4,
}

export namespace SettlementTimeTypes {
  export function values(): Array<IdName<string>> {
    return idNames(SettlementTimeTypes);
  }
}

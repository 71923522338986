export enum TfaType {
  authenticator = 1,
  email = 2,
  sme
}

export interface Tfa {
  typeId: TfaType;
  id: number;
  isEnabled: boolean;
  creationDate: string;
}

export interface TfaResponse {
  base32?: string;
  authUrl?: string;
  id?: number;
}

import { IdName, idNames } from '../common/id-name';
import { IBaseGame, IGameAttribute, IGameProvider } from './game';

export enum gameTypeEnum {
  Casino = 1,
  Poker = 2,
  LiveCasino = 3,
  Virtual = 4,
  Lottery = 5,
  ScratchCard = 6,
  CrashGames = 7,
  SportsBook = 10,
}

export namespace gameTypeEnum {
  export function values(): Array<IdName<string>> {
    return idNames(gameTypeEnum);
  }
}

export function getSectionByTypeId(typeId: gameTypeEnum): string {
  switch (typeId) {
    case gameTypeEnum.CrashGames:
      return 'crash-games';
    case gameTypeEnum.LiveCasino:
      return 'casino-live';
    case gameTypeEnum.Casino:
      return 'casino';
    case gameTypeEnum.Lottery:
      return 'lottery';
    case gameTypeEnum.Poker:
      return 'poker';
    case gameTypeEnum.ScratchCard:
      return 'scratch-card';
    case gameTypeEnum.Virtual:
      return 'virtuals';
    default :
      return 'casino';
  }
}

export function getTypeIdBySection(section: string): gameTypeEnum {
  switch (section) {
    case 'casino':
      return gameTypeEnum.Casino;
    case 'casino-live':
      return gameTypeEnum.LiveCasino;
    case 'virtuals':
      return gameTypeEnum.Virtual;
    case 'lotteries':
      return gameTypeEnum.Lottery;
    case 'scratch-cards':
      return gameTypeEnum.ScratchCard;
    case 'crash-games':
      return gameTypeEnum.CrashGames;
    default:

  }
}

export interface IGameType {
  id: number,
  name: string
  gameSubTypes: Array<IGameSubType>;
  providers: Array<IGameProvider>;
  attributes?: Array<IGameAttribute>;
}

export interface IGameSubType {
  id: number;
  icon?: string;
  name: string;
  games?: Array<IBaseGame>
}

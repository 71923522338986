<html>

<head>
  <meta charset="utf-8">
  <title>{{'wallets' | translate}}</title>
</head>

<body style="font-family: sans-serif;font-size: 12px; color: black;">

<div id="printable" style="position: relative; width: 277px; height: 578px;">
  <svg width="277" height="578" xmlns="http://www.w3.org/2000/svg" style="position:absolute; top: 0; left: 0;">
    <mask id="a" fill="#fff">
      <path
        d="M262.3 449.7a16.8 16.8 0 0114.7-16.4V0h-11.6v3a8.9 8.9 0 11-17.5 0V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 11-17.8 0V0H171v3a8.9 8.9 0 01-17.8 0V0h-5.8v3a8.9 8.9 0 11-17.8 0V0h-5.8v3A8.9 8.9 0 01106 3V0h-5.8v3a8.9 8.9 0 01-17.8 0V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 11-17.5 0V0H0v433.3a16.4 16.4 0 010 32.9V578h11.6v-3.4a8.9 8.9 0 0117.5 0v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 1117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h6.1v-3.4a8.5 8.5 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.6 8.6 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.5 0v3.4H277V466.2a16.8 16.8 0 01-14.7-16.5z"/>
    </mask>
    <path
      d="M262.3 449.7a16.8 16.8 0 0114.7-16.4V0h-11.6v3a8.9 8.9 0 11-17.5 0V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 11-17.8 0V0H171v3a8.9 8.9 0 01-17.8 0V0h-5.8v3a8.9 8.9 0 11-17.8 0V0h-5.8v3A8.9 8.9 0 01106 3V0h-5.8v3a8.9 8.9 0 01-17.8 0V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 11-17.5 0V0H0v433.3a16.4 16.4 0 010 32.9V578h11.6v-3.4a8.9 8.9 0 0117.5 0v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 1117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h6.1v-3.4a8.5 8.5 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.6 8.6 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.5 0v3.4H277V466.2a16.8 16.8 0 01-14.7-16.5z"
      fill="#fff" stroke="#DBDBDB" stroke-width="2" mask="url(#a)"/>
    <path stroke="#000" stroke-dasharray="2 2" d="M18 449.5h243"/>
  </svg>
  <div style="position: relative; top: 0; z-index: 2;">
    <img src="{{host}}/assets/layout/images/logo-dark.svg" alt="logo" width="60%" style="display: block; margin: auto; padding-top: 40px;">

    <div style="text-align: center; margin: 25px 0 90px;">
      <span>{{'causal' | translate}}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 0 18px;">
      <strong style="text-transform: uppercase;">{{'date' | translate}}</strong>
      <span>{{item.date | localizedDate}}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 0 18px;">
      <strong style="text-transform: uppercase;">{{'movementId' | translate}}</strong>
      <span>{{item.id}}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 0 18px;">
      <strong style="text-transform: uppercase;">{{'account-id' | translate}}</strong>
      <span>{{item.account.id}}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 0 18px;">
      <strong style="text-transform: uppercase;">{{'username' | translate}}</strong>
      <span>{{item.account.username}}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 0 18px;">
      <strong style="text-transform: uppercase;">{{'account-type' | translate}}</strong>
      <span>{{item.account.typeId | formValues:{values: 'accountType'} }}</span>
    </div>


  </div>

  <div style="display: grid; place-items: center; position: absolute; bottom: 60px; right: 0; left: 0; padding: 0 18px; font-size: 14px">
    <strong>{{'amount' | translate}}</strong>
    <span>{{item.amounts.amount | currencyAmount: item.amounts.currencySymbol }}</span>
  </div>

</div>

</body>
</html>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketSport, IMarketsSportSearchFilters, Pager } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketSportService {
  readonly url = `${this.config.api.sportsbook}/markets-sport`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(betOfferId: number, sportId?: number): Observable<Array<IMarketSport>> {
    return this.http.get<Array<IMarketSport>>(`${this.url}`, {params: {bId: betOfferId.toString(), sId: sportId.toString()}});
  }

  createOrUpdate(data: IMarketSport): Observable<IMarketSport> {
    return data.id ? this.http.put<IMarketSport>(`${this.url}/${data.id}`, data) : this.http.post<IMarketSport>(this.url, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  search(filters: IMarketsSportSearchFilters): Observable<Pager<IMarketSport>> {
    return this.http.post<Pager<IMarketSport>>(`${this.url}/search`, filters);
  }
}

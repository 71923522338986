import { inject } from '@angular/core';

import { ICurrency } from '@bs/models';
import { CatalogService } from '../../services/core/catalog.service';
import { ResolveFn } from '@angular/router';

/**
 * Resolver returns array of currencies for the app, currencies are cached in memory after first call because they are static for each bookmakerId
 */
export const CurrenciesResolver: ResolveFn<Array<ICurrency>> = () => inject(CatalogService).currencies();

import { Component, EventEmitter, Inject } from '@angular/core';
import { IWalletMovement } from '@bs/models';
import { WINDOW } from '@bs/universal';

@Component({
  selector: 'wallets-receipt',
  templateUrl: './wallets-receipt.component.html',
  styleUrls: ['./wallets-receipt.component.scss']
})
export class WalletsReceiptComponent {

  loaded: EventEmitter<void> = new EventEmitter<void>();

  host: string;
  item: IWalletMovement;

  constructor(@Inject(WINDOW) window: Window) {
    this.host = window.location.origin;
  }

  ngAfterViewInit() {
    this.loaded.emit();
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html'
})

export class LanguageSelectorComponent {
  langs: any;
  currentLang: { code: string, name: string };

  constructor(private config: EnvironmentConfig, private translation: TranslateService, private router: Router) {
    this.langs = config.langs;
    this.currentLang = this.langs.find(l => l.code === translation.currentLang);
  }

  changeLanguage(lang: { code: string, name: string }) {
    const dest = this.router.url.replace(this.currentLang.code, lang.code);
    this.currentLang = lang;
    this.translation.use(lang.code);
    this.router.navigateByUrl(dest);
  }

}

import { Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICurrency } from '@bs/models';
import { MeService } from '@bs/services';
import { Subscription } from 'rxjs';

/**
 * The component contains currencies pickList, so we can reorder items between different lists.
 */
@Component({
  selector: 'displaycurrency-picklist',
  templateUrl: './displaycurrency-picklist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DisplaycurrencyPicklistComponent),
      multi: true
    }
  ]
})
export class DisplaycurrencyPicklistComponent implements ControlValueAccessor, OnDestroy {
  /**
   * Local reference of Array<ICurrency>
   */
  values: Array<ICurrency>;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: Array<ICurrency> = [];
  /**
   * local reference to Subscription
   */
  subs = new Subscription();

  /**
   * The constructor fetches the display currencies, and we initialize those values
   * @param meService
   */
  constructor(private meService: MeService) {
    this.subs.add(this.meService.displayCurrency$.subscribe({
     next: d => this.values = d
    }));
  }

  /**
   * lifecycle hook, where we unsubscribe from the subscription
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * function when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(): void {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<number>) {
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * on change of values in the picklist we register those changes
   * @param values
   */
  update() {
    this.propagateChange(this.model.map(x=>x.id));
  }
}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "currencies",
      "label": "currencies",
      "type": "select",
      "autoselect": true,
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "label": "maxBet",
      "name": "maxBet",
      "type": "amount",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      },
      "options": {
        "currencyCode": "EUR"
      }
    },
    {
      "label": "maxWin",
      "name": "maxWin",
      "type": "amount",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      },
      "options": {
        "currencyCode": "EUR"
      }
    },
    {
      "label": "maxBetCumulative",
      "name": "maxBetCumulative",
      "type": "amount",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      },
      "options": {
        "currencyCode": "EUR"
      }
    },
    {
      "label": "maxWinCumulative",
      "name": "maxWinCumulative",
      "type": "amount",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      },
      "options": {
        "currencyCode": "EUR"
      }
    }
  ]
}

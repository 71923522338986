import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params } from '@angular/router';

import { EnvironmentConfig, FormInterface, Values } from '@bs/models';
import { FormValuesPipe } from '../pipes/formValues.pipe';
import { ValidationService } from '../services/validation.service';


/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({
  template: ``,
  providers: [FormValuesPipe]
})
export class BaseFormBuilder {
  /**
   * the configuration of the form, with the input fields
   *
   * see {@link FormInterface} for more information of the model
   */
  @Input()
  config: FormInterface;
  /**
   * updates the form values, when values change in parent element
   *
   * @description works as angular form.patchValue method
   */
  @Input()
  patch: any;
  /**
   * disabling a field of the form
   */
  @Input()
  disabled: boolean;

  /**
   * receive and object with key (the name of the field to populate)
   * PLEASE NOTE: the name of the field is singular, but the name of the array is plural, the value passed must be SINGULAR
   *
   */
  @Input()
  values: Values | string;
  /**
   * local reference of FormGroup
   */
  form: FormGroup;
  /**
   * it outputs the submitted values of the form to the parent component
   */
  @Output()
  submits: EventEmitter<any> = new EventEmitter<any>();

  /**
   * The constructor
   * @param environmentConfig
   * @param fb
   * @param validationService
   * @param route
   */
  constructor(private environmentConfig: EnvironmentConfig, private fb: FormBuilder, private validationService: ValidationService, private route: ActivatedRoute) {
  }

  /**
   * in this lifecycle hook we build the form with the fields, and patch its values
   */
  ngOnInit() {
    if (!this.config) {
      throw new Error('Form builder config is required and must not be null');
    }
    this.form = this.validationService.buildForm(this.config, this.fb);

    if (this.patch) {

      if (this.patch.birthDate) {
        this.patch.birthDate = new Date(this.patch.birthDate);
      }

      this.form.patchValue(this.patch);
    }

    const paramMap = this.route.snapshot.queryParamMap;

    if (paramMap.keys.length) {

      const subMap = this.getSubMap(paramMap);
      const toPatch = Object.assign({}, this.route.snapshot.queryParams, subMap);
      this.form.patchValue(toPatch);

      this.form.markAsPristine();
    }
  }

  /**
   * in this lifecycle hook, if a value it's changed in the parent component, we are updating the form values with the correct once
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {

    if(!changes.config?.firstChange){
      this.ngOnInit(); // if config changes at runtime, reInit
    }

    if (!changes?.patch?.firstChange) {
      if (changes.patch?.currentValue) {
        this.form.patchValue(changes.patch.currentValue);
      } /*else {
        this.form?.reset();
      }*/
    }

    if (changes?.disabled?.currentValue) {
      setTimeout(() => this.form.disable(), 100);
    } else if (changes?.disabled && !changes?.disabled) {
      setTimeout(() => this.form.enable(), 100);
    }


    if (changes.values?.currentValue) {
      // const values = changes.values?.currentValue;
      const paramMap = this.route.snapshot.queryParamMap;
      const params: Params = {}

      paramMap.keys.forEach(k => {
        if (k.endsWith('s')) {
          params[k] = paramMap.getAll(k).map(x => isNaN(Number(x)) ? x : +x);
        } else if (k.endsWith('Id') || ['catalog', 'p', 'pp'].includes(k)) {
          params[k] = +paramMap.get(k);
        }
      })

      /* const mapped = Object.entries<Array<IdName<any>>>(this.values).reduce((arr, [key, val]) => {
         if (paramMap.has(key)) {
           arr[key] = val.find(v => v.id === +paramMap.get(key));
         }
         return arr;
       }, {});*/

      const subMap = this.getSubMap(paramMap);

      const toPatch = Object.assign({}, this.route.snapshot.queryParams, subMap, params);

      setTimeout(() => {
        if (Object.keys(toPatch).length) {
          this.form.patchValue(toPatch);
          this.form.markAsPristine();
        } else if (!this.patch) {
          this.form.reset();
          this.form.markAsPristine();
        }
      })
    }
  }

  /**
   * @description transform keys like {"startDate,toDate":date1,date2} into {startDate:date1, toDate:date2}" from submitted forms
   */
  preSubmit() {
    const submit = Object.entries(this.form.value).reduce((acc, [k, v]) => {
      if (k.includes(',')) {
        const values = Object.keys(v);
        k.split(',').forEach((sub, i) => {
          acc[sub] = v[values[i]];
        });
        return acc;
      }

      acc[k] = v;

      return acc;
    }, {});

    this.submits.next(submit);
  }

  /**
   * @description transform keys like {"from,to":date1,date2} into {from:date1, to:date2}" from Params in url
   * transform keys like {"from,to":date1,date2} into {from:date1, to:date2}" from Params in url
   * @param paramMap
   * @private
   */
  private getSubMap(paramMap: ParamMap) {
    return this.form?.controls && Object.keys(this.form.controls).reduce((obj, c) => {
      if (c.includes(',')) {
        let subString = '';
        c.split(',').forEach(sub => {

          if (paramMap.has(sub)) {
            subString += `,${paramMap.get(sub)}`;
          }
        });
        obj[c] = subString.substring(1);
      }
      return obj;
    }, {});
  }
}

import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BettingTypes, Col, FormInterface, ICurrency, OddFormat, Pager, TicketStatus } from '@bs/models';
import { AppSettingsService, DateFactoryService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseFormBuilder } from './base-form-builder';


/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({template: ``})
export class BaseTableBuilder<T=any> implements OnDestroy {
  /**
   * it changes the panel header title
   */
  @Input()
  headerTitle = 'filter';
  /**
   * if true we show a filterable input for the column in the thead
   */
  @Input()
  filterable: boolean;
  /**
   * the json configuration form
   */
  @Input()
  config: FormInterface;
  /**
   * when true we show some button, that has some export us Excel file, at the top of the table results
   */
  @Input()
  exportable: boolean;
  @Input()
  items4excel: Array<any>;
  /**
   * it represents the table cell field of the table items
   */
  @Input()
  cols: Array<Col<T>>;
  /**
   * the item that is rendered in corresponding field of the cols
   */
  @Input()
  items: Pager<T>;
  /**
   * receive and object with key (the name of the field to populate)
   * values are passed to the FormBuilderComponent
   */
  @Input()
  values: any;
  /**
   * it executes some method api call from the service
   * @description it uses a public reference of a service, and the service api method we want to run
   */
  @Input()
  useService: { service:any, method: string, params?: Params };
  /**
   * emits the callback function of a button, and the item data of that row
   */
  @Output()
  emitter: EventEmitter<{ callback: string, data: any }> = new EventEmitter();
  /**
   * local reference of BaseFormBuilder
   * @protected
   *
   * see {@link BaseFormBuilder} for more details
   */
  protected fb: BaseFormBuilder;
  /**
   * total items count
   */
  itemsCount: number;
  /**
   * shows a loader spinner until backend results are fetched
   */
  isLoading = false;
  /**
   * local reference of ICurrency
   */
  displayCurrency: ICurrency;
  /**
   * local reference of OddFormat
   */
  oddFormat: OddFormat;
  /**
   * local reference of Subscription
   */
  subs = new Subscription();
  /**
   * default base params for search
   */
  baseParams: Params = {
    pp: 10,
    p: 1
  }
  /**
   * resets form values
   */
  resetValues: any;
  /**
   * Viewport mobile
   *
   * if true, we switch some templates in the dom for mobile view
   */
  isMobile = false;

  ticketStatus = TicketStatus.values();
  bettingTypes = BettingTypes.values();

  /**
   * constructor gets the app settings and viewport and sets us the values for oddFormat, displayCurrency, and baseParams
   * @param appSettingsService
   * @param route
   * @param router
   * @param dfs
   * @param windowService
   */
  constructor(appSettingsService: AppSettingsService, private route: ActivatedRoute, private router: Router, private dfs: DateFactoryService, private windowService: WindowService) {
    this.subs.add(windowService.device$.subscribe({
     next: device => this.isMobile = device.isMobile
    }));
    this.subs.add(appSettingsService.appSettings$.subscribe({
     next: ({settings}) => {
       this.displayCurrency = settings.displayCurrency;
       this.oddFormat = settings.oddFormat;
       this.baseParams = Object.assign(this.baseParams, {displayCurrencyId: settings.displayCurrency.id});
     }
    }));
  }

  /**
   * function will act like ngAfterViewInit, where we initialize the form build
   * @param fb
   */
  afterViewInit(fb) {
    this.fb = fb;
    setTimeout(() => this.resetValues = fb.form.value, 1000);
  }

  /**
   * function unsubscribes from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * flat object, set id from values, format date to be sent
   * @param data
   * @private
   */
  protected flatten(data): any {
    return Object.entries<any>(data).reduce((acc, [k, v]) => {

      if (typeof v === 'object' && !Array.isArray(v)) {

        if (v?.id) {
          Object.assign(acc, {[k]: v.id});
        } else if (v) {
          Object.entries(v).forEach(([k2, v2]) => {

            if (v2 instanceof Date) {
              acc[k2] = this.dfs.toTz(v2, k2 === 'to');
            } else {
              acc[k2] = v2;
            }
          });
        }

      } else if (v !== '') {
        acc[k] = v;
      }
      return this.removeEmptyStrings(acc);
    }, {});
  }

  /**
   * function submits form values and creates the query params, for updating the query params in the url or fetch results without any query params
   * @param data
   */
  submit(data: any) { // submit of the form to filter the table
    // objs must be flattened
    this.isLoading = true;
    const submitted = this.flatten(data);

    const queryParams = Object.assign({}, this.baseParams, submitted);

    if (this.useService) {
      this.inPageNavigation(queryParams);
    } else {
      this.router.navigate([], {queryParams, ...this.route.snapshot.queryParamMap}).finally(() => this.isLoading = false);
    }
  }

  /**
   * function resets the form values and clears the query params from the url
   * @param fb
   */
  reset(fb) {
    fb.form.reset(this.resetValues);

    if (this.useService) {
      this.submit(this.resetValues);
    } else {
      this.isLoading = true;
      this.router.navigate([], {queryParams: null}).finally(() => this.isLoading = false);
    }
  }

  /**
   * function calls the api method from a service, and initialize the items on successful callback
   * @param queryParams
   */
  inPageNavigation(queryParams: any) {
    const {service, method, params} = this.useService;
    this.isLoading = true;
    service[method]({...params, ...queryParams}).pipe(finalize(() => this.isLoading = false)).subscribe({
     next: data => {
       this.items = data;
       this.isLoading = false;
     },

     error: error => console.error(error)
    })
  }

  /**
   * function if it has useService value, will call the inPageNavigation method, else is adding the queryParams in the url
   */
  _onPage() {
    if (this.useService) {
      const queryParams = Object.assign({}, this.baseParams, this.flatten(this.fb.form.value));
      this.inPageNavigation(queryParams);
    } else {
      this.router.navigate([], {queryParams: this.baseParams, queryParamsHandling: 'merge'}).finally(() => this.isLoading = false);
    }
  }

  /**
   * function emits us the callback function, and the item, of the buttons from the result table
   * @param $event
   * @param clickFunc
   * @param item
   */
  emitterValue($event: Event, clickFunc: string | ((e)=>void), item: any) {
    if(typeof clickFunc === 'string'){ // to be deprecated
      $event.stopPropagation();
      this.emitter.emit({callback: clickFunc, data: item});
    } else {
      clickFunc(item); // new version
    }
  }

  /**
   * function removes the empty strings from the query params
   * @param obj
   * @protected
   */
  protected removeEmptyStrings(obj) {
    const clone = {...obj};

    if (clone['from,to']) {
      const fromTo = clone['from,to'];
      clone.from = this.dfs.toTz(fromTo.from);
      clone.to = this.dfs.toTz(fromTo.to);
      delete clone['from,to'];
    }

    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }

}

import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName } from '@bs/models';
import { BookmakersService } from '@bs/services';

@Component({
  selector: 'bookmaker-picker',
  templateUrl: './bookmaker-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmakerPickerComponent),
      multi: true,
    },
  ],
})
export class BookmakerPickerComponent implements OnInit, ControlValueAccessor {
  model: number;
  isDisabled: boolean;
  bookmaker: IdName<string>;

  @Output() blur = new EventEmitter<any>(null);

  bookmakers: IdName<string>[];

  constructor(private bookmakersService: BookmakersService) {
    this.bookmakersService.getBookmakers().subscribe({
      next: (bookmakers) => {
        this.bookmakers = Array.from(bookmakers, ({ id, name }) => ({
          id,
          name,
        }));
      }
    });
  }

  ngOnInit() {}

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj?: number) {
    if (_obj != undefined) {
      this.bookmaker = this.bookmakers.find((book) => book.id === _obj);
    }
  }

  update() {
    this.model = this.bookmaker.id;
    this.propagateChange(this.model);
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  private onTouched() {}

  propagateChange(_model: any) {}
}

{
  "$schema": "../../schema.json",
  "inputs": [
    {
      "label": "name",
      "name": "name",
      "type": "text",
      "validations": {
        "required": true
      }
    },
    {
      "label": "icon",
      "name": "icon",
      "type": "text",
      "validations": {
        "required": true
      }
    },
    {
      "label": "languages",
      "name": "languages",
      "rowspan": 4,
      "type": "language-editor",
      "validations": {
        "required": true
      }
    }
  ]
}

import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { BaseTableBuilder } from '@bs/forms';
import { saveAs } from 'file-saver';
import { finalize } from 'rxjs/operators';
import { utils, write } from 'xlsx';
import { FormBuilderComponent } from '../form/form-builder.component';

interface ExcelItem {
  id?: number;
  bookmaker?: string;
  date?: Date;
  registrationDate?: Date;
  status?: string;
  bettingType?: string;
  couponCode?: string;
  causalGroup?: string;
  causal?: string;
  accountId?: number;
  username?: string;
  amount?: string;
  paid?: string;
  currencyCode?: string;
}

/**
 * the component creates a form builder, and shows us corresponding data fields with pagination
 */
@Component({
    selector: 'data-table-builder[config][cols]',
    templateUrl: './data-table-builder.component.html',
    styleUrls: ['./data-table-builder.component.scss']
  }
)
export class DataTableBuilderComponent<T = any> extends BaseTableBuilder<T> implements AfterViewInit {
  /**
   * if true, we show the tableHeaderCheckbox, that selects as the row items
   */
  @Input()
  selectable: boolean;
  /**
   * local reference to FormBuilderComponent
   */
  @ViewChild(FormBuilderComponent, {static: false})
  fb: FormBuilderComponent;
  /**
   * selected items of the table
   */
  selectedItems: Array<any>;

  /**
   * lifecycle hook, and calls inherited method from the super class BaseTableBuilder
   *
   * see {@link BaseTableBuilder} for more information
   */
  ngAfterViewInit() {
    super.afterViewInit(this.fb);
  }

  /**
   * functions resets form values, and clears the query params
   *
   * see {@link FormBuilderComponent} for more details
   */
  reset() {
    super.reset(this.fb)
  }

  /**
   * function initializes the baseParams, and inherits method _onPage() from the base class
   * @param $event
   */
  onLazy($event: any) {
    this.baseParams = Object.assign(this.baseParams, {
      p: ($event.first / $event.rows) + 1,
      pp: $event.rows
    });

    super._onPage();
  }

  /**
   * function selects a row of selected items
   * @param $event
   */
  rowSelection($event: any) {
    void $event;
    this.emitter.emit({callback: 'rowSelection', data: this.selectedItems})
  }

  /**
   * function exports the generated excel document
   */
  exportExcel() {
    const {service, method, params} = this.useService;
    this.isLoading = true;
    const queryParams = Object.assign({}, {displayCurrencyId: 1, p: 1, pp: 10000}, this.flatten(this.fb.form.value));

    service[method]({...params, ...queryParams}).pipe(finalize(() => this.isLoading = false)).subscribe({
      next: data => {
        const items4export = data;
        const items4excel: ExcelItem[] = [];
        const propertyMapping = {
          'id': 'id',
          'bookmakerId': 'bookmaker',
          'username': 'username',
          'date': 'date',
          'registrationDate': 'registrationDate',
          'status': 'status.status',
          'causal.causalGroup.name': 'causalGroup',
          'causal.name': 'causal',
          'account.id': 'accountId',
          'account.username': 'accountUsername',
          'issueDate': 'date',
          'available.amount': 'amount',
          'amount.amount': 'amount',
          'amounts.amount': 'amount',
          'amounts.currencyCode': 'currencyCode',
          'amounts.stake': 'amount',
          'amounts.paid': 'paid',
          'amounts.potentialBonus': 'potentialBonus',
          'bettingTypeId': 'bettingType',
          'ticketStatusId': 'status',
          'code': 'couponCode',
          'creationDate': 'creationDate',
          'endDate': 'endDate'
        };
        items4export.list.forEach(item => {
          const obj: ExcelItem = {};
          for (const key in propertyMapping) {
            let value = this.getPropertyValue(item, key);
            if (value !== undefined) {
              if (key === 'amounts.paid' || key === 'amounts.potentialBonus' || key === 'amounts.amount' || key === 'amounts.stake') {
                value = value / 100;
              }
              const outputKey = propertyMapping[key];
              obj[outputKey] = value;
            }
          }
          items4excel.push(obj);
        })
        const worksheet = utils.json_to_sheet(items4excel);
        const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveAsExcelFile(excelBuffer, this.headerTitle || 'file');
        this.isLoading = false;
      },

      error: error => console.error(error)
    })
  }

  getPropertyValue(item: any, key: string): any {
    const keys = key.split('.');
    let value = item;
    for (const k of keys) {
      if (value === null || value === undefined) {
        return undefined;
      }
      value = value[k];
    }
    return value;
  }

  /**
   * function reads the file converts it to blob and saves the file as Excel document
   * @param buffer
   * @param fileName
   * @private
   */
  private saveAsExcelFile(buffer: any, fileName: string) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const data: Blob = new Blob([buffer], {type});

    saveAs(data, `${fileName}_export_ ${new Date().getTime()}.xlsx`);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, GeoLocation } from '@bs/models';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable()
export class GeoIpService {

  readonly url = this.config.api.identity;

  private geoLocation: GeoLocation;

  constructor(private config: EnvironmentConfig, private client: HttpClient) {
  }

  get(): Observable<GeoLocation> {

    if (this.geoLocation) {
      return of(this.geoLocation);
    }

    return this.client.get<GeoLocation>(`${this.url}/validate/geoip`).pipe(tap(gi => this.geoLocation = gi));
  }
}

import { Component, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

/**
 * component outputs us error message of a certain field that contains error
 */
@Component({
  selector: 'field-error[control]',
  template: `
    @if (error) {
      <p-message [text]="('error-' + error.key) | translate: {value: error.value}" class="block mt-1" severity="error"></p-message>
    }`
})
export class FieldErrorComponent {
  /**
   * the control field
   */
  @Input()
  control: AbstractControl | NgControl;

  /**
   * a getter for a control, that loops thought the error message, translate it, and it outputs them in the template
   */
  get error() {

    if (this.control.untouched) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (propertyName in this.control.errors) {
        return {key: propertyName, value: this.control.errors[propertyName]};
      }
    }

    return null;
  }

}

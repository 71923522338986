import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ICatalog, ICatalogTranslation, ICatalogTranslationUpdateRequest } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogsTranslationsService {
  readonly url = this.config.api.catalogs;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(): Observable<Array<ICatalog>> {
    return this.http.get<Array<ICatalog>>(`${this.url}/catalogs`);
  }

  get(catalogId: number, bookmakerId: number, sourceLanguageId: number, targetLanguageId: number): Observable<Array<ICatalogTranslation>> {
    return this.http.get<Array<ICatalogTranslation>>(`${this.url}/catalogs/${catalogId}/translations?bId=${bookmakerId}&sLanguageId=${sourceLanguageId}&tLanguageId=${targetLanguageId}`);
  }

  update(catalogId: number, req: ICatalogTranslationUpdateRequest): Observable<ICatalogTranslation> {
    return this.http.patch<ICatalogTranslation>(`${this.url}/catalogs/${catalogId}/translations`, req);
  }

}

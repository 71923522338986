import { HTTP_INTERCEPTORS, HttpEvent, HttpEventType, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule, Provider } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { AppSettings, EnvironmentConfig, Webapps } from '@bs/models';
import { AppSettingsService, AuthService, GeoIpService } from '@bs/services';
import { WINDOW } from '@bs/universal';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';


/**
 * class for setting interceptor and setting required headers
 */
export class ConfigFactory implements HttpInterceptor {
  /**
   * hash {string}
   */
  private hash: string;
  /**
   * isMobile {boolean} sets mobile viewport
   */
  private isMobile: boolean;
  /**
   * settings variable
   */
  private settings: AppSettings;

  /**
   * The constructor where we sets up the values
   * @param window
   * @param config
   * @param authService
   * @param appSettingsService
   * @param route
   */
  constructor(private window: any, private config: EnvironmentConfig, private authService: AuthService, private appSettingsService: AppSettingsService, private route: ActivatedRoute) {

    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });

    if (this.window.navigator) {
      const fpPromise = FingerprintJS.load()
      fpPromise.then(fp => fp.get()).then(result => {
        this.hash = result.visitorId
        this.appSettingsService.saveSetting('xDeviceId', result.visitorId);
      });
    }

    this.isMobile = this.window.isMobile;

    if (!this.settings.timeZone) {
      let timeZone;
      if (Intl.DateTimeFormat) {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //console.log(timeZone)
      } else {
        // Fallback al fuso orario predefinito del sistema o UTC
        if (typeof Intl === 'undefined') {
          // Fallback a UTC
          timeZone = this.config.timeZone
        } else {
          // Fallback al fuso orario predefinito del sistema
          timeZone = this.config.timeZone
        }
      }
      this.appSettingsService.saveSetting('timeZone', timeZone);
    }

  }

  /**
   * this intercept is responsible for default headers setup, and for the user authentication functionalities
   * @param req
   * @param next
   */
  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {

    let headers = new HttpHeaders({'Accept-Language': this.settings.languageCode || this.config.defaultLangCode});

    if (this.isMobile) {
      headers = headers.append('Mobile', '1');
    }

    if (this.settings.timeZone) {
      headers = headers.append('X-Time-Zone', this.settings.timeZone);
    }

    if (this.hash) {
      headers = headers.append('X-Device-ID', this.hash);
    }

    if (this.settings?.displayCurrency) {
      headers = headers.append('DisplayCurrencyId', `${this.settings.displayCurrency.id}`);
    }

    const sid = this.authService.token;

    if (sid) {
      headers = headers.append('Authorization', `Bearer ${sid}`);
    }

    if (!this.config.production) { // debug other on staging
      this.route.queryParamMap.subscribe({
        next: paramMap => {
          if (paramMap.has('bid')) {
            this.config.bookmakerId = +paramMap.get('bid');
          }
        }
      })
    }

    if (Webapps.winchester && req.body && typeof req.body === 'object') {
      if ('bookmakerId' in req.body) {
        this.config.bookmakerId = Number(req.body['bookmakerId']);
      }
    }

    if (req.headers.has('bookmakerId')) {
      this.config.bookmakerId = +req.headers.get('bookmakerId');
    }

    headers = headers.append('BookmakerId', `${this.config.bookmakerId}`);
    headers = headers.append('WebAppId', `${this.config.webAppId}`);

    return next.handle(req.clone({headers})).pipe(
      catchError(event => {
        switch (event.status) {
          case 401:
            this.authService.logout('forced', 'not-authorized');
            break;
          case 422:
            // if string contains operation not allowed
            if (event.error.message.startsWith('[Operation not allowed')) {
              this.authService.logout('forced', 'not-authorized');
            }
            break;
          case 500:
            this.authService.logout('forced', 'not-authorized');
            this.appSettingsService.globalErrors$.next({key: event.status, value: event.error});
            break
        }
        return this.handleError(event);
      }),
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((r: HttpResponse<T>) => {
        /* const responseBody = r.body as { data: T };
         return r.clone({body: responseBody.data});*/
        if (r.body && typeof r.body === 'object' && 'data' in r.body) {
          const responseBody = r.body as { data: T };
          return r.clone({body: responseBody.data});
        } else {
          return r;
        }
      })
      //map((r: HttpResponse<T>) => r.body && 'data' in r.body ? r.clone({body: (r.body as any).data}) : r)
    );
  }

  /**
   * function expression that handles error messages of the interceptor
   * @param error {any} the error message
   */
  private handleError = (error: any) => {

    // Log.error(error);
    console.error(error);

    return throwError({
      status: {
        code: error.status,
        text: error.statusText
      },
      message: error.error.message,
      details: error.error.details,
      url: error.url
    });
  }
}

@NgModule()
export class ConfigModule {
  /**
   * forRoot separates providers from a module so we can import this module
   */
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<ConfigModule> {

    /*if (!config.production) {
      const [prjStyle, vrsStyle, bmStyle, waStyle, rmStyle] = [
        'background-color:#0AA; color:#FFF; font-style:italic; font-size:1.8em',
        'background-color:#F55; font-size:1.2em',
        'background-color:#555',
        'background-color:#898',
        'background-color:#2774f0'
      ]
      console.log(`%c ${config.project} \x1B[m %c v${config.appVersion} \x1B[m %c ${config.bookmakerId} \x1B[m %c ${config.webAppId} \x1B[m %c ${config.features.registrationModes} `, prjStyle, vrsStyle, bmStyle, waStyle, rmStyle);
    }*/

    const optionalProviders: Array<Provider> = [];

    if (config.features.geolocation) {
      optionalProviders.push(GeoIpService);
    }

    return {
      ngModule: ConfigModule,
      providers: [
        ...optionalProviders,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ConfigFactory,
          multi: true,
          deps: [WINDOW, EnvironmentConfig, AuthService, AppSettingsService, ActivatedRoute]
        },
        {
          provide: LOCALE_ID,
          useValue: 'en-GB'
        },
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}


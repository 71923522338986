import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName, WalletTypes } from '@bs/models';
import { CatalogService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'causals-picklist',
  templateUrl: './causals-picklist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CausalsPicklistComponent),
      multi: true
    }
  ]
})
export class CausalsPicklistComponent implements ControlValueAccessor, OnInit, OnDestroy {

  isDisabled: boolean;
  subs = new Subscription();
  @Output() blur = new EventEmitter<any>(null);

  @Input()
  options: { causalGroupId: number };

  source: Array<any>;
  walletTypes = WalletTypes.values();

  model: Array<IdName<string>>;
  walletTypeId: number;
  causalGroups: Array<IdName<string>>;
  causalGroupId: number;
  cgDisabled = false;

  constructor(private catalogService: CatalogService) {

    this.walletTypeId = this.walletTypes[0].id;

    this.model = [];

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  ngOnInit() {
    if (this.options.causalGroupId) {
      this.subs.add(this.catalogService.causalGroups(this.walletTypeId,[1]).subscribe({
        next: cg => {
          this.causalGroups = cg;
          this.causalGroupId = this.options.causalGroupId;
          this.cgDisabled = true;
          this.updateCausals(this.walletTypeId, this.options.causalGroupId);
        }
      }));
    } else {
      this.subs.add(this.catalogService.causalGroups(this.walletTypeId,[1]).subscribe({
        next: cg => this.causalGroups = cg
      }));
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj: any) {
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  private onTouched() {
  }

  propagateChange(_model: Array<number>) {
  }

  updateCausals(walletTypeId: number, groupId: number) {
    this.subs.add(this.catalogService.causals(walletTypeId, groupId, []).subscribe({
      next: causals => this.source = causals
    }))
  }

  update() {
    this.propagateChange(this.model.map(x => x.id))
  }

}

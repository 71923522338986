{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "typeId": 0,
  "mode": "phoneFast",
  "steps": [
    {
      "name": "phone",
      "label": "phone",
      "icon": "phone",
      "validationType": "requestMobileOtp",
      "inputs": [
        {
          "name": "phone",
          "prefixName": "prefixId",
          "type": "mobile-prefix",
          "label": "insertPhone",
          "icon": "phone",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "mobilePrefixValidator": "^[0-9]{3,15}$"
          },
          "asyncValidator": "phoneExists"
        },
        {
          "name": "validationType",
          "type": "radio",
          "values": [
            {
              "name": "validateBySms",
              "id": "sms",
              "selected": true
            },
            {
              "name": "validateByCall",
              "id": "ivr"
            }
          ]
        },
        {
          "name": "promo",
          "type": "checkbox",
          "label": "iWantToReceivePromotions",
          "icon": "ad"
        },
        {
          "name": "adult",
          "type": "checkbox",
          "label": "majorAgeDeclarationOne",
          "icon": "user",
          "validations": {
            "required": true,
            "requiredTrue": true
          }
        }
      ]
    },
    {
      "name": "otp",
      "label": "otp",
      "icon": "unlock-alt",
      "validationType": "checkMobileOtp",
      "inputs": [
        {
          "name": "otp",
          "type": "pin",
          "label": "OTP",
          "icon": "unlock",
          "validations": {
            "required": true,
            "minLength": 4
          }
        }
      ]
    },
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "user",
      "inputs": [
        {
          "name": "password",
          "icon": "key",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "icon": "key",
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20,
            "matchPassword": true
          }
        },
        {
          "name": "currencyId",
          "icon": "wallet",
          "type": "select",
          "label": "currency",
          "validations": {
            "required": true
          },
          "values:": [],
          "translateValues": false
        },
        {
          "name": "referralCode",
          "icon": "link-plus",
          "type": "text",
          "label": "referralCode",
          "validations": {
            "minLength": 4,
            "maxLength": 20
          }
        }
      ]
    }
  ]
}

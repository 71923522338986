import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

/**
 * guard that on the first visit of the application sets the :lang param
 */
@Injectable({
  providedIn: 'root'
})
export class TranslateGuard  {

  /**
   * The constructor
   * @param translate
   * @param router
   */
  constructor(private translate: TranslateService, private router: Router) {
  }

  /**
   * interface guard deciding if a route can be activated, and it sets the :lang param
   */
  canActivate(): boolean | Promise<boolean> {
    const lang = this.translate.currentLang || 'en'; // ssr has no lang by default
    const currentNavigation = this.router.getCurrentNavigation();
    return this.router.navigate([lang], {queryParams: currentNavigation.extractedUrl.queryParams});
  }


}

{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "typeId": 0,
  "mode": "playerFast",
  "steps": [
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "account-cog",
      "inputs": [
        {
          "name": "email",
          "icon": "pi-envelope",
          "type": "email",
          "label": "email",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "mailFormat": true,
            "maxLength": 64
          },
          "asyncValidator": "emailExists"
        },
        {
          "name": "username",
          "icon": "pi-user",
          "type": "text",
          "label": "username",
          "restrict": ["noSpaces", "username"],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          },
          "asyncValidator": "usernameExists"
        },
        {
          "name": "password",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20,
            "matchPassword": true
          }
        }
      ]
    },
    {
      "name": "person",
      "label": "personalData",
      "icon": "account-box",
      "inputs": [
        {
          "name": "firstName",
          "icon": "user",
          "type": "text",
          "label": "firstName",
          "restrict": ["letters"],
          "validations": {
            "required": true,
            "minLength": 2,
            "maxLength": 20
          }
        },
        {
          "name": "lastName",
          "icon": "user",
          "type": "text",
          "label": "lastName",
          "restrict": ["letters"],
          "validations": {
            "required": true,
            "minLength": 2,
            "maxLength": 20
          }
        },
        {
          "name": "genderTypeId",
          "icon": "calendar-alt",
          "type": "radio",
          "label": "gender",
          "validations": {
            "required": true
          },
          "values": [
            {
              "id": 1,
              "name": "male",
              "selected": true
            },
            {
              "id": 2,
              "name": "female"
            }
          ]
        },
        {
          "name": "birthDate",
          "icon": "account",
          "type": "date",
          "label": "birthdate",
          "options": {
            "selected": "average"
          },
          "validations": {
            "required": true,
            "adult": 18
          }
        }
      ]
    },
    {
      "name": "confirmation",
      "label": "confirmation",
      "icon": "account-box",
      "inputs": [
        {
          "name": "adult",
          "type": "checkbox",
          "label": "majorAgeDeclarationOne",
          "icon": "user",
          "validations": {
            "required": true,
            "requiredTrue": true
          }
        }
      ]
    }
  ]
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IFixtureSlug } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FixtureSlugService {
  readonly url = `${this.config.api.sportsbook}/fixtures`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  update(data: IFixtureSlug): Observable<IFixtureSlug> {
    return this.http.put<IFixtureSlug>(`${this.url}/${data.fixtureId}/slugs`, data);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ITournament, ITournamentSearchFilters, Pager } from '@bs/models';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class TournamentService {

  readonly url = `${this.config.api.sportsbook}/tournaments`;

  activeIds$ = new Subject<Array<ITournament>>();

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

    // this.parseUrlParams(route.params);
  }

  all(): Observable<Array<ITournament>> {
    return this.http.get<Array<ITournament>>(`${this.url}`).pipe(tap(data => {
      this.activeIds$.next(data);
    }));
  }

  get(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`)
  }

  getAll(betOfferId: number, categoryId: number): Observable<Array<ITournament>> {
    return this.http.get<Array<ITournament>>(`${this.url}`, {params: {betOfferId: betOfferId.toString(), cId: categoryId.toString()}});
  }

  getOne(id: number): Observable<ITournament> {
    return this.http.get<ITournament>(`${this.url}/${id}`, {params: {id}});
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  createOrUpdate(data: ITournament): Observable<ITournament> {
    return data.id ? this.http.put<ITournament>(`${this.url}/${data.id}`, data) : this.http.post<ITournament>(this.url, data);
  }

  search(filters: ITournamentSearchFilters): Observable<Pager<ITournament>> {
    return this.http.post<Pager<ITournament>>(`${this.url}/search`, filters);
  }
}

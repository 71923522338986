import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IBookmaker } from '@bs/models';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookmakersService {

  readonly url = this.config.api.accounts;
  bookmakers$: BehaviorSubject<Array<IBookmaker>> = new BehaviorSubject(null);

  private _bookmakers = this.http.get<Array<IBookmaker>>(`${this.url}/accounts/bookmakers`).pipe(shareReplay(1));

  toIdName$ = this.bookmakers$.pipe(map(bm => bm.map(b => ({id: b.id, name: b.name}))));

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getBookmakers(): Observable<Array<IBookmaker>> {
    return this._bookmakers.pipe(tap(bm => this.bookmakers$.next(bm)));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IMarketSelectionOdd, ISportEvent, ISportEventSearchFilters, Pager } from '@bs/models';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

const events: Array<ISportEvent> = []

@Injectable()
export class SportEventService {
  readonly url = `${this.config.api.sportsbook}/sport-events`;
  private prematchMenuItems$: any;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }

  /**
   * get all the current available sport event live and prematch, then create a structure for tables and menu
   */

  getAll(params: Params): Observable<Array<ISportEvent>> {

    if (params.day) {
      return of(events);
    }

    return of(events).pipe(tap(data => {
      // transform data and set to
      this.prematchMenuItems$.next();
    }));
  }

  patchBettingStatus(sportEventId: number, bettingStatusId: number): Promise<ISportEvent> {
    return this.http.patch<ISportEvent>(`${this.url}/${sportEventId}/betting-status`, bettingStatusId).toPromise();
  }

  patchEventStatus(sportEventId: number, eventStatusId: number): Promise<ISportEvent> {
    return this.http.patch<ISportEvent>(`${this.url}/${sportEventId}/betting-status`, eventStatusId).toPromise();
  }

  patchDate(sportEventId: number, newDate: string): Promise<ISportEvent> {
    return this.http.patch<ISportEvent>(`${this.url}/${sportEventId}/date`, newDate).toPromise();
  }

  getByFastBetCode(code: number): Observable<ISportEvent> {
    return this.http.get<ISportEvent>(`${this.url}`, {params: {c: code}});
  }

  search(filters: ISportEventSearchFilters): Observable<Pager<ISportEvent>> {
    return this.http.post<Pager<ISportEvent>>(`${this.url}/search`, filters);
  }

  getOdd(sportEventId: number, oddId: number): Observable<IMarketSelectionOdd> {
    return this.http.get<IMarketSelectionOdd>(`${this.url}/${sportEventId}/odds/${oddId}`);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBookmaker } from '@bs/models';

/**
 * this pipe filters all the bookmakers, so that data-table-builder component shows us the correct field
 */
@Pipe({name: 'bookmakerName'})
export class BookmakerNamePipe implements PipeTransform {
  /**
   * bookmakers items
   */
  items: IBookmaker[];

  /**
   * The constructor fetches the bookmakers items
   */
  constructor(route: ActivatedRoute) {
    const bookmakers = route.root.firstChild.firstChild.snapshot.data.bookmakers;
    if (bookmakers) {
      this.items = bookmakers;
    } else {
      throw new Error('This pipe requires BookmakersResolver to be placed in the route')
    }
  }

  /**
   * return us matched bookmaker name
   * @param value {number} requires the bookmakers id to be passed
   */
  transform(value: number): string {
    return this.items.find(x => x.id === value).name
  }
}

import { IdName, idNames } from '../common/id-name';
import { IAccount } from '../me/me';

export interface INetworkActivity {
  id: number;
  bookmakerId: number;
  account: IAccount;
  tpl?: string;
}


export interface INetworkActivitySearchRequest {
  accountId?: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}


export enum INetworkModule {
  cms = 1,
  promotions = 2,
  sportsbook = 3,
  identity = 4,
  wallets = 13,
  games = 14,
  bonus = 15
}

export interface INetworkActivityType {
  id: number;
  moduleId: number;
  name: string;
}

export namespace NetworkModule {
  export function values(): Array<IdName<string>> {
    return idNames(INetworkModule);
  }
}

import { AfterViewInit, Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroupDirective, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IBaseGame, IdName, IGameType } from '@bs/models';
import { GamesManagementService } from '@bs/services';
import { ConfirmationService } from 'primeng/api';

type OfferType = 'provider' | 'game' | 'all';

interface GameOffer {
  games?: Array<number>,
  providers?: Array<number>
}

@Component({
  selector: 'games-offer',
  templateUrl: './games-offer.component.html',
  styleUrls: ['./games-offer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GamesOfferComponent),
      multi: true,
    },
  ],
})
export class GamesOfferComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  tab: OfferType = 'all';
  form = this.fb.group({
    providerOffer: [],
    gameOffer: this.fb.array([]),
  });

  providers: Array<IdName<string>> = [];
  games: { [key: string]: Array<IBaseGame> } = {}
  selectedProviders = [];
  gameTypeId: number;
  gameType: IGameType;

  @Input()
  options: any;

  @Output() blur = new EventEmitter<any>(null);

  inProviders = provider => !this.selectedProviders.includes(provider.id);

  constructor(private fb: FormBuilder, private injector: Injector, private gamesManagementService: GamesManagementService,
              private confirmationService: ConfirmationService) {
    this.form.valueChanges.subscribe({
      next: data => {

        if (data.gameOffer.length) {
          const games = data.gameOffer.filter((g: { gameIds }) => g.gameIds).flatMap((go: { gameIds }) => go.gameIds);
          if (games.length) {
            return this.propagateChange({games});
          }
        }

        if (data.providerOffer?.length) {
          return this.propagateChange({providers: data.providerOffer});
        }

        if(this.tab ==='all'){
          this.propagateChange('all');
        } else {
          this.propagateChange(null);
        }
      }
    });
  }

  ngOnInit() {
    this.setDisabledState(true);
  }

  ngAfterViewInit(): void {
    const control: FormGroupDirective = this.injector.get(FormGroupDirective, null);
    const gameTypeCtrl = control.form.get(this.options.gameTypeField ?? 'gameType');
    if (gameTypeCtrl) {
      gameTypeCtrl.valueChanges.subscribe({
        next: val => {
          if (val) {
            this.gameTypeId = val;
            this.setDisabledState(false);
          }
        }
      })
    } else {
      // Component is missing form control binding
      throw new Error('this controller need a parent gameType');
    }
  }

  private switchTab(tab: OfferType) {
    this.tab = tab;

    switch (tab) {
      case 'provider':
        this.gameOffer.setValue([]);
        this.gamesManagementService.getGameType(this.gameTypeId, this.options.bookmakerId).subscribe({
          next: p => {
            this.gameType = p
            this.providers = this.gameType.providers
          }
        });
        break;
      case 'game':
        this.form.get('providerOffer').setValue(null);
        this.gamesManagementService.getGameType(this.gameTypeId, this.options.bookmakerId).subscribe({
          next: p => {
            this.gameType = p
            this.providers = this.gameType.providers
          }
        });
        break;
      case 'all':
        this.gameOffer.setValue([]);
        this.form.get('providerOffer').setValue(null);
        break;
    }
  }

  setTab(tab: OfferType) {
    if (this.tab === 'all') {
      this.switchTab(tab)
      return;
    }

    this.confirmationService.confirm({
      message: 'Are you sure that you want to change offer?\n all your data will be lost!',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => this.switchTab(tab)
    });
  }

  setGames(providerId: number, index: number) {
    this.selectedProviders.push(providerId);
    this.games[index] = this.gameType.gameSubTypes.flatMap(g => g.games).filter(x => x?.provider.id === providerId)
  }

  setProvider(providerId: number) {
    this.selectedProviders.push(providerId);
  }

  addOffer() {
    this.gameOffer.push(
      this.fb.group({
        providerId: ['', Validators.required],
        gameIds: ['', Validators.required]
      })
    );
  }

  removeOffer(index: number) {
    const prov = this.gameOffer.at(index).get('providerId').value;
    const idx = this.selectedProviders.findIndex(p => p === prov);
    if (idx > -1) {
      this.selectedProviders.splice(idx, 1);
    }
    this.gameOffer.removeAt(index);
  }

  get gameOffer() {
    return this.form.get('gameOffer') as FormArray;
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  onTouched() {
  }

  /**
   * propagate the blur, and update the form model on blur
   * @param event
   */
  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * we assign a value to the model, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: any) {
    if (obj) {
      this.form.patchValue(obj, {emitEvent: false});
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param _fn
   */
  registerOnChange(_fn: any): void {
    this.propagateChange = _fn;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: GameOffer | 'all') {
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}

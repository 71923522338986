<html>

<head>
  <meta charset="utf-8">
  <title>{{'report-cashier-receipt-title' | translate}}</title>
</head>

<body style="font-family: sans-serif;font-size: 14px; color: black;">

<div id="printable" style="width: 384px;">
  <img src="{{host}}/assets/images/logo-black.svg" alt="logo" width="60%" style="display: block; margin: auto; padding-top: 20px;">

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'total-in' | translate}}:</strong>
    <span>{{totalIn | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'total-out' | translate}}:</strong>
    <span>{{totalOut | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'total-balance' | translate}}:</strong>
    <span>{{totalBalance | displayCurrencyConversion}}</span>
  </div>

  <div style="margin-top:10px; border-width: 1px 0; border-style: dashed; border-color: #363636;">
    <div style="padding: 5px 11px;">
      <strong>{{'sportsbook-ticket-sales' | translate}}</strong>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-sold-count' | translate}}:</strong>
    <span>{{report.ticketsSold.count}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-sold' | translate}}:</strong>
    <span>{{report.ticketsSold.sold | displayCurrencyConversion}}</span>
  </div>

  <div style="margin-top:10px; border-width: 1px 0; border-style: dashed; border-color: #363636;">
    <div style="padding: 5px 11px;">
      <strong>{{'tickets-paid' | translate}}</strong>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-paid-count' | translate}}:</strong>
    <span>{{report.ticketsPaid.count}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-paid-void' | translate}}:</strong>
    <span>{{report.ticketsPaid.void | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-paid-refunded' | translate}}:</strong>
    <span>{{report.ticketsPaid.refunded | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'tickets-paid-won' | translate}}:</strong>
    <span>{{report.ticketsPaid.won | displayCurrencyConversion}}</span>
  </div>

  <!--    <div style="margin-top:10px; border-width: 1px 0; border-style: dashed; border-color: #363636;">-->
  <!--      <div style="padding: 5px 11px;">-->
  <!--        <strong>{{'ticketsToPay' | translate}}</strong>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">-->
  <!--      <strong>{{'ticketCount' | translate}}:</strong>-->
  <!--      <span>{{report.ticketsToPay.count}}</span>-->
  <!--    </div>-->

  <!--    <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">-->
  <!--      <strong>{{'ticketVoid' | translate}}:</strong>-->
  <!--      <span>{{report.ticketsToPay.void | displayCurrencyConversion}}</span>-->
  <!--    </div>-->

  <!--    <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">-->
  <!--      <strong>{{'ticketRefunded' | translate}}:</strong>-->
  <!--      <span>{{report.ticketsToPay.refunded | displayCurrencyConversion}}</span>-->
  <!--    </div>-->

  <!--    <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">-->
  <!--      <strong>{{'ticketWon' | translate}}:</strong>-->
  <!--      <span>{{report.ticketsToPay.won | displayCurrencyConversion}}</span>-->
  <!--    </div>-->

  <div style="margin-top:10px; border-width: 1px 0; border-style: dashed; border-color: #363636;">
    <div style="padding: 5px 11px;">
      <strong>{{'network-transfers' | translate}}</strong>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'withdrawal-in-shop-requested' | translate}}:</strong>
    <span>{{report.withdrawalShopRequest.amount | displayCurrencyConversion}}</span>
  </div>

  <div style="margin-top:10px; border-width: 1px 0; border-style: dashed; border-color: #363636;">
    <div style="padding: 5px 11px;">
      <strong>{{'top-up' | translate}}</strong>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'top-up-count' | translate}}:</strong>
    <span>{{report.topUp.count}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 5px; padding: 0 11px;">
    <strong>{{'top-up-amount' | translate}}:</strong>
    <span>{{report.topUp.amount | displayCurrencyConversion}}</span>
  </div>

  <div style="margin: 50px 40px 0; font-size: 12px">
    <hr>{{'signature-name-and-surname' | translate}}
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 20px; padding: 8px 11px 0; border-top: 1px dashed #363636;">
    <strong>{{'generated-on' | translate}}:</strong>
    <span>{{today | localizedDate: 'dd-MM-yyyy, HH:mm:ss'}}</span>
  </div>

</div>

</body>
</html>

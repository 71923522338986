{
  "$schema": "../../schema.json",
  "inputs": [
    {
      "label": "dateRange",
      "name": "from,to",
      "type": "date-range",
      "rowspan": 3
    },
    {
      "label": "ip",
      "name": "ipAddress",
      "type": "text"
    }
  ]
}

import { IdName, idNames } from '../common/id-name';
import { IAccountBase } from '../wallet/movement';
import { NetworkBettingThresholdBase, SettingValue, TemplateVersionNetwork } from './settings';

export enum SettingsTemplateTypes {
  account = 1,
  sportsbook = 2,
  operator = 3,
  paymentsMethods = 4,
  earnings = 5
}

export namespace SettingsTemplateTypes {
  export function values(): Array<IdName<string>> {
    return idNames(SettingsTemplateTypes);
  }
}

/*
export interface SettingTemplateBase {
  id: number;
  name?: string;
  author: IAccountBase;
  typeId: number;
  version: TemplateVersionBase;
}*/

export interface ISettingTemplate {
  id: number;
  name?: string;
  author: IAccountBase;
  templateTypeId: number;
  version: ISettingTemplateVersion;
  currencyId: number;
  networkTypeId: number;
  // bookmakers: Array<number>;
  bookmakerId: number;
  versions?: Array<ISettingTemplateVersion>;
}

export interface ISettingTemplateRequest {
  settingTemplateId: number;
  templateTypeId: number;
  networkTypeId: number;
}

export interface ISettingTemplateVersion extends TemplateVersionBase {
  creationDate: string;
  endDate: string;
}

export interface TemplateVersionBase {
  id?: number;
  author?: IAccountBase;
  settingValues?: Array<SettingValue>
  // settingThresholdValue?: Array<SettingThresholdValue>;
  bettingThresholds?: Array<NetworkBettingThresholdBase>;
  network?: TemplateVersionNetwork;
}


export interface ISettingsTemplatesRequest {
  displayCurrencyId: number;
  typeId: number;
  networkTypeId: number;
  from?: string;
  to?: string;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface ISettingsTemplateVersionSearchRequest {
  settingTemplateId: number;
  p: number;
  pp: number;
}

export interface ISettingGroup {
  id: number;
  name: string;
  settingTypes?: Array<ISettingType>;
}

export interface ISettingType {
  id: number;
  name: string;
  templateTypeId: number;
  settingGroupId: number;
  thresholdTypes?: Array<IThresholdType>;
  enabled?: boolean;
}

export interface IThresholdType {
  id: number;
  name: string;
  dataType: 'value' | 'odd' | 'currency' | 'percentage' | 'minute';
  min: number;
  max: number;
}

export interface IBettingGroup {
  id: number;
  name: string;
  limitTypes: Array<IBettingLimitType>;
}

export interface IBettingLimitType {
  id: number;
  name: string;
  BettingTypes
  bettingTypes: Array<IBettingType>
}

export interface IBettingType {
  id: number;
  name: string;
  thresholdTypes?: IThresholdType[];
}

export interface ISettingTemplateSubNetwork {
  networkTypeId: number;
  templateTypeId: number;
  networkId?: number;
  templateId?: number;
  template?: string;
  templateVersionId?: number;
  currencyId?: number;
  creationDate?: string;
  author?: IAccountBase;
}

export class Limits {
  constructor(public maxBet: number,
              public maxBetCombination: number,
              public maxOdd: number,
              public maxWin: number,
              public maxWinCombination: number,
              public minBet: number,
              public minBetCombination: number) {
  }
}

/**
 * @deprecated old models are empty classes and very confusional
 */
export class ISettingsTemplateSportsBook {
  constructor(public limits: Limits = null, public parameters: IParameters) {
  }
}

export interface IParameters {
  canPlayPreMatch: boolean,
  canPlayLiveBetting: boolean,
  canPlaySingle: boolean,
  canPlaySystem: boolean,
  canVoidBet: boolean
}

export interface ISettingTemplateSubNetworkCatalog {
  networkTypeId: number;
  templateTypeId: number;
}


export interface ISettingTemplateRequest {
  networkTypeId: number;
  templateTypeId: number;
  settingTemplateId: number;
}

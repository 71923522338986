import { IdName, idNames } from '../common/id-name';


export enum NoteTypes {
  MainNote = 1,
  BookingNote,
  CashierNote,
}

export namespace NoteTypes {
  export function values(): Array<IdName<string>> {
    return idNames(NoteTypes);
  }
}

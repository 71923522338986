import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/core/auth.service';
import { CatalogService } from '../services/core/catalog.service';

/**
 * this guard is to check if a page or a set of pages can be visited, depending on "permission" param, associated to the route. the same param must be present in the booleans params of the logged user.
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard  {

  /**
   * The constructor
   * @param authService
   * @param router
   * @param catalog
   */
  constructor(
    private authService: AuthService,
    private router: Router,
    private catalog: CatalogService,
  ) {
  }

  /**
   * interface guard deciding if a route can be activated, if user contains the permission param associated to the route
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const lang = route.params.lang || route.root.firstChild.params.lang;

    if (this.authService.isValid()) {
      return new Promise((resolve, reject) => {
        this.catalog.currencies().subscribe({
          next: currencies => {
            if (currencies.length) {
              this.authService.getAuthentication().subscribe({
                next: me => {
                  const iCan = me.permissions?.booleans?.includes(route.data.permission);
                  if (iCan) {
                    return resolve(true);
                  } else {
                    return resolve(this.router.createUrlTree([lang, 'not-authorized']));
                  }
                },
                error: error=>reject(error)
              })
            }
          }
        })
      });

    } else {
      return this.router.createUrlTree([lang], {fragment: 'login'});
    }

  }

  /**
   * interface guard deciding if a child route can be activated
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute);
  }
}

import { AfterViewInit, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IBookmaker, IdName } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'webapps',
  templateUrl: './webapps.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WebappsComponent),
      multi: true
    }
  ],
  styles: [`:host {
    display: block;
  }`]
})
export class WebappsComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  bookmakers: Array<IBookmaker>;
  // webApps: Array<IdName<string>>;

  bookmaker: IBookmaker;
  webApp: IdName<string>;

  subs = new Subscription();

  constructor(private route: ActivatedRoute, private appSettingService: AppSettingsService) {
   this.subs.add(route.data.subscribe({
     next: data => {
        this.bookmakers = data.bookmakers.sort((a, b) => a.name.localeCompare(b.name));
      }
   }));

    this.subs.add(appSettingService.appSettings$.subscribe({
      next: ({settings}) => {
        this.bookmaker = settings.bookmaker;
        // this.webApps = this.bookmaker.webApps;
        this.webApp = settings.bookmaker.webApps[0];
      }
    }));
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.update(this.bookmaker.id, this.webApp?.id);
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: {bookmakerId: number, webAppId: number}): void {
    if (obj) {
      const b = this.bookmakers.find(b => b.id === obj.bookmakerId);
      if (b) {
        this.bookmaker = b;
        //this.webApps = b.webApps;
        //const w = this.webApps?.find(w => w.id === obj.webAppId);
        /*if (w) {
          this.webApp = w;
        }*/
      }
    }
  }

  update(bookmakerId: number, webAppId?: number) {

    if(webAppId){
      this.propagateChange({bookmakerId, webAppId});
    } else {
      this.webApp = this.bookmaker.webApps[0];
      this.propagateChange({bookmakerId, webAppId: this.webApp.id});
    }
  }

  private onTouched() {
  }

  private propagateChange(_model: any) {
  }

  /*setWebApps(webApps: Array<IdName<string>>): void {
    //this.webApps = webApps;
    this.webApp = this.webApps[0];
    this.update(this.bookmaker.id, this.webApp?.id);
  }*/
}

import { IAuthor } from '../common/author';
import { CurrencyConversion } from '../common/currency';
import { IdName, idNames } from '../common/id-name';

export enum LimitTypes {
  standard = 1,
  booking = 2,
  cumulative = 3
}

export namespace LimitTypes {
  export function values(): Array<IdName<string>> {
    return idNames(LimitTypes);
  }
}

export interface ISportEventLimit extends CurrencyConversion {
  sportEventId: number;
  limitTypeId: LimitTypes;
  // offerTypeId: number;
  maxBet: number;
  maxWin: number;
  fromDate: string;
  endDate: string;
  author: IAuthor;
  date: string;
}

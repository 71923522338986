import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { TranslateModule } from '@ngx-translate/core';
/* COMPONENTS */
import { DrawerMovementReceiptComponent } from './components/drawer-movement-receipt/drawer-movement-receipt.component';
import { PrinterReceiptComponent } from './components/printer-receipt/printer-receipt.component';
import { ReportsCashierReceiptComponent } from './components/reports-cashier-receipt/reports-cashier-receipt.component';
import { TransferReceiptComponent } from './components/transfer-receipt/transfer-receipt.component';
import { WalletsReceiptComponent } from './components/wallets-receipt/wallets-receipt.component';
/* DIRECTIVES */
import { SvgIconDirective } from './directives/svg-icon.directive';
import { SwiperDirective } from './directives/swiper.directive';
import { EllipsisDirective } from './directives/text-ellipsis.directive';
/* PIPES */
import { AmountTooltipPipe } from './pipes/amountTooltip.pipe';
import { BalanceTooltipPipe } from './pipes/balanceTooltip.pipe';
import { CountPipe } from './pipes/count.pipe';
import { CurrencyAmountPipe } from './pipes/currency-amount.pipe';
import { CurrencyAttributesPipe } from './pipes/currencyAttributes.pipe';
import { DateProxyPipe } from './pipes/date-proxy.pipe';
import { DeliveryTypePipe } from './pipes/deliveryType.pipe';
import { DisplayCurrencyConversionPipe } from './pipes/display-currency-conversion.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { FindPipe } from './pipes/find.pipe';
import { GameTypeSectionPipe } from './pipes/game-type-section.pipe';
import { GeoInfoTypePipe } from './pipes/geoInfoType.pipe';
import { GroupByPipe } from './pipes/groupBy.pipe';
import { HasPermissionPipe } from './pipes/hasPermission.pipe';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { MoneyFormatPipe } from './pipes/money-format.pipe';
import { OddFormatPipe } from './pipes/odd-format.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SluggifyPipe } from './pipes/sluggify.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SwitchMultiCasePipe } from './pipes/swichMultiCase.pipe';
import { TrustedPipe } from './pipes/trusted.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { UniversalModule } from '@bs/universal';
import { BsFormsModule } from '@bs/forms';

/**
 * array of all the components or directives or pipes that goes in NgModule declarations and exports, so they can be read in another module
 */
const collection = [
  DrawerMovementReceiptComponent,
  ReportsCashierReceiptComponent,
  TransferReceiptComponent,
  WalletsReceiptComponent,
  PrinterReceiptComponent,
  // DIRECTIVES
  SvgIconDirective,
  EllipsisDirective,
  // PIPES
  GameTypeSectionPipe,
  CurrencyAttributesPipe,
  CurrencyAmountPipe,
  DisplayCurrencyConversionPipe,
  CountPipe,
  DeliveryTypePipe,
  FilterPipe,
  FindPipe,
  GeoInfoTypePipe,
  GroupByPipe,
  HasPermissionPipe,
  LocalizedDatePipe,
  MoneyFormatPipe,
  OddFormatPipe,
  TrustedPipe,
  AmountTooltipPipe,
  BalanceTooltipPipe,
  SortPipe,
  SwitchMultiCasePipe,
  ReplacePipe,
  SluggifyPipe,
  DateProxyPipe,
  UniquePipe
];

@NgModule({
  declarations: collection,
  imports: [
    CommonModule,
    BsFormsModule,
    TranslateModule,
    UniversalModule,
    SwiperDirective,
  ],
  exports: [...collection, CommonModule, TranslateModule, UniversalModule, SwiperDirective],
  providers: [SluggifyPipe, DateProxyPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BsCommonModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<BsCommonModule> {
    return {
      ngModule: BsCommonModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}

import { ReportData } from '../accounts/reports';

export class NetworkCashFlowReport extends ReportData<NetworkCashFlowReport> {
  cashIn: number;
  cashOut: number;
  cashOutPending: number;
  in: number;
  out: number;
  pending: number;

  initialize(x){
    x.net = {
      cashIn: x.cashIn,
      cashOut: x.cashOut,
      cashOutPending: x.cashOutPending
    };

    x.line = {
      cashIn: x.cashIn,
      cashOut: x.cashOut,
      cashOutPending: x.cashOutPending
    };
  }
}

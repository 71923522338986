{
  "$schema": "../schema.json",
  "inputs": [
    {
      "filter": "name",
      "label": "country",
      "name": "country",
      "placeholder": "select",
      "type": "select",
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "filter": "name",
      "label": "region",
      "name": "region",
      "placeholder": "select",
      "type": "select",
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "filter": "name",
      "label": "province",
      "name": "province",
      "placeholder": "select",
      "type": "select",
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "filter": "name",
      "label": "city",
      "name": "city",
      "placeholder": "select",
      "type": "select",
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "label": "address",
      "name": "address",
      "validations": {
        "required": true
      }
    },
    {
      "label": "zipCode",
      "name": "zipCode",
      "validations": {
        "birthPlace": true,
        "required": true
      }
    }
  ]
}

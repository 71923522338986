<div class="p-inputgroup">
  <p-dropdown (onHide)="focusNext()" (ngModelChange)="update({prefix:$event, phone:model?.phone})" [filter]="true" [ngModel]="model?.prefix" [options]="countries" [placeholder]="placeholder | translate" appendTo="body" class="prefix-container" filterBy="name"
    optionLabel="name">
    <ng-template let-item pTemplate="selectedItem">
      <div class="flex align-items-center">
        <div class="flag-rounded flex align-items-center justify-content-center mr-1">
          <svg [icon]="item.code.toLowerCase()" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
        </div>
        <span class="text">+{{item.prefix}}</span>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="flex align-items-center">
        <div class="flag-rounded flex align-items-center justify-content-center mr-1">
          <svg [icon]="item.code.toLowerCase()" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
        </div>
        <span class="text">{{item.name}} (+{{item.prefix}})</span>
      </div>
    </ng-template>
  </p-dropdown>
  @if (model) {
    <p-inputMask (onBlur)="onBlur($event)" [slotChar]="''" (ngModelChange)="update({prefix:model?.prefix, phone:$event})" [(ngModel)]="model.phone" [autoClear]="false"
    [disabled]="isDisabled" [unmask]="true" class="mobile-phone" mask="999 999 999 999" type="tel"></p-inputMask>
  }
</div>

import { ICurrency } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { ILanguage } from './language';
import { ISport } from './sport';

export enum BetOfferStatus {
  created = 1,
  enabled = 2,
  disabled = 3
}

export namespace BetOfferStatus {
  export function values(): Array<IdName<string>> {
    return idNames(BetOfferStatus);
  }
}

export enum OfferTypes {
  preMatch = 1,
  live,
  mixed
}

export namespace OfferTypes {
  export function values(): Array<IdName<string>> {
    return idNames(OfferTypes);
  }
}

export interface IBetOfferBase {
  sports?: Array<ISport>;
}

export interface IBetOffer extends IBetOfferBase {
  id: number;
  name: string;
  languages?: Array<ILanguage>;
  bookmakers?: Array<number>
  currencies?: Array<IBetOfferCurrency>;
  options: any;
}

export interface IBetOfferCurrency extends ICurrency {
  enabled: boolean;
}

export interface IBetOfferInProgressRequest {
  eventTypeId?: string;
  offerTypeId?: string;
  fromDate?: string;
  toDate?: string;
}



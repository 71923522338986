<p-dropdown (ngModelChange)="changeLanguage($event)" [(ngModel)]="currentLang" [options]="langs" optionLabel="name">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center">
      <div class="flag-rounded flex align-items-center justify-content-center mr-2">
        <svg [icon]="currentLang.code" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
      </div>
      <span>{{currentLang.name}}</span>
    </div>
  </ng-template>
</p-dropdown>

import { Pipe, PipeTransform } from '@angular/core';

import { GeoInfoType } from '@bs/models';

// todo: apparently used only in krusty
/**
 * this pipe finds the matching value from the enum GeoInfoType, and returns the key and value for that type the matched value from enum is Residence
 */
@Pipe({name: 'geoInfoType'})
export class GeoInfoTypePipe implements PipeTransform {

  /**
   * return us the geoInfoType value
   * @param value {number} requires the value number of the enum GeoInfoType
   */
  transform(value: number): string {
    return GeoInfoType.values().find(t => t.key === value)?.value;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ICategory, ICategorySearchFilters, Pager } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  readonly url = `${this.config.api.sportsbook}/categories`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(betOfferId: number, sportId: number): Observable<Array<ICategory>> {
    return this.http.get<Array<ICategory>>(`${this.url}`, {params: {betOfferId: betOfferId.toString(), sId: sportId.toString()}});
  }

  get(id: number): Observable<ICategory> {
    return this.http.get<ICategory>(`${this.url}/${id}`, {params: {id}});
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  createOrUpdate(data: ICategory): Observable<ICategory> {
    return data.id ? this.http.put<ICategory>(`${this.url}/${data.id}`, data) : this.http.post<ICategory>(this.url, data);
  }

  search(filters: ICategorySearchFilters): Observable<Pager<ICategory>> {
    return this.http.post<Pager<ICategory>>(`${this.url}/search`, filters);
  }
}

<ng-content></ng-content>

@if (input.type !== 'checkbox' && input.label) {
  <label [class.req]="input.validations?.required"
         [for]="input.name">{{ input.label | translate }}</label>
}

<div class="p-inputgroup">

  <ng-content select=".pre"></ng-content>
  @switch (input.type) {
    @default {
      <input (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
             [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"
             [restrict]="input?.restrict"
             [pTooltip]="tooltip" [placeholder]="input.placeholder | translate" [readonly]="input.readOnly"
             [type]="input.type"
             pInputText>
    }
    @case ('number') {
      <p-inputNumber (onBlur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                     [format]="false"
                     [(ngModel)]="model" [disabled]="isDisabled" [inputId]="input.name" [name]="input.name"
                     [placeholder]="input.placeholder | translate" [useGrouping]="false"
                     [min]="input.options?.min || undefined" [max]="input.options?.max || undefined"></p-inputNumber>
    }
    @case ('number-decimal') {
      <p-inputNumber (onBlur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                     [(ngModel)]="model" [disabled]="isDisabled" [inputId]="input.name" [name]="input.name"
                     [placeholder]="input.placeholder | translate"
                     [min]="input.options?.min || undefined" [max]="input.options?.max || undefined" mode="decimal"
                     [minFractionDigits]="input.options?.maxFractionDigits"
                     [maxFractionDigits]="input.options?.maxFractionDigits"></p-inputNumber>
    }
    @case ('file') {
      <p-fileUpload #fuRef (onClear)="onRemoveFile()" (onRemove)="onRemoveFile()"
                    (onSelect)="onSelectFile($event.files[0], fuRef)" [customUpload]="true"
                    [disabled]="isDisabled"
                    [id]="input.name" [multiple]="false" [name]="input.name" [showUploadButton]="false"
                    accept="image/jpeg, image/png, application/pdf"
                    maxFileSize="1000000" mode="advanced" ngDefaultControl>
      </p-fileUpload>
    }
    @case ('date') {
      <p-calendar (ngModelChange)="propagateChange($event)" (onBlur)="onBlur($event)"
                  [(ngModel)]="model" [disabled]="isDisabled" [inputId]="input.name" [monthNavigator]="hasRange"
                  [name]="input.name" [placeholder]="input.placeholder | translate" [readonlyInput]="input.readOnly"
                  [maxDate]="fromJSDate(input?.options?.max)" [minDate]="fromJSDate(input.options?.min)"
                  [showIcon]="!input.readOnly" [showOnFocus]="!input.readOnly" [yearNavigator]="hasRange"
                  [yearRange]="yearRange" appendTo="body"></p-calendar>
    }
    @case ('date-time') {
      <p-calendar (ngModelChange)="propagateChange($event)" (onBlur)="onBlur($event)"
                  [showTime]="true"
                  [(ngModel)]="model" [disabled]="isDisabled" [inputId]="input.name" [monthNavigator]="hasRange"
                  [name]="input.name" [placeholder]="input.placeholder | translate" [readonlyInput]="input.readOnly"
                  [maxDate]="fromJSDate(input?.options?.max)" [minDate]="fromJSDate(input.options?.min)"
                  [showIcon]="!input.readOnly" [showOnFocus]="!input.readOnly" [yearNavigator]="hasRange"
                  [yearRange]="yearRange" appendTo="body"></p-calendar>
    }
    @case ('checkbox') {
      <div class="p-field-checkbox">
        <p-checkbox (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                    [(ngModel)]="model" [binary]="true" [disabled]="isDisabled" [inputId]="input.name"
                    [name]="input.name"></p-checkbox>
        <label [for]="input.name" class="ml-2">{{ input.label | translate }}</label>
      </div>
    }
    @case ('date-range') {
      <date-range (ngModelChange)="propagateChange($event)"
                  [maxDate]="fromJSDate(input?.options?.max)" [minDate]="fromJSDate(input.options?.min)"
                  [options]="input?.options"
                  [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [mode]="input.mode"
                  [name]="input.name"></date-range>
    }
    @case ('mobile-prefix') {
      <mobile-prefix (ngModelChange)="propagateChange($event)"
                     [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"
                     [placeholder]="input.placeholder"></mobile-prefix>
    }
    @case ('radio') {
      @for (option of values; track option) {
        <div>
          <p-radioButton (ngModelChange)="propagateChange($event)" [(ngModel)]="model" [inputId]="option.id"
                         [name]="input.name" [value]="option.id"></p-radioButton>
          <label [for]="option.id" class="ml-2">{{ option.name | translate }}</label>
        </div>
      }
    }
    @case ('select-button') {
      <p-selectButton [options]="values" [(ngModel)]="model"
                      (ngModelChange)="propagateChange($event)" [disabled]="input.disabled || isDisabled"
                      [id]="input.name" [name]="input.name" optionLabel="name"
                      optionValue="id"></p-selectButton>
    }
    @case ('slide-toggle') {
      <p-inputSwitch class="flex-grow-0" (ngModelChange)="propagateChange($event)"
                     [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"></p-inputSwitch>
    }
    @case ('textarea') {
      <textarea (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"
                [restrict]="input?.restrict"
                [placeholder]="input.placeholder | translate" cols="30" pInputTextarea rows="10"></textarea>
    }
    @case ('select') {
      @if (input.options?.async ? (input.values|async) : (input.values || []); as selectValues) {
        <p-dropdown (ngModelChange)="propagateChange($event)" (onChange)="onBlur($event)"
                    [(ngModel)]="model" [disabled]="isDisabled" [filterBy]="input.filter" [filter]="!!input.filter"
                    [inputId]="input.name" [name]="input.name" [options]="selectValues" [readonly]="input.readOnly"
                    [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                    optionLabel="name" optionValue="id" [appendTo]="input.appendTo"></p-dropdown>
      }
    }
    @case ('multi-select') {
      @if (input.options?.async ? (input.values|async) : (input.values || []); as selectValues) {
        <p-multiSelect (ngModelChange)="propagateChange($event)" (onChange)="onBlur($event)"
                       [(ngModel)]="model" [disabled]="isDisabled" [readonly]="values?.length===1 || input.readOnly"
                       [id]="input.name" [name]="input.name" [options]="selectValues"
                       [placeholder]="input.placeholder | translate" [autofocusFilter]="true"
                       optionLabel="name" optionValue="id" selectedItemsLabel="{0} items selected">
        </p-multiSelect>
      }
    }
    @case ('parent-selector') {
      <parent-selector (ngModelChange)="propagateChange($event)"
                       [(ngModel)]="model" [disabled]="isDisabled"
                       [id]="input.name" [name]="input.name" [options]="input.options"></parent-selector>
    }
    @case ('password') {
      <password (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                [(ngModel)]="model" [restrict]="input?.restrict"
                [disabled]="isDisabled" [hasStrength]="input.hasStrength" [id]="input.name" [name]="input.name"
                [placeholder]="'password' | translate"
                [validations]="input.validations"></password>
    }
    @case ('amount-range') {
      <amount-range (ngModelChange)="update($event)"
                    [(ngModel)]="model" [disabled]="isDisabled" [id]="input.name" [name]="input.name"></amount-range>
    }
    @case ('amount') {
      <p-inputNumber (ngModelChange)="update($event)" [(ngModel)]="model" mode="currency" [useGrouping]="false"
                     [allowEmpty]="false" [currency]="input.options?.currencyCode"
      ></p-inputNumber>
    }
    @case ('bookmakers-currencies') {
      <bookmakers-currencies [(ngModel)]="model" (ngModelChange)="update($event)" [id]="input.name" [name]="input.name"
                             [options]="input.options" [disabled]="isDisabled"
      ></bookmakers-currencies>
    }
    @case ('language-editor') {
      <language-editor (ngModelChange)="update($event)" [options]="input?.options"
                       [(ngModel)]="model" [availableLangs]="input?.options?.languages" [disabled]="isDisabled"
                       [id]="input.name" [isEdit]="false" [name]="input.name"></language-editor>
    }
    @case ('geo-infos') {
      <geo-infos (ngModelChange)="propagateChange($event)" [(ngModel)]="model"
                 [readOnly]="false"></geo-infos>
    }
    @case ('birth-place') {
      <birth-place (ngModelChange)="propagateChange($event)"
                   [(ngModel)]="model"></birth-place>
    }
    @case ('displaycurrency') {
      <displaycurrency-picklist (ngModelChange)="propagateChange($event)"
                                [(ngModel)]="model"></displaycurrency-picklist>
    }
    @case ('causal-groups') {
      <causal-groups (ngModelChange)="update($event)" [options]="input.options"
                     [(ngModel)]="model"></causal-groups>
    }
    @case ('bookmakers-causal-groups') {
      <bookmakers-causal-groups (ngModelChange)="update($event)"
                                [options]="input.options" [(ngModel)]="model"></bookmakers-causal-groups>
    }
    @case ('bookmakers') {
      <bookmakers-picklist (ngModelChange)="propagateChange($event)"
                           [(ngModel)]="model"></bookmakers-picklist>
    }
    @case ('fixture-selector') {
      <fixture-selector [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
      ></fixture-selector>
    }
    @case ('bookmakers-games-providers') {
      <bookmakers-games-providers [(ngModel)]="model" (ngModelChange)="update($event)"
                                  [id]="input.name"></bookmakers-games-providers>
    }
    @case ('webapps') {
      <webapps (ngModelChange)="propagateChange($event)" [(ngModel)]="model"></webapps>
    }
    @case ('promo-infos') {
      <promo-infos (blur)="onBlur($event)" [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
                   [options]="input?.options"></promo-infos>
    }
    <!--  <image-picker [(ngModel)]="model" (ngModelChange)="propagateChange($event)" *ngSwitchCase="'image-picker'" [options]="input?.options"></image-picker>-->
    @case ('causals-picklist') {
      <causals-picklist [(ngModel)]="model" (ngModelChange)="propagateChange($event)" [options]="input.options"
      ></causals-picklist>
    }
    @case ('currencies-amount') {
      <currencies-amount [(ngModel)]="model" (ngModelChange)="propagateChange($event)" [options]="input.options"
      ></currencies-amount>
    }
    @case ('additional-data-type') {
      <additional-data-type [(ngModel)]="model" (ngModelChange)="propagateChange($event)" [options]="input.options"
      ></additional-data-type>
    }
    @case ('weekly-slots') {
      <weekly-slots [(ngModel)]="model" (blur)="onBlur($event)" (ngModelChange)="propagateChange($event)"
                    [options]="input.options"></weekly-slots>
    }
    <!--  <ng-container *ngSwitchCase="'p-editor'">-->
      <!--    <p-editor [style]="{ height: '240px' }" [(ngModel)]="model" [formats]="input.options?.formats" [modules]="input.options?.modules" (ngModelChange)="propagateChange($event)">-->
      <!--      <ng-template pTemplate="header"></ng-template>-->
      <!--    </p-editor>-->
      <!--  </ng-container>-->
    @case ('p-editor') {
      <editor [(ngModel)]="model"
              (ngModelChange)="propagateChange($event)"
              apiKey="ke6zcpxpqiuavwaduefrio02ex0o3atsdd5qkwnnjit36a2j"
              [init]="input.options?.init">
      </editor>
    }
    @case ('min-max-amount') {
      <min-max-amount [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
                      [options]="input.options"></min-max-amount>
    }
    @case ('games-offer') {
      <games-offer [(ngModel)]="model" [options]="input.options" (ngModelChange)="propagateChange($event)"
      ></games-offer>
    }
    @case ('sportsbook-offer') {
      <sportsbook-offer [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
      ></sportsbook-offer>
    }
    @case ('capped-amount') {
      <capped-amount [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
                     [options]="input.options"></capped-amount>
    }
    @case ('timeline') {
      <timeline [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
                [options]="input.options"></timeline>
    }
    @case ('bookmaker-picker') {
      <bookmaker-picker [(ngModel)]="model" (ngModelChange)="propagateChange($event)"
      ></bookmaker-picker>
    }
  }
  <ng-content select=".suff"></ng-content>
</div>

<field-error [control]="control"></field-error>


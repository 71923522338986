import { IAuthor } from '../common/author';
import { CurrencyConversion } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { IWalletMovement } from '../wallet/movement';
import { ICategory } from './category';
import { ISearchBaseSportsBookFilters } from './common';
import { ISport } from './sport';
import { ITournament } from './tournament';

export enum BettingGroups {
  prematch = 1,
  live,
  mixed
}

export enum BettingTypes {
  single = 1,
  multiple,
  system,
  integral
}

export enum TicketStatus {
  open = 1,
  won,
  lost,
  refunded,
  void = 8
}

export enum TicketRetailPaymentStatus {
  notPaid = 1,
  paid
}

export enum TicketSettlementStatus {
  unsettled = 1,
  settled,
  error,
  void,
  inProgress
}

export enum TicketChannelTypes {
  desktop = 1,
  mobile = 2,
  retail = 3
}

export enum TicketTypes {
  sportsBook = 1,
  jackpot = 2
}

export namespace TicketTypes {
  export function values(): Array<IdName<string>> {
    return idNames(TicketTypes)
  }
}

export namespace TicketChannelTypes {
  export function values(): Array<IdName<any>> {
    return idNames(TicketChannelTypes)
  }
}

export namespace BettingGroups {
  export function values(): Array<IdName<string>> {
    return idNames(BettingGroups);
  }
}

export namespace BettingTypes {
  export function values(): Array<IdName<string>> {
    return idNames(BettingTypes);
  }
}

export namespace TicketRetailPaymentStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TicketRetailPaymentStatus);
  }
}

export namespace TicketStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TicketStatus);
  }
}

export namespace TicketSettlementStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TicketSettlementStatus);
  }
}

export interface ITicketVoid {
  voided: boolean,
  message: string;
}

export interface ITicketRollBack {
  rollback: boolean,
  message: string;
}

export interface ITicketBase {
  id: number;
  code: string;
}

export interface ITicket extends ITicketBase {
  amounts: ITicketAmount;
  bookmakerId: number;
  accountId: number;
  username: string;

  ipAddress: string;

  issueDate: string;
  updateDate: string;
  closeDate: string;

  bettingTypeId: BettingTypes;
  bettingGroupId: BettingGroups;
  ticketStatusId: TicketStatus,
  statusChanges: Array<ITicketStatusChange>;
  channelTypeId: TicketChannelTypes;

  eventsCount: number;
  oddsCount: number;

  settlementStatusId: TicketSettlementStatus;

  tax?: ITicketTax;
  systemTax?: ITicketSystemTax;

  movements: Array<IWalletMovement>;
  multiplier: number;
  payments: Array<any>;

  selections?: Array<ITicketSelection>;
  /**
   * @ deprecated
   */
  odds: ITicketHistoryOdd[];
  /**
   * @ deprecated
   */
  derived: ITicketHistoryCombination;

  combosExploded?: ITicketHistoryComboExploded[];
  combinations: Array<ITicketHistoryCombination>;
}

export interface ITicketStatusChange {
  date: string;
  fromStatusId: number;
  toStatusId: number;
  note?: string;
  author: IAuthor;
}

export interface ITicketStatusChangeRequest {
  note?: string;
}

/**
 * @deprecated old sportsbook
 */
interface ITicketHistoryCombination {
  amount: number;
  maxBonus: number;
  maxOdd: number;
  maxWin: number;
  minBonus: number;
  minOdd: number;
  minWin: number;
  minWinTax?: number;
  maxWinTax?: number;
}

/**
 * @deprecated old sportsbook
 */
interface ITicketHistoryComboExploded {
  betAmount: number;
  type: number;
  selections: ITicketHistoryComboExplodedSelection[];
}

/**
 * @deprecated old sportsbook
 */
interface ITicketHistoryComboExplodedSelection {
  indexes: number[];
  winAmount: number;
}

export interface ITicketAmount extends CurrencyConversion {
  stake: number;
  potentialWin: number;
  paid?: number;
  potentialBonus: number;
  realBonus: number;
}

export interface ITicketTax {
  potentialWin: number;
  potentialWinTax: number;
  potentialWinTaxNet: number;
  stake: number;
  stakeTax: number;
  stakeTaxPercentage: number;
  taxGroupType: string;
  taxGroupTypeId: number;
  taxType: string;
  taxTypeId: number;
  winTax: number;
  winTaxPercentage: number;
}

export interface ITicketSystemTax {
  taxTypeId: number;
}

export interface ITicketCashPaymentRequest {
  note?: string;
}

export interface ITicketChangeRetailStatusRequest {
  code: string;
  note?: string;
}

export interface ITicketSelection {
  id: number;
  oddId: number;
  sport: ISport;
  category: ICategory;
  tournament: ITournament;
  oddStatus: string;
}

export interface ITicketSearchFilters extends ISearchBaseSportsBookFilters {
  bookmakers?: Array<number>,
  groupId?: number;
  typeId?: number;
  statusId?: number;
  sportId?: number;
  categoryId?: number;
  tournamentId?: number;
}

/**
 * @deprecated old sportsbook
 */
export interface ITicketHistoryOdd {
  index?: number;
  oddId: number;
  category: string;
  eventDate: Date;
  eventName: string;
  eventId: number;
  isFixed: boolean;
  isLive: boolean;
  odd: number;
  oddTypeDescription: string;
  outcome: string;
  specialBetValue?: number;
  sport: string;
  status: string;
  statusId: number;
  tournament: string;
}

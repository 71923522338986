import { Component } from '@angular/core';
import { ICurrency } from '@bs/models';
import { AppSettingsService, MeService } from '@bs/services';

@Component({
  selector: 'displaycurrency-selector',
  templateUrl: './displaycurrency-selector.component.html',
})

export class DisplayCurrencySelectorComponent {
  items: ICurrency[];
  itemSelected: ICurrency;

  // subs = new Subscription();

  constructor(private meService: MeService, public appSettingsService: AppSettingsService) {
    this.meService.displayCurrencies().then(data => {
      //this.itemSelected = data[1]
      this.itemSelected = data[0]
      this.appSettingsService.saveSetting('displayCurrency', this.itemSelected);
      // console.log(this.itemSelected)
      this.items = data.sort((a, b) => a.name.localeCompare(b.name));
      // this.subs.add(this.appSettingsService.appSettings$.subscribe(({settings}) => this.itemSelected = settings.displayCurrency));
    })
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICurrency } from '@bs/models';

/**
 * this pipe filters all the currencies, so that data-table-builder component shows us the correct field
 */
@Pipe({name: 'currencyAttributes'})
export class CurrencyAttributesPipe implements PipeTransform {
  /**
   * bookmakers items
   */
  items: ICurrency[];

  /**
   * The constructor fetches the bookmakers items
   */
  constructor(route: ActivatedRoute) {
    const currencies = route.root.firstChild.firstChild.snapshot.data.currencies;
    if (currencies) {
      this.items = currencies;
    } else {
      throw new Error('This pipe requires CurrenciesResolver to be placed in the route')
    }
  };

  /**
   * return us matched bookmaker name
   * @param value {number} requires the bookmakers id to be passed
   */
  transform(value: number, attribute: 'code' | 'name'): string {
    return this.items.find(x => x.id === value)[attribute]
  }
}

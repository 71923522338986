import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ICmsPage, IFooter, IMe, IMenuItem, ITournamentMenuItem } from '@bs/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

type MenuPositions = 'main' | 'section' | 'bottom';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  readonly url = this.config.api.cms;
  footer$ = this.http.get<IFooter>(`${this.url}/footers`).pipe(shareReplay(1));
  mainMenu$: Subject<IMenuItem> = new Subject();
  private me: IMe;
  private lm = new IMenuItem({expanded: false});
  sectionMenu$ = new BehaviorSubject<IMenuItem>(this.lm);
  bottomMenu$ = new BehaviorSubject<IMenuItem>(this.lm);

  constructor(private config: EnvironmentConfig, private http: HttpClient, private authService: AuthService) {
    this.authService.accountLogged$.subscribe({
      next: account => this.me = account
    });
  }

  getTopTournamentMenu(): Observable<ITournamentMenuItem[]> {
    return this.http.get<ITournamentMenuItem[]>(`${this.url}/menus/top-tournaments`);
  }

  getFooter(): Observable<IFooter> {
    return this.footer$;
  }

  getMenu(id: number, position?: MenuPositions): Observable<IMenuItem> {
    return this.http.get<IMenuItem>(`${this.url}/menus/${id}`).pipe(tap(value => {

      switch (position) {
        case 'main':
          this.mainMenu$.next(value);
          break;
        case 'section':
          this.sectionMenu$.next(value)
          break;
        case 'bottom':
          this.bottomMenu$.next(value);
          break
      }
    }));
  }

  toggleMenu(expanded: boolean) {
    const menu = this.sectionMenu$.getValue();
    menu.expanded = expanded;
    this.sectionMenu$.next(menu);
  }

  getPage(path: string): Observable<ICmsPage> {
    return this.http.get<ICmsPage>(`${this.url}/pages?path=${path}`);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ILogSearchFilters, ISportsBookLog, Pager } from '@bs/models';
import { CrudService } from '@bs/services';

import { Observable, of } from 'rxjs';

@Injectable()
export class SportsbookLogsService implements CrudService<ISportsBookLog> {

  readonly url = `${this.config.api.sportsbook}/sports-books/logs`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: ILogSearchFilters): Observable<Pager<ISportsBookLog>> {
    const mock: Pager<ISportsBookLog> = {
      list: [
        {
          id: 1,
          typeId: 3,
          action: 'Create Fixture node',
          message: 'EntityType [ Sport  -  35  -  Baseball ] was created!',
          betOffer: {id: 1, name: 'Worldwide'},
          author: {id: 1, typeId: 10, username: 'marco'},
          date: '2023-10-01T12:48:45.06Z',
          frequency: 1
        },
        {
          id: 2,
          typeId: 3,
          action: 'Mapping Tournament node',
          message: 'Tournament UnifiedFeed [ CPBL  -  993 ] was mapped with Tournament [ Germany : 1st Bundesliga  -  916 ]',
          betOffer: {id: 1, name: 'Worldwide'},
          author: {id: 1, typeId: 10, username: 'marco'},
          date: '2023-10-02T12:48:45.06Z',
          frequency: 1
        },
        {
          id: 3,
          typeId: 2,
          action: 'Publication of Events',
          message: '20 Events has been published!',
          betOffer: {id: 1, name: 'Worldwide'},
          author: {id: 1, typeId: 10, username: 'marco'},
          date: '2023-10-01T12:48:45.06Z',
          frequency: 20
        },
      ],
      count: 2
    }

    return of(mock)
    //return this.http.post<Pager<ISportsBookLog>>(`${this.url}/search`, filters);
  }

  delete(item: Partial<ISportsBookLog>): Observable<ISportsBookLog> {
    return undefined;
  }

  get(item: Partial<ISportsBookLog>): Observable<ISportsBookLog> {
    return this.http.get<ISportsBookLog>(`${this.url}/${item.id}`);
  }

  patch(id: number, item: Partial<ISportsBookLog>): Observable<ISportsBookLog> {
    return undefined;
  }

  post(item: Partial<ISportsBookLog>): Observable<Partial<ISportsBookLog>> {
    return undefined;
  }


}

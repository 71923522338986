<ng-template #textTpl let-col="col" let-item="item">
  <div class="ellipsis">{{ item[col.field] }}</div>
</ng-template>

<ng-template #nestedTpl let-col="col" let-item="item">
  <div class="ellipsis">{{ item | nested: col.field }}</div>
</ng-template>

<ng-template #formValueTpl let-col="col" let-item="item">
  <div class="ellipsis">
    <span>{{ item[col.field]|formValues:{values: col.values} | translate }}</span>
  </div>
</ng-template>

<ng-template #booleanTpl let-col="col" let-item="item">
  <!--  <p-toggleButton [(ngModel)]="item[col.field]" [disabled]="true" [offLabel]="'off'" [onLabel]="'on'" offIcon="pi pi-times" onIcon="pi pi-check"></p-toggleButton>-->
  <p-inputSwitch [(ngModel)]="item[col.field]" [disabled]="true"></p-inputSwitch>
</ng-template>

<ng-template #statusTpl let-col="col" let-item="item">
  <span>{{ item[col.field].status }}</span>
</ng-template>

<ng-template #imageTpl let-col="col" let-item="item">
  <img [src]="item[col.field]" height="40" loading="lazy"/>
</ng-template>

<ng-template #imageNestedTpl let-col="col" let-item="item">
  <p-image width="100%" height="40px" [preview]="true" [src]="item | nested: col.field"></p-image>

  <!--  <img [src]="item | nested: col.field" height="80" loading="lazy"/>-->
</ng-template>

<ng-template #iconTpl let-col="col" let-item="item">
  <i class="pi {{item[col.field]}}"></i>
</ng-template>

<ng-template #accountParentTpl let-col="col" let-item="item">
  <div class="flex align-items-center justify-content-between">
    <span [escape]="false" [pTooltip]="item[0][col.field].typeId | formValues: { values: 'accountType' }">{{ item[0][col.field].username }}</span>
    <button (click)="emitterValue($event, 'openItem', item[0][col.field])"
            [pTooltip]="'openAccount'| translate"
            class="m-1" icon="pi pi-search" pButton pRipple type="button"></button>
  </div>
</ng-template>

<ng-template #accountPersonTpl let-col="col" let-item="item">
  <ng-container>
    <div class="flex align-items-center">
      <span>{{ item[col.field].firstName }} {{ item[col.field].lastName }}</span>
    </div>
  </ng-container>

</ng-template>

<ng-template #accountTpl let-col="col" let-item="item">
  <div class="flex align-items-center justify-content-between">
    <span [pTooltip]="item[col.field].id">{{ item[col.field].username }}
    </span>

    <button (click)="emitterValue($event, 'openAccount', item[col.field].id)"
            [pTooltip]="'openAccount'| translate"
            class="m-1" icon="pi pi-search" pButton pRipple type="button"></button>
  </div>
</ng-template>

<ng-template #bookmakerTpl let-col="col" let-item="item">
  <span>{{ item[col.field] | bookmakerName }}</span>
</ng-template>

<ng-template #bookmakersTpl let-col="col" let-item="item">
  <!--  <pre>{{item[col.field] | json}}</pre>-->
  @for (b of item[col.field]; track b) {
    <p-chip [label]="b | bookmakerName" styleClass="mr-2 custom-chip"></p-chip>
  }
  <!--  <span *ngfo>{{item[col.field] | bookmakerName}}</span>-->
</ng-template>

<ng-template #currencyTpl let-col="col" let-item="item">
  <span>{{ item[col.field] | currencyAttributes:'name' }}</span>
</ng-template>

<ng-template #ipAddressTpl let-col="col" let-item="item">
  <div class="flex align-items-center">
    <span class="mr-2">{{ item[col.field].ipAddress }}</span>
    @if (item[col.field].counter > 1) {
      <p-badge
        [value]="item[col.field].counter" severity="danger"></p-badge>
    }
  </div>
</ng-template>

<ng-template #amountNestedTpl let-col="col" let-item="item">
  <!--  <pre>{{item[col.field][col.values] | json}}</pre>-->
  <span>{{ item[col.field][col.values]  | displayCurrencyConversion: item[col.field].rate }}</span>
</ng-template>

<ng-template #amountTpl let-col="col" let-item="item">
  @if (!col.values) {
    <span>{{ item[col.field]['amount'] | currencyAmount: item[col.field]['currencySymbol'] }}</span>
    <!--    <span [escape]="false" [pTooltip]="item[col.field]  | amountTooltip: {symbol: displayCurrency.symbol, rate:item.rate }">{{item[col.field]['amount'] | currencyAmount: item[col.field]['currencySymbol']}}</span>-->
  }
  @if (col.values) {
    <span [escape]="false" [pTooltip]="item[col.field][col.values] | currencyAmount: item[col.field].currencySymbol">
    {{ item[col.field][col.values] | displayCurrencyConversion: item[col.field].rate }}</span>
  }
</ng-template>

<ng-template #dateNestedTpl let-col="col" let-item="item">
  {{ item | nested: col.field | localizedDate: 'dd-MM-yyyy, HH:mm:ss' }}
</ng-template>

<ng-template #dateTpl let-col="col" let-item="item">
  {{ item[col.field] | localizedDate: 'dd-MM-yyyy, HH:mm:ss' }}
</ng-template>

<ng-template #inputTpl let-col="col" let-item="item">
  <input (change)="emitter.emit({callback:'update', data:item})" [(ngModel)]="item[col.field]" class="p-inputtext" type="text">
</ng-template>

<ng-template #oddsFormatTpl let-col="col" let-item="item">
  {{ item[col.field] | oddFormat: oddFormat: true }}
</ng-template>

<ng-template #conditionButtonTpl let-button="button" let-item="item">
  @if (item | buttonConditions:button.visible:button.condition:'=') {
    <button (click)="emitterValue($event, button.click, item)"

            [pTooltip]="(button.label|translate) || (button.click | translate)" [tooltipPosition]="'top'"
            class="m-1" icon="pi pi-{{button.icon}}"
            pButton pRipple type="button"></button>
  }
</ng-template>

<ng-template #buttonTpl let-button="button" let-item="item">
  @if ((!button.name || item[button.name]) && !(button.click === 'deleteItem' && item.isDeleted)) {
    <button (click)="emitterValue($event, button.click, item)"
            [pTooltip]="(button.label|translate) || (button.click | translate)" [tooltipPosition]="'top'"
            class="m-1" icon="pi pi-{{button.icon}}"
            pButton pRipple type="button"></button>
  }
</ng-template>

<ng-template #buttonsTpl let-col="col" let-item="item">
  <div class="flex">
    @for (button of col.buttons | hasPermission; track button) {
      <ng-template [ngTemplateOutlet]="button.visible ? conditionButtonTpl: buttonTpl" [ngTemplateOutletContext]="{button:button, item:item}"></ng-template>
    }
  </div>
</ng-template>

<ng-content select="p-messages"></ng-content>

<p-panel [header]="headerTitle | translate" [toggleable]="true">
  <form-builder #builderComponent (submits)="submit($event)" [config]="config" [values]="values">

    @if (isLoading) {
      <div class="overlay">
        <p-progressSpinner strokeWidth="5"></p-progressSpinner>
      </div>
    }

    <div class="button-container">
      <button [disabled]="!builderComponent.form.valid || isLoading" [label]="'search' | translate" class="ml-auto p-button-secondary" icon="pi pi-search" iconPos="right" pButton pRipple type="submit"></button>
      @if (!config.hideReset) {
        <button (click)="reset()" [disabled]="!builderComponent.form.touched" [label]="'reset' | translate" class="ml-auto p-button-secondary" icon="pi pi-trash" iconPos="left" pButton pRipple type="button"></button>
      }
    </div>
  </form-builder>
</p-panel>

<div class="p-2"></div>


@if (items?.count) {
  @if (items?.totals) {
    <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
      <div class="grid">
        @for (total of items.totals; track total) {
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3" translate>{{ total.key }}</span>
                  <div class="text-900 font-medium text-xl">{{ total.sum | displayCurrencyConversion: total.rate }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <div>
                <span class="text-green-500 font-medium">{{ total.count }} records</span>
              </div>
              <div>
                <span class="text-500">first: {{ total.firstDate | localizedDate: 'dd-MM-yyyy, HH:mm:ss' }}</span>
              </div>
              <div>
                <span class="text-500">last: {{ total.lastDate | localizedDate: 'dd-MM-yyyy, HH:mm:ss' }}</span>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  }
  <p-table #dt (onLazyLoad)="onLazy($event)" [first]="(baseParams.p-1)*baseParams.pp" [lazyLoadOnInit]="false" [lazy]="true"
           [loading]="isLoading" [paginator]="true" [rowsPerPageOptions]="[10,25,50]" [rows]="+baseParams.pp" [totalRecords]="items.count" [value]="items.list"
           (onRowSelect)="rowSelection($event)"
           (onRowUnselect)="rowSelection($event)"
           [(selection)]="selectedItems" [globalFilterFields]="['name']"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           dataKey="id" responsiveLayout="scroll" selectionMode="single" styleClass="p-datatable-sm">
    @if (exportable) {
      <ng-template pTemplate="caption">
        <div class="flex justify-content-end flex-wrap">
          <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
    }
    <ng-template pTemplate="header">
      <tr>
        @if (selectable) {
          <th style="width: 3rem">
            <p-tableHeaderCheckbox (click)="rowSelection($event)"></p-tableHeaderCheckbox>
          </th>
        }
        @for (col of cols; track col) {
          <th [attr.rowspan]="col.rowspan" [style.width.rem]="col.width">{{ col.header | translate }}</th>
        }
      </tr>
      @if (filterable) {
        <tr>
          @for (col of cols; track col) {
            <th>
              <input (ngModelChange)="dt.filter($event, col.field, 'contains')" [placeholder]="'searchByField'" [value]="dt.filters[col.field]?.value"
                     class="p-column-filter"
                     ngModel pInputText type="text">
            </th>
          }
        </tr>
      }
    </ng-template>
    <ng-template let-item pTemplate="body">
      <tr [class.deleted]="item.isDeleted">
        @if (selectable) {
          <td>
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </td>
        }
        @for (col of cols; track col) {
          <td [attr.rowspan]="col.rowspan">
            <div class="block sm:hidden">{{ col.header }}</div>
            @switch (col.type) {
              @case ('date') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="dateTpl"></ng-template>
              }
              @case ('dateNested') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="dateNestedTpl"></ng-template>
              }
              @case ('input') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="inputTpl"></ng-template>
              }
              @case ('bookmaker') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="bookmakerTpl"></ng-template>
              }
              @case ('bookmakers') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="bookmakersTpl"></ng-template>
              }
              @case ('currency') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="currencyTpl"></ng-template>
              }
              @case ('ipAddress') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="ipAddressTpl"></ng-template>
              }
              @case ('account') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountTpl"></ng-template>
              }
              @case ('accountParent') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountParentTpl"></ng-template>
              }
              @case ('accountPerson') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="accountPersonTpl"></ng-template>
              }
              @case ('amount') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="amountTpl"></ng-template>
              }
              @case ('amountNested') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="amountNestedTpl"></ng-template>
              }
              @case ('status') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="statusTpl"></ng-template>
              }
              @case ('icon') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="iconTpl"></ng-template>
              }
              @case ('image') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="imageTpl"></ng-template>
              }
              @case ('imageNested') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="imageNestedTpl"></ng-template>
              }
              @case ('button') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="buttonsTpl"></ng-template>
              }
              @case ('boolean') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="booleanTpl"></ng-template>
              }
              @case ('formValue') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="formValueTpl"></ng-template>
              }
              @case ('nested') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="nestedTpl"></ng-template>
              }
              @case ('odds') {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="oddsFormatTpl"></ng-template>
              }
              @default {
                <ng-template [ngTemplateOutletContext]="{item:item, col:col}" [ngTemplateOutlet]="textTpl"></ng-template>
              }
            }
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center">
        <span [translate]="'inTotalThereAre'"></span>
        <span class="mx-1">{{ items.count }}</span>
        <span [translate]="'elements'"></span>
      </div>
    </ng-template>
  </p-table>
} @else {
  <p-messages severity="warn">
    <ng-template pTemplate>
      <span class="custom-message" translate>{{ 'noResultsFound' }}</span>
    </ng-template>
  </p-messages>
}


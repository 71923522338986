import { AfterViewInit, Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'printer-receipt',
  templateUrl: 'printer-receipt.component.html',
  styleUrls: ['printer-receipt.component.scss']
})
export class PrinterReceiptComponent implements AfterViewInit {

  loaded: EventEmitter<void> = new EventEmitter<void>();
  host: string;

  constructor() {
    this.host = window.location.origin;
  }

  ngAfterViewInit(): void {
    this.loaded.emit();
  }
}

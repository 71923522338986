import { DOCUMENT } from '@angular/common';
import { ComponentRef, Inject, Injectable, Optional, Renderer2, RendererFactory2, ViewContainerRef } from '@angular/core';
import { IDrawerMovement, IWalletMovement, NetworkCashierReport } from '@bs/models';
import { CordovaService } from '@bs/services';
import { DrawerMovementReceiptComponent } from '../components/drawer-movement-receipt/drawer-movement-receipt.component';
import { PrinterReceiptComponent } from '../components/printer-receipt/printer-receipt.component';
import { ReportsCashierReceiptComponent } from '../components/reports-cashier-receipt/reports-cashier-receipt.component';
import { WalletsReceiptComponent } from '../components/wallets-receipt/wallets-receipt.component';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: any, @Optional() private cordovaService: CordovaService) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**/

  /*

  printTransfer(containerRef: ViewContainerRef, transfer: INetworkTransfer): void {
    const ref = containerRef.createComponent<TransferReceiptComponent>(TransferReceiptComponent, {index: 0});

    ref.instance.transfer = transfer;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }*/

  /**/

  printWallets(containerRef: ViewContainerRef, data: IWalletMovement) {
    const ref = containerRef.createComponent<WalletsReceiptComponent>(WalletsReceiptComponent);

    ref.instance.item = data;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  printReportsCashier(containerRef: ViewContainerRef, data: NetworkCashierReport, totalIn: number, totalOut: number, totalBalance: number, today: Date) {
    const ref = containerRef.createComponent<ReportsCashierReceiptComponent>(ReportsCashierReceiptComponent);

    ref.instance.report = data;
    ref.instance.totalIn = totalIn;
    ref.instance.totalOut = totalOut;
    ref.instance.totalBalance = totalBalance;
    ref.instance.today = today;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  printDrawerMovement(containerRef: ViewContainerRef, data: IDrawerMovement) {
    const ref = containerRef.createComponent<DrawerMovementReceiptComponent>(DrawerMovementReceiptComponent);

    ref.instance.item = data;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }


  printMarkets(containerRef: ViewContainerRef) {
    const ref = containerRef.createComponent<PrinterReceiptComponent>(PrinterReceiptComponent);

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  private openUp(componentRef: ComponentRef<any>) {

    if (this.cordovaService) {
      this.cordovaService.print(componentRef.location.nativeElement.innerHTML);
    } else {
      const blob = new Blob([componentRef.location.nativeElement.innerHTML], {type: 'text/html'});
      const blobUrl = URL.createObjectURL(blob);

      const printIframe = this.document.querySelector('iframe#print');
      if (printIframe) {
        printIframe.src = blobUrl;
      } else {
        const iframe = this.renderer.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = blobUrl;
        iframe.setAttribute('id', 'print');
        this.renderer.appendChild(this.document.body, iframe);
        iframe.addEventListener('load', () => {
          iframe.contentWindow.print();
        });
      }
    }
  }
}

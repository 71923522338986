import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ICompetitor, ICompetitorLanguage, ICompetitorSearchFilters, Pager } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompetitorService {
  readonly url = `${this.config.api.sportsbook}/competitors`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  getAll(countryId: number): Observable<Array<ICompetitor>> {
    return this.http.get<Array<ICompetitor>>(`${this.url}`, {params: {cId: countryId.toString()}});
  }

  get(id: number): Observable<ICompetitor> {
    return this.http.get<ICompetitor>(`${this.url}/${id}`, {params: {id}});
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  createOrUpdate(data: ICompetitor): Observable<ICompetitor> {
    return data.id ? this.http.put<ICompetitor>(`${this.url}/${data.id}`, data) : this.http.post<ICompetitor>(this.url, data);
  }

  search(filters: ICompetitorSearchFilters): Observable<Pager<ICompetitor>> {
    return this.http.post<Pager<ICompetitor>>(`${this.url}/search`, filters);
  }

  updateLanguage(data: ICompetitorLanguage): Observable<ICompetitorLanguage> {
    return this.http.put<ICompetitorLanguage>(`${this.url}/${data.competitorId}/languages`, data);
  }

}

<form [formGroup]="form">
  <p-tabView formArrayName="languages">
    @for (lang of langs?.controls; track lang; let i = $index; let first = $first) {
      @if (bookmaker.languages[i]; as language) {
        <p-tabPanel>
          <ng-template pTemplate="header">
            <h6 class="py-1 m-0">
              <div tooltipPosition='top' [pTooltip]='language.name' class="flag-rounded flex align-items-center justify-content-center mr-1">
                <svg [icon]="language.code" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
              </div>
            </h6>
          </ng-template>
          <div [formGroup]="lang" class="mr-5 {{language.code}}">
            <input formControlName="languageId" pInputText type="hidden">
            <div class="flex">
              @for (ctrl of nestedForm; track ctrl) {
                <field class="flex-1" [formControlName]="ctrl.name" (blur)="onBlur($event)" [input]="ctrl"></field>
              }
              @if (first) {
                <div class="flex align-items-center">
                  <p-button (click)="copyAll()" [pTooltip]="'copyAll'|translate"
                    type="button" icon="pi pi-copy" styleClass="p-button-rounded p-button-text p-button p-button-icon-only">
                  </p-button>
                </div>
              }
            </div>
          </div>
        </p-tabPanel>
      }
    }
  </p-tabView>
</form>

import { asStrings, IdName, idNames } from '../common/id-name';

export enum PromotionType {
  registration = 100, // promozione di benvneuto
  firstDeposit = 101, // promozione sul primo deposito
  deposits = 102, // promozione sui depositi
  sportsbook = 103,// promozione sportsbook
  casino = 104, // promozione casino.
  cashBack = 110
}

export namespace PromotionType {
  export function values(): Array<IdName<string>> {
    return idNames(PromotionType)
  }
}

/**
 * @deprecated: will be removed
 */
export enum PromoStepActivationType {
  automatic = 1,
  claim = 2
}
/**
 * @deprecated: will be removed
 */
export namespace PromoStepActivationType {
  export function values(): Array<IdName<string>> {
    return idNames(PromoStepActivationType)
  }
}

export enum PromoStepType {
  entry = 1,
  deposit = 2,
  play = 3,
}

export namespace PromoStepType {
  export function values(): Array<IdName<string>> {
    return idNames(PromoStepType)
  }
}

export enum PromoActionType {
  signUp = 1,
  claim = 2,
  firstDeposit = 3,
  deposits = 4,
  playSportsbook = 5,
  playCasinoGames = 6
  //dailyDeposit,
  //monthlyDeposit,
  //nDeposits
}

export namespace PromoActionType {
  export function values(): Array<IdName<string>> {
    return idNames(PromoActionType);
  }
}

export enum PromoStatus {
  created = 1,
  validated = 5,
  cancelled = 7,
  open = 10,
  closed = 15,
  suspended = 20,
  exhausted = 25,
}

export namespace PromoStatus {
  export function values(): Array<IdName<string>> {
    return idNames(PromoStatus);
  }
}

export enum ParticipationRequirementGroup {
  registration = 2,
  deposit = 3,
  sportsBook = 5,
  games = 6
}

export namespace ParticipationRequirementGroup {
  export function values(): Array<IdName<string>> {
    return idNames(ParticipationRequirementGroup);
  }
}

export enum ParticipationRequirement {
  claim = 21,
  firstTimeDeposit = 3,
  deposit,
  playSportsBook,
  playGames,
  validatePhone,
  validaEmail,
  validateKyc
}

export namespace ParticipationRequirement {
  export function values(): Array<IdName<string>> {
    return idNames(ParticipationRequirement);
  }
}

export enum PromoUserStatus {
  open = 1,
  closed = 2
}

export namespace PromoUserStatus {
  export function values(): Array<IdName<string>> {
    return idNames(PromoUserStatus);
  }
}

export enum PromoTargetProfiling {
  all = 1,
  list = 2,
  profiling
}

export namespace PromoTargetProfiling {
  export function values(): Array<IdName<string>> {
    return idNames(PromoTargetProfiling);
  }
}

export enum PromoTournamentStatus {
  active = 1,
  announced,
  ended
}

export namespace PromoTournamentStatus {
  export function names(): Array<string> {
    return asStrings(PromoTournamentStatus);
  }
}

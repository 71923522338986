{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "sports",
      "filter": "name",
      "label": "sports",
      "type": "select",
      "noReset": true,
      "autoselect": true
    }
  ]
}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "label": "sports",
      "name": "sports",
      "type": "select",
      "validations": {
        "required": true
      }
    },
    {
      "label": "offerTypes",
      "name": "offerTypes",
      "type": "select",
      "values": [
        {
          "id": 1,
          "name": "PreMatch"
        },
        {
          "id": 2,
          "name": "Live"
        },
        {
          "id": 3,
          "name": "Mixed"
        }
      ],
      "validations": {
        "required": true
      }
    },
    {
      "label": "templatesWeb",
      "name": "templatesWeb",
      "type": "select",
      "values": [
        {
          "id": 1,
          "name": "Standard Layout"
        },
        {
          "id": 2,
          "name": "Correct Scores"
        },
        {
          "id": 3,
          "name": "Spreads"
        }
      ],
      "validations": {
        "required": true
      }
    },
    {
      "label": "name",
      "name": "name",
      "type": "text",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      }
    },
    {
      "label": "code",
      "name": "code",
      "type": "text",
      "validations": {
        "maxLength": 20,
        "minLength": 4,
        "pattern": "^[a-zA-Z0-9-_]+$",
        "required": true
      }
    },
    {
      "label": "hasSpecifier",
      "name": "hasSpecifier",
      "type": "inputSwitch"
    },
    {
      "label": "hasCompetitor",
      "name": "hasCompetitor",
      "type": "inputSwitch"
    },
    {
      "label": "isPrimary",
      "name": "isPrimary",
      "type": "inputSwitch"
    }
  ]
}

import { Amount } from '../common/currency';
import { IdName, idNames } from '../common/id-name';

export interface FinanceHistory {
  walletMovementId: number;
  isSession: boolean;
  date: Date;
  causalGroup: string;
  causal: string;
  amount: Amount;
}

export interface SportsHistory {
  ticketId: number;
  walletMovementId: number;
  code: string;
  type: string;
  groupType: string;
  issuedAt: string;
  amount: number;
  potentialWin: number;
  paidAmount: number;
  status: string;
  settlementStatus: string;
  multiplier: number;
  eventsNumber: number;
  cashPayment?: any;
}

export interface GamesHistory {
  walletMovementId: number;
  gameId: number;
  game: string;
  brand: string;
  causal: string;
  date: Date;
  amount: number;
}

export enum WalletTypes {
  mainWallet = 1
}

export namespace WalletTypes {
  export function values(): Array<IdName<string>> {
    return idNames(WalletTypes);
  }
}


import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IBookmaker, IdName } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

/**
 * model type for the BookmakersCurrencies
 */
interface IBookmakersCurrencies {
  /**
   * the bookmakersIds values
   */
  bookmakers?: number[];
  /**
   * currencyId value
   */
  currencyId?: number;
}

/**
 * The component contains bookmakers, and currencies dropdowns
 */
@Component({
  selector: 'bookmakers-currencies',
  templateUrl: './bookmakers-currencies.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmakersCurrenciesComponent),
      multi: true
    }
  ],
  styles: [`
    :host {
      display: block;
      width: 100%;
    }
  `]
})
export class BookmakersCurrenciesComponent implements ControlValueAccessor, OnDestroy {
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * the bookmakers list items
   */
  bookmakersOptions: IBookmaker[];
  /**
   * the currencies list items
   */
  currencies: IdName<string, number>[];
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: IBookmakersCurrencies = {}
  /**
   * local reference to Subscription
   */
  subs = new Subscription();

  @Input()
  options = {multiple: true};

  /**
   * The constructor, where we initialize the currencies, and the bookmakers values
   * @param route
   * @param settingsService
   */
  constructor(private route: ActivatedRoute, private settingsService: AppSettingsService) {
    this.subs.add(route.data.subscribe({
      next: data => {
        this.bookmakersOptions = data.bookmakers;
      }
    }));
    this.currencies = this.settingsService.settings.bookmaker.currencies.map(x => ({id: x.id, name: x.name}));
    this.currencies = this.currencies.sort(((a, b) => a.name.localeCompare(b.name)))
  }

  /**
   * lifecycle hook, where we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * when bookmakers been changed from the view, we filter the currencies for that bookmakerId, and assign the new currencies dropdown list
   * @param bookmakers
   */
  bookmakersChanged(bookmakers: number[]) {
    if (!Array.isArray(bookmakers)) {
      bookmakers = [bookmakers];
    }
    this.model.currencyId = null;
    const filteredBookmakers = this.bookmakersOptions.filter(t => bookmakers.includes(t.id));
    const mappedCurrencies: IdName<string, number>[] = filteredBookmakers.flatMap(r => r.currencies).map(x => ({id: x.id, name: x.name}));
    this.currencies = [...mappedCurrencies.reduce((acc, curr) => acc.set(curr.id, curr), new Map()).values()];
    this.currencies = this.currencies.sort(((a, b) => a.name.localeCompare(b.name)))
    this.update(this.model);
  }

  /**
   * we are keeping track of the value changes of the cva, so they are always updated
   * @param values
   */
  update(values: IBookmakersCurrencies) {
    this.onTouched();
    /*const change: IBookmakersCurrencies = {};
    Object.entries(values).map(([k, v]) => {
      change[k] = v;
    });*/

    //if (values.bookmakers && values.currencyId) { // this has been commented cause when filter wants just bookmaker and not currency, it should be possible
    this.propagateChange(values);
    //}
  }

  /**
   * we assign the values of the model, when programmatic changes from model to view are requested, and when we have bookmakers value we call the bookmakersChanged function to filter the correct currencies
   * @param model
   */
  writeValue(model: any): void {
    if (model) {
      const paramMap = this.route.snapshot.queryParamMap
      // debugger;
      // todo verificare il currencyid quando è nullo dal form
      // const modelValues = model.split(',');
      this.model.bookmakers = paramMap.getAll('bookmakers').map(Number) //modelValues.map(Number).slice(0, -1);
      // this.model.currencyId = +paramMap.get('currencyId') // modelValues.map(Number).pop();
      this.bookmakersChanged(this.model.bookmakers);
    } else {
      this.model.bookmakers = [this.settingsService.settings.bookmaker.id];
      this.model.currencyId = this.settingsService.settings.bookmaker.currencies[0].id;
      // this.model.currencyId = this.settingsService.settings.displayCurrency.id;
      this.update(this.model);
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: any) {
  }
}

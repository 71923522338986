import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '@bs/models';
import { Centrifuge } from 'centrifuge';
import { Subscription } from 'centrifuge/build/subscription';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CentrifugeService {
  readonly url = `${this.config.api.ws}`;

  private centrifuge: Centrifuge;

  publications: Subject<{ channel: string, data: any }> = new Subject();

  private subscribedChannels: Array<string> = [];

  /**
   * @ignore
   */
  constructor(private config: EnvironmentConfig) {
    this.centrifuge = new Centrifuge(this.url);
    this.centrifuge.connect();
  }

  unsubscribe(subs: Subscription | string) {

    if (typeof subs === 'string') {
      const currentSubscriptions = this.centrifuge.subscriptions();
      return this.unsubscribe(currentSubscriptions[subs]);
    } else {
      const founds = this.subscribedChannels.filter(x => x === subs.channel);
      if (founds.length === 1) { // there can be only one
        this.centrifuge.removeSubscription(subs);
      }

      const idx = this.subscribedChannels.findIndex(x => x === subs.channel);
      if (idx > -1) {
        this.subscribedChannels.splice(idx, 1);
      }
      // console.log(this.subscribedChannels);
    }

  }

  subscribe(channel: string): void {
    const currentSubscriptions = this.centrifuge.subscriptions();
    this.subscribedChannels.push(channel);
    // console.log(this.subscribedChannels);
    if (!(channel in currentSubscriptions)) {
      const sub = this.centrifuge.newSubscription(channel);
      sub.on('publication', ctx => {
        this.publications.next(ctx);
      });

      sub.subscribe();
    }
  }

}

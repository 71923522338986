import { IdName, idNames } from '@bs/models';

export enum TransactionRequestStatus {
  toSend = 1,
  sended = 2
}

export namespace TransactionRequestStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TransactionRequestStatus);
  }
}

export enum TransactionResponseStatus {
  completed = 1,
  not_completed_with_errors = 2,
  not_completed = 3,
}

export namespace TransactionResponseStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TransactionResponseStatus);
  }
}

export enum KraResponseStatus {
  success = 1,
  jsonSyntaxError = 2,
  ataValidationError = 3,
  hashCodeValidationFailed = 4,
  invalidUserIdOrPassword = 5,
  timeOut = 6
}

export namespace KraResponseStatus {
  export function values(): Array<IdName<string>> {
    return idNames(KraResponseStatus);
  }
}


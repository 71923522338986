import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BookmakersService } from '@bs/services';
import { Subscription } from 'rxjs';

interface MinMaxAmount {
  currencyId: number;
  name?: string;
  code: string;
  symbol?: string;
  min: number;
  max: number;
}

@Component({
  selector: 'min-max-amount',
  templateUrl: './min-max-amount.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MinMaxAmountComponent),
      multi: true,
    },
  ],
})
export class MinMaxAmountComponent implements OnInit, ControlValueAccessor {
  model: Array<MinMaxAmount> = [];
  oldModel: Array<MinMaxAmount> = [];
  isDisabled: boolean;
  subs = new Subscription();

  @Input()
  options: { bookmakerId: number };

  @Output() blur = new EventEmitter<any>(null);

  constructor(private bms: BookmakersService) {}

  ngOnInit() {
    if (this.options.bookmakerId) {
      const bookmakers = this.bms.bookmakers$.getValue();
      const current = bookmakers.find((b) => b.id === this.options.bookmakerId);

      this.model = current.currencies.map((c) => ({
        name: c.name,
        currencyId: c.id,
        code: c.code,
        symbol: c.symbol,
        min: 100,
        max: 1000,
      }));

      setTimeout(() => this.propagateChange(this.model), 0);
    } else {
      throw new Error('cannot read bookmakerId');
    }
  }

  onRowEditInit(index: number) {
    this.oldModel[index] = { ...this.model[index] };
  }

  onRowEditSave(index: number) {}

  onRowEditCancel(index: number) {
    this.model[index] = { ...this.oldModel[index] };
  }

  getAmount(type: 'min' | 'max', index: number) {
    return this.model[index][type] / 100;
  }

  setAmount(type: 'min' | 'max', index: number, value: number) {
    this.model[index][type] = value * 100;
    this.propagateChange(this.model)
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj: any) {
    if (_obj !== undefined) {
      // this.model = _obj;
    }
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  private onTouched() {}

  propagateChange(_model: Array<MinMaxAmount>) {}
}

import { Component, OnDestroy } from '@angular/core';
import { IdName, timezones } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'timezone-selector',
  templateUrl: './timezone-selector.component.html'
})
export class TimezoneSelectorComponent implements OnDestroy {
  items: IdName<string, any>[];
  itemSelected: any;
  subs = new Subscription();

  constructor(public appSettingsService: AppSettingsService) {
    this.items = Object.keys(timezones).map(k => ({id: k, name: `${k} ${timezones[k]}`}));
    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.itemSelected = settings.timeZone
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

import { CurrencyConversion, IdName, idNames, IKraTicketAmount } from '@bs/models';

// BatchPaymentStatus
export enum TaxRemittanceStatus {
  toBeApproved = 1,
  approved = 2,
  paid = 3,
  refused
}

export namespace TaxRemittanceStatus {
  export function values(): Array<IdName<string>> {
    return idNames(TaxRemittanceStatus);
  }
}

export interface ITaxRemittance {
  id: number;
  bookmakerId: number;
  creationDate: string;
  stake: IKraTicketAmount;
  tax: IKraTicketAmount;
  statusId: number;
  histories?: Array<ITaxRemittanceHistory>;
}

export interface ITaxRemittanceHistory {
  date: string;
  statusId: number;
}

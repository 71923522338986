<!--<button *ngIf="options?.copyAll" pButton class="p-button-warning mb-2" type="button" (click)="copyAll(options.copyAll)" [label]="'copyAll' | translate"></button>-->
<form [formGroup]="form">
  <ng-container formArrayName="infos">
    <p-tabView (onChange)="onTabChange($event)">
      @for (info of infos?.controls; track info; let i = $index; let first = $first) {
        @if (currentBookmaker.languages[i]; as lang) {
          <p-tabPanel>
            <ng-template pTemplate="header">
              <h6 class="px-3 py-1 m-0 flex align-items-center">
                <div class="flag-rounded flex align-items-center justify-content-center mr-1">
                  <svg [icon]="lang.code" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
                </div>
                <span>{{lang.name}}</span>
              </h6>
            </ng-template>
            <ng-template pTemplate="footer">
              @if (first) {
                <div class="flex align-items-center">
                  <button (click)="copyAll()" [label]="'copyAll' | translate" class="p-button-secondary" icon="pi pi-copy" iconPos="right" pButton pRipple></button>
                </div>
              }
            </ng-template>
            <div [formGroup]="info" class="mr-5 {{lang.code}}" [class.mb-2]="!options.hasThumbs" [class.hasThumbs]="options.hasThumbs">
              <input formControlName="languageId" pInputText type="hidden">
              <div class="p-float-label mb-3">
                <input id="name" formControlName="name" pInputText type="text">
                <label class="req" for="name">{{'name' | translate}}</label>
                <field-error class="inner-error" [control]="info.get('name')"></field-error>
              </div>
              <div class="p-float-label mb-3">
                <textarea id="description" pInputTextarea type="text" style="resize: none" rows="5" formControlName="description"></textarea>
                <label class="req" for="description">{{'description' | translate}}</label>
                <field-error class="inner-error" [control]="info.get('description')"></field-error>
              </div>
              @if (options?.terms) {
                <div class="p-float-label">
                  <p-editor formControlName="terms" [style]="{height:'320px'}"></p-editor>
                </div>
              }
              @if (options?.terms) {
                <canvas style="height: 1px; width: 300px"></canvas>
              }
            </div>
          </p-tabPanel>
        }
      }
    </p-tabView>
  </ng-container>
</form>

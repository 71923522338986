import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IPostManSms, IPostManSmsSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class AccountsPostmanService { // in postman accounts
  readonly url = `${this.config.api.accounts}/post-man`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<IPostManSms> {
    return this.http.get<IPostManSms>(`${this.url}/sms/${id}`);
  }

  search(filters: IPostManSmsSearchRequest): Observable<Pager<IPostManSms>> {
    return this.http.post<Pager<IPostManSms>>(`${this.url}/sms/search`, filters);
  }
}

import { IAuthor, IdName, idNames, ISearchBaseSportsBookFilters } from '@bs/models';

export enum SportsBookLogTypes {
  betOffer = 1,
  eventPublished = 2,
  fixture = 3,
  eventSubscribe = 4,
  sportEvent,
  market,
  limit,
  template
}

export namespace SportsBookLogTypes {
  export function values(): Array<IdName<string>> {
    return idNames(SportsBookLogTypes);
  }
}

export interface ISportsBookLog {
  id: number;
  typeId: SportsBookLogTypes;
  action: string;
  message: string;
  betOffer: { id: number; name: string };
  author: IAuthor;
  feedProviderId?: number;
  date: string;
  frequency: number;
}

export interface ILogBetOffer extends ISportsBookLog {
  logBetOfferId: number;
  statusId: number;
  bookmakers: string; // xml
  currencies: string; // xml
  languages: string; // xml
  fromDate: string;
  toDate: string;
  optionId?: number;
  optionParentId?: number;
  version: number;
  entityTypeId?: number;
  entityId?: number;
  offerTypeId?: number;
  publishTimeBeforeStarting?: number;
  settlementTypeId?: number;
  settlementTimeTypeId?: number;
  settlementTime?: number;
  paymentTypeId?: number;
  paymentTimeTypeId?: number;
  paymentTime?: number;
}

export interface ILogPublishing extends ISportsBookLog {

}


export interface ILogSearchFilters extends ISearchBaseSportsBookFilters {
  typeId?: number;
}

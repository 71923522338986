import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketSuperGroup, IMarketSuperGroupSearchFilters, Pager } from '@bs/models';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MarketSuperGroupService {
  readonly url = `${this.config.api.sportsbook}/market-super-groups`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(itemId: number): Observable<IMarketSuperGroup> {
    return this.http.get<IMarketSuperGroup>(`${this.url}/${itemId}`);
  }

  getAll(betOfferId: number, sportId?: number): Observable<Array<IMarketSuperGroup>> {
    return this.http.get<Array<IMarketSuperGroup>>(`${this.url}`, {params: {bId: betOfferId.toString(), sId: sportId.toString()}});
  }

  createOrUpdate(data: IMarketSuperGroup): Observable<IMarketSuperGroup> {
    return data.id ? this.http.put<IMarketSuperGroup>(`${this.url}/${data.id}`, data) : this.http.post<IMarketSuperGroup>(this.url, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  search(filters: IMarketSuperGroupSearchFilters): Observable<Pager<IMarketSuperGroup>> {
    return this.http.post<Pager<IMarketSuperGroup>>(`${this.url}/search`, filters);
  }

}

<p-dropdown
  class="mr-2 inline"
  [options]="items"
  [(ngModel)]="itemSelected"
  [disabled]="isDisabled"
  [filter]="true" filterBy="name"
  [required]="true"
  (ngModelChange)="onChange($event)"

  optionLabel="name">
</p-dropdown>


@if (!geoInfos?.length) {
  <div class="flex align-items-center justify-content-between">
    <span [translate]="'noGeoInfosAvailable'"></span>
    @if (permission.canAddGeoInfo | hasPermission) {
      <button (click)="openModal()" [pTooltip]="'addGeoInfoDetails'| translate" tooltipPosition="top"
      [label]="'addGeoInfo' | translate" class="m-1" icon="pi pi-plus" pButton pRipple type="button"></button>
    }
  </div>
}

@if (geoInfos && geoInfos.length>0) {
  @if (permission.canAddGeoInfo | hasPermission) {
    <p-toolbar styleClass="mb-4">
      <ng-template pTemplate="left">
        <button (click)="openModal()" [pTooltip]="'addGeoInfoDetails'| translate"
          [label]="'addGeoInfo' | translate" [disabled]="geoInfos.length >= geoInfoTypeNames.length"
        class="m-1" icon="pi pi-plus" pButton pRipple type="button"></button>
      </ng-template>
      <ng-template pTemplate="right"></ng-template>
    </p-toolbar>
  }
  @if (geoInfos.length) {
    <p-table [value]="geoInfos" [rowHover]="true" dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <!--      <th>{{ 'id' | translate }}</th>-->
          <th>{{ 'typeId' | translate }}</th>
          <th>{{ 'country' | translate }}</th>
          <th>{{ 'region' | translate }}</th>
          <th>{{ 'provinceCity' | translate }}</th>
          <th>{{ 'addressZipcode' | translate }}</th>
          <th>{{ 'actions' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-geoInfo>
        <tr>
          <!--      <td>{{ geoInfo.id }}</td>-->
          <td>{{ geoInfo.typeId | geoInfoType }}</td>
          <td class="flex align-items-center">
            <div class="flag-rounded flex align-items-center justify-content-center mr-1">
              <svg [icon]="geoInfo.country.code?.toLowerCase()" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
            </div>
            {{ geoInfo.country.name }}
          </td>
          <td>{{ geoInfo.region.name }}</td>
          <td>{{ geoInfo.province.name ? geoInfo.province.name + ', ' + geoInfo.city.name : geoInfo.city.name }}</td>
          <td>{{ geoInfo.address ? geoInfo.address + ', ' + geoInfo.zipCode : geoInfo.zipCode }}</td>
          <td>
            @if (permission.canDeleteGeoInfo | hasPermission) {
              <button (click)="submit(geoInfo, 'delete')" pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"></button>
            } @else {
              <span translate>noActionsAvailable</span>
            }
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
}


<div class="p-inputgroup">
  <p-dropdown
    [(ngModel)]="model.currency" [options]="currencies"
    class="currency-id" optionLabel="code"></p-dropdown>
  <p-inputNumber [(ngModel)]="model.from"
                 [showButtons]="true"
                 [placeholder]="'amountFrom'| translate"
                 (ngModelChange)="update('from', $event)"
                 [minFractionDigits]="2"
                 [currency]="model.currency?.code"
                 [max]="model.to"
                 class="from"
                 [min]="0" mode="currency"></p-inputNumber>
  <span class="p-inputgroup-addon"> - </span>
  <p-inputNumber [(ngModel)]="model.to"
                 [showButtons]="true"
                 (ngModelChange)="update('to', $event)"
                 [min]="model.from"
                 [placeholder]="'amountTo'| translate"
                 class="to"
                 [currency]="model.currency?.code" mode="currency"></p-inputNumber>
</div>

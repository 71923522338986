import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { EnvironmentConfig, ICurrency, ITicketCashPayment, OddFormat } from '@bs/models';
import { AppSettingsService, CatalogService } from '@bs/services';
import { WINDOW } from '@bs/universal';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { BsCommonModule } from '@bs/common';

@Component({
  selector: 'ticket-cash-payment-receipt',
  styleUrls: ['./ticket-cash-payment-receipt.scss'],
  templateUrl: './ticket-cash-payment-receipt.html',
  imports: [
    QRCodeModule,
    NgxBarcode6Module,
    TranslateModule,
    BsCommonModule
  ],
  standalone: true
})
export class TicketCashPaymentReceipt implements AfterViewInit, OnInit, OnDestroy {
  loaded: EventEmitter<void> = new EventEmitter<void>();

  defaultCurrency: ICurrency;
  host: string;
  oddFormat: OddFormat;
  subs = new Subscription();
  ticket: any;
  isReprint: boolean;
  //ticket: ITicketRetail;
  ticketCashPayment: ITicketCashPayment;
  qrUrl: string;

  constructor(@Inject(WINDOW) window: Window, private settingsService: AppSettingsService,
              private catalog: CatalogService, private translate: TranslateService, public config: EnvironmentConfig) {
    this.host = window.location.origin;

    this.subs.add(this.settingsService.appSettings$.subscribe({
      next: ({settings}) => {
        this.oddFormat = settings.oddFormat
      }
    }));

    this.defaultCurrency = this.catalog.currentCurrency;
  }

  ngOnInit(): void {
    this.qrUrl = `${this.host}/${this.translate.currentLang}/sportbook/prematch?ticketCode=${this.ticket.code}`;
  }

  ngAfterViewInit() {
    this.loaded.emit();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

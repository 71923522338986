import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IFeedTournament, IFeedTournamentSearchFilters, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class FeedTournamentService {

  readonly url = `${this.config.api.sportsbook}/feeds/tournaments`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }

  search(filters: IFeedTournamentSearchFilters): Observable<Pager<IFeedTournament>> {
    return this.http.post<Pager<IFeedTournament>>(`${this.url}/search`, filters);
  }

}

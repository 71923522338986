import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { TreeTableNode } from '@bs/models';

@Component({
  selector: 'tree-table',
  templateUrl: 'tree-table.component.html',
  styleUrls: ['tree-table.component.scss']
})
export class TreeTableComponent {
  @ContentChild('headerTemplate', {static: true, read: TemplateRef})
  headerTemplate: TemplateRef<any>;
  @ContentChild('bodyTemplate', {static: true, read: TemplateRef})
  bodyTemplate: TemplateRef<any>;

  @Input()
  class: string;
  @Input()
  tree: TreeTableNode[];


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IGameVisibilityBase } from '@bs/models';

@Injectable({
  providedIn: 'root'
})
export class GamesVisibilityService {
  readonly url = `${this.config.api.games}/visibility`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  updateExternalSystems(itemId: number, data: IGameVisibilityBase) {
    return this.http.patch(`${this.url}/external-systems/${itemId}`, data);
  }

  updateProviders(itemId: number, data: IGameVisibilityBase) {
    return this.http.patch(`${this.url}/providers/${itemId}`, data);
  }

  patchGame(itemId: number, data: IGameVisibilityBase) {
    return this.http.patch(`${this.url}/games/${itemId}`, data);
  }
}

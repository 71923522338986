import { Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICurrency } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

/**
 * interface with properties AmountRange
 */
interface AmountRange {
  from: number;
  to: number;
  currency: ICurrency;
}

/**
 * AmountRangeComponent cva provides us 2 fields (amountFrom, to) so we can choose the amount
 */
@Component({
  selector: 'amount-range',
  templateUrl: './amount-range.component.html',
  styleUrls: ['./amount-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AmountRangeComponent),
      multi: true
    }
  ]
})
export class AmountRangeComponent implements ControlValueAccessor, OnDestroy {
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: AmountRange;

  /**
   * responsible for disabling the field
   */
  disabled: boolean;
  /**
   * local reference of array of ICountry
   */
  currencies: Array<ICurrency>;
  /**
   * local reference of Subscription
   * @private
   */
  private subs = new Subscription();

  /**
   * The constructor where we fetch and set the currencies values, and we set a default value for the model variable
   * @param appSettingService
   */
  constructor(private appSettingService: AppSettingsService) {

    this.subs.add(appSettingService.appSettings$.subscribe({
      next: ({settings}) => {
        // if (valueChanged === 'bookmaker') {
        this.currencies = settings.bookmaker.currencies;
        // }
        this.model = {
          from: null,
          to: null,
          currency: this.currencies[0]
        };
      }
    }));

  }

  /*onBlur() {
      this.onTouched();
    }*/

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * sets the model value, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: AmountRange): void {
    if (obj && obj.from && obj.to) {
      this.model = obj;
    }
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * on change on some amount value from the view, we update the model value, and propagate the changes
   *
   * @param who
   * @param amount
   */
  update(who: 'from' | 'to', amount: number) {

    this.model[who] = amount;
    this.model.from = this.model.from || this.model.to - 1;
    this.model.to = this.model.to || this.model.from + 1;
    this.propagateChange(this.model);
  }

  /**
   * on lifecycle hook, we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(model: AmountRange) {
    console.log(model);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IBetOfferCurrency } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BetOfferCurrencyService {
  readonly url = `${this.config.api.sportsbook}/bet-offer`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  create(id: number, req: IBetOfferCurrency): Observable<Array<IBetOfferCurrency>> {
    return this.http.post<Array<IBetOfferCurrency>>(`${this.url}/${id}/currencies`, req)
  }

  patch(id: number, item: IBetOfferCurrency): Promise<IBetOfferCurrency> {
    return this.http.patch<IBetOfferCurrency>(`${this.url}/${id}/currencies/${item.id}`, item).toPromise();
  }
}

import { IAuthor } from '../common/author';
import { IdName, idNames } from '../common/id-name';
import { IAccount } from '../me/me';


export interface IThread {
  id: number;
  name: string;
  status: IThreadStatus;
  messages: Array<IThreadMessage>;
  recipients?: Array<IAccount>
}

export interface IThreadStatus {
  date: string;
  status: threadStatusEnum;
  author: IAuthor;
  history?: Array<IThreadStatus>;
}

export interface IThreadMessage {
  id: number;
  author: IAuthor;
  message: string;
  creationDate: string;
}

export enum threadStatusEnum {
  draft = 1,
  sent = 2,
  deleted,
  archived
}

export namespace threadStatusEnum {
  export function values(): Array<IdName<string>> {
    return idNames(threadStatusEnum);
  }
}

{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "mode": "shop",
  "typeId": 20,
  "steps": [
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "account-cog",
      "inputs": [
        {
          "name": "currencyId",
          "icon": "pi-euro",
          "type": "select",
          "label": "currency",
          "validations": {
            "required": true
          },
          "values:": [],
          "translateValues": false,
          "autoselect": true
        },
        {
          "name": "email",
          "icon": "pi-envelope",
          "type": "email",
          "label": "email",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "mailFormat": true,
            "maxLength": 64
          },
          "asyncValidator": "emailExists"
        },
        {
          "name": "username",
          "icon": "pi-user",
          "type": "text",
          "label": "username",
          "restrict": ["noSpaces", "username"],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          },
          "asyncValidator": "usernameExists"
        },
        {
          "name": "password",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20,
            "matchPassword": true
          }
        },
        {
          "name": "parentId",
          "label": "parent",
          "type": "parent-selector",
          "options": {
            "typeId": 20
          }
        }
      ]
    }
  ]
}

import { IdName, idNames } from '../common/id-name';

export enum DeviceTypes {
  desktop = 1,
  mobile = 2,
  both = 3
}

export namespace DeviceTypes {
  export function values(): Array<IdName<any>> {
    return idNames(DeviceTypes);
  }
}

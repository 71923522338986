import { NgModule } from '@angular/core';
import { SsrNorenderDirective } from './ssr-norender.directive';
import { WINDOW_PROVIDERS } from './window.service';

abstract class UniversalStorage implements Storage {

  readonly length!: number;

  clear() {
  }

  getItem(_key: string): string | null {
    return null;
  }

  key(_index: number): string | null {
    return null;
  }

  removeItem(_key: string) {
  }

  setItem(_key: string, _value: string) {
  }
}

export abstract class SessionStorage extends UniversalStorage {
  [name: string]: unknown;
}

export abstract class LocalStorage extends UniversalStorage {
  [name: string]: unknown;
}

/**
 * function gets local storage value
 */
export function getLocalStorage(): Storage {
  return (typeof window !== 'undefined') ? window.localStorage : mockStorage;
}

/**
 * function gets session storage
 */
export function getSessionStorage(): Storage {
  return (typeof window !== 'undefined') ? window.sessionStorage : mockStorage;
}

/**
 * defining default values of the Storage
 */
const mockStorage: Storage = {
  getItem(): string | null {
    return null;
  },
  setItem() {
    return null;
  },
  removeItem() {
  },
  clear() {
  },
  key(): string | null {
    return null;
  },
  length: 0
};

@NgModule({
  declarations: [
    SsrNorenderDirective,
  ],
  exports: [
    SsrNorenderDirective
  ],
  providers: [
    SsrNorenderDirective,
    WINDOW_PROVIDERS,
    {
      provide: SessionStorage,
      useFactory: getSessionStorage
    },
    {
      provide: LocalStorage,
      useFactory: getLocalStorage
    }
  ]
})
/**
 * export class
 */
export class UniversalModule {
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BookmakersService } from '@bs/services';

enum CappedAmountTypeId {
  amounts = 1,
  none = 2,
  percentage = 3,
}

interface CappedAmount {
  typeId: CappedAmountTypeId;
  amounts?: CappedAmountItem[];
  percentage?: number;
}

interface CappedAmountItem {
  currencyId: number;
  name?: string;
  code: string;
  symbol?: string;
  amount: number;
}

@Component({
  selector: 'capped-amount',
  templateUrl: './capped-amount.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CappedAmountComponent),
      multi: true,
    },
  ],
})
export class CappedAmountComponent implements OnInit, ControlValueAccessor {
  model: CappedAmount;
  isDisabled: boolean;

  typeId: CappedAmountTypeId = CappedAmountTypeId.amounts;
  amounts: CappedAmountItem[] = [];
  oldAmounts: CappedAmountItem[] = [];
  percentage: number;

  @Input() options: { bookmakerId: number };

  @Output() blur = new EventEmitter<any>(null);

  constructor(private bms: BookmakersService) {}

  ngOnInit() {
    if (this.options.bookmakerId) {
      const bookmakers = this.bms.bookmakers$.getValue();
      const current = bookmakers.find((b) => b.id === this.options.bookmakerId);

      this.amounts = current.currencies.map((c) => ({
        name: c.name,
        currencyId: c.id,
        code: c.code,
        symbol: c.symbol,
        amount: 0,
      }));

      setTimeout(() => this.update(), 0);
    } else {
      throw new Error('cannot read bookmakerId');
    }
  }

  onRowEditInit(index: number) {
    this.oldAmounts[index] = { ...this.amounts[index] };
  }

  onRowEditSave(_index: number) {
    this.update();
  }

  onRowEditCancel(index: number) {
    this.amounts[index] = { ...this.oldAmounts[index] };
  }

  getAmount(index: number) {
    return this.amounts[index].amount / 100;
  }

  setAmount(index: number, value: number) {
    this.amounts[index].amount = value * 100;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj: CappedAmount) {
    if (_obj != undefined) {
      this.model = _obj;
      this.percentage = _obj.percentage ?? 1;
      this.amounts = _obj.amounts ?? [];
    }
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  setTab(newTab: CappedAmountTypeId) {
    this.amounts = this.amounts.map((item) => ({ ...item, amount: 0 }));
    this.percentage = 1;
    this.typeId = newTab;
    this.update();
  }

  private onTouched() {}

  propagateChange(_model: CappedAmount) {}

  update() {
    if (this.typeId === CappedAmountTypeId.amounts) {
      this.model = {
        typeId: this.typeId,
        amounts: this.amounts,
      };
    } else if (this.typeId === CappedAmountTypeId.percentage) {
      this.model = {
        typeId: this.typeId,
        percentage: this.percentage,
      };
    } else {
      this.model = {
        typeId: this.typeId,
      };
    }

    this.propagateChange(this.model);
  }
}

{
  "$schema": "../schema.json",
  "cols": [
    {
      "field": "id",
      "header": "id",
      "type": "number"
    },
    {
      "field": "name",
      "header": "name",
      "type": "text"
    },
    {
      "field": "code",
      "header": "code",
      "type": "text"
    },
    {
      "field": "description",
      "header": "description",
      "type": "text"
    },
    {
      "field": "offerTypeId",
      "header": "offerType",
      "type": "text"
    },
    {
      "field": "actions",
      "width": 15,
      "header": "actions",
      "type": "button",
      "buttons": [
        {
          "icon": "search",
          "click": "openItem"
        },
        {
          "icon": "cog",
          "click": "editMarketOption"
        },
        {
          "icon": "exclamation-triangle",
          "click": "editMarketLimits"
        }
      ]
    }
  ]
}

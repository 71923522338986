import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseBirthPlace } from '@bs/forms';
import { FormInterface, IInput } from '@bs/models';
import * as subGeoInfos from '../../../lib/me/subGeoInfos.json';

/**
 * The component contains us birthplace fields
 *
 * @description input field for country, region, province, city
 */
@Component({
  selector: 'birth-place',
  templateUrl: './birth-place.component.html',
  styles: [`:host {
    display: block
  }`],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BirthPlaceComponent),
      multi: true
    }
  ]
})
export class BirthPlaceComponent extends BaseBirthPlace {
  /**
   * when true we display the fill template where we fill in the form, otherwise we use the static template
   */
  @Input()
  readOnly: boolean;
  /**
   * referencing to FormInterface
   */
  subGeoInfosConfig: FormInterface = subGeoInfos;

  /**
   * function returns us IInput, so we can decide in template which template to render, a dropdown, or a single input for a control
   * @param input
   * @param isDropdown
   */
  getInput(input: IInput, isDropdown: boolean): IInput {
    Object.assign(input, {type: isDropdown ? 'select' : 'input'});
    return input;
  }

}

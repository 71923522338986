{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "typeId": 0,
  "mode": "emailFast",
  "steps": [
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "pen",
      "inputs": [
        {
          "name": "email",
          "icon": "at",
          "type": "email",
          "label": "email",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "mailFormat": true,
            "maxLength": 64
          },
          "asyncValidator": "emailExists"
        },
        {
          "name": "username",
          "icon": "user",
          "type": "text",
          "label": "username",
          "autocomplete": "username",
          "restrict": ["noSpaces", "username"],
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20
          },
          "asyncValidator": "usernameExists"
        },
        {
          "name": "password",
          "icon": "key",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "icon": "key",
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "restrict": ["noSpaces"],
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20,
            "matchPassword": true
          }
        },
        {
          "name": "currencyId",
          "icon": "euro-sign",
          "type": "select",
          "label": "currency",
          "autoselect": true,
          "validations": {
            "required": true
          },
          "values:": [],
          "translateValues": false
        },
        {
          "name": "referralCode",
          "icon": "link",
          "type": "text",
          "label": "referralCode",
          "validations": {
            "minLength": 4,
            "maxLength": 20
          }
        }
      ]
    },
    {
      "name": "confirmation",
      "label": "confirmation",
      "icon": "user",
      "inputs": [
        {
          "name": "adult",
          "type": "checkbox",
          "label": "majorAgeDeclarationOne",
          "validations": {
            "required": true,
            "requiredTrue": true
          }
        }
      ]
    }
  ]
}

import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { ValidationService } from '@bs/forms';
import { EnvironmentConfig, FormInterface, IInput } from '@bs/models';


@Injectable()
export class FormService {

  constructor(private fb: UntypedFormBuilder, private validationService: ValidationService, private config: EnvironmentConfig) {
  }

  /**
   * @description transform url parameters, so they cane be used to patch the form
   * @param params query parameters read from url
   */
  readParams(params: Params): Params {
    const result: Params = {...params};

    if (params.startDate && params.endDate) {
      result.startDate_endDate = `${params.startDate}_${params.endDate}`;
      delete result.startDate;
      delete result.endDate;
    }

    return result;
  }

  /**
   * @description transform keys like {"startDate_toDate":date1_date2} into {startDate:date1, toDate:date2}" to submit as param
   * @param formValues values from the form
   */
  buildParams(formValues: Params): Params {
    return Object.entries<string>(this.removeEmptyProperties(formValues)).reduce((acc: Params, [k, v]) => {
      if (k.includes('_')) {

        const values = v.split('_');

        k.split('_').forEach((sub, i) => {
          acc[sub] = values[i];
        });
        return acc;
      }

      acc[k] = v;

      return acc;
    }, {});
  }

  /**
   * build the form to be used with fields
   * @param config
   * @param patch
   */
  buildForm(config: FormInterface, patch = {}): UntypedFormGroup {

    const form: UntypedFormGroup = this.fb.group({});


    const inputs = config.inputs as Array<IInput>;
    inputs.filter(e => e.name).forEach(i => this.setControls(form, i, patch[i?.name as keyof typeof patch]));


    return form;
  }

  /**
   * function removes the key and value from the queryParam, if no value specified
   * @param o
   * @protected
   */
  protected removeEmptyProperties(o: Params): Params {
    return Object.entries(o).reduce((a: Params, [k, v]) => (v ? (a[k] = v, a) : a), {});
  }

  /**
   * @description set controls for build form, avoid code repetition
   * @param group
   * @param i
   * @param patch
   * @private
   */
  private setControls(group: UntypedFormGroup, i: IInput, patch: string) {
    group.setControl(i.name, this.fb.control({
      value: patch !== null ? patch : '',
      disabled: i?.disabled || (i?.disableOnComplete && patch)
    }, this.validationService.buildValidator(i), this.validationService.buildAsyncValidator(i)));
  }

}

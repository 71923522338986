{
  "$schema": "../config/registration-schema.json",
  "autoLogin": true,
  "typeId": 0,
  "mode": "phoneFastNoOTP",
  "steps": [
    {
      "name": "account",
      "label": "accountDetails",
      "icon": "pen",
      "inputs": [
        {
          "name": "currencyId",
          "icon": "pi-euro",
          "type": "select",
          "label": "currency",
          "validations": {
            "required": true
          },
          "values:": [],
          "translateValues": false,
          "autoselect": true
        },
        {
          "name": "phone",
          "prefixName": "prefixId",
          "type": "mobile-prefix",
          "label": "insertPhone",
          "icon": "pi-phone",
          "restrict": [
            "noSpaces"
          ],
          "validations": {
            "required": true,
            "mobilePrefixValidator": "^[1-9]+[0-9]{3,15}$"
          },
          "asyncValidator": "phoneExists"
        },
        {
          "name": "password",
          "icon": "pi-key",
          "type": "password",
          "label": "password",
          "autocomplete": "new-password",
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "icon": "pi-key",
          "type": "password",
          "label": "repeatPassword",
          "autocomplete": "new-password",
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20,
            "matchPassword": true
          }
        }
      ]
    }
  ]
}

<!-- <pre>{{ form.value | json }}</pre> -->

<div class="mb-3">
  <span class="p-buttonset">
    <button pButton (click)="setTab('all')" type="button" [class.disabled]="tab !== 'all'" translate>all</button>
    <button pButton (click)="setTab('provider')" type="button" [disabled]="!gameTypeId" [class.disabled]="tab !== 'provider'" translate>providers</button>
    <button pButton (click)="setTab('game')" type="button" [disabled]="!gameTypeId" [class.disabled]="tab !== 'game'" translate>games</button>
  </span>
</div>

<ng-container [formGroup]="form">
  @switch (tab) {
    @case ('all') {
      <div class=""></div>
    }
    @case ('provider') {
      <div class="md:col-3 col-12">
        <label>{{'providers' | translate}}</label>
        <p-multiSelect formControlName="providerOffer" [options]="providers|filter:inProviders" (ngModelChange)="setProvider($event)" optionLabel="name" optionValue="id"></p-multiSelect>
      </div>
    }
    @case ('game') {
      <ng-container formArrayName="gameOffer">
        <button (click)="addOffer()" class="p-button-primary mb-3" icon="pi pi-plus" pButton pRipple type="button"></button>
        @for (item of gameOffer.controls; track item; let index = $index) {
          <div class="grid align-items-end" [formGroupName]="index">
            <div class="col-5">
              <label>{{'provider' | translate}}</label>
              <p-dropdown formControlName="providerId" [filter]="true" [autoDisplayFirst]="false" [options]="providers|filter:inProviders" (ngModelChange)="setGames($event, index)" optionLabel="name" optionValue="id"></p-dropdown>
            </div>
            <div class="col-5">
              <label>{{'game' | translate}}</label><!--({{gameOffer.at(index).get('gameIds')?.value | json}})-->
              <p-multiSelect formControlName="gameIds" optionLabel="name" optionValue="id" [options]="games[index]">
                <ng-template let-game pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <p-image width="60" height="40" (click)="$event.stopPropagation()" [preview]="true" [src]="game.thumbnail"></p-image>
                    <div>{{ game.name }}</div>
                  </div>
                </ng-template>
              </p-multiSelect>
            </div>
            <div class="col-2">
              <button (click)="removeOffer(index)" class="p-button-danger" icon="pi pi-trash" pButton pRipple type="button"></button>
            </div>
          </div>
        }
      </ng-container>
    }
  }
</ng-container>

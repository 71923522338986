import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GeoInfoType, IdName, IGeoInfo, IOperatorPermission, NetworkTypes } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { AddGeoInfoDialog } from '../../dialogs/add-geoinfo/add-geo-info-dialog.component';

@Component({
  selector: 'geo-infos-crud-table',
  templateUrl: './geo-infos-crud-table.component.html',
  styleUrls: ['./geo-infos-crud-table.component.scss'],
  providers: [DialogService]
})
export class GeoInfosCrudTableComponent implements OnDestroy {
  /**
   * Values of the table
   */
  @Input() geoInfos = [];

  /**
   * Callback of the operation in the table
   */
  @Output() submits: EventEmitter<any> = new EventEmitter();

  geoInfoTypeNames: Array<IdName<string>>;
  operation: string;
  ref: DynamicDialogRef;
  subs = new Subscription();
  permission = IOperatorPermission;

  constructor(
    private translate: TranslateService,
    private dialogService: DialogService,
    route: ActivatedRoute) {
    this.subs.add(route.data.subscribe({
      next: data => {
        this.geoInfoTypeNames = GeoInfoType.names().filter(g => g.id !== GeoInfoType.Headquarters || data.typeId === NetworkTypes.shop);
      }
    }))
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * @description it opens or closes the dialog for the create operation
   *
   */
  openModal() {

    const typeId = this.geoInfoTypeNames.filter(typeIdValue => !this.geoInfos?.find(g => typeIdValue.id === g.typeId));

    this.ref = this.dialogService.open(AddGeoInfoDialog, {
      header: this.translate.instant('addressDetails'),
      data: {
        typeId
      },
      styleClass: 'p-fluid',
      width: '600px',
      height: '100%'
    });

    this.subs.add(this.ref.onClose.subscribe({
      next: data => {
        if (data?.geoInfo) {
          this.submit(data.geoInfo, 'create');
        }
      }
    }));
  }

  /**
   * @description it submits the values of the form via emitter
   *
   * @param {IGeoInfo} geoInfo - values of the form
   * @param {string} operation - operation in the C.R.U.D. table
   */
  submit(geoInfo: IGeoInfo, operation: string) {
    this.submits.next({geoInfo, operation});
  }
}

import { AfterViewInit, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseField } from '@bs/forms';
import { FileUpload } from 'primeng/fileupload';

/**
 * component act as a parent cva for all the cva, so we can switch the template field by input type passed
 */
@Component({
  selector: 'field[input], field[formControlName][type], field[ngModel]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true
    }
  ]
})
export class FieldComponent extends BaseField implements AfterViewInit, OnInit {
  @ViewChild('fuRef', {static: false})
  fuRef: FileUpload;
  /**
   * the years range of birthDate
   */
  yearRange: string;
  /**
   * the birthDate field has range
   */
  hasRange: boolean;

  /**
   * execute the constructor of the base class, and sets years range on birthDate field
   */
  ngOnInit(): void {
    super._ngOnInit();

    if (this.input.name === 'birthDate') {
      this.hasRange = true;
      const year = new Date().getFullYear();
      this.yearRange = `${year - 99}:${year}`;
    }
  }

  ngAfterViewInit(): void {
    if (this.input.type === 'file' && this.model) {
      fetch(this.model)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], this.input.name, {type: blob.type});
          this.fuRef.onFileSelect({target: {files: [file]}});
        })
    }
  }

  /**
   * function that converts the date time
   * @param jsDate
   * @protected
   */
  fromJSDate(jsDate: string | Date): Date {
    return super._fromJSDate(jsDate);
  }

  /**
   * function that reads a file that we upload
   * @param file
   * @param fuRef
   */
  onSelectFile(file: File, fuRef: FileUpload) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.model = reader.result;
      this.propagateChange(this.model);
    };
    reader.onerror = () => {
      fuRef.clear();
    };
    reader.onloadend = (event) => {
      this.onBlur(event);
    }
  }

  /**
   * function removes the file from the fileUpload and empties the model value
   */
  onRemoveFile() {
    this.model = '';
    this.propagateChange(this.model);
  }
}

<ng-template #staticTpl let-birthplace>
  <div class="grid">
    @if (birthplace.country.name) {
      <div class="col-12 md:col-6">
        <label>{{ 'country' | translate }}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="birthplace.country.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (birthplace.region.name) {
      <div class="col-12 md:col-6">
        <label>{{ 'region' | translate }}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="birthplace.region.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (birthplace.province.name) {
      <div class="col-12 md:col-6">
        <label>{{ 'province' | translate }}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="birthplace.province.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (birthplace.city.name) {
      <div class="col-12 md:col-6">
        <label>{{ 'city' | translate }}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="birthplace.city.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
  </div>
</ng-template>


<ng-template #fillTpl let-birthplace>
  <div class="row m-0 py-2">
    <ng-container [formGroup]="birthplace">
      <div class="col-12 p-1 grid">

        @for (input of subGeoInfosConfig.inputs; track input) {
          @switch (input.name) {
            @case ('country') {
              @if (!input.empty) {
                <div class="col-12 md:col-6">
                  <label class="req">{{ input.label | translate }}</label>
                  @if (countries?.length) {
                    <div class="p-inputgroup">
                      <p-dropdown (ngModelChange)="setRegions($event)" [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="countries"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    </div>
                  }
                </div>
              }
            }
            @case ('region') {
              @if (!input.empty && regions) {
                <div class="col-12 md:col-6">
                  <label class="req">{{ input.label | translate }}</label>
                  <div class="p-inputgroup">
                    @if (getInput(input, !!regions?.length).type === 'select') {
                      <p-dropdown (ngModelChange)="setProvinces($event)" [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="regions"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
            @case ('province') {
              @if (!input.empty && provinces) {
                <div class="col-12 md:col-6">
                  <label class="req">{{ input.label | translate }}</label>
                  <div class="p-inputgroup">
                    @if (getInput(input, !!provinces?.length).type === 'select') {
                      <p-dropdown (ngModelChange)="setCities($event)" [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="provinces"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
            @case ('city') {
              @if (!input.empty && cities) {
                <div class="col-12 md:col-6">
                  <label class="req">{{ input.label | translate }}</label>
                  <div class="p-inputgroup">
                    @if (getInput(input, !!cities?.length).type === 'select') {
                      <p-dropdown [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="cities"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
          }
        }
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template [ngTemplateOutletContext]="{$implicit: readOnly ? birthPlace.value : birthPlace}"
[ngTemplateOutlet]="readOnly ? staticTpl: fillTpl"></ng-template>

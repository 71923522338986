<div class="grid p-fluid">
  @if (!bookmakerIdForced) {
    <div class="col-12 md:col-6">
      <label>{{'bookmakers' | translate}}</label>
      <p-multiSelect [options]="bookmakers" [(ngModel)]="model.bookmakers"
        (ngModelChange)="bookmakersChanged($event)"
        [disabled]="isDisabled || bookmakerIdForced" [readonly]="options?.readonly || false"
        optionLabel="name" optionValue="id" selectedItemsLabel="{0} items selected">
      </p-multiSelect>
    </div>
  }
  <div class="col-12 md:col-6">
    <label>{{'walletTypeId' | translate}}</label>
    <p-dropdown [options]="walletTypes"
      optionLabel="name" [autoDisplayFirst]="true"
    [disabled]="true" [showClear]="false"></p-dropdown>
  </div>
  <div class="col-12 md:col-6">
    <label>{{'causalGroupId' | translate}}</label>
    <p-dropdown [options]="causalGroupsValues" [(ngModel)]="causalGroupId" [placeholder]="'select' | translate"
      (ngModelChange)="causalGroupChange($event?.id)" optionLabel="name" [autoDisplayFirst]="false"
    [showClear]="true"></p-dropdown>
  </div>
  <div class="col-12 md:col-6">
    <label>{{'causalId' | translate}}</label>
    <p-dropdown [options]="causalIdsValues" [(ngModel)]="causalId" (ngModelChange)="causalChange($event?.id)"
      [placeholder]="!causalIdsValues?.length || isDisabled ? ('none' | translate) : ('select' | translate)"
    optionLabel="name" [showClear]="true"></p-dropdown>
  </div>
</div>

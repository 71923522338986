import { Pipe, PipeTransform } from '@angular/core';

import { DeliveryType } from '@bs/models';

// todo: apparently used only in krusty
/**
 * this pipe finds the matching value from the enum DeliveryType, and returns the key and value for that type, output: 'Mobile'
 */
@Pipe({name: 'deliveryType'})
export class DeliveryTypePipe implements PipeTransform {

  /**
   * return us the deliveryType value
   * @param value {number} requires the value number of the enum DeliveryType
   */
  transform(value: number): string {
    return DeliveryType.values().find(t => t.key === value).value;
  }
}

import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { AppSettings, AppSettingsKey, EnvironmentConfig } from '@bs/models';
import { LocalStorage } from '@bs/universal';
import { BehaviorSubject, Subject } from 'rxjs';

type scrollToPos = 'top' | 'bottom' | 'left' | 'right';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  globalErrors$: Subject<KeyValue<string, any>> = new Subject<KeyValue<string, any>>();
  scrollTo$: Subject<scrollToPos> = new Subject<scrollToPos>();
  appSettings$ = new BehaviorSubject<{ settings: AppSettings, valueChanged: AppSettingsKey }>(null);
  private readonly STORAGE_KEY = 'AppSettings';

  constructor(private config: EnvironmentConfig, private localStorage: LocalStorage) {
    this.restoreSettings();
  }

  get settings(): AppSettings {
    return JSON.parse(this.localStorage.getItem(this.STORAGE_KEY));
  }

  set settings(settings: AppSettings) {
    this.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(settings));
  }

  /*patchSettings(settingKey: AppSettingsKey, mergeValue: any) {
    const currentSettings = this.appSettings$.getValue().settings;
    currentSettings[settingKey.toString()] = {...currentSettings[settingKey], ...mergeValue};
    this.setSettings(currentSettings, settingKey);
  }*/

  saveSetting(settingKey: AppSettingsKey, settingValue: any) {
    const currentSettings = this.clone<{ settings: AppSettings, valueChanged: AppSettingsKey }>(this.appSettings$.getValue()).settings;
    if (currentSettings[settingKey.toString()] === settingValue) {
      return;
    }
    currentSettings[settingKey.toString()] = settingValue;
    this.setSettings(currentSettings, settingKey);
  }

  toggleDarkTheme(value: boolean) {
    this.saveSetting('darkTheme', value);
  }

  private clone<T>(obj): T {
    return JSON.parse(JSON.stringify(obj));
    // todo: return structuredClone(obj); when browser support (end 2022)
  }

  private areDeepEquals(aObj, bObj) {
    return Object.keys(aObj).every(k => aObj[k] === bObj[k]);
  }

  private restoreSettings() {
    const settings = this.settings || new AppSettings(this.config);
    const currentValue = this.appSettings$.getValue();
    if (!currentValue || !this.areDeepEquals(settings, currentValue.settings)) {
      this.appSettings$.next({settings, valueChanged: null});
    }
  }

  private setSettings(settings: AppSettings, key: AppSettingsKey) {
    if (JSON.stringify(settings) !== JSON.stringify(this.appSettings$.getValue())) {
      this.appSettings$.next({settings, valueChanged: key});
    }
    this.settings = settings;
  }
}

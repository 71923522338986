import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * component outputs us error message of from
 */
@Component({
  selector: 'form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent {
  /**
   * form group that we pass from parent component
   */
  @Input() form: FormGroup;

  /**
   * The constructor
   * @param translate
   */
  constructor(private translate: TranslateService) {
  }

  /**
   * a getter that translate the form error message and output it in the template
   */
  get errorMessage(): string {
    for (const propertyName in this.form.errors) {
      if (propertyName in this.form.errors) {
        return this.translate.instant(this.form.errors.message, {value: this.form.errors[propertyName]});
      }
    }
    return null;
  }

}

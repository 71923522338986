import { Injector, Pipe, PipeTransform } from '@angular/core';
import {
  AccountStateId,
  AccountTypeId,
  AdditionalDataTypes,
  BetOfferStatus,
  BettingGroups,
  BettingStatus,
  BettingTypes,
  CmsPageModules,
  CmsPageStatus,
  DeviceTypes,
  DocumentTypes,
  EventStatus,
  EventTypes,
  GameDeviceTypes,
  GameTag,
  gameTypeEnum,
  GenderTypes,
  IBannerStatus,
  IdName, KraTicketBatchStatus, KraTicketBatchType,
  MappingStatus,
  NetworkModule,
  NetworkTransferStates,
  NetworkTransferTypes,
  NetworkTypes,
  NoteTypes, OddStatus, OfferTypes,
  OutputModel,
  OutputStatus,
  ParticipationRequirement,
  ParticipationRequirementGroup, PaymentTransactionMovementType, PaymentTransactionStatus,
  PromoActionType,
  PromoStatus,
  PromoStepActivationType,
  PromoStepType,
  PromoTargetProfiling,
  PromotionType,
  PromoUserStatus,
  ReferralLinkStatus,
  ResultStatus,
  SettingsTemplateTypes,
  SettlementTimeTypes,
  SettlementTypes,
  SmsGateway,
  SmsStatusId,
  SmsTemplateId,
  SportsBookLogTypes, TaxRemittanceStatus,
  TicketChannelTypes,
  TicketRetailPaymentStatus,
  TicketSettlementStatus,
  TicketStatus,
  TicketTypes,
  TournamentEventTypes,
  Values,
  WalletTypes,
  Webapps,
  WithdrawalChangeStates,
  WithdrawalStates
} from '@bs/models';

import { BookmakersService, CatalogService, GamesService } from '@bs/services';

export const serviceMap = {
  catalogService: CatalogService,
  gamesService: GamesService,
  bookmakersService: BookmakersService
}

@Pipe({
  name: 'formValues',
  // pure: false
})
export class FormValuesPipe implements PipeTransform {

  constructor(private injector: Injector) {
  }

  static get paymentTransactionMovementType(): Array<IdName<string>> {
    return PaymentTransactionMovementType.values()
  }

  static get paymentTransactionStatus(): Array<IdName<string>> {
    return PaymentTransactionStatus.values()
  }

  static get networkStatus(): Array<IdName<string>> {
    return AccountStateId.values();
  }

  /**
   * returns SmsTemplate enum values
   */
  static get smsTemplate(): Array<IdName<string>> {
    return SmsTemplateId.values();
  }

  /**
   * returns smsStatus enum values
   */
  static get smsStatus(): Array<IdName<string>> {
    return SmsStatusId.values();
  }

  /**
   * returns SmsGateway enum values
   */
  static get smsGateways(): Array<IdName<string>> {
    return SmsGateway.values();
  }

  /**
   * returns BettingTypes enum values
   */
  static get bettingTypes(): Array<IdName<string>> {
    return BettingTypes.values();
  }

  static get offerTypes(): Array<IdName<string>> {
    return OfferTypes.values();
  }

  /**
   * returns BettingGroups enum values
   */
  static get bettingGroups(): Array<IdName<string>> {
    return BettingGroups.values();
  }

  static get betOfferStatus(): Array<IdName<string>> {
    return BetOfferStatus.values()
  }

  /**
   * returns TicketStatus enum values
   */
  static get ticketStatus(): Array<IdName<string>> {
    return TicketStatus.values();
  }

  static get ticketSettlementStatus(): Array<IdName<string>> {
    return TicketSettlementStatus.values();
  }

  /**
   * returns TicketRetailPaymentStatus enum values
   */
  static get ticketRetailPaymentStatus(): Array<IdName<string>> {
    return TicketRetailPaymentStatus.values();
  }

  /**
   * returns TicketTypes enum values
   */
  static get ticketTypes(): Array<IdName<string>> {
    return TicketTypes.values();
  }

  /**
   * returns TicketChannelTypes enum values
   */
  static get ticketChannelTypes(): Array<IdName<string>> {
    return TicketChannelTypes.values();
  }

  /**
   * returns WalletTypes enum values
   */
  static get walletTypes(): Array<IdName<string>> {
    return WalletTypes.values();
  }

  /**
   * returns Webapps enum values
   */
  static get webapps(): Array<IdName<string>> {
    return Webapps.values();
  }

  /**
   * returns DeviceTypes enum values
   */
  static get deviceTypes(): Array<IdName<string>> {
    return DeviceTypes.values();
  }

  /**
   * returns GenderTypes enum values
   */
  static get genderTypes(): Array<IdName<string>> {
    return GenderTypes.values();
  }

  /**
   * returns NetworkTypes enum values
   */
  static get networkTypes(): Array<IdName<string>> {
    return NetworkTypes.values();
  }

  /**
   * returns gameTypeEnum enum values
   */
  static get gameTypes(): Array<IdName<string>> {
    return gameTypeEnum.values();
  }

  /**
   * returns gameTagsEnum enum values
   */
  static get gameTags(): Array<IdName<string>> {
    return GameTag.values();
  }

  /**
   * returns GameDeviceTypes enum values
   */
  static get gameDeviceTypes(): Array<IdName<string>> {
    return GameDeviceTypes.values();
  }

  /**
   * returns DocumentTypes enum values
   */

  static get documentTypes(): Array<IdName<string>> {
    return DocumentTypes.values();
  }

  /**
   * returns ReferralLinkStatus enum values
   */
  static get referralLinks(): Array<IdName<string>> {
    return ReferralLinkStatus.values();
  }

  /**
   * returns BetResultStatus enum values
   */
  static get betResultStatus(): Array<IdName<string>> {
    return ResultStatus.values();
  }

  /**
   * returns MappingStatus enum values
   */
  static get mappingStatus(): Array<IdName<string>> {
    return MappingStatus.values();
  }

  /**
   * returns NoteTypes enum values
   */
  static get settlementTypes(): Array<IdName<string>> {
    return SettlementTypes.values();
  }

  /**
   * returns NoteTypes enum values
   */
  static get noteTypes(): Array<IdName<string>> {
    return NoteTypes.values();
  }

  /**
   * returns SettlementTimeTypes enum values
   */
  static get settlementTimeTypes(): Array<IdName<string>> {
    return SettlementTimeTypes.values()
  }

  /**
   * returns SettingsTemplateTypes enum values
   */
  static get settingTemplatesTypes(): Array<IdName<string>> {
    return SettingsTemplateTypes.values()
  }

  /**
   * returns ParticipationRequirement enum values
   */
  static get participationRequirements(): Array<IdName<string>> {
    return ParticipationRequirement.values()
  }

  /**
   * returns ParticipationRequirementGroup enum values
   */
  static get participationRequirementsGroups(): Array<IdName<string>> {
    return ParticipationRequirementGroup.values()
  }

  /**
   * returns PromoStatus enum values
   */
  static get promoStatus(): Array<IdName<string>> {
    return PromoStatus.values()
  }

  static get promoUserStatus(): Array<IdName<string>> {
    return PromoUserStatus.values()
  }

  /**
   * returns PromoEntry enum values
   */
  static get promoActionTypes(): Array<IdName<string>> {
    return PromoActionType.values()
  }

  /**
   * returns PromoStepActivationTypes enum values
   */
  static get promoStepActivationTypes(): Array<IdName<string>> {
    return PromoStepActivationType.values()
  }

  /**
   * returns AccountTypeId enum values
   */
  static get accountType(): Array<IdName<string>> {
    return AccountTypeId.values();
  }

  /**
   * returns WithdrawalStates enum values
   */
  static get withdrawalStates(): Array<IdName<string>> {
    return WithdrawalStates.values();
  }

  /**
   * returns WithdrawalChangeStates enum values
   */
  static get withdrawalChangeStates(): Array<IdName<string>> {
    return WithdrawalChangeStates.values()
  }

  static get promotionTypes(): Array<IdName<string>> {
    return PromotionType.values()
  }

  static get promoStepTypes(): Array<IdName<string>> {
    return PromoStepType.values()
  }

  static get bonusModels(): Array<IdName<string>> {
    return OutputModel.values()
  }

  static get bonusStatus(): Array<IdName<string>> {
    return OutputStatus.values()
  }

  static get networkModules(): Array<IdName<string>> {
    return NetworkModule.values()
  }

  static get networkTransferTypes(): Array<IdName<string>> {
    return NetworkTransferTypes.values()
  }

  static get networkTransferStates(): Array<IdName<string>> {
    return NetworkTransferStates.values()
  }

  static get additionalDataTypes(): Array<IdName<string>> {
    return AdditionalDataTypes.values();
  }


  static get bettingStatusTypes(): Array<IdName<string>> {
    return BettingStatus.values();
  }

  static get eventStatusTypes(): Array<IdName<string>> {
    return EventStatus.values();
  }

  static get tournamentEventTypes(): Array<IdName<string>> {
    return TournamentEventTypes.values();
  }

  static get eventTypes(): Array<IdName<string>> {
    return EventTypes.values();
  }

  static get promoTargetProfiling(): Array<IdName<string>> {
    return PromoTargetProfiling.values();
  }

  static get cmsPageStatus(): Array<IdName<string>> {
    return CmsPageStatus.values();
  }

  static get cmsPageModules(): Array<IdName<string>> {
    return CmsPageModules.values();
  }

  static get webApps(): Array<IdName<string>> {
    return Webapps.values();
  }

  static get bannerStatus(): Array<IdName<string>> {
    return IBannerStatus.values();
  }

  static get sportsbookLogTypes(): Array<IdName<string>> {
    return SportsBookLogTypes.values();
  }

  static get oddStatus(): Array<IdName<string>> {
    return OddStatus.values();
  }

  static get taxRemittanceStatus(): Array<IdName<string>> {
    return TaxRemittanceStatus.values();
  }

  static get kraTicketBatchType(): Array<IdName<string>> {
    return KraTicketBatchType.values();
  }

  static get kraTicketBatchStatus(): Array<IdName<string>> {
    return KraTicketBatchStatus.values()
  }

  /**
   * finds the matching enum collection returns that value
   * @param values {(Values | number)} providing an id number, or Values interface to match the given enum collection
   * @param input {{ values?: any, name?: string }} values that will match one of the enum collection
   *
   */
  transform(values: (Values | number), input: { values?: any, name?: string }): Array<IdName<any>> | any {
    if (!values && !input.values) {
      return;
    }

    if (values || values === 0) { // values can be zero

      if (typeof values === 'number') {
        /**
         * a value by name in the enum collection
         */
        if (typeof input.values === 'string') {
          return FormValuesPipe[input.values].find(x => x.id === values).name;
        }
      } else if (input.name in values) {
        /**
         * Array<IdName<string>> passed as values[name]
         */
        return values[input.name];
      }

    }
    /**
     * Array<IdName<string>> of the enum collection
     */
    if (typeof input.values === 'string') {

      // the values comes from a service
      if (input.values.includes('.')) {
        const [serviceType, method] = input.values.split('.');
        if (serviceType in serviceMap) {
          const service = this.injector.get(serviceMap[serviceType]);
          // const method = m.slice(0, -2);
          let returnData = null;

          service[method]().subscribe({
            next: data => returnData = data
          });

          return returnData;

        } else {
          throw new Error(`There's no such service as '${serviceType}'`);
        }
      }
      return FormValuesPipe[input.values];
    }

    return input.values;
  }

}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "marketTypeId",
      "label": "marketTypes",
      "type": "select",
      "noReset": true,
      "autoselect": true,
      "values": [
        {
          "id": 1,
          "name": "Match"
        },
        {
          "id": 2,
          "name": "GoalsKeeper"
        },
        {
          "id": 3,
          "name": "Cards"
        },
        {
          "id": 4,
          "name": "Corners"
        },
        {
          "id": 5,
          "name": "Shots"
        },
        {
          "id": 10,
          "name": "Outright"
        }
      ]
    },
    {
      "name": "code",
      "label": "code",
      "type": "text"
    }
  ]
}

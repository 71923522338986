import { CurrencyConversion, ICurrency } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { IGame } from '../game/game';
import { ICategory } from './category';
import { ISearchBaseSportsBookFilters } from './common';
import { IFixture } from './fixture';
import { IMapping } from './mapping';
import { IMarketsCollection } from './market-collection';
import { ISport } from './sport';
import { ISportEvent } from './sportEvent';

export enum TournamentEventTypes {
  match = 1,
  outright,
  mixed
}

export namespace TournamentEventTypes {
  export function values(): Array<IdName<string>> {
    return idNames(TournamentEventTypes);
  }
}

export interface ITournamentBase extends IFixture {
  //  betOfferId: number;
  sport: ISport;
  category: ICategory;
  id: number;
  name: string;
  flag: string;
  slug: string;
  counts: number;
  offerTypeId: number;
  tags: Array<number>;
}

export interface ITournament extends ITournamentBase {
  marketSports: Array<number>;
  typeId: TournamentEventTypes;
  mapping?: Array<IMapping>;
  sportEvents?: Array<ISportEvent>;
  selected?: boolean;
  activeMarketsCollection?: IMarketsCollection;
}

export interface IPrize {
  position: string;
  percentage: number;
  amount: number;
  currency: CurrencyConversion;
}

export interface IPrizePool {
  type: IdName<string>;
  prizes: Array<IPrize>;
  amount: number;
  currency: ICurrency;
  //currency: CurrencyConversion;
  taxTypeId?: number;
}

export enum PromoTournamentType {
  SportsBook = 1,
  Casino
}

export interface ITournamentSearchFilters extends ISearchBaseSportsBookFilters {
  categoryId?: number;
  sportId?: number;
}

export interface IPromoTournamentSportsBookWinners {
  ticketId: number;
  code: string;
}

export interface IPromoTournamentSportsBookStake {
  stake: number;
  currency: ICurrency;
}

export interface IPromoTournamentSportsBook {
  stakes: IPromoTournamentSportsBookStake[];
  matches: ISportEvent[];
  winners?: IPromoTournamentSportsBookWinners[];
}

export interface IPromoTournament {
  id: number;
  type: PromoTournamentType;
  status: IdName<string>;
  //currency: ICurrency;
  startDate: string;
  endDate: string;
  needRegistration: boolean;
  name: string;
  details: string;
  thumbnail: string;
  rules: string;
  prizePool: IPrizePool[];
  sportsBook?: IPromoTournamentSportsBook;
  games?: IGame[];
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * this pipe is nesting multiple values
 */
@Pipe({
  name: 'nested'
})
export class NestedPipe implements PipeTransform {

  /**
   * the transform pipe value
   * @param values {any} item values
   * @param nested {string} nesting the values by it's keys ex: causal.causalGroup.name
   */
  transform(values: any, nested: string): string {
    return this.recompose(values, nested);
  }

  /**
   * this method returns us the nested values
   */
  private recompose(values: any, nested: string): string {
    const parts = nested.split('.');
    let newObj = values[parts[0]];
    if (Array.isArray(newObj)) {
      newObj = newObj[0]
    }
    if (parts[1]) {
      parts.splice(0, 1);
      const newString = parts.join('.');
      return this.recompose(newObj, newString);
    }
    return newObj;
  }

}

<p-pickList [target]="model" [disabled]="isDisabled"
            [dragdrop]="true" filterBy="name"
            (onMoveToSource)="update()" (onMoveToTarget)="update()" (onMoveAllToSource)="update()" (onMoveAllToTarget)="update()"
            [showSourceControls]="false" [showTargetControls]="false"
            [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}"
            [sourceFilterPlaceholder]="'searchCurrency' | translate" [sourceHeader]="'available' | translate" [source]="values"
            [targetFilterPlaceholder]="'searchCurrency' | translate" [targetHeader]="'selected' | translate">
  <ng-template let-item pTemplate="item">
    <div class="flex align-items-center justify-content-between">
      <span>{{ item.name}}</span>
    </div>
  </ng-template>
</p-pickList>

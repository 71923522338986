import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IdName } from '@bs/models';
import { AppSettingsService, BookmakersService, GamesManagementService, GamesService } from '@bs/services';
import { forkJoin, Observable, Subscription, take, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * model IBookmakerGamesProvider
 */
interface IBookmakerGamesProvider {
  bookmakerId?: number;
  providerId?: number;
  gameSubTypeId?: number;
}

/**
 * The component has 2 dropdown fields with items: bookmakers, providers, for searching
 */
@Component({
  selector: 'bookmakers-games-providers',
  templateUrl: './bookmakers-games-providers.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmakersGamesProvidersComponent),
      multi: true
    }
  ]
})
export class BookmakersGamesProvidersComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * @ignore
   */
  deviceTypeId = 1;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: IBookmakerGamesProvider = {};
  /**
   * local reference for the bookmakers
   */
  bookmakers: IdName<string>[];
  /**
   * local reference for the brands
   */
  providers: IdName<string>[];
  /**
   * local reference for the gamesSubTypes
   */
  gameSubTypes: IdName<string>[];
  /**
   * local reference of Subscription
   */
  subs = new Subscription();

  /**
   * The constructor, where we fetch and set the bookmakers values
   * @param route
   * @param bookmakersService
   * @param gamesManagementService
   * @param settingsService
   */
  constructor(private route: ActivatedRoute, private bookmakersService: BookmakersService,
              private gamesManagementService: GamesManagementService,
              private settingsService: AppSettingsService) {

    this.subs.add(bookmakersService.getBookmakers().subscribe({
      next: bookmakers => {
        this.bookmakers = Array.from(bookmakers, ({id, name}) => ({id, name}))
      }
    }))
  }

  /**
   * @ignore
   */
  ngOnInit() {
  }

  /**
   * lifecycle hook, where we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * we assign the values of the model, when programmatic changes from model to view are requested, and when we have bookmakerId value we fetch and assign the brands and categories values
   * @param model
   */
  writeValue(model: any): void {
    if (model) {
      [this.model.bookmakerId, this.model.providerId, this.model.gameSubTypeId] = [model.bookmakerId, model.providerId, model.gameSubTypeId]
    } else {
      [this.model.bookmakerId, this.model.providerId, this.model.gameSubTypeId] = [this.settingsService.settings.bookmaker.id, null, null];
    }

    if (this.model.bookmakerId) {
      this.subs.add(this.routeDataCall(this.model.bookmakerId).subscribe({
        next: () => {
          this.update(this.model);
        }
      }));
    }
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: IBookmakerGamesProvider) {
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * when bookmaker value was changed in the view, we are updating the values of brands, and categories list
   * @param bookmakerId
   */
  bookmakersChanged(bookmakerId: number) {
    [this.model.providerId, this.model.gameSubTypeId] = [null, null];
    this.update(this.model);
    if (bookmakerId) {
      this.subs.add(this.routeDataCall(bookmakerId).subscribe());
    }
  }

  /**
   * we are keeping track of the value changes of the cva, so they are always updated
   * @param values
   */
  update(values: IBookmakerGamesProvider) {
    this.onTouched();
    const change: IBookmakerGamesProvider = {};
    Object.entries(values).map(([k, v]) => {
      change[k] = v;
    });

    this.propagateChange(change);
  }

  /**
   * on bookmakerId value change, we call the backend api that will return us the values of brands, and categories, so we can initialize them
   * @param bookmakerId
   * @private
   */
  private routeDataCall(bookmakerId: number): Observable<readonly [any]> {
    return this.route.data.pipe(
      switchMap(data => {
        return forkJoin([
          this.gamesManagementService.getGameType(data.gameTypeId, bookmakerId)
        ]);
      }),
      take(1),
      tap(([gameType]) => {
        this.providers = gameType.providers.sort((a, b) => a.name.localeCompare(b.name));
        this.gameSubTypes = gameType.gameSubTypes.sort((a, b) => a.name.localeCompare(b.name));
      })
    )
  }

}

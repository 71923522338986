
import { IdName, idNames } from '../common/id-name';
import { ISearchBaseSportsBookFilters } from './common';
import { ICompetitorLanguage } from './language';

export enum CompetitorGender {
  male = 1,
  female
}

export namespace CompetitorGender {
  export function values(): Array<IdName<string>> {
    return idNames(CompetitorGender);
  }
}

export enum CompetitorTypes {
  team = 1,
  player,
  competitor,
}

export namespace CompetitorTypes {
  export function values(): Array<IdName<string>> {
    return idNames(CompetitorTypes);
  }
}

export interface ICompetitor {
  id: number;
  name: string;
  typeId: CompetitorTypes; // teams or players
  genderId?: CompetitorGender; //
  feedCompetitorId?: number;
  countryId?: number;
  lastUpdate?: string;
  languages?: ICompetitorLanguage[]
}

export interface ICompetitorSearchFilters extends ISearchBaseSportsBookFilters {
  countryId?: number;
  competitor?: string;
  typeId?: CompetitorTypes;
}

<p-table [value]="model">
  <ng-template pTemplate="header">
    <tr>
      <th class="p-1">{{'name' | translate}}</th>
      <th class="p-1">{{'amount' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-currency>
    <tr>
      <td class="p-1">{{currency.name}}</td>
      <td class="p-1" style="width: 50%">
        <div class="p-inputgroup">
          <p-inputNumber [style]="{width: '200px'}" [(ngModel)]="currency.amount" [minFractionDigits]="2" (ngModelChange)="update()"></p-inputNumber>
          <span class="p-inputgroup-addon">{{currency.symbol}}</span>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { BonusSportsbook, EnvironmentConfig, ICurrency, IDelivery, IDocument, IMe } from '@bs/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';

@Injectable({
  providedIn: 'root'
})

export class MeService {
  readonly url = `${this.config.api.identity}/me`;

  displayCurrency$: BehaviorSubject<Array<ICurrency>> = new BehaviorSubject(null);

  constructor(private config: EnvironmentConfig, private http: HttpClient, private authService: AuthService) {
  }

  /**
   * @alias refresh()
   */
  getMe(): Observable<IMe> {
    return this.http.get<IMe>(`${this.url}`).pipe(tap(me => this.authService.accountLogged$.next(me)));
  }

  updatePassword(passwords: { password: string, newPassword: string }) {
    return this.http.patch(`${this.url}/password`, passwords);
  }

  patchDelivery(delivery: Partial<IDelivery>, id: number) {
    return this.http.patch(`${this.url}/delivery/${id}`, delivery).toPromise();
  }

  saveDelivery(delivery: Partial<IDelivery>): Promise<IMe> {
    return this.http.post<IMe>(`${this.url}/delivery`, delivery).toPromise();
  }

  resend(delivery: Partial<IDelivery>): Promise<any> {
    return this.http.post(`${this.url}/email-confirmationlink/send`, delivery).toPromise();
  }

  deleteInfo(id: number, name: 'delivery' | 'geoinfo' | 'document'): Promise<IMe> {

    return this.http.delete<IMe>(`${this.url}/${name}/${id}`).pipe(tap(() => {
      const me = this.authService.accountLogged$.getValue();
      let entity;
      switch (name) {
        case 'delivery':
          entity = me.deliveries;
          break;
        case 'document':
          entity = me.documents;
          break;
        case 'geoinfo':
          entity = me.geoInfos;
          break;
      }
      const idx = entity.findIndex(e => e.id === id);
      entity.splice(idx, 1);

      this.authService.accountLogged$.next(me);

    })).toPromise();
  }

  updateInfo(data: any, name: 'person' | 'delivery' | 'geoinfo' | 'document'): Observable<IMe> {

    if (data.id) {
      return this.http.patch<IMe>(`${this.url}/${name}/${data.id}`, data).pipe(tap(me => this.authService.accountLogged$.next(me)));
    } else {
      return this.http.post<IMe>(`${this.url}/${name}`, data).pipe(tap(me => this.authService.accountLogged$.next(me)));
    }

  }

  validate(validationFieldValue: string, validationType: string, prefixId: number) {
    return this.http.post(`${this.url}/register/validate`, {
      validationFieldValue,
      validationType,
      bookmakerId: this.config.bookmakerId,
      prefixId
    });
  }

  verifyPin(validationFieldValue: string, pin: string) {
    return this.http.post(`${this.url}/register/verify`, {pin, validationFieldValue});
  }

  markDeliveryAsPrimary(id: number) {
    return this.http.patch(`${this.url}/delivery/${id}`, {isPrimary: true}).toPromise();
  }

  getEmittedSportsBookBonus(): Observable<BonusSportsbook> {
    return this.http.get<BonusSportsbook>(`${this.url}/bonus/sportsbook`);
  }

  getHistory(params: Params, route: string) {
    return this.http.post(`${this.url}/history/${route}`, params);
  }

  gamesBrands(gameTypeId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.config.api.games}/brands`, {params: {t: gameTypeId.toString()}});
  }

  displayCurrencies(): Promise<Array<ICurrency>> {
    return this.http.get<Array<ICurrency>>(`${this.url}/displaycurrencies`).pipe(tap(d => this.displayCurrency$.next(d))).toPromise();
  }

  createDocument(request: IDocument): Observable<IDocument> {
    return this.http.post<IDocument>(`${this.url}/documents`, request);
  }

  getDocuments(): Observable<Array<IDocument>> {
    return this.http.get<Array<IDocument>>(`${this.url}/documents`);
  }

}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "sportId",
      "label": "sportId",
      "type": "select",
      "autoselect": true,
      "values": [
        {
          "id": 1,
          "name": "Soccer",
          "selected": true
        },
        {
          "id": 2,
          "name": "Tennis"
        }
      ]
    }
  ]
}

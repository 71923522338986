import { IdName, idNames } from '../common/id-name';

export enum MappingStatus {
  //disabled = 1,
  active = 2,
  suspended = 3,
  notMapped = 100,
}

export namespace MappingStatus {
  export function values(): Array<IdName<any>> {
    return idNames(MappingStatus);
  }
}

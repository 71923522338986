{
  "$schema": "../schema.json",
  "inputs": [
    {
      "name": "marketSuperGroups",
      "filter": "name",
      "label": "marketSuperGroups",
      "type": "select",
      "noReset": true,
      "autoselect": true,
      "values": [
        {
          "id": 1,
          "name": "Main"
        }
      ]
    }
  ]
}

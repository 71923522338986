import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/core/auth.service';

/**
 * this guard is to check if users is not Logged, is different from private-area
 */
@Injectable({
  providedIn: 'root'
})
export class UserLoggedGuard  {
  /**
   * The constructor
   * @param authService
   * @param router
   */
  constructor(private authService: AuthService, private router: Router) {
  }

  /**
   * interface guard deciding if a route can be activated, and checks if user is logged
   */
  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this.authService.isValid()) {
      const lang = route.params.lang;
      const redirect = route.data.redirect || 'home'

      return this.router.createUrlTree([lang, redirect]);
    } else {
      return true;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IWalletGameSession, IWalletGameSessionDetails, IWalletGameSessionDetailsSearchRequest, IWalletMovement, IWalletMovementBase, IWalletMovementCreateRequest, IWalletMovementSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class WalletMovementsService { // in krusty accounts
  readonly url = `${this.config.api.accounts}/wallets/movements`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(id: number): Observable<IWalletMovementBase> {
    return this.http.get<IWalletMovementBase>(`${this.url}/${id}`);
  }

  search(filters: IWalletMovementSearchRequest): Observable<Pager<IWalletMovementBase>> {
    return this.http.post<Pager<IWalletMovementBase>>(`${this.url}/search`, filters);
  }

  create(req: IWalletMovementCreateRequest): Observable<IWalletMovement> {
    return this.http.post<IWalletMovement>(`${this.url}`, req);
  }

  // sessions
  getGameSession(id: number): Observable<IWalletGameSession> {
    return this.http.get<IWalletGameSession>(`${this.url}/game-sessions/${id}`);
  }

  searchGameSessionDetails(filters: IWalletGameSessionDetailsSearchRequest): Observable<Pager<IWalletGameSessionDetails>> {
    return this.http.post<Pager<IWalletGameSessionDetails>>(`${this.url}/game-sessions/details/search`, filters);
  }

}

<div class="grid p-fluid">
  <div class="col-12 md:col-6">
    <label>{{'causalGroupId' | translate}}</label>
    <p-dropdown [options]="causalGroupsValues" [(ngModel)]="causalGroupId" [placeholder]="'select' | translate"
                (ngModelChange)="causalGroupChange($event?.id)" optionLabel="name" [autoDisplayFirst]="false"
                [disabled]="isDisabled || causalGroupIdForced" [showClear]="true"></p-dropdown>
  </div>
  <div class="col-12 md:col-6">
    <label>{{'causalId' | translate}}</label>
    <p-dropdown [options]="causalIdsValues" [(ngModel)]="causalId" (ngModelChange)="update(causalGroupId.id, $event?.id)"
                [placeholder]="!causalIdsValues?.length || isDisabled ? ('none' | translate) : ('select' | translate)"
                [disabled]="!causalIdsValues?.length || isDisabled" optionLabel="name" [showClear]="true"></p-dropdown>
  </div>
</div>

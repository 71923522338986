import { IdName, idNames } from '../common/id-name';
import { IAccountBase, IWalletAmount, IWalletMovementBase } from './movement';

export interface INetworkTransfersSearchRequest {
  walletTypeId: number;
  accountId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface INetworkTransfersChangeStatesRequest {
  id: number;
  stateId: NetworkTransferStates;
  note?: string;
  code?: string;
}

export interface INetworkTransfersRequest {
  typeId: NetworkTransferTypes;
  beneficiaryId: number;
  holderId?: number;
  amount: number;
  notes?: string;
}

export enum NetworkTransferStates {
  completed = 1,
  inPending,
  refused,
  readyToCollect,
  canceled
}

export namespace NetworkTransferStates {
  export function values(): Array<IdName<string>> {
    return idNames(NetworkTransferStates);
  }
}

export enum NetworkTransferTypes {
  directDeposit = 1,
  directWithdrawal,
  shopPayoutRequest = 3,
  topUp = 4
}

export namespace NetworkTransferTypes {
  export function values(): Array<IdName<string>> {
    return idNames(NetworkTransferTypes);
  }
}

export interface INetworkTransfer {
  id: number;
  code: string;
  requestedAt: string;
  closedAt: string;
  typeId: NetworkTransferTypes;
  stateId: NetworkTransferStates;
  applicant: IAccountBase;
  holder: IAccountBase;
  beneficiary: IAccountBase;
  amount: IWalletAmount;
  movements: Array<IWalletMovementBase>;
}

<html>
<head>

  <meta charset="utf-8">
  <title>{{'printer' | translate}}</title>
</head>
<div id="printable" style="width:80mm">
  <table border="1" cellpadding="0" cellspacing="0" width="100%">
    <tr>
      <th>
        <img src="{{host}}/assets/images/logo-black.svg" alt="logo" style="width: 150px">
      </th>
    </tr>
  </table>
</div>
</html>

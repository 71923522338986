import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IResult, ISearchBaseSportsBookFilters, Pager } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  readonly url = `${this.config.api.sportsbook}/results`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: ISearchBaseSportsBookFilters): Observable<Pager<IResult>> {
    return this.http.post<Pager<IResult>>(`${this.url}/search`, filters);
  }
}

import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountTypeId, IAccount } from '@bs/models';
import { AccountsService } from '@bs/services';

@Component({
  selector: 'parent-selector',
  templateUrl: './parent-selector.component.html',
  styles: [`.drop-type {
    max-width: 90px;
  }

  .drop-type::ng-deep.p-dropdown {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  p-autoComplete::ng-deep .p-autocomplete-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParentSelectorComponent),
      multi: true
    }
  ]
})
export class ParentSelectorComponent implements ControlValueAccessor, OnInit {

  isDisabled: boolean;
  parent: any;
  bookmakerId: number;

  @Input()
  options: any;

  @Input()
  account: IAccount;

  types = [];
  typeId: number;
  results: Array<IAccount>;

  constructor(private accountService: AccountsService, @Optional() private container: ControlContainer) {

  }


  ngOnInit() {

    if (!this.account) {
      this.typeId = this.options.typeId
    } else {
      this.typeId = this.account.typeId
    }

    switch (this.typeId) {
      case AccountTypeId.Player:
        this.types = [
          {name: 'shop', id: 20},
          {name: 'agent', id: 30}
        ];
        break
      case AccountTypeId.Shop:
        this.types = [
          {name: 'agent', id: 30}
        ];
        break
      case AccountTypeId.Agent:
        this.types = [
          {name: 'agent', id: 30}
        ];
        break
      case AccountTypeId.Cashier:
        this.types = [
          {name: 'shop', id: 20},
        ];
        break
    }

    if (this.container) {
      this.bookmakerId = this.container.control.get('bookmakerId').value;
    } else if (this.account) {
      this.bookmakerId = this.account.bookmakerId;
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * function when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(): void {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  propagateChange(_model: any) {
  }

  /**
   * on change of values in the picklist we register those changes
   * @param values
   */
  update(value: any) {
    this.propagateChange(value.id);
  }

  search(event: any) {
    this.accountService.search({
      bookmakers: [this.bookmakerId],
      displayCurrencyId: 1,
      fullNetwork: false,
      p: 1,
      pp: 100,
      typeId: this.typeId,
      username: event.query,
      usernameSearchCriteriaId: 2
    }).subscribe({
      next: data => this.results = data.list
    });

  }

  reset() {
    this.parent = '';
    this.propagateChange(null);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

}

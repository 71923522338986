import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IDelivery, IOperatorPermission } from '@bs/models';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { DeliveryDialog } from '../../dialogs/delivery/delivery.dialog';

@Component({
  selector: 'deliveries-crud-table',
  templateUrl: './deliveries-crud-table.component.html',
  providers: [DialogService]
})
export class DeliveriesCrudTableComponent implements OnDestroy, OnInit {
  /**
   * Values of the table
   */
  @Input() data = [];

  /**
   * Callback of the operation in the table
   */
  @Output() submits: EventEmitter<any> = new EventEmitter();

  ref: DynamicDialogRef;
  subs = new Subscription();
  permission = IOperatorPermission;

  constructor(public dialogService: DialogService,
              private messageService: MessageService,
              private translate: TranslateService) {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
  }

  /**
   * @description it submits the values of the form via emitter
   *
   * @param {IDelivery} delivery - values of the form
   * @param {string} operation - operation in the C.R.U.D. table
   */
  submit(delivery: IDelivery, operation: string) {
    this.submits.next({delivery, operation});
  }

  /**
   * @description it opens or closes the dialog for the create operation
   *
   * @param {string} delivery - operation in the C.R.U.D. table
   */
  openModal(delivery?: IDelivery) {
    if (delivery?.isConfirmed) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('cantEditWhenIsConfirmed'),
        detail: this.translate.instant('deliveryEdit'),
        life: 3000
      });
    } else {
      this.ref = this.dialogService.open(DeliveryDialog, {
        header: this.translate.instant('addressDetails'),
        data: delivery,
        styleClass: 'p-fluid',
        width: '450px'
      });
      this.subs.add(this.ref.onClose.subscribe({
        next: (data) => {
          if (data?.delivery) {
            this.submit(data.delivery, data.operation);
          }
        }
      }));
    }
  }

  refresh() {

  }
}

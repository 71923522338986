import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DeliveryType } from '@bs/models';
import { mailFormat } from './mail-format.validator';

/**
 * validating if control is email we are adding email validation, otherwise we only add a validators required
 * @param targetName
 */
export function deliveriesValidator(targetName = 'delivery'): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    if (!control.parent) {
      return null;
    }

    const targetField = control.parent.get(targetName);
    let validator: ValidatorFn = () => null;

    if (control.value?.id === DeliveryType.Email) { // email
      validator = () => mailFormat(targetField);
    }

    targetField.setValidators([Validators.required, validator]);
    targetField.updateValueAndValidity();

    return null;
  };
}

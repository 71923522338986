{
  "$schema": "../schema.json",
  "cols": [
    {
      "field": "id",
      "header": "id",
      "type": "number"
    },
    {
      "field": "ipAddress",
      "header": "ip",
      "type": "ipAddress"
    },
    {
      "field": "account.username",
      "header": "account",
      "type": "nested"
    },
    {
      "field": "creationDate",
      "header": "creationDate",
      "type": "date"
    },
    {
      "field": "lastUpDated",
      "header": "lastUpDated",
      "type": "date"
    },
    {
      "field": "closingDate",
      "header": "closingDate",
      "type": "date"
    },
    {
      "field": "actions",
      "header": "actions",
      "type": "button",
      "buttons": [
        {
          "icon": "search",
          "click": "openItem"
        }
      ]
    }
  ]
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseBirthPlace } from './base/base-birth-place';
import { BaseCausalGroups } from './base/base-causal-groups';
import { BaseField } from './base/base-field';
import { BaseFormBuilder } from './base/base-form-builder';
import { BaseGeoInfos } from './base/base-geo-infos';
import { BaseMobilePrefix } from './base/base-mobile-prefix';
import { BasePinInput } from './base/base-pin-input';
import { BaseTableBuilder } from './base/base-table-builder';
import { BaseTreeTable } from './base/base-tree-table';
import { RestrictInputDirective } from './directives/restrict-input.directive';
import { ButtonConditionsPipe } from './pipes/button-conditions.pipe';
import { ButtonPermissionsPipe } from './pipes/button-permissions.pipe';
import { FormValuesPipe } from './pipes/formValues.pipe';
import { NestedPipe } from './pipes/nested.pipe';
import { BookmakerNamePipe } from './pipes/bookmakerName.pipe';
import { TreeTableComponent } from './tree-table/tree-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from './services/form.service';

/**
 * array of all the components or pipes that goes in NgModule exports
 */
const exports = [
  ButtonConditionsPipe,
  ButtonPermissionsPipe,
  FormValuesPipe,
  NestedPipe,
  BookmakerNamePipe,
  TreeTableComponent,
  RestrictInputDirective
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    BaseTableBuilder,
    BaseFormBuilder,
    BaseBirthPlace,
    BaseCausalGroups,
    BaseField,
    BaseMobilePrefix,
    BasePinInput,
    BaseGeoInfos,
    BaseTreeTable,
    ...exports
  ],
  exports: [...exports, FormsModule, ReactiveFormsModule],
  providers: [FormService]
})
export class BsFormsModule {

}

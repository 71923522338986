import { CurrencyPipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BsCommonModule } from '@bs/common';
import { EnvironmentConfig } from '@bs/models';
import { KrustyFormsModule } from '@krusty/forms';
import { BaseTabs } from './base/base-tabs';
import { BookmakerSelectorComponent } from './components/bookmaker-selector/bookmaker-selector.component';
import { DeliveriesCrudTableComponent } from './components/deliveries-crud-table/deliveries-crud-table.component';
import { DisplayCurrencySelectorComponent } from './components/displaycurrency-selector/displaycurrency-selector.component';
import { GeoInfosCrudTableComponent } from './components/geo-infos-crud-table/geo-infos-crud-table.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { OddsformatSelectorComponent } from './components/oddsformat-selector/oddsformat-selector.component';
import { SectionMenuBuilderComponent } from './components/section-menu-builder/section-menu-builder.component';
import { TimezoneSelectorComponent } from './components/timezone-selector/timezone-selector.component';
import { AddGeoInfoDialog } from './dialogs/add-geoinfo/add-geo-info-dialog.component';
import { DeliveryDialog } from './dialogs/delivery/delivery.dialog';
import { FormatFileSizePipe } from './pipe/format-file-size.pipe';

import { PrimeModule } from './prime.module';
import { BreadcrumbService } from './services/breadcrumb.service';

/**
 * array of all the dialogs components that goes in NgModule exports and declaration, so they can be read in another module
 */
const dialogsComponent = [
  DeliveryDialog,
  AddGeoInfoDialog
]

/**
 * array of all the pipes components that goes in NgModule exports and declaration, so they can be read in another module
 */
const pipes = [
  FormatFileSizePipe
]

/**
 * array of all the components that goes in NgModule exports and declaration, so they can be read in another module
 */
const components = [
  BookmakerSelectorComponent,
  DeliveriesCrudTableComponent,
  DisplayCurrencySelectorComponent,
  GeoInfosCrudTableComponent,
  LanguageSelectorComponent,
  OddsformatSelectorComponent,
  SectionMenuBuilderComponent,
  TimezoneSelectorComponent
];

@NgModule({
  imports: [
    KrustyFormsModule,
    PrimeModule.forRoot(),
    BsCommonModule,
    //.forRoot(EnvironmentConfig)
  ],
  exports: [components, dialogsComponent, pipes, BsCommonModule, KrustyFormsModule],
  declarations: [
    BaseTabs,
    components,
    dialogsComponent,
    pipes
  ]
})

export class KrustyCommonModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<KrustyCommonModule> {
    return {
      ngModule: KrustyCommonModule,
      providers: [
        CurrencyPipe,
        BreadcrumbService,
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}

import { IdName, idNames } from '../common/id-name';
import { IPrefix } from '../common/prefix';
import { IAccountBase } from '../wallet/movement';

export enum SmsTemplateId {
  login = 1,
  registration,
  deposit,
  withdrawal,
  betWon,
  betVoid,
  otp
}

export namespace SmsTemplateId {
  export function values(): Array<IdName<any>> {
    return idNames(SmsTemplateId);
  }
}

export enum SmsStatusId {
  waiting = 1,
  sent,
  error
}

export namespace SmsStatusId {
  export function values(): Array<IdName<any>> {
    return idNames(SmsStatusId);
  }
}

export enum SmsGateway {
  drumOfAfrica = 1,
  bulkTechnology,
  checkMobi,
  yoSms,
  blackBall,
  onFonMedia,
  mtn
}

export namespace SmsGateway {
  export function values(): Array<IdName<any>> {
    return idNames(SmsGateway);
  }
}

export interface IPostManSmsSearchRequest {
  accountId?: number;
  smsGatewayId?: number;
  smsStatusId?: number;
  smsTemplateId?: number;
  prefix?: IPrefix;
  phone?: string;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IPostManSms {
  id: number;
  bookmakerId: number;
  account?: IAccountBase;
  statusId: number;
  phone: string;
  text: string;
  templateId?: number;
  creationDate: string;
  sendDate?: string;
  externalReference: string;
  gatewayId: number;
  gatewayMessage?: string;
  otp?: string;
  tpl?: string;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ITournamentTemplate, ITournamentTemplateSearchFilters, Pager } from '@bs/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TournamentsTemplatesService {
  readonly url = `${this.config.api.sportsbook}/tournaments-templates`;

  private templates: ITournamentTemplate[] = [
    {id: 1, activeVersionId: 1, betOfferId: 1, sport: {id: 1, name: 'Soccer'}, name: 'Soccer Major Leagues - Standard risk', creationDate: '2023-10-03T14:08:49.1Z', lastUpdated: '2023-10-03T14:08:49.1Z', author: {id: 10, username: 'marco', typeId: 10}},
    {id: 2, activeVersionId: 2, betOfferId: 1, sport: {id: 1, name: 'Soccer'}, name: 'Soccer Minor Leagues - Standard risk', creationDate: '2023-10-03T14:08:49.1Z', lastUpdated: '2023-10-03T14:08:49.1Z', author: {id: 10, username: 'marco', typeId: 10}},
    {id: 3, activeVersionId: 22, betOfferId: 1, sport: {id: 1, name: 'Soccer'}, name: 'Soccer Major Leagues - High risk', creationDate: '2023-10-03T14:08:49.1Z', lastUpdated: '2023-10-03T14:08:49.1Z', author: {id: 10, username: 'marco', typeId: 10}},
    {id: 4, activeVersionId: 13, betOfferId: 1, sport: {id: 1, name: 'Soccer'}, name: 'Soccer Minor Leagues - High risk', creationDate: '2023-10-03T14:08:49.1Z', lastUpdated: '2023-10-03T14:08:49.1Z', author: {id: 10, username: 'marco', typeId: 10}}
  ]

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: ITournamentTemplateSearchFilters): Observable<Pager<ITournamentTemplate>> {
    const mock: Pager<ITournamentTemplate> = {count: this.templates.length, list: this.templates}
    console.log(mock)
    return of(mock)
    //return this.http.post<Pager<ITournamentTemplate>>(`${this.url}/search`, filters);
  }

  create(item: ITournamentTemplate): Observable<ITournamentTemplate> {
    return this.http.post<ITournamentTemplate>(`${this.url}`, item);
  }

  get(id: number, versionId?: number): Observable<ITournamentTemplate> {
    // if versionId is null return the last version
    return this.http.get<ITournamentTemplate>(`${this.url}/${id}`, {params: {vId: versionId}});
  }

  findActive(betOfferId: number, entityTypeId: number, entityId: number): Observable<ITournamentTemplate> {
    return null
    // return this.http.get<ITournamentTemplate>(`${this.url}`, {betOfferId, entityTypeId, entityId});
  }

}

/**
 * @deprecated
 */
import { IdName, idNames } from '../../common/id-name';
import { IBetSlipType } from '../enum';
import { Selection } from '../selection';

interface ITicketRequestselection {
  blocked: boolean;
  oddId: number;
  value?: number;
}
/**
 * @deprecated
 */
export enum IBetslipgroup {
  Prematch = 1,
  Live,
  Mixed
}
/**
 * @deprecated
 */
export namespace IBetslipgroup {
  export function values(): Array<IdName<any>> {
    return idNames(IBetslipgroup);
  }
}
/**
 * @deprecated
 */
export interface ITicketbooking {
  expiringDate: string;
  stake: number;
  code: string;
  selections: Array<Selection>;
}
/**
 * @deprecated
 */
export interface BookbetRequest {
  betTypeId: IBetSlipType; // type
  betGroupId?: IBetslipgroup; // context
  stake: number; // betType.amount
  selections: Array<ITicketRequestselection>;
}

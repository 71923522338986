import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IBookmaker } from '@bs/models';
import { BookmakersService } from '@bs/services';

/**
 * The component contains all the bookmakers pickList, so we can reorder the bookmakers between different lists.
 */
@Component({
  selector: 'bookmakers-picklist',
  templateUrl: './bookmakers-picklist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmakersPicklistComponent),
      multi: true
    }
  ]
})
export class BookmakersPicklistComponent implements ControlValueAccessor {
  /**
   * Local reference of Array<IBookmaker>
   */
  values: Array<IBookmaker>;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: Array<IBookmaker> = [];

  /**
   * The constructor where we initialize the values of bookmakers
   * @param bookmakersService
   */
  constructor(private bookmakersService: BookmakersService) {
    this.values = this.bookmakersService.bookmakers$.getValue().map(r => r);
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * function when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(): void {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<number>) {
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * on change of values in the picklist we register those changes
   * @param values
   */
  update() {
    this.propagateChange(this.model.map(x => x.id));
  }
}

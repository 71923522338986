import { ParamMap, Params } from '@angular/router';
import { DateFactoryService } from '../../services/core/date-factory.service';

/**
 * a base class for the Pager items when we make a search by using the form-builder component to add the query params of the search
 */
export class BaseResolver {
  /**
   * The constructor
   * @param {DateFactoryService} dfs public variable
   */
  constructor(public dfs: DateFactoryService) {
  }

  /**
   * remaps the query params and returns the params
   *
   * see {@link Params} for the return type
   *
   * @param queryParamMap
   */
  protected remap(queryParamMap: ParamMap): Params {
    const params: Params = {
      p: queryParamMap.get('p') || 1,
      pp: queryParamMap.get('pp') || 10,
    };

    // cast parameters to Boolean
    ['fullNetwork'].forEach(item => {
      if (queryParamMap.has(item)) {
        params[item] = queryParamMap.get(item) === 'true'; // is not beautiful but avoids eval()
      }
    });


    /* OLD FUNCTION
     [
       'accountId', 'walletTypeId', 'currencyId', 'statusId', 'usernameSearchCriteriaId',
       'typeId', 'brandId', 'groupId', 'causalGroupId', 'causalId',
       'sourceLanguageId', 'targetLanguageId', 'catalog', 'p', 'pp', 'webAppId'
     ].forEach(item => {
       if (queryParamMap.has(item)) {
         params[item] = +queryParamMap.get(item);
       }
     });*/

    // cast parameters to Int
    queryParamMap.keys.forEach(k => {
      if (k.endsWith('s')) {
        params[k] = queryParamMap.getAll(k).map(x => isNaN(Number(x)) ? x : +x);
      } else if (k.endsWith('Id') || ['catalog', 'p', 'pp'].includes(k)) {
        params[k] = +queryParamMap.get(k);
      }
    })

    if (queryParamMap.has('from')) {
      params.from = this.dfs.toTz(queryParamMap.get('from'));
    }

    if (queryParamMap.has('to')) {
      params.to = this.dfs.toTz(queryParamMap.get('to'), true);
    }

    const toRemove = queryParamMap['params'] as Params;
    return Object.assign({}, this.removeEmptyStrings(toRemove), params);
  }

  /**
   * function removes the key and value from the queryParam, if no value specified
   * @param obj
   * @protected
   */
  protected removeEmptyStrings(obj) {
    const clone = {...obj};
    Object.entries(clone).forEach(([key, val]) => val === '' && delete clone[key]);
    return clone;
  }
}

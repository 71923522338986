<div class="grid">

  <p-dropdown
    class="col-12 md:col-6"
    [options]="betoffers"
    [(ngModel)]="betOfferSelected"
    [disabled]="isDisabled"
    [filter]="true" filterBy="name"
    [required]="true"
    (ngModelChange)="setSport($event)"
    [placeholder]="'selectBetOffer'| translate"
    optionLabel="name">
  </p-dropdown>

  <p-dropdown
    class="col-12 md:col-6"
    [options]="sports"
    [(ngModel)]="sportSelected"
    [disabled]="isDisabled || !betOfferSelected"
    [filter]="true" filterBy="name"
    [required]="true"
    (ngModelChange)="setCategory($event)"
    [placeholder]="'selectSport'| translate"
    optionLabel="name">
  </p-dropdown>

  <p-dropdown
    class="col-12 md:col-6"
    [options]="categories"
    [(ngModel)]="categorySelected"
    [disabled]="isDisabled || !sportSelected"
    [filter]="true" filterBy="name"
    (ngModelChange)="setTournament($event)"
    [placeholder]="'selectCategory'| translate"
    optionLabel="name">
  </p-dropdown>

  <p-dropdown
    class="col-12 md:col-6"
    [options]="tournaments"
    [(ngModel)]="tournamentSelected"
    [disabled]="isDisabled || !categorySelected"
    [filter]="true" filterBy="name"
    (ngModelChange)="setMatches($event)"
    [placeholder]="'selectTournament'| translate"
    optionLabel="name">
  </p-dropdown>
</div>

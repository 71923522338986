import { IdName, idNames } from '../common/id-name';

export enum NetworkTypes {
  player = 0,
  agent = 30,
  operator = 10,
  shop = 20,
  cashier = 21,
  bookmaker = 200
}

export namespace NetworkTypes {
  export function values(): Array<IdName<any>> {
    return idNames(NetworkTypes);
  }
}

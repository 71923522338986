<p-toolbar class="block mb-2">
  <div class="p-toolbar-group-left">
    <p-dropdown [(ngModel)]="walletTypeId" [disabled]="isDisabled" [options]="walletTypes" name="walletTypeId" optionLabel="name" optionValue="id" (ngModelChange)="updateCausals($event, causalGroupId)" class="mr-2"></p-dropdown>
    @if (causalGroups?.length) {
      <p-dropdown [(ngModel)]="causalGroupId" [disabled]="isDisabled || cgDisabled" [options]="causalGroups" name="causalGroupId" optionLabel="name" optionValue="id" (ngModelChange)="updateCausals(walletTypeId, $event)"></p-dropdown>
    }
  </div>
</p-toolbar>

<p-pickList [source]="source" [target]="model" [dragdrop]="true" filterBy="name"
  (onMoveToSource)="update()" (onMoveToTarget)="update()" (onMoveAllToSource)="update()" (onMoveAllToTarget)="update()"
  [showSourceControls]="false" [showTargetControls]="false"
  [sourceStyle]="{'height':'10rem'}" [targetStyle]="{'height':'10rem'}" [responsive]="true">
  <ng-template let-causal pTemplate="item">
    <div>{{causal.name}}</div>
  </ng-template>
</p-pickList>

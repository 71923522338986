{
  "$schema": "../../schema.json",
  "inputs": [
    {
      "type": "date-range",
      "name": "from,to",
      "label": "fromToDate",
      "rowspan": 1
    }
  ]
}

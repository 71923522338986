import { IdName, idNames } from '../common/id-name';
import { ISearchBaseSportsBookFilters } from './common';

export enum ScoreTypes {
  FT = 1
}

export namespace ScoreTypes {
  export function values(): Array<IdName<any>> {
    return idNames(ScoreTypes);
  }
}

export interface IResult {
  id: number;
  sportEvent: {
    id: number;
    name: string;
  },
  betResultStatusId: number;
  selection: string;
}

import { CurrencyConversion } from '../common/currency';

export interface Report<T> {
  reports: Array<T>;
}

export class ReportData<T> {
  value?: T;
  accountId: number;
  children?: T[];
  type: string;
  typeId: number;
  username: string;
  // data?: S[];
  line: any;
  net: any;
}

export class GameReportData<T> extends ReportData<T> {
  bet: number;
  hands: number;
  won: number;
  rake?: any;
}

export class NetworkBettingReport extends GameReportData<NetworkBettingReport> {
  liveplusmixed: NetworkBettingReportData;
  prematch: NetworkBettingReportData;

  initialize(x){
    const zeroedValues = {
      count: 0,
      bet: 0,
      won: 0,
    };
    if (!x.prematch) {
      x.prematch = Object.create(zeroedValues);
    }

    if (!x.liveplusmixed) {
      x.liveplusmixed = Object.create(zeroedValues);
    }

    x.net = {
      prematch: Object.create(x.prematch),
      liveplusmixed: Object.create(x.liveplusmixed)
    };

    x.line = {
      prematch: Object.create(x.prematch),
      liveplusmixed: Object.create(x.liveplusmixed)
    };
  }
}

export class NetworkFinanceReport extends ReportData<NetworkFinanceReport> {
  balance?: NetworkFinanceReportBalance;
  payment?: {
    deposit: number;
    withdrawal: number;
  };
  transfer: {
    cashIn: number;
    cashOut: number;
  }

  initialize(x: any) {
    const zeroedValues = {
      payment: {
        deposit: 0,
        withdrawal: 0,
      },
      transfer: {
        cashIn: 0,
        cashOut: 0,
      }
    };

    if (!x.payment) {
      x.payment = zeroedValues.payment;
    }

    if (!x.transfer) {
      x.transfer = zeroedValues.transfer;
    }

    x.net = {
      balance: Object.create(x.balance),
      payment: Object.create(x.payment),
      transfer: Object.create(x.transfer)
    };

    x.line = {
      balance: Object.create(x.balance),
      payment: Object.create(x.payment),
      transfer: Object.create(x.transfer)
    };
  }

}

export class NetworkCasinoReport extends GameReportData<NetworkCasinoReport> {
  initialize(x){
    x.net = {
      hands: x.hands,
      bet: x.bet,
      won: x.won
    };

    x.line = {
      hands: x.hands,
      bet: x.bet,
      won: x.won
    }
  }
}

// finance
interface NetworkFinanceReportBalance extends CurrencyConversion {
  available: number;
  deposit: number;
  withdrawable: number;
  bonus: number;
  overdraft: number;
}

export interface NetworkBettingReportData {
  count: number;
  bet: number;
  hands: number;
  line: any;
  net: any;
  won: number;
  rake?: any;
}

export class NetworkCashierReport extends CurrencyConversion {
  ticketsSold: ICashierTicketsSold;
  ticketsToPay: ICashierTickets;
  ticketsPaid: ICashierTickets;
  topUp: ICashierGenericCount;
  withdrawalShopRequest: ICashierGenericCount;
}

export class ICashierTicketsSold {
  count: number;
  sold: number;
}

export class ICashierTickets {
  count: number;
  void: number;
  refunded: number;
  won: number;
}

export class ICashierGenericCount {
  count: number;
  amount: number;
}

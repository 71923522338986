import { IdName, idNames } from '../common/id-name';
import { IFixtureLanguage } from './language';
import { IMarketsCollection } from './market-collection';

export enum EntityTypes {
  // betOffer = 2,
  // settingSelection = 3,
  sport = 5,
  category = 10,
  tournament = 15,
  // season = 17,
  // sportEvent = 20,
  // competitor = 30,
  // team = 35,
  // player = 40,
  // market = 50,
  // outcome = 55,
  // specifier = 60
}

export namespace EntityTypes {
  export function values(): Array<IdName<string>> {
    return idNames(EntityTypes);
  }
}

export namespace FixtureTypes {
  export function values(): Array<IdName<string>> {
    return idNames(FixtureTypes);
  }
}

export enum IFixtureTag {
  boosted = 1,
  // new,
  suggested = 3,
  // mostPopular,
  cashOut,
  streaming
}

export namespace IFixtureTag {
  export function values(): Array<IdName<string>> {
    return idNames(IFixtureTag);
  }
}

export interface IFixture {
  betOfferId?: number;
  id?: number;
  name: string;
  slug?: string;
  flag?: string;
  counts?: number;
  parentId?: number;
  entityParentId?: number;
  languages?: Array<IFixtureLanguage>;
  expanded?: boolean;
  selected?: boolean;
  activeMarketsCollection?: IMarketsCollection;
  //marketsCollections?: Array<IMarketsCollectionFixture>
}

export interface IFixtureOrder {
  fixtureId: number;
  bookmakerId: number;
  orderId: number;
}

export interface IFixtureOrderUpdateRequest {
  bookmakerId: number;
  parentFixtureId: number;
  fixtures: Array<IFixtureOrderUpdate>;
}

export interface IFixtureOrderUpdate {
  fixtureId: number;
  orderId: number;
}

export interface IFixtureSlug {
  fixtureId: number;
  bookmakerId: number;
  slug: string;
}

export interface IFixtureDeny {
  fixtureId: number;
  networkId: number;
  entityTypeId: number;
  entityId: number;
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { AppSettings, IBookmaker, IdName, IPromoInfo } from '@bs/models';
import { AppSettingsService, BookmakersService } from '@bs/services';
import { ChangeDetectorRef, AfterViewInit, AfterViewChecked } from '@angular/core';

const defaultOptions = { termsLink: true };

@Component({
  selector: 'promo-infos',
  templateUrl: './promo-infos.component.html',
  styles: [`
    .hasThumbs {
      margin-bottom: -50px;
    }

    .inner-error {
      position: absolute;
      bottom: -25px;
      width: 100%;
    }

    field-error.inner-error::ng-deep .p-inline-message {
      padding: 2px;
      width: 100%;
      justify-content: flex-start;
    }

    p-card.en {
      order: -1;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PromoInfosComponent),
      multi: true
    }
  ]
})
export class PromoInfosComponent implements ControlValueAccessor, OnInit, AfterViewInit, AfterViewChecked {

  model: Array<IPromoInfo> = [];
  settings: AppSettings;
  form: FormGroup;
  isDisabled: boolean = false;
  control: NgControl;

  bookmakers: Array<IBookmaker>;
  currentBookmaker: IBookmaker;
  activeIndex: number = 0;

  @Input() options: any = {};
  @Output() blur = new EventEmitter<any>(null);

  private propagateChange: any = () => { };

  constructor(
    private settingService: AppSettingsService,
    private fb: FormBuilder,
    @Optional() private container: ControlContainer,
    private bookmakersService: BookmakersService,
    private cdr: ChangeDetectorRef  // Inject ChangeDetectorRef
  ) {
    this.form = fb.group({});
    this.bookmakers = bookmakersService.bookmakers$.getValue();
  }

  ngOnInit() {
    this.options = { ...defaultOptions, ...this.options };

    // Subscribe to form status changes
    this.form.statusChanges.subscribe({
      next: status => {
        const infos = this.form.value?.infos;
        if (this.options.isEdit) {
          this.propagateChange(status === 'VALID' ? infos[this.activeIndex] : null);
        } else {
          this.propagateChange(status === 'VALID' ? infos : null);
        }
      }
    });

    if (this.options?.bookmakerId) {
      this.setBookmakerById(this.options.bookmakerId);
    } else {
      this.setBookmakerFromParent();
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges(); // Ensure change detection after view initialization
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges(); // Ensure all changes are applied
  }

  private setBookmakerById(bookmakerId: number) {
    this.currentBookmaker = this.bookmakers.find(b => b.id === bookmakerId);
    this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
  }

  private setBookmakerFromParent() {
    const bookmakerIdCtrl = this.container.control.get('bookmakerId');
    bookmakerIdCtrl?.valueChanges.subscribe({
      next: bmId => {
        this.currentBookmaker = this.bookmakers.find(b => b.id === bmId);
        if (this.form.contains('infos')) {
          this.form.removeControl('infos');
        }
        this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
      }
    });

    this.settingService.appSettings$.subscribe({
      next: ({ settings }) => {
        this.settings = settings;
        this.currentBookmaker = settings.bookmaker;

        if (this.form.contains('infos')) {
          this.form.removeControl('infos');
        }

        this.form.addControl('infos', this.buildForm(this.currentBookmaker.languages));
      }
    });
  }

  private buildForm(languages: Array<IdName<string>>): FormArray {
    const arr = languages.map(l => this.fb.group({
      languageId: l.id,
      name: ['', Validators.required],
      description: ['', Validators.required],
      terms: ['', Validators.required],
    }));
    return this.fb.array(arr);
  }

  writeValue(obj: Array<IPromoInfo>) {
    if (obj) {
      setTimeout(() => {
        obj.forEach(pi => {
          const infoCtrl = this.infos.controls.find(c => c.value.languageId === pi.languageId);
          if (infoCtrl) {
            infoCtrl.patchValue(pi);
          }
        });
      });
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    setTimeout(() => {
      this.isDisabled = isDisabled;
      isDisabled ? this.form.disable() : this.form.enable();
    });
  }

  onTouched() {}

  get infos() {
    return this.form.get('infos') as FormArray;
  }

  onBlur(event: any) {
    this.onTouched();
    this.blur.emit(event);
  }

  copyAll() {
    const first = this.infos.controls[0].value;
    this.infos.controls.forEach(ctrl => {
      ctrl.patchValue({
        description: first.description,
        name: first.name,
        terms: first.terms
      });
    });
  }

  onTabChange($event: any) {
    if (this.options.isEdit) {
      this.activeIndex = $event.index;
      setTimeout(() => {
        this.propagateChange(this.form.value?.infos[this.activeIndex]);
      });
    }
  }
}

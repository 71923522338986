import { Component, OnInit } from '@angular/core';
import { FormInterface, IdName } from '@bs/models';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

interface IFormGeoInfo {
  typeId: IdName<string, number>;
  'geo-infos': IFormSubGeoInfo;
}

interface IFormSubGeoInfo {
  country: IdName<string, number>;
  region: IdName<string, number> | string;
  province: IdName<string, number> | string;
  city: IdName<string, number> | string;
  address: string;
  zipCode: string;
}

@Component({
  selector: 'add-geo-info.dialog',
  templateUrl: './add-geo-info-dialog.component.html'
})

export class AddGeoInfoDialog implements OnInit {

  geoInfosConfig!: FormInterface;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef) { }

  ngOnInit(): void {
    this.geoInfosConfig = {
      inputs: [
        {
          autoselect: true,
          label: "typeId",
          name: "typeId",
          type: "select",
          noReset: true,
          validations: {
            required: true
          },
          values: this.config.data.typeId
        },
        {
          name: "geo-infos",
          type: "geo-infos",
          validations: {
            required: true,
            geoInfos: true
          }
        }
      ]
    }
  }

  /**
   * @description it closes the dialog ahd submits the values of the form via emitter
   *
   * @param {IFormGeoInfo} formValues - values of the form
   */
  submit(formValues: IFormGeoInfo): void {
    this.closeDialog(this.getGeoinfo(formValues));
  }

  /**
   * @description it closes the dialog
   *
   * @param {IGeoInfo} geoInfo - geoinfo for the operation
   */
  closeDialog(geoInfo?): void {
    this.ref.close({geoInfo});
  }

  /**
   * @description it builds the body which is going to be sent to the API
   *
   * @param {IFormGeoInfo} formValues - values of the form
   */
  private getGeoinfo(formValues: IFormGeoInfo) {
    const geoInfoValues = formValues['geo-infos'];
    delete formValues['geo-infos'];

    return {
      typeId: formValues.typeId,
      country: geoInfoValues.country,
      region: this.setGeoInfoField(geoInfoValues.region),
      province: this.setGeoInfoField(geoInfoValues.province),
      city: this.setGeoInfoField(geoInfoValues.city),
      address: geoInfoValues.address,
      zipCode: geoInfoValues.zipCode
    };
  }

  /**
   * @description it changes the value to the specified one by th API
   *
   * @param {IdName<string, number>} value - values of the form
   */
  private setGeoInfoField(value: IdName<string, number> | string): IdName<string, number> {
    return (typeof value === 'string') ? {id: null, name: value} : value;
  }
}

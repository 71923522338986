<html>

<head>
  <meta charset="utf-8">
  <title>{{'drawer-movement-cashier-title' | translate}}</title>
</head>

<body style="font-family: sans-serif;font-size: 11px;">

<div id="printable" style="width:80mm">
  <img src="{{host}}/assets/images/logo-black.svg" alt="logo" width="40%" style="display: block; margin: auto; padding-top: 10px;">

  <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
    <strong>{{'date' | translate}}:</strong>
    <span>{{item.date | localizedDate:'dd.MM.YYYY - HH:mm a'}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
    <strong>{{'type' | translate}}:</strong>
    <span>{{item.causal}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
    <strong>{{'balance-before' | translate}}:</strong>
    <span>{{item.balance.amount | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
    <strong>{{'amount' | translate}}:</strong>
    <span>{{item.amount.amount | displayCurrencyConversion}}</span>
  </div>

  <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
    <strong>{{'balance-after' | translate}}:</strong>
    <span>{{item.balance.amount + item.amount.amount | displayCurrencyConversion}}</span>
  </div>

  <div style="margin: 50px 40px 0; font-size: 12px; color: #2B2B2B">
    <hr class="border: 1px solid #C0C0C0;">
    {{'drawer-movement-cashier-signature' | translate}}
  </div>

  <div style="margin: 50px 40px 0; font-size: 12px; color: #2B2B2B">
    <hr class="border: 1px solid #C0C0C0;">
    {{'drawer-movement-shop-manager-signature' | translate}}
  </div>

</div>

</body>
</html>

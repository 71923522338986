import { Pipe, PipeTransform } from '@angular/core';
import { Comparison } from '@bs/models';

/**
 * array of condition number or booleans to be paired with the visibility of buttons, used only in data-table-builder component
 */
@Pipe({
  name: 'buttonConditions'
})
export class ButtonConditionsPipe implements PipeTransform {

  /**
   * return us the button condition
   * @param value {any} the button item
   * @param visible {string[]}
   * @param condition {(string | boolean | number)[]}
   * @param comparison {Comparison}
   */
  transform(value: any, visible: string[], condition: (string | boolean | number)[], comparison: Comparison = '='): boolean {
    //return this.recompose(values, nested);
    if (visible.length !== condition.length) {
      throw new Error('visible and condition must be pair');
    }

    const method = comparison === '||=' ? 'some' : 'every'

    return visible[method]((v, i) => {
      switch (comparison) {
        case '||=':
        case '=':
          return this.recompose(value, v) === condition[i];
        case '!=':
          return this.recompose(value, v) !== condition[i];
        case '>':
          return this.recompose(value, v) > condition[i];
        case '<':
          return this.recompose(value, v) < condition[i];
        case '<=':
          return this.recompose(value, v) <= condition[i];
        case '>=':
          return this.recompose(value, v) >= condition[i];
      }
    })


  }

  /**
   * @ignore
   */
  private recompose(value: any, nested: string): string {
    const parts = nested.split('.');
    const newObj = value[parts[0]];
    if (parts[1]) {
      parts.splice(0, 1);
      const newString = parts.join('.');
      return this.recompose(newObj, newString);
    }
    return newObj;
  }

}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormGroup, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { EventTypes, IBetOfferCurrency, LimitTypes, OfferTypes } from '@bs/models';
import { debounceTime } from 'rxjs';

interface OfferLimitRow {
  currencyId: number;
  eventTypeId: number;
  limitTypeId: number;
  maxBet: number;
  maxWin: number;
  offerTypeId: number;
}

@Component({
  selector: 'bet-offer-limits',
  templateUrl: './bet-offer-limits.component.html',
  styleUrls: ['./bet-offer-limits.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BetOfferLimitsComponent),
      multi: true
    }
  ]
})

export class BetOfferLimitsComponent implements ControlValueAccessor, OnInit {

  offerTypes = OfferTypes.values().filter(x => x.name !== 'mixed');
  eventTypes = EventTypes.values();
  limitTypes = LimitTypes.values();
  form: FormGroup;

  @Input()
  currencies?: Array<IBetOfferCurrency>;

  @Output()
  rowUpdate = new EventEmitter<any>();
  copyMode: boolean;

  constructor(private fb: UntypedFormBuilder) {

  }

  getCurrenciesFormArray(controlString: string): FormArray {
    return this.form.get(controlString) as FormArray;
  }

  ngOnInit(): void {
    /*const betWin: FormArray = this.currencies.map(x => this.fb.control({maxBet: [], minBet: []}));
    const exposure: FormArray = this.currencies.map(x => this.fb.control({exposure: []}));*/


    this.form = this.fb.group({
      match: this.fb.group({
        preMatch: this.fb.group({
          standard: this.fb.array(this.getCurrencyData()),
          booking: this.fb.array(this.getCurrencyData()),
          cumulative: this.fb.array(this.getCurrencyData())
        }),
        live: this.fb.group({
          standard: this.fb.array(this.getCurrencyData()),
          booking: this.fb.array(this.getCurrencyData()),
          cumulative: this.fb.array(this.getCurrencyData())
        })
      }),
      outright: this.fb.group({
        preMatch: this.fb.group({
          standard: this.fb.array(this.getCurrencyData()),
          booking: this.fb.array(this.getCurrencyData()),
          cumulative: this.fb.array(this.getCurrencyData())
        }),
      })
    });

    //this.form.valueChanges.subscribe(c => this.update())
    this.form.statusChanges.pipe(debounceTime(500)).subscribe({
      next: status => {
        if (status === 'VALID') {
          this.update();
        } else {
          this.propagateChange(null)
        }
      }
    })

  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(rows: Array<OfferLimitRow>): void {
    if (rows) {
      //console.log(rows);
      const nestedObject = rows.reduce((result, currentItem) => {
        const eventType = EventTypes[currentItem.eventTypeId];
        const offerType = OfferTypes[currentItem.offerTypeId];
        const limitType = LimitTypes[currentItem.limitTypeId];

        if (eventType && offerType && limitType) {
          if (!result[eventType]) {
            result[eventType] = {};
          }
          if (!result[eventType][offerType]) {
            result[eventType][offerType] = {};
          }
          if (!result[eventType][offerType][limitType]) {
            result[eventType][offerType][limitType] = [];
          }
          result[eventType][offerType][limitType].push({
            maxBet: currentItem.maxBet,
            currencyId: currentItem.currencyId,
            maxWin: currentItem.maxWin
          });
        }

        return result;
      }, {});
      this.form.patchValue(nestedObject);
    }
  }

  onRowEditInit(product: IBetOfferCurrency) {

  }

  onRowEditSave(ctrl: UntypedFormControl, eventTypeValue: string, offerTypeId: number, subTypeValue: string) {
    if (ctrl.valid) {
      const row = ctrl.value
      const eventTypeId = this.eventTypes.find(v => v.name === eventTypeValue).id || 0;
      const limitTypeId = this.limitTypes.find(v => v.name === subTypeValue).id || 0;
      Object.assign(row, {maxBet: row.maxBet * 100, maxWin: row.maxWin * 100});
      this.rowUpdate.emit({...row, offerTypeId, eventTypeId, limitTypeId});
    }
  }

  update() {
    // console.log(this.form.value);
    const submitValue = Object.entries(this.form.value).reduce((acc, [k, v]) => {
      const eventTypeId = EventTypes[k];
      Object.entries(v).forEach(([k1, v1]) => {
        const offerType = OfferTypes[k1];

        Object.entries(v1).forEach(([k2, v2]) => {
          const limitType = LimitTypes[k2];

          if (v2) {
            Object.values(v2).forEach(v3 => {
              acc.push({eventTypeId, limitType, offerType, currencyId: v3.currencyId, maxBet: (v3.maxBet * 100), maxWin: (v3.maxWin * 100)});
            });
          }
        })
      })

      return acc;
    }, []);

    //console.log(submitValue);
    this.propagateChange(submitValue);
  }

  private getCurrencyData() {
    const betWin = [];
    // const exposure = [];

    this.currencies.forEach(c => {
      const bwGroup = this.fb.group({
        currencyId: [c.id],
        maxBet: [null, Validators.compose([Validators.required, Validators.min(0.1)])],
        maxWin: [null, Validators.compose([Validators.required, Validators.min(0.1)])]
      }, {validators: this.customValidation});
      betWin.push(bwGroup)
      //exposure.push(this.fb.group({code: [c.code], exposure: ['']}))
    });

    return betWin;
  }

  private customValidation(formGroup: FormGroup) {
    const maxBet = formGroup.get('maxBet').value;
    const maxWin = formGroup.get('maxWin').value;

    if (maxBet && maxWin && maxBet >= maxWin) {
      formGroup.get('maxBet').setErrors({greaterThanMaxWin: true});
      formGroup.get('maxWin').setErrors({lessThanMaxBet: true});
    } else if (maxBet && maxWin) {
      formGroup.get('maxBet').setErrors(null);
      formGroup.get('maxWin').setErrors(null);
    }
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(model: Array<OfferLimitRow>) {
    //console.log(model);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }
}

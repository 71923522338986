import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';
import { ValidationService } from '@bs/forms';
import { AppSettings, IBookmaker, ICmsPageLanguage, IdName, IInput } from '@bs/models';
import { AppSettingsService, BookmakersService } from '@bs/services';

@Component({
  selector: 'page-contents',
  templateUrl: './page-contents.component.html',
  styles: [`
    :host {
      display: block
    }

    .hasThumbs {
      margin-bottom: -50px;
    }

    .inner-error {
      position: absolute;
      bottom: -25px;
      width: 100%;
    }

    field-error.inner-error::ng-deep .p-inline-message {
      padding: 2px;
      width: 100%;
      justify-content: flex-start;
    }

    p-card.en {
      order: -1;
    }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PageContentsComponent),
      multi: true
    }
  ]
})
export class PageContentsComponent implements ControlValueAccessor, OnInit, OnChanges {

  model: Array<ICmsPageLanguage> = [];
  settings: AppSettings;
  form: FormGroup;
  isDisabled: boolean;
  control: NgControl;

  activeIndex = 0;

  @Input()
  bookmaker: IBookmaker;

  @Input()
  options: any = {};

  @Input()
  what: string;

  @Input()
  nestedForm: Array<IInput>;

  @Output() blur = new EventEmitter<any>(null);

  constructor(private settingService: AppSettingsService, private fb: FormBuilder, private validationService: ValidationService, private bookmakersService: BookmakersService) {

    this.form = fb.group({});
  }

  ngOnInit() {
    // if valid whatagate values, otherwise null
    if (this.options.isEdit) {
      this.form.statusChanges.subscribe({
        next: status => this.propagateChange(status === 'VALID' ? this.form.value?.languages : null)
      })
      //this.form.statusChanges.subscribe(status => this.propagateChange(status === 'VALID' ? this.form.value?.languages[this.activeIndex] : null))
    } else {
      this.form.statusChanges.subscribe({
        next: status => this.propagateChange(status === 'VALID' ? this.form.value?.languages : null)
      })
    }
  }

  private propagateChange(_model: Array<ICmsPageLanguage>) {
  }

  onBlur(event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
  }

  onTouched() {
  }

  get langs(): FormArray {
    return this.form.get('languages') as FormArray;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  writeValue(obj: Array<ICmsPageLanguage>) {
    if (obj) {
      obj.forEach(pi => {
        const infoCtrl = this.langs.controls.find(c => c.value.languageId === pi.languageId);
        infoCtrl.patchValue(pi);
      })
    }
  }

  private buildForm(languages: Array<IdName<string>>): FormArray {
    const arr = [];

    languages.forEach(l => {

      const langForm = this.validationService.buildForm({inputs: this.nestedForm}, this.fb);

      langForm.addControl('languageId', this.fb.control(l.id));
      langForm.addValidators(Validators.required)

      arr.push(langForm);
    })

    return this.fb.array(arr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form.contains('languages')) {
      this.form.removeControl('languages', {emitEvent: false});
    }
    this.form.addControl('languages', this.buildForm(changes.bookmaker.currentValue.languages), {emitEvent: false});
  }

  copyAll() {
    const first = this.langs.controls[0].value;
    this.langs.controls.forEach(ctrl => {
      ctrl.patchValue({
        value: first.value
      });
    })
  }
}

import { IdName, idNames } from './id-name';

export enum gameVerticalEnum {
  sportsBook = 10,
  casino = 1,
  poker = 2,
  casinoLive = 3,
  virtuals = 4,
  lotteries = 5,
  scratchGames = 6,
  crashGames = 7
}

export namespace gameVerticalEnum {
  export function values(): Array<IdName<string>> {
    return idNames(gameVerticalEnum);
  }
}

import { IdName, idNames } from '../common/id-name';
import { IMenu } from './menu';

export interface IFooter {
  disclaimer: string;
  menus: IMenu[];
  payments: IFooterPayments[];
  socials: IFooterSocials[];
  license: IFooterLicensee;
  copyright: boolean; // mostra il logo betstarters in fondo
}

interface IFooterPayments {
  name: string;
  icon: string;
  link: string;
}

interface IFooterSocials {
  name: string;
  icon: string;
  link: string;
  orderId: number;
}

export interface IFooterLicensee {
  typeId: number; // Curacao, Malta , Nigeria , Tanzania -- in base al tipo renderizzare un diverso template
  text: string;
  text2?: string;
  link?: string;
  sealId?: string;
  active: boolean;
}

export interface ICmsLicense {
  id: number;
  isActive: boolean;
  bookmakerId: number;
  typeId: number; // Curacao, Malta , Nigeria , Tanzania -- in base al tipo renderizzare un diverso template
  primaryTexts?: Array<ICmsLanguage>;
  secondaryTexts?: Array<ICmsLanguage>;
}

export interface ICmsFieldTypeLanguage {
  fieldTypeId: number;
  languageId: number;
  bookmakerId: number;
  value: string;
}

export interface ICmsLanguage {
  languageId: number;
  code: string;
  name: string;
  value: string;
}

export enum LicenseTypes {
  curacao = 1,
  tanzania,
  nigeria,
  malawi,
  drc,
  zambia,
  ghana,
  ethiopia,
  panama,
  romania,
  kenya,
  haiti,
  curacaoEGaming,
  madagascar,
  rwanda = 15,
  uganda = 16,
  curacaoEcg = 17,
  swaziland = 18,
  gcb
}

export namespace LicenseTypes {
  export function values(): Array<IdName<any>> {
    return idNames(LicenseTypes);
  }
}

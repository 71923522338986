import { Component, OnDestroy } from '@angular/core';
import { IdName, OddFormat } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oddsformat-selector',
  templateUrl: './oddsformat-selector.component.html'
})
export class OddsformatSelectorComponent implements OnDestroy {

  items: Array<IdName<string, string>>;
  itemSelected: any;
  subs = new Subscription();

  constructor(public appSettingsService: AppSettingsService) {
    this.items = OddFormat.values();
    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.itemSelected = settings.oddFormat
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName, idNames } from '@bs/models';

export interface IOutputExpiration {
  typeId: IExpirationType;
  date?: string;
  afterX?: number;
}

export enum IExpirationType {
  fixedDate = 1,
  afterXDays = 2,
  afterXMonths = 3,
  never = 10,
}

export enum IMeetConditionsType {
  immediately = 1,
  afterXDays = 2,
  firstDayOfFollowedWeek = 3,
  firstDayOfFollowedMonth = 4,
}

export type TimelineType = 'expiration' | 'meetConditions';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimelineComponent),
      multi: true,
    },
  ],
})
export class TimelineComponent implements OnInit, ControlValueAccessor {
  model: IOutputExpiration;
  isDisabled: boolean;

  typeId: IdName<string>;
  date = new Date();
  afterX = 1;
  optionsValues: IdName<string>[] = []

  @Input() options: { type: TimelineType } = { type: 'expiration' };

  @Output() blur = new EventEmitter<any>(null);

  constructor() {
    // console.log()
  }

  ngOnInit() {
    if (this.optionType === 'expiration') {
      this.optionsValues = idNames(IExpirationType);
    } else if (this.optionType === 'meetConditions') {
      this.optionsValues = idNames(IMeetConditionsType);
    }
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj?: IOutputExpiration) {
    if (_obj != undefined) {
      this.model = _obj;
      this.typeId = this.optionsValues.find((x) => x.id === _obj.typeId);
      this.date = new Date(_obj.date);
      this.afterX = _obj.afterX;
    }
  }

  update() {
    if (this.isDateInputVisbile) {
      const d = this.date.getDate().toString().padStart(2, '0');
      const m = (this.date.getMonth() + 1).toString().padStart(2, '0');
      const y = this.date.getFullYear().toString().padStart(4, '0');

      this.model = {
        typeId: this.typeIdValue,
        date: `${y}-${m}-${d}`,
      };
    } else if (this.isNumberInputVisible) {
      this.model = {
        typeId: this.typeIdValue,
        afterX: this.afterX,
      };
    } else {
      this.model = {
        typeId: this.typeIdValue,
      };
    }

    this.propagateChange(this.model);
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  private onTouched() {}

  propagateChange(_model: IOutputExpiration) {}

  get isDateInputVisbile() {
    let toRet = false;

    if (this.optionType === 'expiration') {
      toRet = [IExpirationType.fixedDate].includes(this.typeIdValue);
    } else if (this.optionType === 'meetConditions') {
      toRet = [].includes(this.typeIdValue);
    }

    return toRet;
  }

  get isNumberInputVisible() {
    let toRet = false;

    if (this.optionType === 'expiration') {
      toRet = [IExpirationType.afterXDays, IExpirationType.afterXMonths].includes(this.typeIdValue);
    } else if (this.optionType === 'meetConditions') {
      toRet = [IMeetConditionsType.afterXDays].includes(this.typeIdValue);
    }

    return toRet;
  }

  get typeIdValue() {
    return this.typeId?.id
  }

  get optionType() {
    return this?.options?.type
  }
}

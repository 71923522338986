<!--<pre>{{options | json}}</pre>-->

<p-table [value]="languages"
  [paginator]="true" [rows]="5" [showCurrentPageReport]="true" responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[5,10,25,50]"
  dataKey="id" editMode="row">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name">{{'language' | translate}}
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      @for (key of filteredKeys; track key) {
        @if (!(options?.isSystem && key ==='url')) {
          <th>
            <div class="flex">
              <div class="mr-2">{{ key | translate }}</div>
              @if (languages.length > 1 && !isEdit) {
                <div>
                  <i (click)="copy(key)" class="pi pi-copy" pTooltip="'copy first value'"></i>
                </div>
              }
            </div>
          </th>
        }
      }
      @if (isEdit) {
        <th width="150">{{'actions' | translate}}</th>
      }
    </tr>
  </ng-template>
  <ng-template let-editing="editing" let-ri="rowIndex" let-rowData pTemplate="body">
    <tr [pEditableRow]="rowData">
      <td class="flex align-items-center">
        <div class="flag-rounded flex align-items-center justify-content-center mr-2">
          <svg [icon]="rowData.code" lib="flags" [size]="{w:28}" class="flex-shrink-0 h-full"></svg>
        </div>
        <span [pTooltip]="rowData.code">{{rowData.name}}</span>
      </td>
      @for (key of filteredKeys; track key) {
        @if (!(options?.isSystem && key ==='url')) {
          <td>
            @if (isEdit) {
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input [(ngModel)]="rowData[key]" (blur)="update()" pInputText required type="text">
                </ng-template>
                <ng-template pTemplate="output">{{rowData[key]}}</ng-template>
              </p-cellEditor>
            }
            @if (!isEdit) {
              <input (blur)="update()" [(ngModel)]="rowData[key]" pInputText required type="text">
            }
          </td>
        }
      }
      @if (isEdit) {
        <td>
          @if (!editing) {
            <button (click)="onRowEditInit(rowData)" class="p-button-rounded p-button-text" icon="pi pi-pencil" pButton pInitEditableRow type="button"></button>
          }
          @if (editing) {
            <button (click)="onRowEditSave(rowData)" class="p-button-rounded p-button-text p-button-success mr-2" icon="pi pi-check" pButton pSaveEditableRow type="button"></button>
          }
          @if (editing) {
            <button (click)="onRowEditCancel(rowData, ri)" class="p-button-rounded p-button-text p-button-danger" icon="pi pi-times" pButton pCancelEditableRow type="button"></button>
          }
        </td>
      }
    </tr>
  </ng-template>
</p-table>

<!-- <pre>{{ model | json }}</pre> -->
<!-- <pre>{{ amounts | json }}</pre> -->
<!-- <pre>{{ oldAmounts | json }}</pre> -->
<!-- <pre>{{ rate | json }}</pre> -->

<div class="mb-3">
  <span class="p-buttonset">
    <button pButton (click)="setTab(1)" type="button" [class.opacity-50]="typeId !== 1" translate>yes</button>
    <button pButton (click)="setTab(2)" type="button" [class.opacity-50]="typeId !== 2" translate>no</button>
    <button pButton (click)="setTab(3)" type="button" [class.opacity-50]="typeId !== 3" translate>%</button>
  </span>
</div>

@switch (typeId) {
  @case (1) {
    <p-table [value]="amounts" dataKey="currencyId" editMode="row">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 47%" translate>currencyCode</th>
          <th style="width: 47%" translate>amount</th>
          <th style="width: 6%" translate>action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex" let-rowData editMode="row">
        <tr [pEditableRow]="rowData">
          <td>{{ rowData.name }}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <div class="p-inputgroup">
                  <p-inputNumber [ngModel]="getAmount(ri)" (ngModelChange)="setAmount(ri, $event)" [currency]="rowData.currencyId | currencyAttributes: 'code'" mode="decimal" [minFractionDigits]="2" [amount]="getAmount(ri)"></p-inputNumber>
                  <span class="p-inputgroup-addon">{{ rowData.currencyId | currencyAttributes: 'code' }}</span>
                </div>
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.amount | currencyAmount: rowData.symbol }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              @if (!editing) {
                <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInit(ri)"></button>
              }
              @if (editing) {
                <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" class="p-button-rounded p-button-text p-button-success mr-2" (click)="onRowEditSave(ri)"></button>
              }
              @if (editing) {
                <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancel(ri)"></button>
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  }
  @case (2) {
    <div class=""></div>
  }
  @case (3) {
    <div class="grid">
      <div class="col">
        <label>{{'bonusPercentage' | translate}}</label>
        <div class="p-inputgroup">
          <p-inputNumber [(ngModel)]="percentage" (ngModelChange)="update()" [useGrouping]="false" suffix=" %" [min]="0" [max]="100"></p-inputNumber>
        </div>
      </div>
      <div class="col"></div>
    </div>
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IGameExternalSystem, IGameExternalSystemSearchRequest, Pager } from '@bs/models';
import { Observable } from 'rxjs';
import { CrudService } from '../base/crud.service';

@Injectable({
  providedIn: 'root'
})
export class GamesExternalSystemsService implements CrudService<IGameExternalSystem>{
  readonly url = `${this.config.api.games}/external-systems`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: IGameExternalSystemSearchRequest): Observable<Pager<IGameExternalSystem>> {
    return this.http.post<Pager<IGameExternalSystem>>(`${this.url}/search`, filters);
  }

  delete(item: Partial<IGameExternalSystem>): Observable<IGameExternalSystem> {
    return undefined;
  }

  get(item: Partial<IGameExternalSystem>): Observable<IGameExternalSystem> {
    return this.http.get<IGameExternalSystem>(`${this.url}/${item.id}`, {params: {bId: item.bookmakerId.toString()}});
  }

  patch(id: number, item: Partial<IGameExternalSystem>): Observable<IGameExternalSystem> {
    return undefined;
  }

  post(item: Partial<IGameExternalSystem>): Observable<Partial<IGameExternalSystem>> {
    return undefined;
  }
}

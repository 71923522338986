<html>

  <head>
    <meta charset="utf-8">
    <title>{{data.code}}</title>
  </head>

  <body style="font-family: sans-serif;font-size: 11px; color: black;">
    <div id="printable" style="position: relative; width: 277px; height: 578px;">
      <svg width="277" height="578" xmlns="http://www.w3.org/2000/svg" style="position:absolute; top: 0; left: 0;">
        <mask id="a" fill="#fff">
          <path
            d="M262.3 449.7a16.8 16.8 0 0114.7-16.4V0h-11.6v3a8.9 8.9 0 11-17.5 0V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 11-17.8 0V0H171v3a8.9 8.9 0 01-17.8 0V0h-5.8v3a8.9 8.9 0 11-17.8 0V0h-5.8v3A8.9 8.9 0 01106 3V0h-5.8v3a8.9 8.9 0 01-17.8 0V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 11-17.5 0V0H0v433.3a16.4 16.4 0 010 32.9V578h11.6v-3.4a8.9 8.9 0 0117.5 0v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 1117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h6.1v-3.4a8.5 8.5 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.6 8.6 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.5 0v3.4H277V466.2a16.8 16.8 0 01-14.7-16.5z"/>
          </mask>
          <path
            d="M262.3 449.7a16.8 16.8 0 0114.7-16.4V0h-11.6v3a8.9 8.9 0 11-17.5 0V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 01-9 9 8.9 8.9 0 01-8.5-9V0h-6.1v3a8.9 8.9 0 11-17.8 0V0H171v3a8.9 8.9 0 01-17.8 0V0h-5.8v3a8.9 8.9 0 11-17.8 0V0h-5.8v3A8.9 8.9 0 01106 3V0h-5.8v3a8.9 8.9 0 01-17.8 0V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 01-8.6 9 8.9 8.9 0 01-8.9-9V0h-6.1v3a8.9 8.9 0 11-17.5 0V0H0v433.3a16.4 16.4 0 010 32.9V578h11.6v-3.4a8.9 8.9 0 0117.5 0v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 019-8.6 8.5 8.5 0 018.5 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 1117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h5.8v-3.4a8.9 8.9 0 0117.8 0v3.4h6.1v-3.4a8.5 8.5 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.6 8.6 0 018.6-8.6 8.9 8.9 0 018.9 8.6v3.4h6.1v-3.4a8.9 8.9 0 0117.5 0v3.4H277V466.2a16.8 16.8 0 01-14.7-16.5z"
            fill="#fff" stroke="#DBDBDB" stroke-width="2" mask="url(#a)"/>
            <path stroke="#000" stroke-dasharray="2 2" d="M18 449.5h243"/>
          </svg>
          <div style="position: relative; top: 0; z-index: 2;">
            <img src="{{host}}/assets/images/logo-black.svg" alt="logo" width="140" height="80" style="display: block; margin: auto; padding-top: 16px;">
            <div style="display: flex; flex-direction: column; margin: 25px 0 30px; align-items: center;">
              <span style="font-size: 13px; margin-bottom: 10px">{{'booking-number' | translate }}:</span>
              <span style="font-size: 20px;">{{data.code}}</span>
            </div>
            <div style="display: flex; justify-content: space-between; font-size: 12px; padding: 0 18px;">
              <span>{{'expiry-date' | translate}}:</span>
              <span>{{data.expiringDate | localizedDate}}</span>
            </div>
            <div style="display: flex; justify-content: space-between; font-size: 12px; padding: 16px 18px 0;">
              <span>{{'amount' | translate}}:</span>
              <span>{{data.stake | moneyFormat: defaultCurrency}}</span>
            </div>

            <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 20px; padding: 0 10px 0 18px;">
              @if (!config.features.betSlip?.showQrCode) {
                <qrcode [qrdata]=qrUrl [allowEmptyString]="false" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
              }

              @if (config.features.betSlip?.showBarCode) {
                <ngx-barcode6 [bc-value]="data.code" [bc-width]="1" [bc-height]="60" [bc-font-size]="12" [bc-display-value]="true"></ngx-barcode6>
              }
            </div>


          </div>

          <div style="position: absolute; bottom: 25px; right: 0; left: 0; text-align: center; padding: 0 18px">
            <p [translate]="'booking-bet-receipt-note'">Please note that this bet It hasn't been registered yet. You can place it using the code above.</p>
            <p [translate]="'booking-bet-receipt-odds-reminder'">We remind you that the odds may undergo changes up to the moment of confirmation of the bet.</p>
          </div>
        </div>
      </body>
    </html>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EnvironmentConfig, IBaseRequestFilters, INetworkBettingFilters, INetworkFinanceFilters, INetworkGamesFilters, INetworkGamesGenericFilters, NetworkBettingReport, NetworkCashFlowReport, NetworkCashierReport, NetworkCasinoReport, NetworkDrawersReport, NetworkFinanceReport, NetworkSportsBookReport, Report } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  readonly url = `${this.config.api.reports}/reports`;

  constructor(private config: EnvironmentConfig, private client: HttpClient) {
  }

  networkFinance(filters: INetworkFinanceFilters): Observable<Report<NetworkFinanceReport>> {
    return this.client.post<Report<NetworkFinanceReport>>(`${this.url}/network-finance`, filters);
  }

  networkCashFlow(filters: IBaseRequestFilters): Observable<Report<NetworkCashFlowReport>> {
    return this.client.post<Report<NetworkCashFlowReport>>(`${this.url}/network-cashflow`, filters);
  }

  networkCashier(filters: IBaseRequestFilters): Observable<NetworkCashierReport> {
    return this.client.post<NetworkCashierReport>(`${this.url}/cashier`, filters);
  }

  networkDrawers(filters: IBaseRequestFilters): Observable<Report<NetworkDrawersReport>> {
    return this.client.post<Report<NetworkDrawersReport>>(`${this.url}/network-drawers`, filters);
  }

  networkBetting(filters: INetworkBettingFilters): Observable<Report<NetworkBettingReport>> {
    return this.client.post<Report<NetworkBettingReport>>(`${this.url}/network-betting`, filters);
  }

  networkSportsBook(filters: INetworkBettingFilters): Observable<Report<NetworkSportsBookReport>> {
    return this.client.post<Report<NetworkSportsBookReport>>(`${this.url}/network-sportsbook`, filters);
  }

  networkCasino(filters: INetworkGamesFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-casino`, filters);
  }

  networkCasinoLive(filters: INetworkGamesFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-casinolive`, filters);
  }

  networkVirtual(filters: INetworkGamesFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-virtual`, filters);
  }

  networkPoker(filters: INetworkGamesFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-poker`, filters);
  }

  networkLottery(filters: INetworkGamesFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-lottery`, filters);
  }

  networkGames(filters: INetworkGamesGenericFilters): Observable<Report<NetworkCasinoReport>> {
    return this.client.post<Report<NetworkCasinoReport>>(`${this.url}/network-games`, filters);
  }
}

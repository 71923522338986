import { Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

/**
 * directive to create svg dynamically
 */
@Directive({
  selector: 'svg[icon]'
})
export class SvgIconDirective implements OnChanges {
  /**
   * Icon size
   */
  @Input()
  size: { w: number, h: number };

  /**
   * Symbol ID value from SVG sprite
   */
  @Input()
  icon: string;

  /**
   * apply different lib, default icons
   */
  @Input()
  lib: string;

  /**
   * width of the svg
   */
  @HostBinding('style.width.px')
  width: number;

  /**
   * height of the svg
   */
  @HostBinding('style.height.px')
  height: number;

  /**
   * init used in each svg[icon]
   * @param elementRef
   * @param renderer
   */
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.lib = this.lib || 'icons';
  }

  /**
   * if is first change apply the icon, otherwise use new value
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.icon.firstChange) {

      const use: SVGUseElement = this.renderer.createElement('use', 'svg');

      this.renderer.setAttribute(use, 'href', `assets/icons/${this.lib}.svg#${this.icon}`, 'xlink');

      if (this.size) {
        this.width = this.size.w;
        this.height = this.size.h;
      }

      this.renderer.appendChild(this.elementRef.nativeElement as Array<SVGElement>, use);
    } else {
      this.renderer.setAttribute(this.elementRef.nativeElement.children[0], 'href', `assets/icons/${this.lib}.svg#${changes.icon.currentValue}`, 'xlink');
    }
  }
}

/**
 * Example usage reading a font awesome icon:
 *
 * @example
 * <svg icon="user-plus" lib="solid"></svg>
 */

import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBookmaker } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bookmaker-selector',
  templateUrl: './bookmaker-selector.component.html'
})
export class BookmakerSelectorComponent implements OnDestroy {

  items: IBookmaker[];
  itemSelected: IBookmaker;

  @Input()
  isDisabled: boolean;
  subs = new Subscription();

  constructor(route: ActivatedRoute, public appSettingsService: AppSettingsService) {
    this.subs.add(route.data.subscribe({
      next: data => {
        this.items = data.bookmakers.sort((a, b) => a.name.localeCompare(b.name));
        if (!this.appSettingsService.appSettings$.getValue().settings.bookmaker) {
          // first call
          this.itemSelected = data.bookmakers[0];
          this.itemSelected.languages = this.itemSelected.languages.sort(((a, b) => a.name.localeCompare(b.name)))
          this.appSettingsService.saveSetting('bookmaker', this.itemSelected);
        }
      }
    }));

    this.subs.add(this.appSettingsService.appSettings$.subscribe({
      next: ({settings, valueChanged}) => {
        // if (valueChanged === 'bookmaker') {
        this.itemSelected = settings.bookmaker;
        // this.itemSelected.languages = this.itemSelected.languages.sort(((a, b) => a.name.localeCompare(b.name)))
        // }

        if (valueChanged === 'bookmakerSelectorDisabled') {
          this.isDisabled = settings.bookmakerSelectorDisabled;
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onChange(iBookmaker: IBookmaker) {
    this.appSettingsService.saveSetting('bookmaker', iBookmaker);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AccountTypeId, AppSettings, EnvironmentConfig, IAccount, IAccountsRequestFilter, Pager, Webapps } from '@bs/models';
import { Observable } from 'rxjs';
import { AccountsService } from '../../services/accounts/accounts.service';
import { AppSettingsService } from '../../services/core/app-settings.service';
import { DateFactoryService } from '../../services/core/date-factory.service';
import { BaseResolver } from '../core/base.resolver';

/**
 * Resolver is returning us accounts as a pagination items
 *
 * see {@link BaseResolver} for more info
 */
@Injectable()
export class AccountsResolver extends BaseResolver  {

  /**
   * settings variable
   */
  settings: AppSettings;

  /**
   * The constructor that passes to the super class BaseResolver, and sets the settings
   *
   * see {@link DateFactoryService} for more info
   *
   * @param config
   * @param accountsService
   * @param appSettingsService
   * @param {DateFactoryService} dfs public variable
   */
  constructor(private config: EnvironmentConfig, private accountsService: AccountsService, appSettingsService: AppSettingsService, public dfs: DateFactoryService) {
    super(dfs);
    appSettingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   *
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<Pager<IAccount>> | Promise<Pager<IAccount>> | Pager<IAccount> {

    if (route.data.stopResolve && !route.queryParamMap.keys.length) {
      return {
        list: [],
        count: 0
      };
    }

    let bookmakers: Array<number> = [];

    if (this.config.webAppId == Webapps.winchester) {
      if (route.data.typeId === AccountTypeId.Operator) {
        bookmakers.push(4)
      } else {
        bookmakers.push(this.settings?.bookmaker.id)
      }
    } else {
      bookmakers.push(this.config.bookmakerId)
    }

    bookmakers = bookmakers.filter((item, index) => bookmakers.indexOf(item) === index);

    const values: IAccountsRequestFilter = {
      typeId: route.data.typeId,
      fullNetwork: false,
      displayCurrencyId: this.settings.displayCurrency.id,
      bookmakers: bookmakers,
      p: route.queryParams.p || 1,
      pp: route.queryParams.pp || 10,
      ...this.remap(route.queryParamMap)
    };

    return this.accountsService.search(values);
  }
}

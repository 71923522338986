import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Ages, ISportEvent, ISportEventSearchFilters, Pager } from '@bs/models';
import { BaseResolver, DateFactoryService } from '@bs/services';
import { Observable, of } from 'rxjs';
import { SportEventService } from '../services/sport-event.service';

/**
 * Resolver is returning us the sports events items when using data-table-builder component
 */
@Injectable()
export class SportEventsResolver extends BaseResolver  {

  /**
   * The constructor
   * @param sportEventService
   * @param {DateFactoryService} dfs public variable
   */
  constructor(private sportEventService: SportEventService, public dfs: DateFactoryService) {
    super(dfs);
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<Pager<ISportEvent>> {
    if (route.data.stopResolve && !route.queryParamMap.keys.length) {
      return of({
        list: [],
        count: 0,
      });
    }

    const filters: ISportEventSearchFilters = {
      betOfferId: 1,
      bookmakerId: 4,
      displayCurrencyId: 1,
      p: route.queryParams.p || 1,
      pp: route.queryParams.pp || 10,
      from: this.dfs.toTz(Ages.today),
      to: this.dfs.toTz(Ages.today, true),
      eventTypeId: route.data.eventTypeId,
      ...this.remap(route.queryParamMap)
    }
    return this.sportEventService.search(filters);
  }
}

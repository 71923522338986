@if (itemSelected) {
  <p-dropdown
    class="mr-2 inline"
    (onChange)="appSettingsService.saveSetting('displayCurrency', itemSelected)"
    [(ngModel)]="itemSelected"
    optionLabel="name"
    [required]="true"
    [options]="items">
  </p-dropdown>
}

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BookmakersService } from '@bs/services';
import { Subscription } from 'rxjs';

interface CurrencyAmount {
  currencyId: number;
  name?: string;
  symbol?: string;
  amount?: number;
}

@Component({
  selector: 'currencies-amount',
  templateUrl: './currencies-amount.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrenciesAmountComponent),
      multi: true
    }
  ]
})
export class CurrenciesAmountComponent implements ControlValueAccessor, OnInit {

  isDisabled: boolean;
  subs = new Subscription();
  @Output() blur = new EventEmitter<any>(null);

  model: Array<CurrencyAmount> = [];

  @Input()
  options: { bookmakerId: number };

  constructor(private bms: BookmakersService) {
  }

  ngOnInit() {
    if (this.options.bookmakerId) {
      const bookmakers = this.bms.bookmakers$.getValue();
      const current = bookmakers.find(b => b.id === this.options.bookmakerId);

      this.model = current.currencies.map(c => ({
        name: c.name,
        currencyId: c.id,
        symbol: c.symbol
      }))

    } else {
      throw new Error('cannot read bookmakerId');
    }
  }


  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(_obj: any) {
  }

  onBlur(event) {
    this.onTouched();
    this.blur.emit(event);
  }

  private onTouched() {
  }

  update() {
    this.propagateChange(this.model.map(x => ({currencyId: x.currencyId, amount: x.amount})));
  }

  propagateChange(_model: Array<CurrencyAmount>) {
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IAccount, ICreateOperator, ISettingsTemplateSportsBook, IShopLocation, Pager, RegistrationBaseRequest } from '@bs/models';
import { Observable } from 'rxjs';
import { CrudService } from '../base/crud.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountsService implements CrudService<IAccount> {

  readonly url = `${this.config.api.accounts}`;

  //readonly urlBoe = `${this.config.api.boe}`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  /**
   * @description a.k.a. registration
   */
  register(newUser: Partial<RegistrationBaseRequest>): Observable<any> {
    return this.http.post<any>(`${this.url}/accounts`, newUser);
  }

  createOperator(newUser: ICreateOperator): Observable<any> {
    return this.http.post<any>(`${this.url}/accounts/operators`, newUser);
  }

  searchShops(): Promise<Array<IShopLocation>> {
    return this.http.get<Array<IShopLocation>>(`${this.url}/accounts/shops-locations`).toPromise();
  }

  getTemplateSportsBook(networkId: number, c: number): Observable<ISettingsTemplateSportsBook | any> { // Todo: model is deprecated and new model is in betslip.ts
    return this.http.get<any>(`${this.url}/settings-templates/network/${networkId}/sportsbook`, {params: {c}});
  }

  delete(item: Partial<IAccount>): Observable<IAccount> {
    return undefined;
  }

  get(item: Partial<IAccount>): Observable<IAccount> {
    return this.http.get<IAccount>(`${this.url}/accounts/${item.id}`);
  }

  patch(id: number, item: Partial<IAccount>): Observable<IAccount> {
    return undefined;
  }

  post(item: Partial<IAccount>): Observable<Partial<IAccount>> {
    return undefined;
  }

  search(params: Params): Observable<Pager<IAccount>> {
    return this.http.post<Pager<IAccount>>(`${this.url}/accounts/search`, params);
  }

}

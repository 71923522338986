import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnvironmentConfig, ICity, ICountry, IPersonBirthPlace, IProvince, IRegion } from '@bs/models';
import { CatalogService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({template: ``})
export class BaseBirthPlace implements ControlValueAccessor {
  /**
   * Viewport mobile
   *
   * if true, we switch some templates in the dom for mobile view
   */
  @Input()
  isMobile = false;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  // loading: boolean;
  /**
   * keeps tracks if we get IPersonBirthPlace value from writeValue method
   *
   * see {@link IPersonBirthPlace} for more details of the model
   */
  hasValues: boolean;
  /**
   * the search input fields that are inside the dropdowns when we open
   *
   * used for filtering the items of the dropdowns
   */
  searchFilter = {country: '', region: '', province: '', city: ''};
  /**
   * local reference of the FormBuilder
   */
  birthPlace: FormGroup;
  /**
   * local reference of array of ICountry
   */
  countries: Array<ICountry> = [];
  /**
   * local reference of array of IRegion
   */
  regions: Array<IRegion> = [];
  /**
   * local reference of array of IProvince
   */
  provinces: Array<IProvince> = [];
  /**
   * local reference of array of ICity
   */
  cities: Array<ICity> = [];
  /**
   * local reference to Subscription
   */
  subs = new Subscription();
  /**
   * @ignore
   */
  skipOnce = [true, true, true];

  /**
   * The constructor
   * @param config
   * @param fb
   * @param catalogService
   * @param windowService
   */
  constructor(private config: EnvironmentConfig, private fb: FormBuilder, private catalogService: CatalogService, private windowService: WindowService) {
    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));
    this.catalogService.countries().subscribe({
      next: countries => this.countries = countries
    });
  }

  /**
   * lifecycle hook, where we unsubscribe the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param _fn
   */
  registerOnChange(_fn: any) {
    this.propagateChange = _fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(_fn: any) {
  }

  /**
   * creates the form builder, and on form builder value change it propagates the change
   * @param birthPlace
   */
  writeValue(birthPlace: IPersonBirthPlace) {
    this.hasValues = !!birthPlace;

    this.birthPlace = this.createBirthPlace(birthPlace);

    this.subs.add(this.birthPlace.valueChanges.subscribe({
      next: res => this.propagateChange(res)
    }));
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  /**
   * fetches the regions when we change the country value in the view, and initialize the new regions values
   * @param country {ICountry} region value
   *
   * see {@link ICountry} for model information
   */
  setRegions(country: ICountry) {
    if (this.skipOnce[0]) {
      this.skipOnce[0] = false;
    } else {
      this.resetFields(true, true, true);
      if (!country) {
        return;
      }
      this.catalogService.regions(country).subscribe({
        next: regions => {
          this.birthPlace.get('region').enable();
          if (regions.length) {
            this.regions = regions;
          } else {
            this.bypass(true, true, true);
          }
        },

        error: () => this.bypass(true, true, true)
      });
    }
  }

  /**
   * fetches the provinces from the api, on change of region value from the view, and initialize the new provinces values
   * @param country {IRegion} province value
   *
   * see {@link IRegion} for model information
   */
  setProvinces(region: IRegion) {
    if (this.skipOnce[1]) {
      this.skipOnce[1] = false;
    } else {
      this.resetFields(true, true);
      if (!region) {
        return;
      }
      this.catalogService.provinces(region).subscribe({
        next: provinces => {
          this.birthPlace.get('province').enable();
          if (provinces.length) {
            this.provinces = provinces;
          } else {
            this.bypass(true, true);
          }
        },

        error: () => this.bypass(true, true)
      });
    }
  }

  /**
   * fetches the cities from the api, on change of province value from the view, and initialize the new cities
   * @param country {IProvince} cities value
   *
   * see {@link IProvince} for model information
   */
  setCities(province: IProvince) {
    if (this.skipOnce[2]) {
      this.skipOnce[2] = false;
    } else {
      this.resetFields(true);
      if (!province) {
        return;
      }
      this.catalogService.cities(province).subscribe({
        next: cities => {
          this.birthPlace.get('city').enable();
          if (cities.length) {
            this.cities = cities;
          } else {
            this.bypass(true);
          }
        },

        error: () => this.bypass(true)
      });
    }
  }

  /**
   * this method resets the field value, when true value is passed for one of them
   *
   * @description for example when we pass resetFields(true, false, false) only the cities value will be cleared
   *
   * @param r reset region
   * @param p reset province
   * @param c reset city
   * @private
   */
  private resetFields(c?: boolean, p?: boolean, r?: boolean) {

    const patch = {};

    if (r) {
      this.regions = null;
      this.searchFilter.region = '';
      Object.assign(patch, {region: ''});
    }

    if (p) {
      this.provinces = null;
      this.searchFilter.province = '';
      Object.assign(patch, {province: ''});
    }

    if (c) {
      this.cities = null;
      this.searchFilter.city = '';
      Object.assign(patch, {city: ''});
    }

    this.birthPlace.patchValue(patch);
  }

  /**
   * creates the form builder group with the controls, and patches their values
   * @param infos
   * @private
   */
  private createBirthPlace(infos?: Partial<IPersonBirthPlace>) {
    const group = this.fb.group({
      country: ['', Validators.required],
      region: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required]
    });

    if (infos) {
      this.skipOnce = [true, true, true];
      this.catalogService.countries().subscribe({
        next: countries => {
          this.countries = countries;

          const country = this.countries.find(c => c.id === infos.country.id);

          setTimeout(() => group.get('country').setValue(country as any))

          this.catalogService.regions(country).subscribe({
            next: r => {
              this.regions = r;
              const region = this.regions.find(r => r.id === infos.region.id);
              setTimeout(() => group.get('region').setValue(region as any));

              if (infos.province.id) {
                this.catalogService.provinces(region).subscribe({
                  next: p => {
                    this.provinces = p;
                    const province = p.find(p => p.id === infos.province.id);
                    setTimeout(() => group.get('province').setValue(province as any));

                    if (infos.city.id) {
                      this.catalogService.cities(province).subscribe({
                        next: c => {
                          this.cities = c;
                          const city = c.find(c => c.id === infos.city.id);
                          setTimeout(() => group.get('city').setValue(city as any));
                        }
                      });
                    } else {
                      group.get('city').patchValue(infos.city.name);
                    }
                  }
                });
              } else {
                group.get('province').patchValue(infos.province.name);
                group.get('city').patchValue(infos.city.name);
                this.skipOnce[2] = false;
              }
            }
          })
        }
      });
    } else {
      this.skipOnce = [false, false, false];
    }

    return group;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: IPersonBirthPlace) {
  }

  /**
   * function enables the control field, when true value is passed for one of them
   *
   * @description for example when we pass bypass(true) only the cities value will be cleared and enabled
   *
   * @param c
   * @param p
   * @param r
   * @private
   */
  private bypass(c?: boolean, p?: boolean, r?: boolean) {

    if (c) {
      this.cities = [];
      this.birthPlace.get('city').enable();
    }

    if (p) {
      this.provinces = [];
      this.birthPlace.get('province').enable();
    }

    if (r) {
      this.regions = [];
      this.birthPlace.get('region').enable();
    }
  }
}

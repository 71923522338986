import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, INetworkActivity, INetworkActivitySearchRequest, INetworkActivityType, Pager } from '@bs/models';
import { Observable } from 'rxjs';
import { CrudService } from '../base/crud.service';

@Injectable()
export class AccountsActivitiesService implements CrudService<INetworkActivity> { // in krusty accounts
  readonly url = `${this.config.api.accounts}/activities`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: INetworkActivitySearchRequest): Observable<Pager<INetworkActivity>> {
    return this.http.post<Pager<INetworkActivity>>(`${this.url}/search`, filters);
  }

  getActivityTypes(moduleId: number): Observable<Array<INetworkActivityType>> {
    return this.http.get<Array<INetworkActivityType>>(`${this.url}/activityTypes?m=${moduleId}`);
  }

  delete(item: Partial<INetworkActivity>): Observable<INetworkActivity> {
    return undefined;
  }

  get(item: Partial<INetworkActivity>): Observable<INetworkActivity> {
    return this.http.get<INetworkActivity>(`${this.url}/${item.id}`);
  }

  patch(id: number, item: Partial<INetworkActivity>): Observable<INetworkActivity> {
    return undefined;
  }

  post(item: Partial<INetworkActivity>): Observable<Partial<INetworkActivity>> {
    return undefined;
  }
}

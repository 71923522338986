<!-- <pre>{{ form.value | json }}</pre> -->

<div class="mb-3">
  <span class="p-buttonset">
    <button (click)="tab = 'all'" [class.disabled]="tab !== 'all'" pButton pRipple>{{'all' | translate}}</button>
    <button (click)="tab = 'sport'" [class.disabled]="tab !== 'sport'" pButton pRipple>{{'sports' | translate}}</button>
    <button (click)="tab = 'tournament'" [class.disabled]="tab !== 'tournament'" pButton pRipple>{{'tournaments' | translate}}</button>
    <button (click)="tab = 'match'" [class.disabled]="tab !== 'match'" pButton pRipple>{{'match' | translate}}</button>
  </span>
</div>

@switch (tab) {
  @case ('all') {
    <div class=""></div>
  }
  @case ('sport') {
    <button (click)="addOffer('sport')" class="p-button-primary mb-3" icon="pi pi-plus" pButton pRipple type="button"></button>
    @for (item of getOffer('sport').value; track item; let index = $index) {
      <div class="grid">
        <div class="col">
          <label class="label">{{'betOffer' | translate}}</label>
          <p-dropdown [(ngModel)]="item.offer" [options]="offers" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col">
          <label class="label">{{'sports' | translate}}</label>
          <p-multiSelect [(ngModel)]="item.sports" [options]="sports" optionLabel="name" optionValue="id"></p-multiSelect>
        </div>
        <div class="col">
          <label class="label">{{'markets' | translate}}</label>
          <p-multiSelect [(ngModel)]="item.markets" [options]="markets" optionLabel="name" optionValue="id"></p-multiSelect>
        </div>
        <div class="col">
          <label class="label">{{'selection' | translate}}</label>
          <p-dropdown [(ngModel)]="item.selection" [options]="selections" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col-fixed flex align-items-end">
          <button (click)="removeOffer('sport', index)" class="p-button-danger" icon="pi pi-trash" pButton pRipple type="button"></button>
        </div>
      </div>
    }
  }
  @case ('tournament') {
    <button (click)="addOffer('tournament')" class="p-button-primary mb-3" icon="pi pi-plus" pButton pRipple type="button"></button>
    @for (item of getOffer('tournament').value; track item; let index = $index) {
      <div class="grid">
        <div class="col">
          <label class="label">{{'betOffer' | translate}}</label>
          <p-dropdown [(ngModel)]="item.offer" [options]="offers" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col">
          <label class="label">{{'tournaments' | translate}}</label>
          MISSING COMMENTED
          <!--<p-multiSelect [(ngModel)]="item.tournaments" [options]="tournaments" optionLabel="name" optionValue="id"></p-multiSelect>-->
        </div>
        <div class="col">
          <label class="label">{{'markets' | translate}}</label>
          <p-multiSelect [(ngModel)]="item.markets" [options]="markets" optionLabel="name" optionValue="id"></p-multiSelect>
        </div>
        <div class="col">
          <label class="label">{{'selection' | translate}}</label>
          <p-dropdown [(ngModel)]="item.selection" [options]="selections" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col-fixed flex align-items-end">
          <button (click)="removeOffer('tournament', index)" class="p-button-danger" icon="pi pi-trash" pButton pRipple type="button"></button>
        </div>
      </div>
    }
  }
  @case ('match') {
    <button (click)="addOffer('match')" class="p-button-primary mb-3" icon="pi pi-plus" pButton pRipple type="button"></button>
    @for (item of getOffer('match').value; track item; let index = $index) {
      <div class="grid">
        <div class="col">
          <label class="label">{{'betOffer' | translate}}</label>
          <p-dropdown [(ngModel)]="item.offer" [options]="offers" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col">
          <label class="label">{{'match' | translate}}</label>
          <p-dropdown [(ngModel)]="item.match" [options]="matches" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col">
          <label class="label">{{'markets' | translate}}</label>
          <p-multiSelect [(ngModel)]="item.markets" [options]="markets" optionLabel="name" optionValue="id"></p-multiSelect>
        </div>
        <div class="col">
          <label class="label">{{'selection' | translate}}</label>
          <p-dropdown [(ngModel)]="item.selection" [options]="selections" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col">
          <label class="label">{{'outcome' | translate}}</label>
          <p-dropdown [(ngModel)]="item.outcome" [options]="outcomes" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="col-fixed flex align-items-end">
          <button (click)="removeOffer('match', index)" class="p-button-danger" icon="pi pi-trash" pButton pRipple type="button"></button>
        </div>
      </div>
    }
  }
}

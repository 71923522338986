import { Injectable } from '@angular/core';
import { IBetOffer, IMenuItem } from '@bs/models';
import { Subject } from 'rxjs';

@Injectable()
export class SportsMenuService {

  menu: IMenuItem = {
    items: []
  };

  prematchMenuItems$ = new Subject<IBetOffer>();

  constructor() {
    this.prematchMenuItems$.subscribe({
      next: data => {

        this.menu.items = data.sports.map(i => {

          return {
            label: i.name
          }
        });
      }
    })

  }

  get(): IMenuItem {
    return this.menu;
  }

}

/**
 * The component contains the causal, and causalGroup field
 */
import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IBookmaker, IdName, WalletTypes } from '@bs/models';
import { AppSettingsService, CatalogService } from '@bs/services';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

interface IBookmakerCausalGroups {
  bookmakers?: number[];
  walletTypeId?: number;
  causalGroupId?: number;
  causalId?: number;
}

@Component({
  selector: 'bookmakers-causal-groups',
  templateUrl: './bookmakers-causal-groups.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookmakersCausalGroupsComponent),
      multi: true
    }
  ]
})
export class BookmakersCausalGroupsComponent {
  /**
   * the bookmakers list items
   */
  bookmakers: IBookmaker[];

  model: IBookmakerCausalGroups = {};

  /**
   * option values that we receive from parent
   */
  @Input()
  options: any;

  /**
   * local reference to Subscription
   */
  subs = new Subscription();
  isDisabled: any;

  bookmakerIdForced: boolean;
  /**
   * local reference for causalId
   */
  causalId: IdName<number> = null;
  /**
   * local reference for causalGroupId
   */
  causalGroupId: IdName<number> = null;
  /**
   * when from the options variable input decorator we receive forced values, this boolean keeps track of it
   */
  causalGroupIdForced: boolean;
  /**
   * local reference of IdName<any, number>[]
   */
  causalGroupsValues: IdName<any, number>[];
  /**
   * local reference of IdName<any, number>[]
   */
  causalIdsValues: IdName<any, number>[];

  walletTypes = WalletTypes.values();
  /**
   * variable walletTypeId, default set to 1
   */
  walletTypeId = 1;

  constructor(private route: ActivatedRoute, private catalogService: CatalogService, private settingsService: AppSettingsService) {
    this.subs.add(route.data.subscribe({
      next: data => {
        // console.log(this.route)
        this.bookmakers = data.bookmakers;
        // this.settingsService.settings.bookmaker.id
        // console.log(this.bookmakersOptions)
      }
    }));

    this.subs.add(route.queryParamMap.pipe(
      switchMap(query => {
        if (query.has('walletTypeId')) {
          this.walletTypeId = +query.get('walletTypeId');
        }
        return this.catalogService.causalGroups(this.walletTypeId, [this.settingsService.settings.bookmaker.id]).pipe(map(causal => ({causal, query})));
      })
    ).subscribe({
      next: result => {
        if (result.query.has('causalGroupId')) {
          this.catalogService.causals(this.walletTypeId, +result.query.get('causalGroupId'), []).subscribe({
            next: res => {
              this.causalIdsValues = res;
              this.causalId = result.query.get('causalId') ? this.causalIdsValues?.find(r => r.id === +result.query.get('causalId')) : null;
            }
          });
        } else {
          // [this.causalId, this.causalIds] = [undefined, undefined];
        }
        this.causalGroupsValues = result.causal;

        if (this.options) {
          if (this.options?.force) {
            const {causalGroupId, causalId, bookmakerId} = this.options.force;

            this.causalGroupId = this.causalGroupsValues.find(v => v.id === causalGroupId);
            this.causalId = causalId;
            this.causalGroupIdForced = true;

            if(bookmakerId){
              this.model.bookmakers = [bookmakerId];
            }

            this.causalGroupChange(causalGroupId);
          }
        }
      }
    }));
  }

  /**
   * lifecycle hook, where we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  bookmakersChanged(bookmakers: number[]) {
    if (!Array.isArray(bookmakers)) {
      bookmakers = [bookmakers];
    }

    this.catalogService.causalGroups(1, bookmakers).subscribe({
      next: data => {
        this.model = {
          bookmakers: bookmakers,
          walletTypeId: 1,
        }
        this.causalGroupsValues = data;
      }
    })
    this.update(this.model);
  }

  /**
   * when we change causalGroup value in the view, we register change of the field, and we fetch the new causals and initialize to the causalIdsValues
   * @param id
   */
  causalGroupChange(id: number) {

    if (id) {
      this.model.causalGroupId = id;

      this.catalogService.causals(1, id, this.model.bookmakers).subscribe({
        next: data => {
          this.causalIdsValues = data
          this.causalId = null;
          //this.model.causalId = this.causalIdsValues[0].id
        }
      })
    } else {
      this.causalIdsValues = null;
      this.causalId = null;
    }

    this.update(this.model);
  }

  /**
   * when we change causalChange value in the view, we register change of the field, and we fetch the new causals and initialize to the causalIdsValues
   * @param id
   */
  causalChange(id: number) {
    if (id) {
      this.model.causalId = id;
    } else {
      this.causalId = null;
    }
    this.update(this.model);
  }

  /**
   * we are keeping track of the value changes of the cva, so they are always updated
   * @param values
   */
  update(values: IBookmakerCausalGroups) {
    this.onTouched();
    this.propagateChange(values);
  }

  /**
   * we assign the values of the model, when programmatic changes from model to view are requested, and when we have bookmakers value we call the bookmakersChanged function to filter the correct currencies
   * @param model
   */
  writeValue(model: any): void {
    this.model.walletTypeId = 1;
    this.model.bookmakers = [this.settingsService.settings.bookmaker.id]

    if (model) {
      if (model?.options?.force?.bookmakerId) {
        this.model.bookmakers = [model.options.force.bookmakerId]
        this.bookmakerIdForced = true;
      }

      if (this.options?.force?.causalGroupId) {
        this.causalGroupId = this.causalGroupsValues.find(v => v.id === this.options.force.causalGroupId);
      } else {
        this.causalGroupId = this.causalGroupsValues.find(v => v.id === model.causalGroupId);
      }
      this.causalId = null;
    } else {
      //handle reset
      this.causalGroupId = null;
      this.causalId = null;
      if (this.causalGroupsValues?.length) {
        this.causalIdsValues.length = 0;
      }
    }


    this.update(this.model);
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: any) {
  }
}

import { HttpClient } from '@angular/common/http';
import { ComponentRef, Inject, Injectable, Renderer2, RendererFactory2, ViewContainerRef } from '@angular/core';
import { BettingTypes, EnvironmentConfig, ITicket, ITicketBooking, ITicketCashPayment, ITicketCashPaymentRequest, ITicketChangeRetailStatusRequest, ITicketPlaced, ITicketRequest, ITicketRetail, ITicketRollBack, ITicketSearchFilters, ITicketStatusChangeRequest, ITicketVoid, Pager } from '@bs/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { BookingBetReceiptComponent } from '../components/booking-bet-receipt/booking-bet-receipt.component';
import { TicketCashPaymentReceipt } from '../components/ticket-cash-payment-receipt/ticket-cash-payment-receipt';
import { TicketReceiptComponent } from '../components/ticket-receipt/ticket-receipt.component';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  readonly url = this.config.api.sportsbook + '/tickets';
  private renderer: Renderer2;

  constructor(private config: EnvironmentConfig, rendererFactory: RendererFactory2, private client: HttpClient, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  post(ticketRequest: ITicketRequest): Observable<ITicketPlaced> {
    return this.client.post<ITicketPlaced>(`${this.url}`, ticketRequest);
  }

  get(id: number): Observable<ITicket> {
    return this.client.get<ITicket>(`${this.url}/${id}`).pipe(this.ticketMap());
  }

  getByCode(code: string): Observable<ITicket> {
    return this.client.get<ITicket>(`${this.url}`, {params: {code}}).pipe(this.ticketMap());
  }

  checkByCode(code: string): Observable<ITicket> {
    return this.client.get<ITicket>(`${this.url}/checker`, {params: {code}});
  }

  search(filters: ITicketSearchFilters): Observable<Pager<ITicket>> {
    if (!filters.bookmakers) {
      Object.assign(filters, {bookmakers: [this.config.bookmakerId]});
    }
    return this.client.post<Pager<ITicket>>(`${this.url}/search`, filters);
  }

  searchRetail(filters: ITicketSearchFilters): Observable<Pager<ITicket>> {
    if (!filters.bookmakers) {
      Object.assign(filters, {bookmakers: [this.config.bookmakerId]});
    }
    return this.client.post<Pager<ITicket>>(`${this.url}/retail/search`, filters);
  }

  changeRetailPaymentStatus(id: number, request: ITicketChangeRetailStatusRequest): Observable<ITicketRetail> {
    return this.client.patch<ITicketRetail>(`${this.url}/${id}/retail-payment-status`, request);
  }

  createCashPayment(id: number, request: ITicketCashPaymentRequest): Observable<ITicketCashPayment> {
    return this.client.post<ITicketCashPayment>(`${this.url}/${id}/cash-payment`, request);
  }

  getCashPayment(id: number): Observable<ITicketCashPayment> {
    return this.client.get<ITicketCashPayment>(`${this.url}/${id}/cash-payment`);
  }

  void(id: number, req: ITicketStatusChangeRequest): Observable<ITicketVoid> {
    return this.client.post<ITicketVoid>(`${this.url}/${id}/void`, req);
  }

  rollback(id: number, req: ITicketStatusChangeRequest): Observable<ITicketRollBack> {
    return this.client.post<ITicketRollBack>(`${this.url}/${id}/rollback`, req);
  }

  private ticketMap() {
    return map((result: ITicket) => {
      if (result.bettingTypeId === BettingTypes.integral || result.bettingTypeId === BettingTypes.system) {
        result.derived = {
          amount: 0,
          minOdd: Math.min(...result.combinations.map(c => c.minOdd)),
          maxOdd: result.combinations.map(c => c.maxOdd).reduce((a, b) => a + b),
          minBonus: Math.min(...result.combinations.map(c => c.minBonus)),
          maxBonus: result.combinations.map(c => c.maxBonus).reduce((a, b) => a + b),
          minWin: Math.min(...result.combinations.map(c => c.minWin)),
          maxWin: result.combinations.map(c => c.maxWin).reduce((a, b) => a + b)
        };
      }
      return result;
    })
  }

  getTopWinnings(currencyId: number, from: string, to: string): Observable<any> {
    return this.client.get(`${this.config.api.boe}/tickets/topwinnings`, {params: {c: currencyId.toString(), from, to, bId: this.config.bookmakerId.toString()}});
  }

  printTicketRetailDrawerMovement(containerRef: ViewContainerRef, ticket: ITicketRetail, isReprint?: boolean) {
    const ref = containerRef.createComponent<TicketCashPaymentReceipt>(TicketCashPaymentReceipt);

    ref.instance.ticket = ticket;
    ref.instance.isReprint = isReprint;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  printTicket(containerRef: ViewContainerRef, ticket: ITicket, code: string, isReprint?: boolean) {
    const ref = containerRef.createComponent<TicketReceiptComponent>(TicketReceiptComponent);
    ref.instance.ticket = ticket;
    ref.instance.code = code;
    ref.instance.isReprint = isReprint;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  printTicketCashPayment(containerRef: ViewContainerRef, ticket: ITicket, ticketCashPayment: ITicketCashPayment) {
    const ref = containerRef.createComponent<TicketCashPaymentReceipt>(TicketCashPaymentReceipt);

    ref.instance.ticketCashPayment = ticketCashPayment;
    ref.instance.ticket = ticket;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  printBookingBet(containerRef: ViewContainerRef, data: ITicketBooking): void {
    const ref = containerRef.createComponent<BookingBetReceiptComponent>(BookingBetReceiptComponent, {index: 0});

    ref.instance.data = data;

    ref.instance.loaded.subscribe({
      next: () => this.openUp(ref)
    });
  }

  private openUp(componentRef: ComponentRef<any>) {


    const blob = new Blob([componentRef.location.nativeElement.innerHTML], {type: 'text/html'});
    const blobUrl = URL.createObjectURL(blob);

    const printIframe = this.document.querySelector('iframe#print') as HTMLIFrameElement;
    if (printIframe) {
      printIframe.src = blobUrl;
    } else {
      const iframe = this.renderer.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      iframe.setAttribute('id', 'print');
      this.renderer.appendChild(this.document.body, iframe);
      iframe.addEventListener('load', () => {
        iframe.contentWindow.print();
      });
    }
  }
}

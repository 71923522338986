import { IdName, idNames } from '../common/id-name';
import { IBetSlipSelection } from './betslip';
import { IBetSlipType } from './enum';

export interface ITicketRequestSelection {
  blocked: boolean;
  oddId: number;
  value?: number;
}

export enum IBetslipGroup {
  Prematch = 1,
  Live,
  Mixed
}

export namespace IBetslipGroup {
  export function values(): Array<IdName<any>> {
    return idNames(IBetslipGroup);
  }
}

// old model sportsbook
export interface ITicketBooking {
  expiringDate: string;
  stake: number;
  code: string;
  selections: Array<IBetSlipSelection>;
}

export interface BookBetRequest {
  betTypeId: IBetSlipType; // type
  // betGroupId?: IBetslipGroup; // context
  stake: number; // betType.amount
  selections: Array<ITicketRequestSelection>;
}

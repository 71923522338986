<div class="p-inputgroup">

  <p-dropdown (ngModelChange)="reset()" [(ngModel)]="typeId" [disabled]="isDisabled" [options]="types" class="drop-type" optionLabel="name" optionValue="id"></p-dropdown>
  <p-autoComplete (completeMethod)="search($event)" (onClear)="reset()" (onSelect)="update($event)" [(ngModel)]="parent" [field]="'username'" [disabled]="isDisabled" [suggestions]="results" minLength="3">
    <ng-template let-parent pTemplate="item">
      <div>{{parent.username}} - ({{parent.id}})</div>
    </ng-template>
  </p-autoComplete>

</div>


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IBaseGame, IGameType, IWinner } from '@bs/models';
import { WindowService } from '@bs/universal';
import { Observable } from 'rxjs';
import { CatalogService } from '../core/catalog.service';

@Injectable({
  providedIn: 'root'
})
export class GamesService {

  readonly url = `${this.config.api.games}/games`;

  isMobile: boolean;
  deviceTypeIdDetected: number;

  constructor(private config: EnvironmentConfig, private catalogService: CatalogService, private http: HttpClient,
              private windowService: WindowService) {
    windowService.device$.subscribe({
      next: device => {
        this.isMobile = device.isMobile
        this.deviceTypeIdDetected = device.isMobile ? 2 : 1
      }
    });

  }

  listByType(gameTypeId: number, bookmakerId = this.config.bookmakerId): Observable<IGameType> {
    const params: Params = {tId: gameTypeId, bId: bookmakerId, dId: this.deviceTypeIdDetected};
    return this.http.get<IGameType>(`${this.url}/list`, {params});
  }

  get(id: number): Observable<IBaseGame> {
    const params: Params = {displayDeviceTypeId: this.deviceTypeIdDetected};
    return this.http.get<IBaseGame>(`${this.url}/${id}`, {params});
  }

  play(id: number, playMode: 'real' | 'fun'): Observable<any> {
    const currencyCode = this.catalogService.currentCurrency.code;
    return this.http.get(`${this.url}/play`, {params: {gameId: id.toString(), playMode, currencyCode}});
  }

  getWinners(typeId: number, wt: number): Observable<Array<IWinner>> {
    return this.http.get<Array<IWinner>>(`${this.url}/winners`, {params: {t: typeId.toString(), wt: wt.toString()}});
  }
}

import { DOCUMENT, KeyValue } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentConfig } from '@bs/models';
import { WINDOW } from '@bs/universal';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

const DELIMITER = '_:::_';

interface Cordova {
  platformId: string;
  version: string;
  plugins: any;
  file: any;
  getAppVersion: any;
  InAppBrowser?: any;
}

interface LocalNotification {
  title?: string;
  text?: string;
  actions?: Array<any>;
  smallIcon?: string;
  color?: string;
  foreground?: boolean;
  attachments?: Array<any>;
  className?: string;
}

type Biometric = false | 'face' | 'finger' | 'biometric';

@Injectable()
export class CordovaService {

  _version: string;
  private resume: BehaviorSubject<boolean>;
  private readonly url = `${this.config.api.catalogs}/app`;
  notifications: Subject<LocalNotification> = new Subject<LocalNotification>();
  private biometricType: Biometric;

  constructor(@Inject(WINDOW) private window: any, @Inject(DOCUMENT) private document: any, private config: EnvironmentConfig, private translate: TranslateService,
              private http: HttpClient, private router: Router, private zone: NgZone) {

    this.window.open = this.cordova.InAppBrowser.open;

    this.resume = new BehaviorSubject<boolean>(null);
    fromEvent(document, 'resume').subscribe({
      next: () => this.zone.run(() => this.onResume())
    });

    document.addEventListener('offline', () => {
      // tslint:disable-next-line:no-string-literal
      // navigator['app'].exitApp();
      this.notifications.next({title: 'no-internet-connection', className:'warning'});
    }, false);

    document.addEventListener('online', () => {
      this.notifications.next({title: 'internet-connection-back', className:'success'});
    }, false);

    if (this.cordova?.plugins.idfa) {
      this.idfaInfo(this.cordova.plugins.idfa);
    }

    this.appVersion();
    // this.oneSignalInit();

  }

  get cordova(): Cordova {
    return this.window.cordova;
  }

  get credentials(): Array<KeyValue<string, string>> {
    const current = this.window.localStorage.getItem('credentials');
    return current ? JSON.parse(current) : [];
  }

  set credentials(credentials: Array<KeyValue<string, string>>) {
    this.window.localStorage.setItem('credentials', JSON.stringify(credentials));
  }

  get fingerprint(): { show: any, isAvailable: any, registerBiometricSecret: any, loadBiometricSecret: any } {
    return this.window.Fingerprint;
  }

  print(stringOrHtml: string) {
    this.cordova.plugins.printer.print(stringOrHtml);
  }

  hasFingerprint(): Promise<Biometric> {
    // result depends on device and os.
    // iPhone X will return 'face' other Android or iOS devices will return 'return finger' Android P+ will return 'biometric'
    // 'error' will be an object with an error code and message
    return new Promise((resolve, reject) => {
      this.window.Fingerprint.isAvailable(
        result => {
          this.biometricType = result;
          return resolve(result);
        },
        error => reject({message: error.message}),
        {});
    });
  }

  saveCredentials(auth: any) {
    this.fingerprint.registerBiometricSecret({
        title: this.translate.instant('save-credentials'),
        description: this.translate.instant('biometric-register', {value: this.translate.instant(`app.${this.biometricType}`)}),
        secret: `${auth.username}${DELIMITER}${auth.password}`,
        invalidateOnEnrollment: true,
        disableBackup: true,
      },
      result => this.credentials = result,
      error => console.error(error));
  }

  getCredentials(): Promise<{ username: string, password: string }> {
    return new Promise((resolve, reject) => {
      this.fingerprint.loadBiometricSecret({
          title: this.translate.instant('biometric-login'),
          description: this.translate.instant('biometric-use', {value: this.translate.instant(`app.${this.biometricType}`)}),
          disableBackup: true
        },
        result => {
          const [username, password] = result.split(DELIMITER);
          return resolve({username, password});
        }, error => reject(error));
    });
  }

  hasCredentials(): boolean {
    return this.credentials?.length >= 1;
  }

  onResume() {
    this.resume.next(true);
  }

  openLinkInBrowser(url: string, options?: any, forceInApp = false, forceInBrowser = false) {
    url = new URL(url).toString();

    if (forceInBrowser) {

      const parsed = new URL(url);
      const extUrl = `${this.config.project}${parsed.pathname}`;
      this.window.cordova.InAppBrowser.open(extUrl, '_system');

      return;
    }

    this.window.SafariViewController.isAvailable(available => {
      if (available && !forceInApp) {
        return this.window.SafariViewController.show(
          {
            url,
            animated: false,
            barColor: options?.toolbarcolor,
            tintColor: '#FFF',
            controlTintColor: '#1CA8DD',
          },
          () => {
            /* this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
            if (result.event === 'opened') {
              console.log('opened');
            } else if (result.event === 'loaded') {
              console.log('loaded');
            } else if (result.event === 'closed') {
              console.log('closed');
            }*/
          },
          msg => console.error('KO: ' + msg));
      } else {
        const ref = this.window.cordova.InAppBrowser.open(url, '_blank', `location=${options?.location || 'yes'},lefttoright=yes,hidenavigationbuttons=yes,toolbarcolor=${options?.toolbarcolor}`);
        ref.addEventListener('loadstart', event => {
          // if redirect to website, close the InAppBrowser
          if (event.url.includes(this.config.project)) {
            const goto = event.url.replace(this.config.project, '');
            this.router.navigate([goto]);
            ref.close();
          }
        });
        ref.addEventListener('loaderror', () => {
          this.router.navigate(['/']);
          ref.close();
        });
        return ref;
      }
    });
  }

  versionCheck() {
    // local versioning is valid only for android
    if (this.cordova.platformId !== 'android') {
      return;
    }

    this.cordova.plugins.notification.local.on('download-new-version', handler => {
      this.downloadNewVersion(handler);
    });

    this.cordova.plugins.notification.local.on('click', handler => {
      this.downloadNewVersion(handler);
    });

    this.cordova.plugins.notification.local.on('remind-tomorrow', handler => {
      const trigger = {in: 1, unit: 'day'};
      this.downloadNotification(handler.attachments[0].version, trigger);
    });

    this.http.get(`${this.url}/versions`).subscribe({
      next: (versions: Array<string>) => {

        const lastAvailableVersion = versions[0].match('v(.*?).apk')[1];

        if (lastAvailableVersion !== this._version) {
          // console.log('version mismatch');
          this.downloadNotification(lastAvailableVersion);
        }
      }
    });
  }

  getStorageLocation(): string {
    let storageLocation = '';
    switch (this.cordova.platformId) {
      case 'android':
        storageLocation = this.cordova.file.externalDataDirectory;
        break;
      case 'ios':
        storageLocation = this.cordova.file.documentsDirectory;
        break;
    }

    return storageLocation;
  }

  notify(notification: LocalNotification) { // opens notification from anywhere
    const base = Object.assign({title: 'notification'}, notification);

    this.cordova.plugins.notification.local.schedule(base);
  }

  private oneSignalInit() {
    console.log('Initializing Onesignal');

    const signal = this.window.plugins.OneSignal;
    // Uncomment to set OneSignal device logging to VERBOSE
    signal.setLogLevel(6, 0);
    signal.addTrigger('level', 5);

    /*// NOTE: Update the setAppId value below with your OneSignal AppId.

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    this.window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(accepted => {
      console.log('User accepted notifications: ' + accepted);
    });*/


    signal.setAppId(this.config.onesignalKey);
    signal.setNotificationOpenedHandler(jsonData => {
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    signal.promptForPushNotificationsWithUserResponse(accepted => {
      console.log('User accepted notifications: ' + accepted);
    });


    signal.setNotificationOpenedHandler(result => {

      const launchURL = result.notification.actionButtons[0]?.id;

      if (launchURL.includes(this.config.project)) {
        // is launching local
        const localUrl = new URL(launchURL);

        void this.router.navigateByUrl(localUrl.pathname);
      }

    });

    signal.setInAppMessageClickHandler(result => {
      const launchURL = result.clickName;

      if (launchURL.includes(this.config.project)) {
        // is launching local
        const localUrl = new URL(launchURL);

        void this.router.navigateByUrl(localUrl.pathname);
      }
    });
  }

  private appVersion(): string {

    if (this._version) {
      return this._version;
    }

    this.cordova.getAppVersion.getVersionNumber().then(version => {
      this._version = version;
      // console.info('version: ' + version);
      return version;
    });
  }

  private downloadNewVersion(handler: any) {

    const latest = handler.attachments[0].version;
    const folderPath = this.getStorageLocation();
    const mimeType = 'application/vnd.android.package-archive';

    this.window.resolveLocalFileSystemURL(
      folderPath,
      dir => {
        dir.getFile(
          `android-v${latest}.apk`,
          {
            create: true
          },
          file => {
            const url = file.toURL();

            file.createWriter(
              fileWriter => {

                if (fileWriter.length) {
                  // get the in memory
                  this.openDownloadedApp(url, mimeType);
                } else {
                  // download new one
                  const uri = encodeURI(`${this.url}/download/${latest}`);

                  this.cordova.plugins.notification.local.schedule({
                    title: this.translate.instant('download-in-progress'),
                    text: this.translate.instant('downloading-version', {latest}),
                    smallIcon: 'res://ic_stat_icon',
                    color: '#01A89E',
                    foreground: true
                  });


                  this.http.get(uri, {responseType: 'blob', reportProgress: true, observe: 'events'}).subscribe({
                    next: source => {

                      if (source.type === HttpEventType.DownloadProgress) {

                        const percentDone = Math.round(100 * source.loaded / source.total);

                        this.cordova.plugins.notification.local.update({
                          progressBar: {value: percentDone}
                        });

                      }

                      if (source.type === HttpEventType.Response) {
                        // file has finished download

                        const blob = new Blob([source.body], {type: mimeType});

                        fileWriter.onwriteend = () => {
                          this.cordova.plugins.notification.local.schedule({
                            title: this.translate.instant('download-complete'),
                            smallIcon: 'res://ic_stat_icon',
                            color: '#01A89E',
                            foreground: true,
                            actions: [
                              {id: 'discard', title: this.translate.instant('ok')}
                            ]
                          });
                          this.openDownloadedApp(url, mimeType);
                        };

                        // todo: close the notification

                        fileWriter.onerror = err => this.errorHandler(err);

                        fileWriter.write(blob);
                      }

                    }
                  });
                }

              }, err => this.errorHandler(err)
            );
          },
          err => this.errorHandler(err)
        );
      },
      err => this.errorHandler(err));

  }

  private openDownloadedApp(url: string, mimeType: string) {
    this.cordova.plugins.fileOpener2.open(url, mimeType, {
      error: err => this.errorHandler(err),
      success: () => console.log('success with opening the file')
    });
  }

  private errorHandler(err) {
    alert('Unable to download');
    console.error(err);
  }

  private downloadNotification(lastVersion: string, trigger?: any) {
    this.translate.get(`new-version-title`).subscribe({
      next: title => {

        const params: any = {
          title,
          text: this.translate.instant(`new-version-text`, {current: this._version, latest: lastVersion}),
          actions: [
            {id: 'new-version-download', title: this.translate.instant('yes')},
            {id: 'remind-tomorrow', title: this.translate.instant('remind-me-tomorrow')},
            {id: 'discard', title: this.translate.instant('no')}
          ],
          smallIcon: 'res://ic_stat_icon',
          color: '#01A89E',
          foreground: true,
          attachments: [{version: lastVersion}]
        };

        if (trigger) {
          params.trigger = trigger;
        }

        this.cordova.plugins.notification.local.schedule(params);
      }
    });
  }

  private idfaInfo(idfaPlugin) {

    idfaPlugin.getInfo()
      .then(info => {
        if (!info.trackingLimited) {
          return info.idfa || info.aaid;
        } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {

          return idfaPlugin.requestPermission().then(result => {
            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {

              return idfaPlugin.getInfo().then(i => {
                return i.idfa || i.aaid;
              });

            }
          });
        }
      })
      .then(idfaOrAaid => {
        if (idfaOrAaid) {
          console.log(idfaOrAaid);
        }
      });
  }
}

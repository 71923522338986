import { ModuleWithProviders, NgModule } from '@angular/core';
import { BsCommonModule } from '@bs/common';
import { BsFormsModule } from '@bs/forms';
import { EnvironmentConfig } from '@bs/models';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { AdditionalDataTypeComponent } from './components/additional-data-type/additional-data-type.component';

import { AmountRangeComponent } from './components/amount-range/amount-range.component';
import { BirthPlaceComponent } from './components/birth-place/birth-place.component';
import { BookmakersCausalGroupsComponent } from './components/bookmakers-causal-groups/bookmakers-causal-groups.component';
import { BookmakersCurrenciesComponent } from './components/bookmakers-currencies/bookmakers-currencies.component';
import { BookmakersGamesProvidersComponent } from './components/bookmakers-games-providers/bookmakers-games-providers.component';
import { BookmakersPicklistComponent } from './components/bookmakers-picklist/bookmakers-picklist.component';
import { CausalGroupsComponent } from './components/causal-groups/causal-groups.component';
import { CausalsPicklistComponent } from './components/causals-picklist/causals-picklist.component';
import { CurrenciesAmountComponent } from './components/currencies-amount/currencies-amount.component';
import { DataTableBuilderComponent } from './components/data-table-builder/data-table-builder.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DisplaycurrencyPicklistComponent } from './components/displaycurrency-picklist/displaycurrency-picklist.component';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { FieldComponent } from './components/field/field.component';
import { FixtureSelectorComponent } from './components/fixture-selector/fixture-selector.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { FormBuilderComponent } from './components/form/form-builder.component';
import { GamesOfferComponent } from './components/games-offer/games-offer.component';
import { GeoInfosComponent } from './components/geo-infos/geo-infos.component';
import { LanguageEditorComponent } from './components/language-editor/language-editor.component';
import { MinMaxAmountComponent } from './components/min-max-amount/min-max-amount.component';
import { MobilePrefixComponent } from './components/mobile-prefix/mobile-prefix.component';
import { PageContentsComponent } from './components/page-contents/page-contents.component';
import { ParentSelectorComponent } from './components/parent-selector/parent-selector.component';
import { PasswordComponent } from './components/password/password.component';
import { PromoInfosComponent } from './components/promo-infos/promo-infos.component';
import { SportsbookOfferComponent } from './components/sportsbook-offer/sportsbook-offer.component';
import { WebappsComponent } from './components/webapps/webapps.component';
import { WeeklySlotsComponent } from './components/weekly-slots/weekly-slots.component';
import { CappedAmountComponent } from './components/capped-amount/capped-amount.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { BookmakerPickerComponent } from './components/bookmaker-picker/bookmaker-picker.component';
import { BetOfferLimitsComponent } from './components/betoffer-limits/bet-offer-limits.component';
import { AccordionModule } from 'primeng/accordion';

/**
 * array of all the components that goes in NgModule exports and declaration, so they can be read in another module
 */
const components = [
  AdditionalDataTypeComponent,
  AmountRangeComponent,
  BirthPlaceComponent,
  BookmakersGamesProvidersComponent,
  BookmakersCurrenciesComponent,
  BookmakersPicklistComponent,
  CausalGroupsComponent,
  BookmakersCausalGroupsComponent,
  CausalsPicklistComponent,
  CurrenciesAmountComponent,
  DataTableBuilderComponent,
  DateRangeComponent,
  DisplaycurrencyPicklistComponent,
  FieldComponent,
  FieldErrorComponent,
  FixtureSelectorComponent,
  FormBuilderComponent,
  FormErrorsComponent,
  GeoInfosComponent,
  MobilePrefixComponent,
  PasswordComponent,
  LanguageEditorComponent,
  PromoInfosComponent,
  PageContentsComponent,
  ParentSelectorComponent,
  WebappsComponent,
  MinMaxAmountComponent,
  WeeklySlotsComponent,
  SportsbookOfferComponent,
  GamesOfferComponent,
  CappedAmountComponent,
  TimelineComponent,
  BookmakerPickerComponent,
  BetOfferLimitsComponent
];


@NgModule({
    imports: [
        BsFormsModule,
        BsCommonModule,
        TooltipModule,
        InputNumberModule,
        FileUploadModule,
        CalendarModule,
        CheckboxModule,
        RadioButtonModule,
        InputSwitchModule,
        DropdownModule,
        MultiSelectModule,
        PickListModule,
        ToggleButtonModule,
        BadgeModule,
        ChipModule,
        PanelModule,
        ProgressSpinnerModule,
        TableModule,
        MessageModule,
        FieldsetModule,
        InputMaskModule,
        InputTextModule,
        PasswordModule,
        InputTextareaModule,
        CardModule,
        ToolbarModule,
        AutoCompleteModule,
        TabViewModule,
        EditorModule,
        SelectButtonModule,
        InplaceModule,
        ImageModule,
        AccordionModule,
        EditorComponent,
    ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
    BsFormsModule
  ]
})
export class KrustyFormsModule {
  static forRoot(config: any): ModuleWithProviders<KrustyFormsModule> {
    return {
      ngModule: KrustyFormsModule,
      providers: [
        {
          provide: EnvironmentConfig,
          useValue: config
        }
      ]
    };
  }
}


import { IdName, idNames } from '../common/id-name';

export enum ResultStatus {
  not_confirmed = 1,
  confirmed = 2
}

export namespace ResultStatus {
  export function values(): Array<IdName<any>> {
    return idNames(ResultStatus);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

/*
 * Sluggify a text
 * Usage:
 *   text | sluggify
 * Example:
 *   {{ hello world | sluggify }}
 *   formats to: hello-world
*/

/**
 * this pipe slugify a text, formats to: 'hello-world'
 */
@Pipe({
  name: 'sluggify'
})
export class SluggifyPipe implements PipeTransform {

  /**
   * returns us the slugify text in string
   * @param t {string[] | string} some text or array of string that needs to be transformed
   * @param slashes {boolean} if it should match any slash characters
   *
   */
  transform(t: string[] | string, slashes = false): string {
    if (t == undefined) {
      return "-"
    }

    let text = '';

    if (Array.isArray(t)) {
      text = t.join('-');
    } else {
      text = t;
    }

    const pattern = slashes ? /(\s+-\s+|,|\s+|\/)+/gi : /(\s+-\s+|,|\s+|_)+/gi;
    return text.replace(pattern, '-').toLowerCase();
  }
}

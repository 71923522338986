import { IdName, idNames } from '../common/id-name';

export enum DocumentTypes {
  drivingLicense = 1,
  passport = 3,
  voterId = 5,
  socialSecurityAndNationalInsurance = 8,
  cpf = 9
}
export namespace DocumentTypes {
  export function values(): Array<IdName<any>> {
    return idNames(DocumentTypes);
  }
}

export interface IDocumentsSearchRequest {
  networkId?: number;
  documentTypeId?: number;
  documentNumber?: string;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IDocumentCreateRequest extends IDocument {
  accountId: number;
}

/*export enum DocumentTypeImage {
  front,
  back,
  selfie
}*/

export interface IDocumentAttachment {
  typeId: number;
  type: number;
  url: string;
  isConfirmed: boolean;
  creationDate: string;
}

export interface IDocument {
  id?: number;
  typeId: number;
  documentNumber: string;
  expiringDate: string;
  isConfirmed?: boolean;
  issueAuth: string;
  issueDate: string;
  issuePlace: string;
  attachments?: Array<IDocumentAttachment>;
  tpl?:string;
}

export interface IDocumentType {
  id: number;
  name: string;
  attachments: Array<IdName<string>>;
}

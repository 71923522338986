import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { AdditionalDataTypes } from '@bs/models';
import { Subscription } from 'rxjs';

interface AdditionalData {
  additionalDataTypeId: number;
  additionalDataType?: string;
}

/**
 * The component contains the additional data types and the value
 */
@Component({
  selector: 'additional-data-type',
  templateUrl: './additional-data-type.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdditionalDataTypeComponent),
      multi: true
    }
  ]
})

export class AdditionalDataTypeComponent implements ControlValueAccessor, OnDestroy {
  @Input()
  options: { bookmakerId: number };

  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: AdditionalData;
  /**
   * responsible for disabling the field
   */
  disabled: boolean;
  /**
   * local reference of Subscription
   * @private
   */
  private subs = new Subscription();
  values = AdditionalDataTypes.values();

  additionalDataTypeSelected: number;
  additionalDataType: string;

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * sets the model value, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: AdditionalData): void {
    if (obj === null) {
    }

    if (obj) {
      this.model = obj;
    }
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * on change on some amount value from the view, we update the model value, and propagate the changes
   *
   * @param values
   */
  update(values: AdditionalData) {
    this.onTouched();

    this.propagateChange(values);
  }

  /**
   * on lifecycle hook, we unsubscribe from the subscription
   */
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param model
   * @private
   */
  private propagateChange(model: AdditionalData) {
    console.log(model);
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

}

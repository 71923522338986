import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdName } from '@bs/models';
import { BetOfferService, CategoryService, SportService, SportsMenuService, TournamentService } from '@bs/sportsbook';
import { map } from 'rxjs/operators';

/**
 * the model of FixtureSelected
 */
interface FixtureSelected {
  betOfferId: number,
  sportId?: number,
  categoryId?: number,
  tournamentId?: number
}

/**
 * The component provides us dropdown values of sports, categories, tournaments and bet offers in a dropdown fields
 */
@Component({
  selector: 'fixture-selector',
  templateUrl: './fixture-selector.component.html',
  providers: [
    SportService,
    SportsMenuService,
    BetOfferService,
    CategoryService,
    TournamentService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FixtureSelectorComponent),
      multi: true
    }
  ]
})
export class FixtureSelectorComponent implements ControlValueAccessor {
  /**
   * responsible for disabling the field
   */
  isDisabled = false;
  /**
   * list of the bet offers
   */
  betoffers: Array<IdName<any>>;
  /**
   * list of the sports
   */
  sports: Array<IdName<any>>;
  /**
   * list of the categories
   */
  categories: Array<IdName<any>>;
  /**
   * list of the tournaments
   */
  tournaments: Array<IdName<any>>;

  /**
   * local reference for the betOffer
   */
  betOfferSelected: IdName<any>;
  /**
   * local reference for the sport selected
   */
  sportSelected: IdName<any>;
  /**
   * local reference for the category selected
   */
  categorySelected: IdName<any>;
  /**
   * local reference for the tournament selected
   */
  tournamentSelected: IdName<any>;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: FixtureSelected;

  /**
   * The constructor, where we fetch all betOffsers and initialize to the variable
   * @param sportsService
   * @param categoryService
   * @param tournamentService
   * @param betOfferService
   */
  constructor(private sportsService: SportService, private categoryService: CategoryService, private tournamentService: TournamentService, betOfferService: BetOfferService) {

  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * function remaps array, and returns us mapped array with property and value of name and id
   * @param i
   * @private
   */
  private remap(i: Array<any>) {
    return i.map(x => ({name: x.name, id: x.id}))
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  /**
   * function when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(model: any) {
    if(model){
      // then write
    } else {
      // initialize
    }
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  onTouched() {
  }

  /**
   * function invokes registerOnTouched method
   * @param event
   */
  onBlur() {
    this.onTouched();
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  propagateChange(_model: FixtureSelected) {

  }

  /**
   * on change of betoffer value from the view, we fetch the sports matches, initialize the sports values, and propagates value change of betoffer
   * @param betOffer
   */
  setSport(betOffer: IdName<any>) {
    this.sportsService.getAll(betOffer.id).pipe(map(s => this.remap(s))).subscribe({
     next: s => this.sports = s
    });
    this.propagateChange({betOfferId: betOffer.id});
  }

  /**
   * on change of sport value from the view, we fetch the categories, initialize the categories values, and propagates value change of betoffer, and sportId
   * @param betOffer
   */
  setCategory(sport: IdName<any>) {
    this.categoryService.getAll(this.betOfferSelected.id, sport.id).pipe(map(s => this.remap(s))).subscribe({
     next: c => this.categories = c
    });
    this.propagateChange({betOfferId: this.betOfferSelected.id, sportId: sport.id});
  }

  /**
   * on change of category value from the view, we fetch the tournaments, initialize the tournaments values, and propagates value change of betoffer, sportId, categoryId
   * @param category
   */
  setTournament(category: IdName<any>) {
    this.tournamentService.getAll(this.betOfferSelected.id, category.id).pipe(map(s => this.remap(s))).subscribe({
     next: t => this.tournaments = t
    });
    this.propagateChange({betOfferId: this.betOfferSelected.id, sportId: this.sportSelected.id, categoryId: category.id});
  }

  /**
   * on change of tournament value from the view, we propagates value change of betOfferId, sportId, categoryId, tournamentId
   * @param betOffer
   */
  setMatches(tournament: IdName<any>) {
    this.propagateChange({betOfferId: this.betOfferSelected.id, sportId: this.sportSelected.id, categoryId: this.categorySelected.id, tournamentId: tournament.id});
  }


}

import { Pipe, PipeTransform } from '@angular/core';
import { ICurrency, IWalletAmount } from '@bs/models';
import { AppSettingsService } from '@bs/services';
import { TranslateService } from '@ngx-translate/core';

/**
 * this pipe transform an amount of type Walletamount into a html string readable from a tooltip
 */
@Pipe({
  name: 'amountTooltip'
})
export class AmountTooltipPipe implements PipeTransform {
  /**
   * displayCurrency variable
   */
  displayCurrency: ICurrency;

  /**
   * The constructor sets us the display currency
   */
  constructor(private translate: TranslateService, private appSettingsService: AppSettingsService) {
    this.displayCurrency = this.appSettingsService.settings.displayCurrency;
  }

  /**
   * return us the template html string
   * @param walletAmount {IWalletAmount} requires a IWalletAmount value
   * @param rate {any} optionally we can pass rate for the amount to be calculated
   */
  transform(walletAmount: IWalletAmount, rate?: any): string {
    const details = this.translate.instant('details');
    const noWithdrawable = this.translate.instant('not-withdrawable');
    const withdrawable = this.translate.instant('withdrawable');
    const bonus = this.translate.instant('bonus');

    const nw = walletAmount.notWithdrawable * (rate || 1) / 100;
    const w = walletAmount.withdrawable * (rate || 1) / 100;
    const b = walletAmount.bonus * (rate || 1) / 100;

    let symbol = walletAmount.currencySymbol

    if (rate) {
      symbol = this.displayCurrency.symbol
    }

    return `<strong>${details}</strong>
            <span>${noWithdrawable}: ${symbol} ${(nw).toFixed(2)}</span>
            <span>${withdrawable}: ${symbol} ${(w).toFixed(2)}</span>
            <span>${bonus}: ${symbol} ${(b).toFixed(2)}</span>`;
  }
}



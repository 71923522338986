import { Pipe, PipeTransform } from '@angular/core';
import { OddFormat } from '@bs/models';

/*
 * Format a value to the locale currency and money format
 * Usage:
 *   integer | oddFormat
 * Example:
 *   {{ 3.5 | oddFormat }}
 *   formats to: 3.50
*/

/**
 * Format a value to the locale currency and money format, formats to: 3.50
 */
@Pipe({
  name: 'oddFormat'
})
export class OddFormatPipe implements PipeTransform {

  /**
   * Example usage:
   *
   * @example
   * <strong>{{3.5 | oddFormat:oddFormat:true}}</strong>
   */

  /**
   * returns us formatted locale currency
   * @param value {number} decimal number value
   * @param format {OddFormat} formatting the decimals by a country
   * @param fromCents {boolean} if the value needs to be converted to cents
   */
  transform(value: number, format: OddFormat, fromCents?: boolean): string {
    let t: any;

    if (!value) {
      return '--';
    }

    if (fromCents) {
      value = value / 100;
    }
    const e = value - 1;
    const n = Math.round(e);
    let o = Math.abs(n - e);

    switch (format) {
      case OddFormat.Fractional:
        t = Math.round(e) + '/1';

        for (let i = 2; i <= 200; i++) {
          const a = Math.round(e * i) / i;
          const r = Math.abs(a - e);
          if (o > r) {
            t = Math.round(e * i) + '/' + i;
            if (0 === r) {
              break;
            }
            o = r;
          }
        }
        return t;
      case OddFormat.HongKong:
        return t = 1 >= value
          ? 0 / 0
          : value - 1, t.toFixed(4);
      case OddFormat.Indonesian:
        return t = 1 >= value
          ? 0 / 0
          : value >= 2 ? value - 1 : 1 / (1 - value), t.toFixed(4);
      case OddFormat.Malesian:
        return t = 1 >= value
          ? 0 / 0
          : 2 >= value ? value - 1 : 1 / (1 - value), t.toFixed(4);
      case OddFormat.American:
        return t = 1 >= value
          ? 0 / 0
          : 2 > value ? -100 / (value - 1) : 100 * (value - 1), (t > 0 ? '+' : '') + (Math.round(100 * t) / 100).toFixed(0);
      case OddFormat.Decimal:
      default:
        return value.toFixed(2);
    }
  }
}

import { IAuthor } from '../common/author';
import { IdName, idNames } from '../common/id-name';
import { IMenuItem } from './menu';

export enum CmsPageModules {
  cms = 1
  //core = 2,
  //games = 3
}

export namespace CmsPageModules {
  export function values(): Array<IdName<string>> {
    return idNames(CmsPageModules);
  }
}

export enum CmsPageStatus {
  created = 1,
  published = 2,
  trashed = 3
}

export namespace CmsPageStatus {
  export function values(): Array<IdName<string>> {
    return idNames(CmsPageStatus);
  }
}

export interface ICmsPageView {
  id: number;
  title: string;
  path: string;
  content: string;
}

export interface ICmsPage {
  id?: number;
  name: string;
  moduleId: number;
  webAppId: number;
  bookmakerId: number;
  title?: string; // in language
  content?: string; // in language
  statusId: CmsPageStatus;
  menu?: IMenuItem;
  options?: ICmsPageOptions;
  titles: Array<ICmsPageLanguage>;
  paths: Array<ICmsPageLanguage>;
  contents?: Array<ICmsPageLanguage>;
  lastVersion?: ICmsPageVersion;
  versions?: Array<ICmsPageVersion>;
  meta?: Array<ICmsPageMeta>
}

export interface ICmsPageMeta {
  name: string;
  property: string;
  content: string;
}

export interface ICmsPageVersion {
  versionId: number;
  author?: IAuthor;
  creationDate: string;
}

export interface ICmsPageOptions {
  layout: ICmsPageOptionsLayout;
  sidebars: ICmsPageOptionsSideBar;
}

export interface ICmsPageOptionsLayout {
  typeId: number;
}

export interface ICmsPageOptionsSideBar {
  position: 'left' | 'right' | 'both'
}

export interface ICmsPageLanguage {
  languageId: number
  value: string
}

export interface ISearchFiltersBase {
  bookmakerId: number;
  p: number;
  pp: number;
}

export interface ICmsPageSearchFilters {
  bookmakerId?: number;
  webAppId: number;
  p: number;
  pp: number;
}

import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@bs/services';

/**
 * this pipe checks if logged user has a permissions for some buttons visibility and actions, renders the button if user has permission 4099 shown on example
 */
@Pipe({
  name: 'buttonPermissions'
})
export class ButtonPermissionsPipe implements PipeTransform { // todo: questa pipe non serve a niente, fa gia tutto permission.pipe

  /**
   * The constructor
   */
  constructor(private authService: AuthService) {

  }

  /**
   * return us if user has permission
   * @param value {any} the user permission value
   */
  transform(value: any): boolean {
    return this.authService.accountLogged$.value.permissions.booleans?.includes(value);
  }

}

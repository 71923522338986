<table [ngClass]="class" class="tree-table">
  <thead>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  </thead>
  <tbody>
    @for (row of tree; track row) {
      <ng-container *ngTemplateOutlet="rowTemplate; context: {row:row, childLevel:0}"></ng-container>
    }
  </tbody>
</table>

<ng-template #rowTemplate let-childLevel="childLevel" let-treeRow="row">
  <ng-container *ngTemplateOutlet="bodyTemplate; context: {row:treeRow, childLevel:childLevel}"></ng-container>
  @if (treeRow.expanded) {
    @for (subrow of treeRow.children; track subrow) {
      <ng-container *ngTemplateOutlet="rowTemplate; context:{row:subrow, childLevel:childLevel+1}">
      </ng-container>
    }
  }
</ng-template>

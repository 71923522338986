import { Pipe, PipeTransform } from '@angular/core';

// todo: apparently used only in krusty
/**
 * this pipe transforms the currency amount to a given string, first the currency symbol then the amount
 */
@Pipe({
  name: 'currencyAmount'
})
export class CurrencyAmountPipe implements PipeTransform {

  /**
   * returns us the currency amount in string
   * @param amount {number} the currency amount value
   * @param currencySymbol {string} the symbol of the currency
   */
  transform(amount: number, currencySymbol: string): string {

    return `${(currencySymbol)} ${(amount / 100).toFixed(2)}`;
  }
}

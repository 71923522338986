import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ISport, ISportSearchFilters, Pager } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable()
export class SportService {

  readonly url = `${this.config.api.sportsbook}/sports`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }

  getAll(betOfferId: number): Observable<Array<ISport>> {
    return this.http.get<Array<ISport>>(`${this.url}`, {params: {betOfferId: betOfferId.toString()}});
    // return of([
    //   {betOfferId: 1, id: 1, name: 'calcio'},
    //   {betOfferId: 1, id: 2, name: 'tennis'},
    //   {betOfferId: 2, id: 3, name: 'pattinaggio su ghiaccio'},
    //   {betOfferId: 2, id: 4, name: 'curling'},
    // ].filter(x => x.betOfferId === betOfferId));
  }

  getOne(id: number): Observable<ISport> {
    return this.http.get<ISport>(`${this.url}/${id}`, {params: {id}});
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  createOrUpdate(data: ISport): Observable<ISport> {
    return data.id ? this.http.put<ISport>(`${this.url}/${data.id}`, data) : this.http.post<ISport>(this.url, data);
  }

  search(filters: ISportSearchFilters): Observable<Pager<ISport>> {
    return this.http.post<Pager<ISport>>(`${this.url}/search`, filters);
  }

}

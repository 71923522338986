import { IdName, idNames } from '../common/id-name';

export enum IOutputModel {
  realBonusSportsBook = 1,
  realBonusCasino = 2,
  freeBetsSportsBook = 8,
  casinoFreeSpins = 10,
  cashBack = 7,
  loyaltyPoints = 11,
  realBonusSportsBookWagering = 4,
  realBonusCasinoWagering = 20,
  realBonusCrashGames = 30,
  realBonusVirtual = 32,
  realBonusCrashGamesWagering = 31,
  cashBackCrashGames = 15,
  cashBackVirtual = 16,
  //bonusRedeemed = 10
}

export namespace OutputModel {
  export function values(): Array<IdName<string>> {
    return idNames(IOutputModel);
  }
}

export enum IOutputStatus {
  created = 1,
  emitted = 2,
  running,
  exhausted = 4,
  expired = 5,
  wagered = 6,
  suspended = 7,
  redeemed = 10,
  forfait = 50,
  cancelled = 51
}

export namespace OutputStatus {
  export function values(): Array<IdName<string>> {
    return idNames(IOutputStatus);
  }
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMenuLanguage } from '@bs/models';

/**
 * The component shows us languages which we can edit
 */
@Component({
  selector: 'language-editor',
  templateUrl: 'language-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageEditorComponent),
      multi: true
    }
  ]
})
export class LanguageEditorComponent implements ControlValueAccessor {
  /**
   * responsible if we can edit a language
   */
  @Input()
  isEdit = true;
  /**
   * local reference of available languages
   */
  @Input()
  availableLangs: Array<any> = [];
  /**
   * local reference of Array<string>
   */
  keys: Array<string>;
  /**
   * local reference of Array<IMenuLanguage>
   */
  languages: Array<IMenuLanguage>;
  /**
   * local reference of Array<IMenuLanguage>
   */
  languages2: Array<IMenuLanguage>;
  /**
   * responsible for disabling the field
   */
  disabled: boolean;
  /**
   * cloning products
   */
  clonedProducts: { [s: string]: IMenuLanguage; } = {};
  /**
   * filter values by keys
   */
  filteredKeys: Array<string>;
  /**
   * options passed by parent
   */
  @Input()
  options: any;

  /**
   * on edit of language value, we propagate the new changes
   * @param values
   */
  update() {
    const isValid = this.languages.every(x => x.text !== '' && x.url !== '')
    this.propagateChange(isValid ? this.languages : null);
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  /**
   * we assign the languages, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: Array<IMenuLanguage>) {
    if (!this.availableLangs) {
      return
    }
    if (obj && obj.length) {
      const allLanguages = this.availableLangs;
      const missingLanguages = allLanguages.filter(lang => !obj.some(menuLang => menuLang.id === lang.id));
      const missingMenuLanguages: IMenuLanguage[] = missingLanguages.map(lang => {
        return {
          id: lang.id,
          code: lang.code,
          name: lang.name,
          text: obj[0].text +` ${lang.name}`,
          //description: `missing description in ${lang.name}`
        };
      });
      obj.push(...missingMenuLanguages);

      this.keys = Object.keys(obj[0]);
      this.filteredKeys = this.keys.filter(k => k !== 'id' && k !== 'name' && k !== 'code');
      this.languages = obj;
      this.languages2 = obj;

      this.languages.forEach(l => {
        const curr = this.availableLangs.find(a => a.id === l.id);
        if (curr) {
          [l.name, l.code] = [curr.name, curr.code];
        }
      });
    }
  }

  /**
   * function switches table cell template, for editing clicked language
   * @param language
   */
  onRowEditInit(language: IMenuLanguage) {
    this.clonedProducts[language.id] = {...language};
  }

  /**
   * function saves language changes
   * @param language
   */
  onRowEditSave(language: IMenuLanguage) {
    delete this.clonedProducts[language.id];
    this.update();
  }

  /**
   * function closes the edition of language
   * @param product
   * @param index
   */
  onRowEditCancel(product: IMenuLanguage, index: number) {
    this.languages2[index] = this.clonedProducts[product.id];
    delete this.clonedProducts[product.id];
  }

  /**
   * function copies the first value of the array
   * @param key
   */
  copy(key) {
    const val = this.languages[0][key];
    this.languages.forEach(l => l[key] = val);
    this.update();
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<IMenuLanguage>) {
  }
}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "type": "password",
      "icon": "key",
      "label": "newPassword",
      "name": "newPassword",
      "hasStrength": true,
      "validations": {
        "required": true,
        "minLength": 6
      }
    },
    {
      "type": "password",
      "icon": "key",
      "label": "confirmPassword",
      "name": "confirmPassword",
      "hasStrength": false,
      "validations": {
        "required": true,
        "matchPassword": true,
        "minLength": 6
      }
    }
  ]
}

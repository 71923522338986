<div class="grid p-fluid">
  <div class="col-12" [class.md:col-6]="additionalDataTypeSelected">
    <p-dropdown [options]="values" [(ngModel)]="additionalDataTypeSelected"
      [placeholder]="'select' | translate"
      (ngModelChange)="update({additionalDataTypeId: additionalDataTypeSelected})"
    optionLabel="name" optionValue="id" [showClear]="true"></p-dropdown>
  </div>
  @if (additionalDataTypeSelected) {
    <div class="col-12 md:col-6">
      <input [(ngModel)]="additionalDataType"
        [placeholder]="'typeValue'"
        (change)="update({additionalDataTypeId: additionalDataTypeSelected, additionalDataType: additionalDataType})"
        type="text" pInputText>
      </div>
    }
  </div>

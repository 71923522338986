<!-- <pre>{{ model | json }}</pre> -->

<p-table [value]="model" dataKey="currencyId" editMode="row">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 35%">{{'currency-code' | translate}}</th>
      <th style="width: 30%">{{'min-amount' | translate}}</th>
      <th style="width: 30%">{{'max-amount' | translate}}</th>
      <th style="width: 5%">{{'action' | translate}}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-editing="editing" let-ri="rowIndex" let-rowData editMode="row">
    <tr [pEditableRow]="rowData">
      <td>{{ rowData.name }}</td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div class="p-inputgroup">
              <p-inputNumber [ngModel]="getAmount('min', ri)" (ngModelChange)="setAmount('min', ri, $event)" [currency]="rowData.currencyId | currencyAttributes: 'code'" mode="decimal" [minFractionDigits]="2" [min]="0" [max]="getAmount('max', ri)"></p-inputNumber>
              <span class="p-inputgroup-addon">{{ rowData.currencyId | currencyAttributes: 'code' }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.min | currencyAmount: rowData.symbol }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div class="p-inputgroup">
              <p-inputNumber [ngModel]="getAmount('max', ri)" (ngModelChange)="setAmount('max', ri, $event)" [currency]="rowData.currencyId | currencyAttributes: 'code'" mode="decimal" [minFractionDigits]="2" [min]="getAmount('min', ri)"></p-inputNumber>
              <span class="p-inputgroup-addon">{{ rowData.currencyId | currencyAttributes: 'code' }}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            {{ rowData.max | currencyAmount: rowData.symbol }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          @if (!editing) {
            <button pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="onRowEditInit(ri)"></button>
          }
          @if (editing) {
            <button pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" class="p-button-rounded p-button-text p-button-success mr-2" (click)="onRowEditSave(ri)"></button>
          }
          @if (editing) {
            <button pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" class="p-button-rounded p-button-text p-button-danger" (click)="onRowEditCancel(ri)"></button>
          }
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseMobilePrefix } from '@bs/forms';
import { InputMask } from 'primeng/inputmask';

/**
 * The component contains the prefix of country, and the input field that let us enter a phone number
 */
@Component({
  selector: 'mobile-prefix',
  styleUrls: ['./mobile-prefix.component.scss'],
  templateUrl: './mobile-prefix.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobilePrefixComponent),
      multi: true
    }
  ]
})
export class MobilePrefixComponent extends BaseMobilePrefix {
  /**
   * getting reference of the input field of mobile number
   */
  @ViewChild(InputMask)
  mobilePhone: any;

  /**
   * function focuses the inputMask field
   */
  focusNext() {
    this.mobilePhone.focus();
  }

}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, ISlider, ISliderConfig } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlidersService {

  readonly url = `${this.config.api.cms}/sliders`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  all(): Observable<ISlider[]> {
    return this.http.get<Array<ISlider>>(`${this.url}`);
  }

  put(sliderId: number, value: { banners: Array<number>, config: ISliderConfig }) {
    return this.http.put(`${this.url}/${sliderId}`, value);
  }
}

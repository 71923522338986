<!--<pre>{{form.value|json}}</pre>-->
<form [formGroup]="form" class="grid">
  <ng-container formArrayName="days">
    @for (item of days.controls; track item; let i = $index) {
      <div class="col day p-panel" [formGroup]="item">
        <div class="head p-panel-header">
          <span class="p-panel-title flex-grow-1">{{item.get('day').value.label}}</span>
          <input name="active" (ngModelChange)="update()" (click)="blur.emit($event)" formControlName="active" class="flex-order-2 w-auto" type="checkbox">
        </div>
        @if (item.value.active) {
          <div class="p-panel-content p-2">
            <p-button [disabled]="!item.value.active" (click)="addSlot(i)">{{'add' | translate}}</p-button>
            <ng-container formArrayName="timeSlots">
              @for (slot of getTimeSlots(i).controls; track slot; let j = $index) {
                <div class="mt-3">
                  <div class="flex align-items-center" [formGroup]="slot">
                    <p-calendar (ngModelChange)="update()" (blur)="blur.emit($event)" formControlName="from" [stepMinute]="1" [timeOnly]="true" class="m-0" dataType="string" inputId="timeonly"></p-calendar>
                    <p-calendar (ngModelChange)="update()" (blur)="blur.emit($event)" formControlName="to" [stepMinute]="1" [timeOnly]="true" class="ml-1" dataType="string" inputId="timeonly"></p-calendar>
                    <p-button icon="pi pi-trash" (click)="deleteSlot(i,j)" styleClass="p-0 p-button-text"></p-button>
                  </div>
                  <field-error [control]="slot"></field-error>
                </div>
              }
              <field-error [control]="getTimeSlots(i)"></field-error>
            </ng-container>
            <form-errors [form]="form"></form-errors>
          </div>
        }
      </div>
    }
  </ng-container>


</form>

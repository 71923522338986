<html>
  <head>
    <meta charset="utf-8">
    <title>{{ticket.code}}</title>
  </head>
  <div id="printable" style="width:80mm">

    <img src="{{host}}/assets/images/logo-black.svg" alt="logo" width="40%" style="display: block; margin: auto; padding-top: 10px;">
    <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
      <strong>{{'ticket-cash-payment-receipt' | translate}}</strong>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
      <strong>{{'cashier' | translate}}:</strong>
      <span>{{ticket.username }}</span>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
      <strong>{{'date-issued' | translate}}:</strong>
      <span>{{ticket.issueDate | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</span>
    </div>

    <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
      <strong>{{'amount-paid' | translate}}:</strong>
      <span>{{ticket.payment.totalAmountPayable | displayCurrencyConversion: ticket.payment.rate }}</span>
    </div>

    @if (ticket.payment.winAmount) {
      <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
        <strong>{{'amount-win' | translate}}:</strong>
        <span>{{ticket.payment.winAmount | displayCurrencyConversion: ticket.payment.rate}}</span>
      </div>
    }

    @if (ticket.payment.winTaxAmount) {
      <div style="display: flex; justify-content: space-between; margin-top: 10px; padding: 0 18px;">
        <strong>{{'amount-win-tax' | translate}}:</strong>
        <span>{{ticket.payment.winTaxAmount | displayCurrencyConversion: ticket.payment.rate }}</span>
      </div>
    }

    <div style="justify-content: space-between; margin-top: 10px; padding: 0 18px;">
      <strong>{{'cash-movements' | translate}}</strong>
      @for (drawerMovement of ticket.drawerMovements; track drawerMovement) {
        <table>
          <tbody>
            <tr>
              <td><span translate>causal</span>: {{drawerMovement.causal}}</td>
            </tr>
            <tr>
              <td><span translate>date</span>: {{drawerMovement.date | localizedDate:'dd/MM/yyyy HH:mm:ss' }}</td>
            </tr>
          </tbody>
        </table>
      }
    </div>

    <div style="display: flex; flex-direction: column; align-items: center; margin-top: 20px;">
      @if (isReprint) {
        <h3>{{'ticket-cash-payment-reprint-disclaimer' | translate}}</h3>
      }

      @if (config.features.betSlip?.showQrCode) {
        <qrcode [qrdata]=qrUrl [allowEmptyString]="false" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
      }

      @if (config.features.betSlip?.showBarCode) {
        <ngx-barcode6 [bc-value]="ticket.code" [bc-width]="1" [bc-height]="70" [bc-font-size]="12" [bc-display-value]="true"></ngx-barcode6>
      }
    </div>

    @if (!isReprint) {
      <div style="margin: 50px 40px 0; font-size: 12px; color: #2B2B2B">
        <hr class="border: 1px solid #C0C0C0;">
        {{'ticket-cash-payment-receipt-signature' | translate}}
      </div>
    }
  </div>
</html>

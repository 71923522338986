import { IdName, idNames } from '../common/id-name';

export interface ICreateAutoExclusionRequest {
  typeId: number;
  lengthId?: number;
}

export interface AutoExclusionResponse {
  id: number;
  typeId: number
  lenghtId: number;
  creationDate: Date;
  endDate: Date;
  closingDate: Date;
}

export interface ISelfLimitation {
  type: selfLimitationType;
  lenghtId: LimitationLength;
  value: number;
  // currency: ICurrency;
  creationDate: Date; // data di creazione del limite
  closingDate?: Date; // data di chiusura del limite, può essere null quindi significa che è attivo
}

export type selfLimitationType = 'deposit' | 'wagering' | 'loss';

export interface IResponsibleGamingConfig {
  selfExclusion: boolean;
  selfLimitation: Array<selfLimitationType>;
  realityChecker: boolean;
}

export enum LimitationLength {
  daily = 1,
  weekly,
  monthly
}

export namespace LimitationLength {
  export function values(): Array<IdName<string>> {
    return idNames(LimitationLength);
  }
}

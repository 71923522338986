import { Component, EventEmitter, forwardRef, Input, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { isPresent } from '@bs/forms';

function timeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const {from, to} = control.value;
    const fromTime = new Date(`1970-01-01T${from}:00Z`);
    const toTime = new Date(`1970-01-01T${to}:59Z`);

    if (fromTime >= toTime) {
      return {'error-time-invalid': {from: fromTime, to: toTime}};
    }
    return null;
  }
}

const convertToSeconds = (timeString, end = 0) => {
  const [hours, minutes] = timeString.split(':');
  return (parseInt(hours) * 60 * 60) + (parseInt(minutes) * 60) + end;
};

function overlapValidator(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const timeSlots = control.value;
    if (timeSlots.length > 1) {

      const minutesArray = timeSlots.map(({from, to}) => ({
        from: convertToSeconds(from),
        to: convertToSeconds(to, 59)
      }));
      for (let i = 0; i < minutesArray.length; i++) {
        for (let j = i + 1; j < minutesArray.length; j++) {
          const a = minutesArray[i];
          const b = minutesArray[j];
          if (a.from < b.to && b.from < a.to) {
            return {'error-time-overlap': true};
          }
        }
      }
      return null;
    }
  }
}

/*interface WeekSlot {
  day: AbstractControl<{ index: number, label: string }>;
  active: AbstractControl<boolean>;
  timeSlots: AbstractControl<Array<{ from: string, to: string }>>;
}*/

interface ModelSlot {
  day: number;
  timeSlots: Array<{ from: string, to: string }>;
}

@Component({
  selector: 'weekly-slots',
  templateUrl: './weekly-slots.component.html',
  styleUrls: ['./weekly-slots.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WeeklySlotsComponent),
      multi: true
    }
  ],
})
export class WeeklySlotsComponent implements ControlValueAccessor {

  /**
   * responsible for disabling a field
   */
  isDisabled: boolean;
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output()
  blur = new EventEmitter<Event>(null);

  @Input()
  options: any;
  form: FormGroup;


  constructor(private route: ActivatedRoute, private fb: FormBuilder) {
    route.root.firstChild.paramMap.subscribe({
      next: paramMap => {
        const lang = paramMap.get('lang');
        const dateFormatter = new Intl.DateTimeFormat(lang, {weekday: 'long'});

        this.form = fb.group({days: fb.array([])});

        Array.from({length: 7}, (_, day) => {
          const date = new Date(Date.UTC(2023, 0, 2 + day));
          const item = fb.group({
            day: {index: day, label: dateFormatter.format(date)},
            active: false,
            timeSlots: fb.array([], {validators: overlapValidator()})
          });
          this.days.push(item);
        });
      }
    })

  }

  get days(): FormArray {
    return this.form.get('days') as FormArray;
  }

  getTimeSlots(i: number): FormArray {
    return this.days.at(i).get('timeSlots') as FormArray;
  }

  writeValue(obj: Array<ModelSlot>) {
    if (obj) {
      obj.forEach(ms => {
        const day = ms.day - 1;
        const dayCtrl = this.days.at(day);
        dayCtrl.get('active').setValue(true);
        ms.timeSlots?.forEach(ts => {
          const tsCtrl = this.getTimeSlots(day);
          const item = this.fb.group({from: [ts.from], to: [ts.to],}, {validators: timeValidator()});
          tsCtrl.push(item);
        })
      })
      this.update();
    }
  }

  /**
   * emits the blur event to the parent element, and it invokes registerOnTouched method
   * @param event
   */
  onBlur(event: Event) {
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  addSlot(i: number) {
    const item = this.fb.group({
      from: ['00:00'],
      to: ['00:00'],
    }, {validators: timeValidator()})
    const slot = this.getTimeSlots(i)
    slot.push(item);
    this.update();
  }

  update() {
    const update = this.form.value.days.filter(x => x.active).map(d => {
      const mod = {day: d.day.index + 1};
      if (d.timeSlots.length) {
        Object.assign(mod, {timeSlots: d.timeSlots});
      }
      return mod;
    });
    this.propagateChange(update);
  }

  deleteSlot(i: number, j: number) {
    const slot = this.getTimeSlots(i);
    slot.removeAt(j);
    this.update();
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: Array<ModelSlot>) {
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, ICmsPageLanguage, IGame, IGameSearchRequest, IGameType, Pager } from '@bs/models';
import { Observable } from 'rxjs';
import { CrudService } from '../base/crud.service';

@Injectable({
  providedIn: 'root'
})
export class GamesManagementService implements CrudService<IGame>{

  readonly url = `${this.config.api.games}/games`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  search(filters: IGameSearchRequest): Observable<Pager<IGame>> {
    return this.http.post<Pager<IGame>>(`${this.url}/search`, filters);
  }

  getGameType(gameTypeId: number, bookmakerId: number): Observable<IGameType> {
    const params: Params = {bId: bookmakerId};
    return this.http.get<IGameType>(`${this.url}/gameTypes/${gameTypeId}`, {params});
  }

  patchGameLanguage(id: number, fieldId: number, languages: Array<ICmsPageLanguage>): Observable<any> {
    return this.http.patch(`${this.url}/${id}/languages`, {fieldId: fieldId, languages: languages});
  }

  patchGameThumbnail(id: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    //append any other key here if required
    return this.http.post(`${this.url}/${id}/thumbnail`, formData);
  }

  sorting(gameId: number, deviceTypeId: number, gameSubTypeId: number, position: number, countryId?: number) {
    const params: Params = {deviceTypeId, position, gameSubTypeId};
    if (countryId) {
      params['countryId'] = countryId;
    }
    return this.http.patch(`${this.url}/${gameId}/sorting`, params);
  }

  delete(item: Partial<IGame>): Observable<IGame> {
    return undefined;
  }

  get(item: Partial<IGame>): Observable<IGame> {
    const params: Params = {bId: item.bookmakerId};
    return this.http.get<IGame>(`${this.url}/${item.id}/details`, {params});
  }

  patch(id: number, item: Partial<IGame>): Observable<IGame> {
    return this.http.patch<IGame>(`${this.url}/${id}`, item);
  }

  post(item: Partial<IGame>): Observable<Partial<IGame>> {
    return undefined;
  }

}

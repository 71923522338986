import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BettingGroups } from '@bs/models';

type OfferType = 'sport' | 'tournament' | 'match';

@Component({
  selector: 'sportsbook-offer',
  templateUrl: './sportsbook-offer.component.html',
  styleUrls: ['./sportsbook-offer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SportsbookOfferComponent),
      multi: true,
    },
  ],
})
export class SportsbookOfferComponent implements OnInit, ControlValueAccessor {
  tab: OfferType | 'all' = 'all';
  form = this.fb.group({
    sportOffer: this.fb.array([]),
    tournamentOffer: this.fb.array([]),
    matchOffer: this.fb.array([]),
  });

  offers = BettingGroups.values();
  sports = BettingGroups.values();
  markets = BettingGroups.values();
  selections = BettingGroups.values();
  matches = BettingGroups.values();
  outcomes = [
    { name: 'won', id: 1 },
    { name: 'lost', id: 2 },
    { name: 'both', id: 3 },
  ];

  @Output() blur = new EventEmitter<any>(null);

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  addOffer(type: OfferType) {
    if (type === 'sport') {
      this.getOffer('sport').push(
        this.fb.group({
          betOfferId: [],
          sportId: [],
          marketSportId: [],
          marketSelectionId: [],
        })
      );
    } else if (type === 'tournament') {
      this.getOffer('tournament').push(
        this.fb.group({
          betOfferId: [],
          tournamentId: [],
          marketSportId: [],
          marketSelectionId: [],
        })
      );
    } else if (type === 'match') {
      this.getOffer('match').push(
        this.fb.group({
          betOfferId: [],
          matchId: [],
          marketSportId: [],
          marketSelectionId: [],
          outcome: [3],
        })
      );
    }
  }

  removeOffer(type: OfferType, index: number) {
    this.getOffer(type).removeAt(index);
  }

  getOffer(type: OfferType) {
    if (type === 'sport') {
      return this.form.get('sportOffer') as FormArray;
    } else if (type === 'tournament') {
      return this.form.get('tournamentOffer') as FormArray;
    } else if (type === 'match') {
      return this.form.get('matchOffer') as FormArray;
    }
  }

  get matchOutcome() {
    return this.form.get('matchOutcome');
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  onTouched() {}

  /**
   * propagate the blur, and update the form model on blur
   * @param event
   */
  onBlur(event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * we assign a value to the model, when programmatic changes from model to view are requested
   * @param obj
   */
  writeValue(obj: any) {
    if (obj) {
      this.form.patchValue(obj, { emitEvent: false });
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe({
      next: fn
    });
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: string) {}
}

<div class="grid p-fluid">
  <div class="col-12 md:col-6">
    <label>{{'bookmakers' | translate}}</label>
    <p-dropdown [options]="bookmakers" [(ngModel)]="bookmaker" (ngModelChange)="update($event.id)"
                [placeholder]="'select' | translate" optionLabel="name"
                [filterBy]="'name'" [filter]="true"></p-dropdown>
  </div>
  <div class="col-12 md:col-6">
    <label>{{'webApp' | translate}}</label>
    <p-dropdown [options]="bookmaker.webApps" [(ngModel)]="webApp" (ngModelChange)="update(bookmaker.id, $event?.id)"
                [placeholder]="'select' | translate" optionLabel="name"
                [filterBy]="'name'" [filter]="true"></p-dropdown>
  </div>
</div>

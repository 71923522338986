import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IPaymentMethod } from '@bs/models';
import { Observable } from 'rxjs';
import { CatalogService } from '../core/catalog.service';

@Injectable()
export class WalletPaymentMethodsService {
  readonly url = `${this.config.api.accounts}/wallets/payment-methods`;

  constructor(private config: EnvironmentConfig, private catalogService: CatalogService, private http: HttpClient) {
  }

  all(isDeposit: boolean, bookmakerId = this.config.bookmakerId, currencyId = this.catalogService.currentCurrency.id): Observable<Array<IPaymentMethod>> {
    return this.http.get<Array<IPaymentMethod>>(`${this.url}`, {params: {c: currencyId.toString(), b: bookmakerId.toString(), d: isDeposit.toString()}});
  }

  get(id: number): Observable<IPaymentMethod> {
    return this.http.get<IPaymentMethod>(`${this.url}/${id}`);
  }

  patchCountries(item: IPaymentMethod): Observable<IPaymentMethod> {
    return this.http.patch<IPaymentMethod>(`${this.url}/${item.id}/countries`, item);
  }

  patchEnabled(item: IPaymentMethod, isEnabled: boolean): Observable<IPaymentMethod> {
    return this.http.patch<IPaymentMethod>(`${this.url}/${item.id}/enabled`, {isEnabled: isEnabled});
  }
}

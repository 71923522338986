import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AccountTypeId, AppSettings, CheckToken, EnvironmentConfig, ILoginCredentials, ILoginResponse, IMe, Tfa, TfaResponse, TfaType } from '@bs/models';
import { SessionStorage, WINDOW } from '@bs/universal';
import { BehaviorSubject, interval, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettingsService } from './app-settings.service';
import { CatalogService } from './catalog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  readonly url = this.config.api.identity;

  accountLogged$ = new BehaviorSubject<IMe>(null);
  settings: AppSettings;
  private _checkToken: Subscription;

  constructor(private config: EnvironmentConfig, private sessionStorage: SessionStorage, @Inject(WINDOW) private window: any,
              private http: HttpClient, private router: Router, private catalog: CatalogService, private settingsService: AppSettingsService) {
    settingsService.appSettings$.subscribe({
      next: ({settings}) => this.settings = settings
    });

    this.accountLogged$.subscribe({
      next: me => me ? this._checkToken = this.checkToken(60000) : this._checkToken?.unsubscribe()
    });
  }

  get token(): string | null {
    const _token = this.sessionStorage.getItem('token');
    return _token || null;
  }

  set token(token: string | null) {
    this.sessionStorage.setItem('token', token);
  }

  get realityCheck(): number | null {
    const check = this.sessionStorage.getItem('realityCheck');
    return Number(check) ?? null;
  }

  set realityCheck(check: number | null) {
    if (check) {
      this.sessionStorage.setItem('realityCheck', check.toString());
    }
  }

  isLogged(): boolean {
    if (this.accountLogged$.getValue()) {
      return true;
    }

    const sessionId = this.token;
    if (!sessionId) {
      return false;
    }

    this.getAuthentication().subscribe({
      next: u => {
        if (u.passwordExpired) {
          return this.securityRedir();
        }

        if (this.config.features.documentNeeded && !u.documents?.length) {
          return this.documentsRedir();
        }
      }
    });

    return false;
  }

  reset() {
    if (this.config.externalScripts?.some(x => x.name === 'ze-snippet')) {
      this.window['zE']('webWidget', 'logout');
    }
    this.sessionStorage.removeItem('token');
  }

  login(credentials: ILoginCredentials): Observable<ILoginResponse> {

    return this.http.post<ILoginResponse>(`${this.url}/auth/login`, credentials).pipe(
      tap(value => {
        if (value.token) {
          this.token = value.token;
          this.catalog.currentCurrency = value.me.currencies[0];
          this.accountLogged$.next(value.me);

          if (value.me.typeId != AccountTypeId.Operator) {
            this.settingsService.saveSetting('displayCurrency', value.me.currencies[0]);
          }

          if (value.me.passwordExpired) {
            return this.securityRedir();
          }

          if (this.config.features.documentNeeded && !value.me.documents?.length) {
            return this.documentsRedir();
          }

          if (value.me.typeId != AccountTypeId.Operator) {
            if (this.config.features.responsibleGaming.realityChecker) {
              this.realityCheck = new Date().getTime();
            }
          }

          if (this.config.features.showNoBalanceDialog && value.me.wallets[0].balance.available < 100) {
            this.router.navigate([], {fragment: 'zeroBalance'})
          }
        }

      })
    );
  }

  loginToken(token: string): Promise<ILoginResponse> {
    return this.http.post<ILoginResponse>(`${this.url}/auth/login-token`, {token}).pipe(
      tap(loginResponse => {
        this.token = loginResponse.token;
        this.accountLogged$.next(loginResponse.me);
        this.settingsService.saveSetting('displayCurrency', loginResponse.me.currencies[0]);
        if (this.config.features.showNoBalanceDialog && loginResponse.me.wallets[0].balance.available < 100) {
          this.router.navigate([], {fragment: 'zeroBalance'})
        }
      }),
      catchError(error => {
        this.reset();
        this.accountLogged$.next(null);
        // this.snackBar.open(this.translate.instant(error.message), this.translate.instant('close'), {panelClass: 'error'});
        return throwError(error);
      })).toPromise();
  }

  refreshMe(partial: Partial<IMe>) {
    const me = this.accountLogged$.getValue();
    const refresh = Object.assign(me, partial);
    this.accountLogged$.next(refresh);
  }

  delete(): Observable<{date:string}> {
    //this.progressService.toggleSpinner('global', true);
    return this.http.delete<{date:string}>(`${this.url}/auth`).pipe(
      tap(() => this.logout()),
      //finalize(() => this.progressService.toggleSpinner('global', false))
    );
  }

  logout(reason?: string, redir = this.config.homePage) {

    if (reason && reason === 'forced') {
      // this.snackBar.open(this.translate.instant(message), this.translate.instant('close'), {panelClass: 'error'});
    }

    if (this.accountLogged$.getValue().typeId != AccountTypeId.Operator) {
      if (this.config.features?.responsibleGaming?.realityChecker) {
        this.sessionStorage.removeItem('realityCheck');
      }
    }

    this._checkToken?.unsubscribe();

    this.reset();

    this.accountLogged$.next(null);

    void this.router.navigateByUrl(`${this.settings.languageCode}/${redir}`);
  }

  isValid(): boolean {
    if (!this.token) {
      return false;
    }

    const tokenData = JSON.parse(this.window.atob(this.token.split('.')[1]));
    const unixTs = Math.floor(new Date().getTime() / 1000);
    return unixTs < tokenData.exp;
  }

  getAuthentication(): Observable<IMe> {

    if (this.accountLogged$.getValue()) {
      return of(this.accountLogged$.getValue());
    }

    return this.http.get<IMe>(`${this.url}/me`).pipe(
      tap(me => {
        this.catalog.currentCurrency = me.currencies[0]; // in order to set the defaultCurrency when the browser is refreshed
        this.accountLogged$.next(me);
      }),
      catchError(err => {
        this.accountLogged$.next(null);
        this.logout();
        return throwError(err);
      })
    );
  }

  generateTfaCode(typeId: TfaType): Observable<TfaResponse> {
    return this.http.post<TfaResponse>(`${this.url}/auth/2fa/generate`, {typeId});
  }

  disableTfaCode(params: Params): Observable<Tfa> {
    return this.http.post<Tfa>(`${this.url}/auth/2fa/disable`, params);
  }

  activateTfaCode(params: Params) {
    return this.http.post(`${this.url}/auth/2fa/activate`, params);
  }

  private securityRedir() {
    void this.router.navigate([`/${this.settings.languageCode}/me/overview/security`]);
  }

  private documentsRedir() {
    void this.router.navigate([`/${this.settings.languageCode}/me/overview/documents`]);
  }

  private checkToken(period: number) {
    return interval(period).subscribe({
      next: () => {
        this.http.get<CheckToken>(`${this.url}/auth/check-token`).subscribe({
          next: data => {
            // const now = Math.floor(Date.now() / 1000); // current epoch time in seconds
            // const lastUpdate = Math.floor(new Date(data.lastUpdate).getTime()/1000) // epoch time in seconds
            if (data.isClosed) {
              this.logout('auto_logout', 'auto-logout');
            }
          },

          error: () => this._checkToken.unsubscribe()
        });
      }
    });
  }
}

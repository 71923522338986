<form (ngSubmit)="preSubmit()" [formGroup]="form" [ngClass]="{'p-fluid': !vertical, 'flex-column':vertical || config.fieldset}" class="grid">
  <!--  <pre class="floating">{{form.value | json}}</pre>-->
  <!--  DEBUG FORM-->
  <ng-content select=".overlay"></ng-content>
  <ng-template #fieldsetTpl let-fieldset>
    @for (set of fieldset; track set) {
      <p-fieldset [collapsed]="set.collapsed" [legend]="set.legend | translate" [toggleable]="set.toggleable">
        <ng-template [ngTemplateOutletContext]="{$implicit: set.inputs}" [ngTemplateOutlet]="fieldTpl"></ng-template>
      </p-fieldset>
    }
  </ng-template>

  <ng-template #fieldTpl let-inputs>
    @for (input of (inputs | hasPermission); track input) {
      @if (!input.empty) {
        <field
          [input]="input"
          [values]="values | formValues:input"
          [formControlName]="input.name"
          [class.md:col-3]="!vertical && !input.rowspan"
          [class.md:col-4]="!vertical && input.rowspan === 2"
          [class.md:col-6]="!vertical && input.rowspan === 3"
          [ngClass]="{'col-12': !vertical, 'grid col flex-column': vertical }" class="p-field">
          @if (input.icon) {
            <span class="p-inputgroup-addon pre">
              <i class="pi pi-{{input.icon}}"></i>
            </span>
          }
        </field>
      }
      @if (input.empty) {
        <div [ngClass]="{'col-12 md:col-3': !vertical, 'grid col flex-column': vertical }"
          [class.col-md-3]="!vertical && !input.rowspan"
          [class.col-md-4]="!vertical && input.rowspan === 2"
          [class.col-md-6]="!vertical && input.rowspan === 3"
        class="empty"></div>
      }
    }

  </ng-template>

  <ng-template [ngTemplateOutletContext]="{$implicit: config.fieldset ? config.fieldset : config.inputs}" [ngTemplateOutlet]="config.fieldset ? fieldsetTpl : fieldTpl"></ng-template>
  @if (!disabled) {
    <ng-content select=".button-container"></ng-content>
  }
  <form-errors [form]="form"></form-errors>

</form>

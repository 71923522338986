import { IdName, idNames } from '../common/id-name';
import { OfferTypes } from './betoffer';
import { ICategory } from './category';
import { ISearchBaseSportsBookFilters } from './common';
import { ICompetitor } from './competitor';
import { IScore } from './score';
import { ISport } from './sport';
import { ISportEventLimit } from './sportEventLimit';
import { ITournament } from './tournament';

export enum EventStatus {
  NOT_STARTED_YET = 1,
  IN_PROGRESS = 2,
  INTERRUPTED = 3,
  FINISHED = 4,
  ABANDONED = 5,
  POSTPONED = 6,
  RESULTED = 7,
}

export namespace EventStatus {
  export function values(): Array<IdName<string>> {
    return idNames(EventStatus);
  }
}

export enum BettingStatus {
  open = 1,
  closed = 2,
  suspended = 3
}

export namespace BettingStatus {
  export function values(): Array<IdName<string>> {
    return idNames(BettingStatus);
  }
}

export enum EventTypes {
  match = 1,
  outright = 4,
}

export namespace EventTypes {
  export function values(): Array<IdName<string>> {
    return idNames(EventTypes);
  }
}

export interface ISportEvent {
  id: number;
  eventId?: number;
  name: string;
  typeId: EventTypes; // matchs , outright
  offerTypeId: OfferTypes; // prematch , live o both
  startDate: string;
  statusId: EventStatus; // stato
  bettingStatusId: BettingStatus;
  lastUpdate: string;
  sport: ISport;
  pubCode?: number;
  category?: ICategory;
  tournament?: ITournament;
  competitors?: Partial<Array<ICompetitor>>;
  scores?: IScore[];
  brId?: number;
  marketSports: Array<number>;
  // marketsSport: Array<IMarketSport>;
  limits?: Array<ISportEventLimit>;
  // lista selezioni
  selections: Array<ISportEventSelection>;
  tags: Array<number>;
}

export interface ISportEventSelection {

  id: number;
  marketSportId: number;
  market?: string;
  outcomeId: number;
  outcome?: string;
  fastBetCode: string;
  specifierId?: number;
  specifier?: string;
  competitorId?: number;
  competitor?: string;
  oddId: number;
  oddValue: number;
  oddNewValue: number;
  bettingStatusId: number;
  playability: number;
}

export interface ISportEventSearchFilters extends ISearchBaseSportsBookFilters {
  eventTypeId: EventTypes;
  sportId?: number;
  categoryId?: number;
  tournamentId?: number;
}


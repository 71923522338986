import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from './is-present.validator';

/**
 * validator for a email format with email regex check for the control
 * @param control
 */
export const mailFormat: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;
  /* tslint:disable */
  const EMAIL_REGEXP = /^\w+([+.-]?\w)*@([+.-]?\w{2,})+\.\w{2,}$/;
  /* tslint:enable */

  return EMAIL_REGEXP.test(v) ? null : {'email-format': true};
};

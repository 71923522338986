import { IdName, idNames } from '../common/id-name';

export enum IBetSlipType {
  Single = 1,
  Multiple = 2,
  System = 3,
  Integral = 4,
  //Jackpot = 5
}

export namespace IBetSlipType {
  export function values(): Array<IdName<any>> {
    return idNames(IBetSlipType);
  }
}

export enum IBetSlipGroup {
  Prematch = 1,
  Live,
  Mixed
}

export namespace BetSlipGroup {
  export function values(): Array<IdName<string>> {
    return idNames(IBetSlipGroup);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { EnvironmentConfig, IBetOffer, IFeedMarket, IFeedTournament, Pager } from '@bs/models';

import { Observable, of } from 'rxjs';

@Injectable()
export class BetOfferMappingService {
  private markets: IFeedMarket[] = [
    {
      id: 1,
      name: 'Innings 1 to 5th top - {$competitor1} total',
      feedProvider: {
        id: 1,
        name: 'FeedProvider 1'
      },
      externalKey: '282',
      acquiringDate: '2022-01-01',
      isMapped: true,
      isHidden: false
    },
    {
      id: 2,
      name: 'FeedMarket 2',
      feedProvider: {
        id: 2,
        name: 'FeedProvider 2'
      },
      externalKey: 'external_key_2',
      acquiringDate: '2022-01-02',
      isMapped: false,
      isHidden: false,
    },
    {
      id: 3,
      name: 'FeedMarket 3',
      feedProvider: {
        id: 3,
        name: 'FeedProvider 3'
      },
      externalKey: 'external_key_3',
      acquiringDate: '2022-01-03',
      isMapped: true,
      isHidden: true,
    },
  ];

  private tournaments: IFeedTournament[] = [];

  readonly url = `${this.config.api.sportsbook}/bet-offers/mapping`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {

  }

  /**
   * get a betoffer by id
   * @param id
   * @param bookmakerId
   */
  get(id: number, bookmakerId: number): Observable<IBetOffer> {
    return this.http.get<IBetOffer>(`${this.url}/${id}`, {params: {id: id, bId: bookmakerId}});
  }

  /**
   * search
   */
  searchTournaments(filters: Params): Observable<Pager<IFeedTournament>> {
    const pager: Pager<IFeedTournament> = {list: this.tournaments, count: this.tournaments.length}
    console.log(pager)
    return of(pager);
    // return this.http.post<Pager<IBetOffer>>(`${this.url}/search`, filters);
  }

  searchMarkets(filters: Params): Observable<Pager<IFeedMarket>> {
    const pager: Pager<IFeedMarket> = {list: this.markets, count: this.markets.length}
    console.log(pager)
    return of(pager);
    // return this.http.post<Pager<IBetOffer>>(`${this.url}/search`, filters);
  }

}

import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings, IMe, ReportData, TreeTableNode } from '@bs/models';
import { AppSettingsService, AuthService, DateFactoryService, ReportsService } from '@bs/services';
import { WindowService } from '@bs/universal';
import { Subscription } from 'rxjs';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({template: ``})
export class BaseTreeTable<T extends ReportData<T>, S> implements OnDestroy {
  me: IMe;
  reports: TreeTableNode<T>[];
  loading = false;
  isMobile: boolean;
  today = new Date();
  subs = new Subscription();
  filters: S | any;
  settings: AppSettings;

  constructor(
    protected reportService: ReportsService,
    protected dfs: DateFactoryService,
    protected route: ActivatedRoute,
    appSettingsService: AppSettingsService,
    authService: AuthService,
    windowService: WindowService) {

    this.subs.add(authService.accountLogged$.subscribe({
      next: data => this.me = data
    }));

    this.resetFilters();

    this.subs.add(windowService.device$.subscribe({
      next: device => this.isMobile = device.isMobile
    }));

    this.subs.add(appSettingsService.appSettings$.subscribe({
      next: ({settings, valueChanged}) => {
        this.settings = settings;
        if (!valueChanged || valueChanged === 'timeZone') {
          this.filters = {from: this.dfs.dateFrom(1), to: this.dfs.dateToday(), accountId: this.me.id, walletTypeId: 1};
        }
      }
    }));
  }

  resetFilters() {
    this.reports = null;
    this.filters = {
      accountId: this.me.id,
      walletTypeId: 1,
      from: this.dfs.dateFrom(1),
      to: this.dfs.dateToday(),
      brandId: null
    };
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  groupAsNodes = (data: T[], report?: any): TreeTableNode<T>[] => {

    return data.map<TreeTableNode<T>>(x => {

      report.initialize(x);

      return {
        value: x,
        expanded: false,
        leaf: x.children.length === 0,
        children: this.groupAsNodes(x.children, report)
      };
    }).map(n => {

      const node = n.value;
      const isLeaf = node.children.length === 0;

      if (!isLeaf) {
        Object.keys(node.net).forEach(key => {
          const subKeys = Object.keys(node[key]);
          if (subKeys.length) {
            subKeys.forEach(subKey => {
              if (!['currencySymbol', 'rate', 'subunit'].includes(subKey)) {
                node.net[key][subKey] = node.children.reduce((acc, c) => acc + c[key][subKey], node[key][subKey]);
              }
            })
          } else {
            node.net[key] = node.children.reduce((acc, c) => acc + c[key], node[key]);
          }

        });

      }

      Object.keys(node.net).forEach(key => node[key] = node.net[key]);

      if (n.children.length >= 500) {
        n.children = [];
      }

      return n;
    });
  }

  toggleExpand(node: TreeTableNode<T>) {
    node.expanded = !node.expanded;
    Object.keys(node.value.line).forEach(key => {
      node.value[key] = node.value[node.expanded ? 'line' : 'net'][key];
    });
  }
}

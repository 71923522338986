{
  "$schema": "../config/registration-schema.json",
  "autoLogin": false,
  "mode": "player-full",
  "steps": [
    {
      "conditions": [
        {
          "repeat": {
            "$eq": "password"
          }
        }
      ],
      "inputs": [
        {
          "name": "username",
          "icon": "account",
          "type": "text",
          "label": "Username",
          "validations": {
            "required": true,
            "minLength": 4,
            "maxLength": 20,
            "pattern": "^[a-zA-Z0-9-_]+$"
          }
        },
        {
          "name": "mobile",
          "prefixName": "prefixId",
          "type": "mobile-prefix",
          "label": "insertPhone",
          "validations": {
            "required": true,
            "mobilePrefixValidator": "^[0-9]{3,15}$"
          }
        },
        {
          "name": "email",
          "icon": "at",
          "type": "email",
          "label": "Email",
          "validations": {
            "required": true
          },
          "asyncValidator": "emailExists"
        },
        {
          "name": "password",
          "icon": "key",
          "type": "password",
          "label": "password",
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20
          }
        },
        {
          "name": "repeat",
          "exclude": true,
          "icon": "key",
          "type": "password",
          "label": "repeatPassword",
          "validations": {
            "required": true,
            "minLength": 6,
            "maxLength": 20,
            "matchPassword": true
          }
        },
        {
          "name": "currencyId",
          "icon": "euro-sign",
          "type": "select",
          "label": "currency",
          "validations": {
            "required": true
          },
          "values:": [],
          "translateValues": false
        },
        {
          "name": "promoCode",
          "icon": "ad",
          "type": "text",
          "label": "promoCode",
          "validations": {
            "minLength": 4,
            "maxLength": 20
          }
        }
      ]
    }
  ]
}

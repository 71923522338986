{
  "$schema": "../schema.json",
  "cols": [
    {
      "field": "catalogKeyId",
      "header": "catalogKeyId",
      "type": "number"
    },
    {
      "field": "source",
      "header": "sourceLanguages",
      "type": "text"
    },
    {
      "field": "target",
      "header": "targetLanguages",
      "type": "input"
    }
  ]
}

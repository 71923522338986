import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettingsService, CrudService } from '@bs/services';
import { Subscription } from 'rxjs';
import { Col, FormInterface, IInput, Pager, Sorting } from '@bs/models';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';

@Component({template: ``})
export class BaseTabs<T extends Partial<{ id: number }>> implements OnDestroy {

  activeTabIndex: number;
  lastTabSelected: number;
  tabs: Array<T> = [];
  tabPending: boolean;
  values: any;

  subs = new Subscription();

  // new Base tabs
  cols!: Array<Col<T>>;
  items!: Pager<T>;
  inputs!: Array<IInput>;
  form!: FormInterface;
  service!: CrudService<T>;
  isEdit = false;
  sorting: Sorting = {field: '', order: 0};

  constructor(protected route: ActivatedRoute, protected messageService: MessageService, public dialogService: DialogService,
              protected translate: TranslateService, protected fb: FormBuilder, appSettingService: AppSettingsService) {

    route.data.subscribe({
      next: data => this.items = data.items
    });

    this.subs.add(route.root.firstChild.firstChild.data.subscribe({
      next: data => {
        const bookmakers = data.bookmakers.map(x => ({id: x.id, name: x.name}));

        this.values = {bookmakers};

        if (appSettingService) {
          this.subs.add(appSettingService.appSettings$.subscribe({
            next: ({settings}) => {
              this.values = {bookmakers, currencyId: settings.bookmaker.currencies};
            }
          }));
        }
      }
    }))
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  intercept(event: { callback: string; data: any }) {
    this[event.callback](event.data);
  }

  tabOnChange($event) {
    this.lastTabSelected = $event.index;
  }

  onCloseTab($event) {
    const tab = this.tabs.splice($event.index - 1, 1);
    this.activeTabIndex--;
    if (!tab[0].id) {
      this.tabPending = null;
    }
    setTimeout(() => this.activeTabIndex = $event.index, 1);
  }

  protected hasNoId = () => (!this.isEdit);

  protected hasId = () => this.isEdit;

  protected openItem(item: T, tpl?: string) {
    this.service.get(item).subscribe(
      {
        next: data => this.openInTab(Object.assign(data, {tpl}))
      }
    );
  }

  protected openInTab(item: T) {
    let index = this.tabs.findIndex(t => t.id === item.id);
    if (index === -1) {
      this.tabs.push(item);
      index = this.tabs.length;
    } else {
      index++;
    }
    setTimeout(() => this.activeTabIndex = index, 1);
  }

}

import { IdName, idNames } from '../common/id-name';

export enum ReportWidgetsEnum {
  GGRbyProduct = 1,
  TurnoverBYProduct = 2
}

export namespace ReportWidgetsEnum {
  export function values(): Array<IdName<string>> {
    return idNames(ReportWidgetsEnum);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { IMenuItem, IMenuStatus } from '@bs/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsService } from '../../services/core/cms.service';

/**
 * returns us the menu of the app bookmakerId
 */
@Injectable({
  providedIn: 'root'
})
export class MenuResolver  {

  private _status: IMenuStatus = {
    side: false,
    section: false,
    main: false
  }
  /**
   * behaviorSubject which is used to control the menu sections in the app
   */
  menuStatus$ = new BehaviorSubject<IMenuStatus>(this._status)

  /**
   * The constructor
   */
  constructor(private cmsService: CmsService) {

  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<IMenuItem> | Promise<IMenuItem> | IMenuItem {
    if (route.data.menuId) {
      return this.cmsService.getMenu(route.data.menuId, 'section');
    }
    return of({items: []}).pipe(tap(value => this.cmsService.sectionMenu$.next(value)));
  }


  set status(status: IMenuStatus) {
    this._status = status;
    this.menuStatus$.next(status);
  }

}

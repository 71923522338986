import { IdName, idNames } from '../common/id-name';

export enum SettlementTypes {
  manual = 1,
  automatic = 2,
}

export namespace SettlementTypes {
  export function values(): Array<IdName<string>> {
    return idNames(SettlementTypes);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IWebAppTranslation, IWebAppTranslationUpdateRequest } from '@bs/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebappTranslationsService {
  readonly url = this.config.api.cms;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(webAppId: number, bookmakerId: number, sourceLanguageId: number, targetLanguageId: number): Observable<Array<IWebAppTranslation>> {
    return this.http.get<Array<IWebAppTranslation>>(`${this.url}/webapps/${webAppId}/translations?bId=${bookmakerId}&sLanguageId=${sourceLanguageId}&tLanguageId=${targetLanguageId}`);
  }

  update(webAppId: number, req: IWebAppTranslationUpdateRequest): Observable<IWebAppTranslation> {
    return this.http.patch<IWebAppTranslation>(`${this.url}/webapps/${webAppId}/translations`, req);
  }

}

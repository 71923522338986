import { ICountry } from '../common/country';
import { IdName, idNames } from '../common/id-name';
import { IAccountBase, IWalletAmount } from './movement';

export enum PaymentTransactionMovementType {
  deposit = 1,
  withdrawal = 2
}
export namespace PaymentTransactionMovementType {
  export function values(): Array<IdName<string>> {
    return idNames(PaymentTransactionMovementType);
  }
}

export enum PaymentTransactionStatus {
  request = 50,
  pending = 55,
  confirmed = 60,
  rejected = 65,
  declined = 70
}

export namespace PaymentTransactionStatus {
  export function values(): Array<IdName<string>> {
    return idNames(PaymentTransactionStatus);
  }
}

export interface IPaymentMethodsType {
  id: number;
  name: string;
}

export interface IPaymentMethod {
  id: number;
  name: string;
  logoPath: string;
  icon?: string;
  externalLink: string;
  groupId: number;
  groupName: string;
  minValue: number;
  maxValue: number;
  percentageFee: number;
  fixedFee: number;
  processingTime: boolean;
  isText: boolean;
  textContent?: string;
  isKycNeeded: boolean;
  openingTypeId: number;
  integrationTypeId: number;
  externalSystem?: IPaymentMethodExternalSystem;
  enabled: boolean;
  bookmakerId: number;
  countries?: Array<IPaymentMethodCountry>;
}

export interface IPaymentMethodCountry extends ICountry {
  isEnabled: boolean;
  orderId: number;
  kycNeeded: boolean;
  iconVisible: string;
}

export interface IPaymentMethodExternalSystem {
  id: number;
  name: string;
}

export interface IPaymentMethodExternalTransaction {
  id?: number
  movementTypeId: number;
  externalSystemId: number
  statusId: number
  paymentMethod: IPaymentMethodExternalSystem;
  account: IAccountBase
  bookmakerId: number
  externalTransactionId: string
  creationDate: string
  amounts: {
    amount: number
    currencyId: number
    currencySymbol: string
    currencyCode: string
    subunit: number
    rate: number
  }
  changeStates: IPaymentMethodExternalTransactionChangeState[]
  tpl?: string;
}

export interface IPaymentMethodExternalTransactionChangeState {
  date: string
  externalTransactionStatusId: number
  payload?: string
}

import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { restrictType } from '@bs/models';
import { Subscription } from 'rxjs';

/**
 * this directive sets restriction to an input field
 */
@Directive({
  selector: 'input[restrict], textarea[restrict], password[restrict]'
})
export class RestrictInputDirective implements OnInit, OnDestroy {
  /**
   * the restriction types array
   */
  @Input('restrict')
  type: Array<restrictType>;
  /**
   * storing previous input value
   */
  private prevString: string;
  /**
   * checking if value has not spaces
   */
  private hasNoSpaces: boolean;
  /**
   * regexs for all the restriction types
   */
  private regexMap = {
    integer: /^[0-9]*$/,
    kenyaPhone: /^[1-9][0-9]*$/,
    float: /^[0-9]*\.?[0-9]*$/,
    words: /([A-z]*\\s)*/,
    letters: /^([a-zA-ZÀ-ȕ']+(\s)?)*$/,
    lowerLetters: /^[a-z]*$/,
    upperLetters: /^[A-Z]*$/,
    username: /^[a-z0-9_\W]*$/,
    lettersAndDigits: /^[a-zA-Z0-9\-_]*$/,
    alphaNum: /^[a-zA-Z0-9]*$/,
    phone: /^(\+)?[0-9]*(\s|\.|-)?[0-9]*$/,
    leadingZero: /^[1-9]+[0-9]*$/,
    noSpaces: /^\S*$/
  };
  /**
   * subscription variable
   */
  private sub: Subscription;

  /**
   * The constructor
   */
  constructor(private ngControl: NgControl) {
  }

  /**
   * sets a restriction to an input, textarea, or password field
   */
  ngOnInit() {
    if (this.type) {
      this.hasNoSpaces = this.type.includes('noSpaces');

      this.sub = this.ngControl.valueChanges.subscribe({
        next: (nextString) => {
          /* Set any value of your custom control */

          // no start with spaces or contains two spaces
          if (/^\s/.test(nextString)) { // && event.data.length === 1
            return this.ngControl.control.setValue(this.prevString);
          }

          if (nextString === '' || this.hasNoSpaces && nextString === ' ') return true;

          this.type.forEach((element) => {

            if (element.includes(':')) {
              const capture = /([\w]+):{([\w]+):([\w]+)}/;

              const rules = element.match(capture);

              // reassignment
              element = rules[1] as restrictType;

              switch (rules[2]) {
                case 'length':
                  if (nextString.length > parseInt(rules[3], 10)) {
                    return this.ngControl.control.setValue(this.prevString);
                  }
                  break;
              }

            }

            if (!this.regexMap[element].test(nextString)) {
              if (element === 'username') {
                return this.ngControl.control.setValue(nextString.toLowerCase());
              }
              if (this.prevString) {
                this.ngControl.control.setValue(this.prevString);
              } else {
                this.ngControl.control.setValue('');
              }
            }
          });

        }
      });
    }
  }

  /**
   * it prevents the space button to add space to the input
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    if (this.type?.includes('noSpaces') && event.code === 'Space') {
      event.preventDefault();
    }
    this.prevString = event.currentTarget.value;
  }

  /**
   * unsubscribes on component destroyed
   */
  ngOnDestroy() {
    if (this.type) {
      this.sub.unsubscribe();
    }
  }

}

/**
 * Example usage:
 *
 * @example
 * <input type="text" [restrict]="['integer', 'lowerLetters']" />
 */

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "label": "note",
      "name": "note",
      "rowspan": 1,
      "type": "textarea",
      "validations": {
        "required": true
      }
    }
  ]
}

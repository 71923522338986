import { IdName, idNames } from '../common/id-name';

export enum EntityLabelTypes {
  marketSportName = 5,
  marketSportDescription = 10,
  marketGroup = 25,
  marketSuperGroup = 30,
  outcomeName = 40,
  outcomeBetSlipLabel = 45,
  outcomeWebSiteLabel = 50,
  specifierName = 60,
  specifierBetSlipLabel = 65,
  specifierWebSiteLabel = 70,
  season = 75
}

export namespace EntityLabelTypes {
  export function values(): Array<IdName<string>> {
    return idNames(EntityLabelTypes);
  }
}

<!--{{ subGeoInfosConfig.inputs | json}}-->

<!--<ng-template #emptyTpl let-tab>
<div class="alert alert-warning m-5" role="alert">
  <strong>Empty!</strong> please add {{tab.value}} address
</div>
</ng-template>-->

<ng-template #staticTpl let-geoInfo>
  <div class="grid">
    @if (geoInfo.country.name) {
      <div class="col-12 md:col-6">
        <label>{{'country' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.country.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (geoInfo.region.name) {
      <div class="col-12 md:col-6">
        <label>{{'region' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.region.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (geoInfo.province.name) {
      <div class="col-12 md:col-6">
        <label>{{'province' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.province.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (geoInfo.city.name) {
      <div class="col-12 md:col-6">
        <label>{{'city' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.city.name" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (geoInfo.address) {
      <div class="col-12 md:col-6">
        <label>{{'city' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.address" [disabled]="true" pInputText>
        </div>
      </div>
    }
    @if (geoInfo.zipCode) {
      <div class="col-12 md:col-6">
        <label>{{'city' | translate}}</label>
        <div class="p-inputgroup">
          <input [(ngModel)]="geoInfo.zipCode" [disabled]="true" pInputText>
        </div>
      </div>
    }
  </div>
</ng-template>

<ng-template #fillTpl let-geoInfo>
  <div class="row m-0 py-2">
    <ng-container [formGroup]="geoInfo">
      <div class="grid">

        @for (input of subGeoInfosConfig.inputs; track input) {
          @switch (input.name) {
            @case ('country') {
              @if (!input.empty) {
                <div class="col-12">
                  <label class="req">{{input.label | translate}}</label>
                  <div class="p-inputgroup">
                    <p-dropdown (ngModelChange)="setRegions($event)" [formControlName]="input.name"
                      [filterBy]="input.filter" [filter]="!!input.filter" [options]="countries"
                      [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                    optionLabel="name"></p-dropdown>
                  </div>
                </div>
              }
            }
            @case ('region') {
              @if (!input.empty && regions) {
                <div class="col-12">
                  <label class="req">{{input.label | translate}}</label>
                  <div class="p-inputgroup">
                    @if(getInput(input, !!regions?.length).type === 'select') {
                      <p-dropdown (ngModelChange)="setProvinces($event)" [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="regions"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
            @case ('province') {
              @if (!input.empty && provinces) {
                <div class="col-12">
                  <label class="req">{{input.label | translate}}</label>
                  <div class="p-inputgroup">
                    @if(getInput(input, !!provinces?.length).type === 'select') {
                      <p-dropdown (ngModelChange)="setCities($event)" [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="provinces"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
            @case ('city') {
              @if (!input.empty && cities) {
                <div class="col-12">
                  <label class="req">{{input.label | translate}}</label>
                  <div class="p-inputgroup">
                    @if(getInput(input, !!cities?.length).type === 'select') {
                      <p-dropdown [formControlName]="input.name"
                        [filterBy]="input.filter" [filter]="!!input.filter" [options]="cities"
                        [placeholder]="input.placeholder | translate" [showClear]="!input.noReset"
                      optionLabel="name"></p-dropdown>
                    } @else {
                      <input [formControlName]="input.name" pInputText/>
                    }
                  </div>
                </div>
              }
            }
            @case ('address') {
              <div class="col-12">
                <label>{{input.label | translate}}</label>
                <div class="p-inputgroup">
                  <input [formControlName]="input.name" pInputText>
                </div>
              </div>
            }
            @case ('zipCode') {
              <div class="col-12">
                <label>{{input.label | translate}}</label>
                <div class="p-inputgroup">
                  <input [formControlName]="input.name" pInputText>
                </div>
              </div>
            }
          }
        }

      </div>
    </ng-container>
  </div>
</ng-template>

<ng-container [ngTemplateOutletContext]="{$implicit: readOnly ? geoInfos.value : geoInfos}" [ngTemplateOutlet]="readOnly ? staticTpl: fillTpl"></ng-container>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IFixtureOrder, IFixtureOrderUpdateRequest } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FixtureOrderService {
  readonly url = `${this.config.api.sportsbook}/fixtures`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  update(data: IFixtureOrderUpdateRequest): Observable<IFixtureOrder> {
    return this.http.put<IFixtureOrder>(`${this.url}/${data.parentFixtureId}/orders`, data);
  }

}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Ages, ITicket, ITicketSearchFilters, Pager } from '@bs/models';
import { BaseResolver, DateFactoryService } from '@bs/services';
import { Observable, of } from 'rxjs';

import { TicketService } from '../services/ticket.service';

/**
 * Resolver is returning us the sports tickets items when using data-table-builder component
 */
@Injectable()
export class TicketsResolver extends BaseResolver  {
  /**
   * The constructor
   * @param ticketService
   * @param {DateFactoryService} dfs public variable
   */
  constructor(private ticketService: TicketService, public dfs: DateFactoryService) {
    super(dfs);
  }

  /**
   * the resolve function retrieves us the data needed to activate for the requested route
   * @param route
   */
  resolve(route: ActivatedRouteSnapshot): Observable<Pager<ITicket>> {
    if (route.data.stopResolve && !route.queryParamMap.keys.length) {
      return of({
        list: [],
        count: 0,
      });
    }

    const filters: ITicketSearchFilters = {
      p: route.queryParams.p || 1,
      pp: route.queryParams.pp || 10,
      from: this.dfs.toTz(Ages.today),
      to: this.dfs.toTz(Ages.today, true),
      ...this.remap(route.queryParamMap)
    }
    return this.ticketService.search(filters);
  }
}

import { CurrencyConversion } from '../common/currency';
import { IdName, idNames, keyValues } from '../common/id-name';

export interface IPromoBonusSportsBook {
  realBonus: IPromoBonusSportsBookReal[];
  freeBets: IPromoBonusSportsBookFreeBet[];
}

export interface IPromoBonusSportsBookReal {
  bonusAccreditationId: number;
}

export interface IPromoBonusSportsBookFreeBet {
  bonusAccreditationId: number;
}

export interface IPromoBonusCasino {
  reals: IPromoBonusReal[];
}

export interface IPromoBonusReal {
  id: number;
}

// old from here
export interface SportbookFreebet {
  bonusAccreditationId: number;
  amount: number;
  spendability: Spendability[];
}

export interface SportbookRealBonus {
  bonusAccreditationId: number;
  availableBalance: number;
  spendability?: Spendability[];
}

export interface BonusSportsbook {
  realBonus: SportbookRealBonus[];
  freeBets: SportbookFreebet[];
}

export interface BonusCasino {
  realBonus: BonusReal[];
}

export interface BonusReal extends CurrencyConversion {
  id: number;
  outModel: string;
  typeGameId: number;
  bonusStatusId: number;
  bonusStatus: string;
  startDate: string;
  endDate: string;
  amount: number;
  amountAvailable: number;
  amountWagering: number;
  amountPlayed: number;
  amountToCredited: number;
  isWagering: true;
}

interface Spendability {
  key: string;
  typedValue: TypedValue;
}

interface TypedValue {
  dataType: string;
  value: number;
}

export enum bonusModelEnum {
  sportsBookRealBonus = 1,
  casinoRealBonus = 2,
  realBonusSportsBookDeprecated = 3,
  sportsBookRealBonusWagering = 4,
  moneyCashBack = 7,
  sportsBookFreeBets = 8,
  bonusRedeemed = 10,
  casinoRealBonusWagering = 20,
  crashGames = 30,
  crashGamesWagering = 31,
}

export namespace bonusModelEnum {
  export function values(): Array<{ key: number, value: string }> {
    return keyValues(bonusModelEnum);
  }
}

export namespace BonusModels {
  export function values(): Array<IdName<string>> {
    return idNames(bonusModelEnum);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentConfig, IMarketGroup, IMarketGroupSearchFilters, Pager } from '@bs/models';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketGroupService {
  readonly url = `${this.config.api.sportsbook}/market-groups`;

  constructor(private config: EnvironmentConfig, private http: HttpClient) {
  }

  get(itemId: number): Observable<IMarketGroup> {
    return this.http.get<IMarketGroup>(`${this.url}/${itemId}`);
  }

  getAll(betOfferId: number, sportId?: number): Observable<Array<IMarketGroup>> {
    return this.http.get<Array<IMarketGroup>>(`${this.url}`, {params: {bId: betOfferId.toString(), sId: sportId.toString()}});
  }

  createOrUpdate(data: IMarketGroup): Observable<IMarketGroup> {
    return data.id ? this.http.put<IMarketGroup>(`${this.url}/${data.id}`, data) : this.http.post<IMarketGroup>(this.url, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/${id}`);
  }

  search(filters: IMarketGroupSearchFilters): Observable<Pager<IMarketGroup>> {
    return this.http.post<Pager<IMarketGroup>>(`${this.url}/search`, filters);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { ICurrency } from '@bs/models';
import { AppSettingsService, CatalogService } from '@bs/services';

/**
 * this pipe converts the currency value to a string of symbol if it has any, and the amount value
 */
@Pipe({
  name: 'displayCurrencyConversion'
})
export class DisplayCurrencyConversionPipe implements PipeTransform {

  /**
   * display currency
   */
  displayCurrency: ICurrency;

  /**
   * The constructor gets us the display currency
   */
  constructor(private appSettingsService: AppSettingsService, private catalog: CatalogService) {
    this.displayCurrency = this.appSettingsService.settings?.displayCurrency || this.catalog.currentCurrency;
  }

  /**
   * returns us the display currency in string
   * @param amount {number} the currency value
   * @param rate {number} optionally the rate will be calculated for that currency amount
   * @param fromCent {boolean} is from cents
   */
  transform(amount: number, rate?: number, fromCent = false): unknown {

    const convertedAmount = fromCent ? amount * (rate || 1) : amount * (rate || 1) / 100;

    return `${(this.displayCurrency?.symbol ? this.displayCurrency.symbol : '')} ${(convertedAmount).toFixed(2)}`;
  }
}

import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from './is-present.validator';


export const urlFormat: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v: string = control.value;

  const  URL_REGEXP =  /^(?:http[s]?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;

  return URL_REGEXP.test(v) ? null : {'url-format': true};
};

import { IAuthor } from '../common/author';
import { CurrencyConversion } from '../common/currency';
import { IdName, idNames } from '../common/id-name';
import { IPaymentMethod, IPaymentMethodExternalTransaction } from './payment-method';

export enum AdditionalDataTypes {
  promoCode = 22,
}

export namespace AdditionalDataTypes {
  export function values(): Array<IdName<string>> {
    return idNames(AdditionalDataTypes);
  }
}

export interface IWalletMovementSearchRequest {
  walletTypeId: number;
  accountId?: number;
  displayCurrencyId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IWalletMovementTotal {
  amount: number;
  notWithdrawable: number;
  withdrawable: number;
  bonus: number;
}

export interface IAccountBase {
  id: number;
  username: string;
  typeId: number;
}

export interface IWalletMovementBase extends CurrencyConversion {
  id: number;
  walletTypeId: number;
  parentId?: number;
  account: IAccountBase;
  causal: IWalletMovementCausal;
  date: Date;
  movementStatusId: number;
  amounts: IWalletAmount; // got an error
  tpl?: string;

  // requestAmount?: number;
  // lostAmount?: number;
  // isGameSession: boolean;
  // isWithdrawal: boolean;
  // isNetworkTransfer: boolean;
  // additionalData?: Array<{ typeId: number, type: string, data: string }>;
  // notes?: Array<IWalletMovementNote>;
}

export interface IWalletMovement extends IWalletMovementBase {
  addInfos?: Array<IWalletMovementAdditionalData>;
  notes?: Array<IWalletMovementNote>;
  gameSession?: IWalletGameSession;
  externalTransactions?: Array<IPaymentMethodExternalTransaction>
}

export interface IWalletMovementNote {
  author: IAuthor;
  id: number;
  note: string;
}

export interface IWalletMovementAdditionalData {
  id: number;
  name: string;
  value: string;
}

export interface IWalletMovementCausal {
  id: number;
  name: string;
  causalGroup: IWalletMovementCausaLGroup;
  isManual: boolean;
  isWithdrawal: boolean;
  isWithdrawalApproved: boolean;
  isRollback: boolean;
  isGameSession: boolean;
  paymentMethod?: IPaymentMethod
}

export interface IWalletMovementCausaLGroup {
  id: number;
  name: string;
}

export interface IWalletAmount {
  amount: number;
  notWithdrawable: number;
  withdrawable: number;
  bonus: number;
  overdraft: number;
  currencySymbol: string;
  currencyCode: string;
  subunit: number;
  rate: number;
}

export interface IWalletMovementCreateRequest {
  causalId: number;
  accountId: number;
  walletSectionId?: number;
  amount: number;
  note?: string;
}


export interface IWalletGameSession {
  // id: number;
  startSession: string;
  endSession: string;
  sessionDebit: number;
  sessionCredit: number;
  sessionBonus: number;
  sessionCount: number;
}

export interface IWalletGameSessionDetailsSearchRequest {
  movementId: number;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  orderBy?: string;
  orderByDirection?: string;
}

export interface IWalletGameSessionDetails {
  id: number;
}

{
  "$schema": "../schema.json",
  "inputs": [
    {
      "type": "text",
      "label": "name",
      "name": "name"
    }
  ]
}

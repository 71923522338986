{
  "$schema": "../schema.json",
  "inputs": [
    {
      "autoselect": true,
      "label": "bookmakers",
      "name": "bookmakers",
      "type": "select",
      "noReset": true,
      "validations": {
        "required": true
      }
    },
    {
      "name": "day",
      "label": "day",
      "type": "select",
      "autoselect": true,
      "noReset": true,
      "validations": {
        "required": true
      },
      "values": [
        {
          "id": 1,
          "name": "monday"
        }
      ]
    }
  ]
}
